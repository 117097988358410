import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { FiCheckCircle } from 'react-icons/fi'
import { useTranslation, useTypedSelector } from '../../../../../hooks'
import { formatDateWithTime } from '../../../../../helpers/date'
import { FiDownload } from 'react-icons/fi'
import { AiFillExclamationCircle } from 'react-icons/ai'
import CommentModal from '../CommentModal'
import ReopenCaseModal from './ReopenCaseModal'
import ExportCaseModal from './ExportCaseModal'

const ClosedCaseBox = styled.div`
    width: 100%;
    height: 8.2rem;
    background: ${({ theme }) => theme.primary};
    border-radius: 0.5rem;
    margin-bottom: 1.4rem;
    margin-top: 2rem;
`

const ClosedCaseBoxFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.white};
    height: 100%;
`

const SolvedTextBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 2.8rem;
`

const SolvedText = styled.h4`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.white};
    margin-left: 1rem;
    display: flex;
    align-items: center;
`

const CloseCaseItemContent = styled.div`
    margin: 0 2.8rem;
`

const CloseCaseBoxItem = styled.div`
    display: flex;
`

const ClosedInformation = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.white};
    margin-top: 0.4rem;
`

const ClosedInformationHeading = styled.div`
    font-size: 1rem;
    color: ${({ theme }) => `${theme.grey}80`};
    display: flex;
`

const CheckIcon = styled(FiCheckCircle)`
    color: ${({ theme }) => theme.white};
    font-size: 1.9rem;
`

const DownloadIcon = styled(FiDownload)`
    margin-left: 1rem;
    stroke-width: 4;
    cursor: pointer;
`

const ReopenContainer = styled.div`
    margin: 0 2.8rem;
`

const ReopenButton = styled.button`
    text-align: center;
    background-color: ${({ theme }) => theme.blue};
    border-radius: 1.5rem;
    border: none;
    width: auto;
    height: 2.7rem;
    color: white;
    font-weight: 700;
    padding: 0 1rem;
    cursor: pointer;
`

const AutomaticDeletionTime = styled.p`
    color: ${({ theme }) => theme.red};
    font-size: 1.2rem;
`

const ExclamationIcon = styled(AiFillExclamationCircle)`
    font-size: 1.5rem;
    margin-left: 0.5rem;
`

const CommentBox = styled.div<CommentBoxProps>`
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50rem;
    overflow: ${(isOverFlow) => (isOverFlow ? 'hidden' : 'visible')};
`

const Readmore = styled.div`
    display: flex;
    align-items: flex-end;
    color: ${({ theme }) => `${theme.grey}80`};
    margin: 0;
    cursor: pointer;
`

const Tooltip = styled.div`
    position: relative;

    .tooltiptext {
        visibility: hidden;
        width: 9.7rem;
        background-color: ${({ theme }) => theme.lighterGrey};
        color: ${({ theme }) => theme.primary};
        text-align: center;
        border-radius: 0.5rem;
        padding: 0.6rem 0.8rem;
        font-size: 1.2rem;
        text-align: left;
        margin-bottom: 1rem;

        // Position the tooltip
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);

        // tooltip arrow
        &:after {
            content: ' ';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -0.5rem;
            border-width: 0.5rem;
            border-style: solid;
            border-color: ${({ theme }) => theme.lighterGrey} transparent
                transparent transparent;
        }
    }

    &:hover .tooltiptext {
        visibility: visible;
    }
`

interface CommentBoxProps {
    isOverFlow: boolean
}

const ClosedOrForwardedBox: React.FC = () => {
    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const translation = useTranslation()
    const ref = useRef<HTMLDivElement>(null)

    const [isOverflowing, setIsOverflowing] = useState(false)
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false)
    const [isReopenModalOpen, setIsReopenModalOpen] = useState(false)
    const [isExportCaseModalOpen, setIsExportCaseModalOpen] = useState(false)

    useEffect(() => {
        if (ref.current) {
            const isContentOverflowing =
                ref.current.scrollWidth > ref.current.clientWidth
            setIsOverflowing(isContentOverflowing)
        }
    }, [openReport.closed_comment])

    return (
        <React.Fragment>
            {openReport?.closed_at && (
                <ClosedCaseBox>
                    <ClosedCaseBoxFlex>
                        <CloseCaseBoxItem>
                            <SolvedTextBox>
                                <CheckIcon />
                                <SolvedText>
                                    {openReport.status === 'Solved'
                                        ? translation.caseworkerOpenReport
                                              .solved
                                        : translation.caseworkerOpenReport
                                              .denied}
                                </SolvedText>
                            </SolvedTextBox>
                            <CloseCaseItemContent>
                                <ClosedInformationHeading>
                                    {openReport?.status !== 'Forwarded'
                                        ? translation.caseClosedViewPartner
                                              .closeDate
                                        : translation.caseworkerOpenReport
                                              .forwardedAt}
                                </ClosedInformationHeading>
                                <ClosedInformation>
                                    {openReport?.closed_at &&
                                        formatDateWithTime(
                                            openReport.closed_at
                                        )}
                                </ClosedInformation>
                            </CloseCaseItemContent>

                            {openReport?.status !== 'Forwarded' && (
                                <CloseCaseItemContent>
                                    <ClosedInformationHeading>
                                        {
                                            translation.caseClosedViewPartner
                                                .comment
                                        }
                                    </ClosedInformationHeading>
                                    <ClosedInformation>
                                        <CommentBox
                                            isOverFlow={isOverflowing}
                                            ref={ref}
                                        >
                                            {openReport?.closed_comment ? (
                                                openReport.closed_comment
                                            ) : (
                                                <em>
                                                    {
                                                        translation
                                                            .caseClosedViewPartner
                                                            .noComment
                                                    }
                                                </em>
                                            )}
                                        </CommentBox>
                                    </ClosedInformation>
                                    {isCommentModalOpen && (
                                        <CommentModal
                                            closeModal={() =>
                                                setIsCommentModalOpen(false)
                                            }
                                        />
                                    )}
                                </CloseCaseItemContent>
                            )}

                            {isOverflowing && (
                                <Readmore
                                    onClick={() => setIsCommentModalOpen(true)}
                                >
                                    {translation.caseClosedViewPartner.readMore}
                                </Readmore>
                            )}
                        </CloseCaseBoxItem>

                        <CloseCaseBoxItem>
                            <CloseCaseItemContent>
                                <ClosedInformationHeading>
                                    {
                                        translation.caseClosedViewPartner
                                            .exportCase
                                    }
                                </ClosedInformationHeading>
                                <ClosedInformation
                                    onClick={() =>
                                        setIsExportCaseModalOpen(true)
                                    }
                                >
                                    {translation.addNewClientPartner.download}
                                    <DownloadIcon />
                                </ClosedInformation>
                                {isExportCaseModalOpen && (
                                    <ExportCaseModal
                                        closeModal={() =>
                                            setIsExportCaseModalOpen(false)
                                        }
                                    />
                                )}
                            </CloseCaseItemContent>
                            {isCommentModalOpen && (
                                <CommentModal
                                    closeModal={() =>
                                        setIsCommentModalOpen(false)
                                    }
                                />
                            )}

                            <CloseCaseItemContent>
                                <ClosedInformationHeading>
                                    {
                                        translation.caseClosedViewPartner
                                            .automaticDelation
                                    }
                                    <Tooltip>
                                        <ExclamationIcon />
                                        <Tooltip className="tooltiptext">
                                            {
                                                translation
                                                    .caseClosedViewPartner
                                                    .caseWillBePermanentlyDeleted
                                            }
                                        </Tooltip>
                                    </Tooltip>
                                </ClosedInformationHeading>
                                <AutomaticDeletionTime>
                                    {openReport.deletion_date.days} days{' '}
                                    {openReport.deletion_date.hours} hours
                                </AutomaticDeletionTime>
                            </CloseCaseItemContent>
                            <ReopenContainer>
                                <ReopenButton
                                    onClick={() => setIsReopenModalOpen(true)}
                                >
                                    {
                                        translation.singleClientViewPartner
                                            .reOpenCase
                                    }
                                </ReopenButton>
                                {isReopenModalOpen && (
                                    <ReopenCaseModal
                                        closeModal={() =>
                                            setIsReopenModalOpen(false)
                                        }
                                    />
                                )}
                            </ReopenContainer>
                        </CloseCaseBoxItem>
                    </ClosedCaseBoxFlex>
                </ClosedCaseBox>
            )}
        </React.Fragment>
    )
}

export default ClosedOrForwardedBox

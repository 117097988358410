import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from '../../../../hooks'
import { HeadingPrimary } from '../../../styled/typography'
import { NextButton } from '../../buttons'

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 12rem;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
`

const ButtonContainer = styled.div`
    padding: 9rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.8rem;m;
`

interface EmployeeCountButtonProps {
    active: boolean
}

const EmployeeCountButton = styled.div<EmployeeCountButtonProps>`
    height: 4.3rem;
    width: 10.3rem;
    border-radius: 2rem;
    background: ${({ theme, active }) =>
        active ? theme.black : theme.lightBlue};
    color: ${({ theme, active }) => (active ? theme.white : theme.primary)};
    font-size: 1.4rem;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

interface NumberOfEmployeesFrameProps {
    setCurrentPageNumber: (pageNumber: number) => void
    updateOrganization: (key: string, value: string) => void
}

const NumberOfEmployeesFrame: React.FC<NumberOfEmployeesFrameProps> = ({
    setCurrentPageNumber,
    updateOrganization,
}) => {
    const translation = useTranslation()

    const [activeButton, setActiveButton] = useState<null | string>(null)

    const employeeCountButtons = ['1-49', '50-249', '250-499', '500-999']

    const handleOnClick = (count: string) => {
        setActiveButton(count)
        updateOrganization('employeeCount', count)
    }

    return (
        <Container>
            <HeadingPrimary>
                {translation.reusable.numberOfEmployees}
            </HeadingPrimary>
            <ButtonContainer>
                {employeeCountButtons.map((button) => (
                    <EmployeeCountButton
                        onClick={() => handleOnClick(button)}
                        active={activeButton === button}
                        key={button}
                    >
                        {button}
                    </EmployeeCountButton>
                ))}
            </ButtonContainer>
            <NextButton
                handleOnClick={() => setCurrentPageNumber(4)}
                isActive={activeButton ? true : false}
            />
        </Container>
    )
}

export default NumberOfEmployeesFrame

import { Redirect, Route } from 'react-router-dom'
import { useTypedSelector } from '../../hooks'

const ProtectedWhistleblowerRoute = ({ component: Component, ...rest }) => {
    const { isWhistleblowerLoggedIn } = useTypedSelector(
        (state) => state.whistleblower
    )
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isWhistleblowerLoggedIn) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/loading',
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    )
                } else {
                    return <Component {...props} />
                }
            }}
        />
    )
}

export default ProtectedWhistleblowerRoute

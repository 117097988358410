import React from 'react'
import { ThemeProvider as Provider } from 'styled-components'
import { useTheme } from '../hooks/useTheme'

type ThemeProviderProps = {}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    const theme = useTheme()

    return <Provider theme={theme}>{children}</Provider>
}

export default ThemeProvider

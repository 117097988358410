import { useHistory } from 'react-router'
import styled from 'styled-components'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import { Card, CardHeader } from '../../../../styled/cards'

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

const Modal = styled.div`
    height: 30rem;
    width: 50rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`

const CancelButton = styled.div`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
    padding: 1rem;
`

const Heading = styled.h4`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    padding-top: 5rem;
`

const Email = styled.h3`
    font-size: 2rem;
    font-weight: 700;
    color: ${({ theme }) => theme.primary};
    padding-top: 2rem;
    padding-bottom: 5rem;
`

const CardContent = styled.div`
    margin: 0 1.4rem;
`

const Button = styled.div`
    height: 5rem;
    width: 100%;
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
    font-size: 1.6rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
`

interface SendEmailModalProps {
    closeModal: () => void
    email: string
    organizationId: number | null
}

const SendEmailModal: React.FC<SendEmailModalProps> = ({
    closeModal,
    email,
    organizationId,
}) => {
    const { organization } = useTypedSelector((state) => state.caseworker)

    const { sendClientInformationEmail } = useActions()

    const history = useHistory()

    const translation = useTranslation()
    const handleSendEmail = async () => {
        if (!organizationId) {
            return
        }

        closeModal()

        const success: any = await sendClientInformationEmail(
            organization.id,
            organizationId
        )

        if (success) {
            history.replace('/clients')
        }
    }
    return (
        <ModalContainer>
            <Modal>
                <Card>
                    <CardHeader>
                        {translation.addNewClientPartner.popUpSendEmail}
                        <CancelButton onClick={closeModal}>
                            {translation.reusable.cancel}
                        </CancelButton>
                    </CardHeader>
                    <CardContent>
                        <Heading>
                            {translation.addNewClientPartner.popUpText}
                        </Heading>
                        <Email>{email}</Email>
                        <Button onClick={handleSendEmail}>
                            {
                                translation.addNewClientPartner
                                    .popUpButtonSendInformation
                            }
                        </Button>
                    </CardContent>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default SendEmailModal

import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import { Card, CardHeader } from '../../../../styled/cards'
import Messages, {
    MessageInterface,
} from '../../../../reusable/messages/Messages'
import MessageForm from '../../../../reusable/message-form/MessageForm'
import { format } from 'date-fns'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import NavigationArrowLink from '../../../../reusable/buttons/NavigationArrowLink'
import { useHistory } from 'react-router'
import config from '../../../../../config'
import axios from 'axios'
import TranslateButton from '../../../../reusable/buttons/translate-button/TranslateButton'
import Spinner from '../../../../layout/spinner/Spinner'
import ReportTimelineCard from '../components/ReportTimelineCard'
import { getReceiptAndFeedbackDates } from '../../../../../helpers/data/getReceiptAndFeedbackDates'
import InfoCard from '../components/InfoCard'
import FilesCard from '../components/FilesCard'
import DescriptionCard from '../components/DescriptionCard'
import { StreamedFile } from '../../new-case/NewCasePage'

const Container = styled.div`
    padding: 4rem;
    width: 100%;

    @media (max-width: 600px) {
        padding: 2rem;
    }
`
const LogoutButton = styled.div`
    padding-top: 4rem;
    padding-left: 4rem;

    @media screen and (max-width: 600px) {
        padding-left: 1.5rem;
    }
`

const Heading = styled.h3`
    font-size: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
`

const FlexContainer = styled.div`
    display: flex;
    gap: 2.2rem;
    padding-top: 2.4rem;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`

const LeftContainer = styled.div`
    width: 43%;

    @media (max-width: 1000px) {
        width: 100%;
        flex-direction: column;
        row-gap: 2rem;
    }
`

const RightContainer = styled.div`
    width: 57%;

    @media (max-width: 1000px) {
        width: 100%;
    }
`

const MessagesLoading = styled.div`
    height: 45rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const DinamicWrapper = styled.div`
    @media (max-width: 1300px) and (min-width: 1000px), (max-width: 600px) {
        flex-wrap: wrap;
        & > :nth-child(2) {
            margin: 1rem 0 0 0;
        }
    }
`

const RowWrap = styled.div`
    display: flex;
    justify-content: space-between;

    & > :nth-child(2) {
        margin-left: 1rem;
    }

    @media (max-width: 1300px) and (min-width: 1000px), (max-width: 600px) {
        flex-wrap: wrap;
        & > :nth-child(2) {
            margin: 1rem 0 0 0;
        }
    }

    @media (max-width: 1300px) {
        justify-content: left;
    }
`

const Report: React.FC = () => {
    const componentMounted = useRef(true)
    const [message, setMessage] = useState('')
    const [files, setFiles] = useState<StreamedFile[]>([])
    const [isTranslateBtnActive, setIsTranslateBtnActive] = useState(false)
    const [translatedMessages, setTranslatedMessages] = useState<
        MessageInterface[] | null
    >([])

    const { selectedTranslation } = useTypedSelector(
        (state) => state.translation
    )
    const [defaultLanguage, setDefaultLanguage] = useState(selectedTranslation)

    const {
        whistleblowerLoading,
        openReport,
        messages,
        whistleblowerError,
        organizationalCode,
    } = useTypedSelector((state) => state.whistleblower)
    const { translateTextLoading, translateTextError } = useTypedSelector(
        (state) => state.translateText
    )

    const { translateToDefaultLang } = useActions()

    const {
        leaveReport,
        addMessage,
        whistleblowerShowLatestMessage,
        refreshCaseAfterForward,
    } = useActions()

    const translation = useTranslation()

    const history = useHistory()

    const handleDownloadFile = async (file: any) => {
        const { apiUrl } = config

        const res: any = await axios.get(
            `${apiUrl}/v1/whistleblower/incidents/${openReport.whistleblower_code}/download/${file.id}`,
            {
                method: 'GET',
                responseType: 'arraybuffer',
                // @ts-ignore
                Accept: 'application/pdf',
            }
        )

        // @ts-ignore
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file.name
        link.click()
    }

    useEffect(() => {
        if (openReport) {
            // @ts-ignore
            window.Echo.channel(`case-talk-${openReport.talk_id}`).listen(
                '.message',
                (message: any) => whistleblowerShowLatestMessage(message)
            )

            // @ts-ignore
            window.Echo.channel(
                `incident-${openReport.whistleblower_code}`
            ).listen('.incident-forward', (report: any) => {
                refreshCaseAfterForward(report)
            })
        }

        // @ts-ignore
        return () => {
            if (openReport) {
                // @ts-ignore
                window.Echo.leave(`case-talk-${openReport.talk_id}`)
            }
        }

        // eslint-disable-next-line
    }, [openReport])

    const formatDate = () => {
        const dateInstance = new Date(openReport?.created_at)
        return format(dateInstance, 'dd.MM.yyyy')
    }

    const startDate = new Date(openReport.created_at)
    const { receiptDate, feedbackDate, repliedWithinWeek } =
        getReceiptAndFeedbackDates(openReport, messages)

    useEffect(() => {
        return () => {
            leaveReport()
        }
        // eslint-disable-next-line
    }, [])

    const handleAddMessage = async () => {
        await addMessage(
            openReport.id,
            openReport.whistleblower_code,
            message,
            files
        )
    }

    const isMsgFromCaseworker = messages.some(
        (message) => message.source === 'caseworker'
    )

    const translateMessagesFromCaseworker = async () => {
        try {
            const translatedContent = await Promise.all(
                messages.map(async (message) => {
                    if (message.source === 'caseworker') {
                        const translatedContent = await translateToDefaultLang(
                            message.content,
                            defaultLanguage,
                            'whistleblower',
                            organizationalCode
                        )
                        return {
                            ...message,
                            content: translatedContent.toString(),
                        }
                    }

                    return message
                })
            )
            setTranslatedMessages(translatedContent)
        } catch (error) {
            translateTextError && setTranslatedMessages(messages)
        }
    }

    //set new default language each time selectedTranslation value has been changed
    useEffect(() => {
        setDefaultLanguage(selectedTranslation)
    }, [selectedTranslation, defaultLanguage])

    //remove translated messages and make button inactive each time when new default language is selected
    useEffect(() => {
        if (isTranslateBtnActive && defaultLanguage !== selectedTranslation) {
            setIsTranslateBtnActive(false)
            setTranslatedMessages(null)
        }
    }, [isTranslateBtnActive, defaultLanguage, selectedTranslation])

    //do not make API request if number of messages is not changed
    useEffect(() => {
        const isNewMessageReceived =
            translatedMessages?.length !== messages.length

        if (isTranslateBtnActive && isNewMessageReceived) {
            translateMessagesFromCaseworker()
        }

        return () => {
            componentMounted.current = false
        }
        // eslint-disable-next-line
    }, [isTranslateBtnActive])

    //display original messages instead of translated when new message has been sent
    useEffect(() => {
        const isNewMessageSent =
            translatedMessages && messages.length > translatedMessages?.length

        if (isTranslateBtnActive && isNewMessageSent) {
            setIsTranslateBtnActive(false)
        }
        // eslint-disable-next-line
    }, [translatedMessages, messages])

    return (
        <>
            {whistleblowerError ? (
                <>Something went wrong please try again later</>
            ) : (
                <>
                    <LogoutButton
                    // style={{
                    //     paddingTop: '4rem',
                    //     paddingLeft: '4rem',
                    // }}
                    >
                        <NavigationArrowLink
                            onClickCallback={() =>
                                history.push(
                                    `/organization/${organizationalCode}/whistleblower`
                                )
                            }
                            linkText={translation.accessCaseFlow.logoutOfReport}
                        />
                    </LogoutButton>
                    <Container>
                        <Heading> {openReport.title}</Heading>
                        <FlexContainer>
                            <LeftContainer>
                                <RowWrap>
                                    <InfoCard
                                        uuid={openReport.uuid}
                                        date={formatDate()}
                                        caseType={openReport.case_type}
                                        assignedTo={openReport.assigned_to}
                                    />
                                    {openReport.files.length > 0 && (
                                        <FilesCard
                                            report={openReport}
                                            handleDownloadFile={
                                                handleDownloadFile
                                            }
                                        />
                                    )}
                                </RowWrap>
                                <DinamicWrapper>
                                    <ReportTimelineCard
                                        dateOne={startDate}
                                        dateTwo={receiptDate}
                                        dateThree={feedbackDate}
                                        repliedWithinWeek={repliedWithinWeek}
                                        closedAt={openReport.closed_at}
                                    />
                                    <DescriptionCard
                                        description={openReport.description}
                                        openReportVoiceRecording={
                                            openReport.voice_recording
                                        }
                                    />
                                </DinamicWrapper>
                            </LeftContainer>
                            <RightContainer>
                                <Card>
                                    <CardHeader>
                                        {translation.reusable.messages}
                                    </CardHeader>
                                    {translateTextLoading ? (
                                        <MessagesLoading>
                                            <Spinner />
                                        </MessagesLoading>
                                    ) : (
                                        <>
                                            <Messages
                                                messages={messages}
                                                userType="reporter"
                                                height={
                                                    isMsgFromCaseworker
                                                        ? '38.8rem'
                                                        : '45rem'
                                                }
                                                isTranslateBtnActive={
                                                    isTranslateBtnActive
                                                }
                                                translatedMessages={
                                                    defaultLanguage ===
                                                    selectedTranslation
                                                        ? translatedMessages
                                                        : null
                                                }
                                            />
                                            {isMsgFromCaseworker && (
                                                <TranslateButton
                                                    isActive={
                                                        isTranslateBtnActive
                                                    }
                                                    handleClick={() =>
                                                        setIsTranslateBtnActive(
                                                            !isTranslateBtnActive
                                                        )
                                                    }
                                                    isLoading={
                                                        translateTextLoading
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                    <MessageForm
                                        message={message}
                                        setMessage={setMessage}
                                        files={files}
                                        setFiles={setFiles}
                                        handleAddMessage={handleAddMessage}
                                        isLoading={whistleblowerLoading}
                                    />
                                </Card>
                            </RightContainer>
                        </FlexContainer>
                    </Container>
                </>
            )}
        </>
    )
}

export default Report

import { useHistory } from 'react-router'
import { useTranslation, useTypedSelector } from '../../../../../hooks'
import NavigationArrowLink from '../../../../reusable/buttons/NavigationArrowLink'
import {
    Container,
    BackToHomeScreen,
    PolicyContainer,
    Heading,
    Paragraph,
    NavigationLink,
    NavigationLinkContainer,
    NavigationLinkDivider,
    Li,
} from '../styled'

const FinishPolicy = () => {
    return (
        <PolicyContainer>
            <Paragraph>
                XXL on sitoutunut noudattamaan mahdollisimman korkeita
                standardeja läpinäkyvyyden, rehellisyyden ja vastuunalaisuuden
                suhteen kaikessa toiminnassamme. Panostamme avoimen puhumisen
                kulttuurin luomiseen, jossa kaikki tuntevat olonsa turvalliseksi
                ja voivat esittää kysymyksiä tai nostaa esiin huolenaiheita.
                Käytännössä todetaan kaikkien mahdollisuus ilmoittaa
                havaitsemistaan huolenaiheista tai valituksista, jotka koskevat
                sovellettavan lainsäädännön rikkomuksia, moitittavia olosuhteita
                XXL:n yrityksissä tai työpaikoilla tai yhteiskunnassa yleisesti
                hyväksyttyjen eettisten normien rikkomuksia.
            </Paragraph>
            <Paragraph>
                Ilmoitukset epäillyistä huolenaiheista tai valituksista voidaan
                tehdä tämän XXL:n ilmoituskanavan kautta. Ilmoituskanavaa
                tukevaa IT-järjestelmää operoi Walor ApS, joka on riippumaton
                kolmas osapuoli, joka takaa järjestelmän turvallisuuden.
                Järjestelmä ei kirjaa IPosoitteita tai laitekoodeja ja salaa
                kaikki käsitellyt tiedot. Vain XXL:n ulkopuolisella eettisyys-
                ja compliance-vastaavalla (ECO), joka käsittelee kaikki tehdyt
                ilmoitukset, on pääsy ilmoituksiin.
            </Paragraph>
            <Paragraph>
                Jos epäilet, pitäisikö kokemus, havainto tai epäily ilmoittaa,
                sinua kannustetaan tekemään niin. Kaikkiin raportteihin
                vastataan ja ne käsitellään.
            </Paragraph>
            <Paragraph>
                Kaikkia, jotka ilmoittavat edellä kuvatuista asioista hyvässä
                uskossa, suojellaan kaikilta kostotoimenpiteiltä. Tämä
                tarkoittaa, että ketään hyvässä uskossa valituksia tai epäkohtia
                ilmoittavaa ei rangaista tai sanktioida, vaikka ilmoitettu asia
                osoittautuisikin vääräksi.
            </Paragraph>

            <Paragraph>
                Ilmoituksia voi tehdä kirjallisesti tai suullisesti; avoimesti
                tai anonyymisti. Kaikkia ilmoituksia käsitellään tiukan
                luottamuksellisesti sovellettavan lainsäädännön mukaisesti.
            </Paragraph>

            <Paragraph>
                Kaikki XXL:n ilmoituskanavaepäkäytäntöä tai XXL:n
                ilmoituskanavaa koskevat kysymykset voi osoittaa osoitteeseen
                compliance.officer@xxl.no.
            </Paragraph>
            <Heading>Ketkä voivat tehdä ilmoituksen?</Heading>
            <Paragraph>
                XXL:n käytäntö koskee laajaa henkilöjoukkoa, joilla on XXL:stä
                hankittua tietoa työsuhteen tai liikesuhteen kautta. Ilmoituksen
                voivat tehdä:
            </Paragraph>
            <ul style={{ fontSize: '1.4rem', marginBottom: '1rem' }}>
                <Li>Työntekijät.</Li>
                <Li>Entiset työntekijät (työsuhde on päättynyt)</Li>
                <Li>Kaikkien tasojen esihenkilöt</Li>
                <Li>Työnhakijat</Li>
                <Li>Palkalliset tai palkattomat harjoittelijat</Li>
                <Li>Vapaaehtoiset</Li>
                <Li>Yrittäjät</Li>
                <Li>Osakkeenomistajat</Li>
                <Li>Hallituksen jäsenet</Li>
                <Li>
                    Henkilöt, jotka työskentelevät XXL:n urakoitsijoiden,
                    alihankkijoiden, toimittajien ja alihankkijoiden valvonnassa
                    ja johdossa missään XXL-konsernin yrityksessä
                </Li>
                <Li>Asiakkaat</Li>
            </ul>
            <Heading>Mitkä asiat tulisi ilmoittaa - ja mitkä ei?</Heading>
            <Paragraph>
                Ilmoitus tulisi tehdä epäillyistä huolenaiheista ja
                valituksista, jotka koskevat sovellettavan lainsäädännön
                rikkomuksia, työehtosopimusten rikkomuksia, moitittavia
                olosuhteita XXL:n yrityksissä tai työpaikoilla, yhteiskunnassa
                yleisesti hyväksyttyjen eettisten normien rikkomuksia.
                Sovellettava lainsäädäntö kattaa kaikki lait, ei vain
                ilmoittajansuojelulakia. Ilmoita siis kaikista epäillyistä
                rikkomuksista kaikilla lain osa-alueilla.
            </Paragraph>
            <Paragraph>
                Seuraavia asioita ei pidetä ilmoittajanvelvollisuuden alaisina
                asioina eikä niitä raportoida tämän kanavan kautta: yksittäisen
                henkilön mielipiteet tai tyytymättömyys esimerkiksi palkkaan,
                pienet kollegoiden väliset erimielisyydet, eriävät mielipiteet,
                ammatilliset riidat jne. Myöskään yhden henkilön omiin
                olosuhteisiin liittyvä huoli tai valitus ei ole
                ilmoittajanvelvollisuutta, joka koskee vain häntä itseään, ei
                ole ilmoittamista edellyttävä asia, ellei se koske lain
                rikkomuksia, kuten syrjintää tai häirintää/kiusaamista.
                Tällaiset ilmoitukset katsotaan aina ilmoittamista
                edellyttäviksi asioiksi.
            </Paragraph>
            <Heading>Ilmoittajan suojelu</Heading>
            <Paragraph>
                XXL-konsernin työntekijöitä, jotka ilmoittavat edellä kuvatuista
                asioista hyvässä uskossa, suojellaan kaikilta
                kostotoimenpiteiltä. Tämä koskee myös työntekijöiden edustajia
                tai työsuojeluvaltuutettuja, jotka tukevat ilmoittajaa. Kaikkia
                ilmoittajan XXL-työtovereita, sukulaisia, jotka työskentelevät
                XXL:lle tai ovat sen sopimuskumppaneita. Yhteisöä, jonka
                omistaja, työntekijä tai muulla tavalla yhteydessä ilmoittaja
                on.
            </Paragraph>
            <Heading>Ilmoitusten käsittely</Heading>
            <Paragraph>
                Kaikki tehdyt ilmoitukset käsittellään eettisyys- ja
                compliance-vastaavan henkilön toimesta (ECO), joka on ainoa
                valtuuttettu elin suorittamaan tutkimuksia. Joissakin
                tapauksissa tutkimusta voidaan kuitenkin tehdä yhteistyössä
                paikallisen XXL-yrityksen Eettisyys & Compliance asiantuntijan
                (ECA):n kanssa, tai poikkeustapauksissa asianajajan tai
                tilintarkastajan toimesta.
            </Paragraph>
            <Heading>Ilmoituksen käsittely</Heading>
            <Paragraph>
                Ilmoituksen tehneille lähetetään vahvistus ilmoituksen
                vastaanottamisesta seitsemän päivän kuluessa. Ilmoituksen
                kohteena olevaa henkilöä tiedotetaan tapahtumasta kuukauden
                kuluttua siitä, kun ilmoituksen vastaanottamisvahvistus on
                lähetetty. Itse ilmoitusta ei luovuteta ilmoituksen kohteena
                olevalle henkilölle. Hänelle ei myöskään anneta mitään suoraa
                tai epäsuoraa tietoa, vaikka ilmoittaja ei olisi valinnut
                anonyymiä ilmoitustapaa.
            </Paragraph>
            <Paragraph>
                Eettisyys ja compliance- vastaava (ECO) suorittaa ilmoituksen
                alustavan tarkastuksen. Jos ilmoitus ei ole ilmoituskäytännön
                alainen, ilmoittajalle ilmoitetaan asiasta ja kerrotaan
                hylkäyksen syys sekä tarvittaessa se, kenelle asia tulisi
                osoittaa. Toistaalta, jos alustava tarkastelu osoittaa, että
                ilmoitus on todennäköisesti perää, ECO aloittaa tutkimukset
                sisäisesti. ECO antaa ilmoittajalle palautetta selvityksen
                etenemisestä ja mielellään myös arvioidun päättymisajankohdan
                enintään 3 kuukauden kuluessa.
            </Paragraph>
            <Paragraph>
                Kun kaikki tarvittavat tutkimukset, mukaan lukien asianosaisten
                kanssa pidettävä ristiriitatilanne, on suoritettu, ECO laatii
                lausunnon, joka toimitetaan kyseisen XXL-yksikön johdolle. Johto
                kuulee ECO:n lausunnon pohjalta asianomaisia linjapäälliköitä ja
                HR Business Partneria, tekee päätöksen tapauksesta ja määrää
                mahdollisista seuraamuksista. Esihenkilö ilmoittaa
                asianosaisille päätöksestä ja mahdollisista seuraamuksista. ECO
                ilmoittaa asianmukaisesti myös ilmoittajalle (ilmoittajille),
                ottaen kuitenkin huomioon syytetyn/ syytettyjen henkilöiden
                tietosuojaoikeuden. Tämän jälkeen tapaus suljetaan, ja kaikki
                tallennetut tiedot ja asiakirjat poistetaan mahdollisimman pian,
                viimeistään kahden vuoden kuluttua tapauksen ratkaisemisesta
                GDPR-asetuksen mukaisesti.
            </Paragraph>
        </PolicyContainer>
    )
}

const SwedishPolicy = () => {
    return (
        <PolicyContainer>
            <Paragraph>
                XXL förbinder sig att upprätthålla högsta möjliga standarder när
                det gäller transparens, ärlighet och ansvarstagande i allt vi
                gör. Vi fokuserar på att bygga en kultur där alla känner sig
                trygga och bekväma att ställa frågor eller lyfta fram problem.
                Policyn fastställer allas möjlighet att rapportera eventuella
                klagomål eller oegentligheter som kan ha skett angående brott
                mot tillämplig lagstiftning, klandervärda förhållanden inom
                XXL:s företag eller arbetsplatser, eller brott mot etiska normer
                som allmänt godtas i samhället.
            </Paragraph>
            <Paragraph>
                Klagomål eller bekymmer kan rapporteras via denna
                Visselblåsningskanal. IT-systemet för Visselblåsningskanalen
                drivs av Walor ApS, som är en oberoende tredje part som
                garanterar systemets säkerhet. Systemet loggar inte IP-adresser
                och maskin-ID:n och krypterar all data som behandlas. Endast
                XXL:s externa Ethics- and Compliance Officer (ECO), som
                behandlar alla inlämnade rapporter, har tillgång till rapporten.
            </Paragraph>
            <Paragraph>
                Om du är osäker på om en upplevelse, observation eller misstanke
                bör rapporteras eller inte, uppmuntras du att göra det. Alla
                rapporter kommer att besvaras och behandlas.
            </Paragraph>
            <Paragraph>
                Alla som rapporterar ärenden enligt ovan i god tro är skyddade
                från alla former av repressalier. Detta innebär att ingen som
                rapporterar klagomål eller problem i god tro kommer att utsättas
                för repressalier eller sanktioner även om den rapporterade
                frågan visar sig vara felaktig.
            </Paragraph>

            <Paragraph>
                Rapporter kan lämnas in skriftligt eller muntligt; öppet eller
                anonymt. Alla rapporter hanteras strikt konfidentiellt, enligt
                tillämplig lagstiftning.
            </Paragraph>

            <Paragraph>
                Alla frågor om XXL:s Visselblåsningspolicy eller XXL:s
                Visselblåsningskanal kan riktas till compliance.officer@xxl.no.
            </Paragraph>
            <Heading>Vem kan rapportera?</Heading>
            <Paragraph>
                XXL:s policy gäller för en bred krets av personer som har
                information om XXL som inhämtats under deras affärs- eller
                arbetsförhållande med företaget. Rapporter kan exempelvis lämnas
                in av:
            </Paragraph>
            <ul style={{ fontSize: '1.4rem', marginBottom: '1rem' }}>
                <Li>Anställda</Li>
                <Li>Tidigare anställda (anställning har upphört)</Li>
                <Li>Chefer på alla nivåer</Li>
                <Li>Arbetssökande</Li>
                <Li>Betalda eller obetalda praktikanter</Li>
                <Li>Volontärer</Li>
                <Li>Företagare</Li>
                <Li>Aktieägare</Li>
                <Li>Styrelseledamöter</Li>
                <Li>
                    Personer som arbetar under ledning och tillsyn av XXL:s
                    entreprenörer, underentreprenörer, leverantörer och
                    underleverantörertill alla företag i XXLkoncernen
                </Li>
                <Li>Kunder</Li>
            </ul>
            <Heading>
                Vad ska man rapportera - och vad ska man inte rapportera?
            </Heading>
            <Paragraph>
                Alla klagomål eller oegentligheter som kan ha skett angående
                brott mot tillämplig lagstiftning, kollektivavtal, klandervärda
                förhållanden inom XXL:s företag eller arbetsplatser, eller brott
                mot etiska normer som allmänt godtas i samhället bör
                rapporteras. "Tillämplig lagstiftning" omfattar alla typer av
                lagar, inte bara visselblåsarlagen.
            </Paragraph>
            <Paragraph>
                Yttranden om personliga åsikter eller missnöje, åsikter angående
                anställningsfrågor, t.ex. missnöje med löner, mindre kollegiala
                problem, meningsskiljaktigheter, yrkesmässiga tvister etc.,
                anses inte vara visselblåsning och ska inte rapporteras. Även
                ett klagomål eller bekymmer som en person väcker om förhållanden
                som endast berör den personen anses inte vara visselblåsning,
                såvida inte rapporten handlar om brott mot lagar, såsom
                diskriminering och trakasserier/mobbning. Sådana rapporter anses
                alltid vara visselblåsning.
            </Paragraph>
            <Heading>Skydd för visselblåsare</Heading>
            <Paragraph>
                Alla XXL-anställda som rapporterar ärenden enligt ovan i god tro
                är skyddade från alla former av repressalier. Denna regel gäller
                även för förtroendevalda eller skyddsombud som stödjer den
                rapporterande, den rapporterandes XXL kollegor, släktingar som
                arbetar för eller har avtal med XXL eller juridiska personer som
                den rapporterande äger, arbetar för eller på annat sätt har
                koppling till.
            </Paragraph>
            <Heading>Hantering av rapporter</Heading>
            <Paragraph>
                Alla inlämnade rapporter hanteras av Ethics- and Compliance
                Officer (ECO) som är den enda behöriga att genomföra
                utredningar. I vissa fall kan dock utredningen utföras med hjälp
                av det lokala XXL-bolagets Ethics- and Compliance Associate
                (ECA), eller i undantagsfall av jurister eller revisorer.
            </Paragraph>
            <Heading>Vad händer med rapporten?</Heading>
            <Paragraph>
                Inom sju dagar ska den som rapporterar få en bekräftelse på att
                rapporten mottagits. Den anklagade ska informeras om ärendet
                inom en månad efter att bekräftelsen på mottagandet har
                skickats. Den faktiska rapporten kommer inte att lämnas vidare
                till den anklagade. Inga uppgifter, vare sig direkt eller
                indirekt, kommer att lämnas till den anklagade även om den
                rapporterande har valt att inte rapportera anonymt.
            </Paragraph>
            <Paragraph>
                ECO kommer att göra en initial granskning av rapporten. Om
                rapporten visar sig inte vara uppenbart föremål för
                visselblåsning kommer den att avslås och den rapporterande
                kommer att underrättas och informeras om anledningen till att
                rapporten avslagits och, om möjligt, var rapporten ska
                adresseras i stället. Om den initiala granskningen däremot visar
                att rapporten är uppenbart föremål för visselblåsning, kommer
                ECO att inleda ytterligare interna utredningar. ECO ska ge
                feedback till den rapporterande om utredningens gång och helst
                ett preliminärt avslutningsdatum inom högst tre månader.
            </Paragraph>
            <Paragraph>
                När alla nödvändiga utredningar har genomförts, inklusive möte
                med den/de anklagade för att ge dem möjlighet bemöta
                anklagelserna, kommer ECO att utarbeta ett uttalande som ska
                skickas vidare till den berörda XXL-enhetens chef. Chefen ska
                sedan i samråd med relevant linjechef och HR Business Partner
                fatta beslut om eventuella åtgärder baserat på ECO:s uttalande.
                Den/de anklagade kommer att underrättas om slutsatserna och
                eventuella åtgärder av den närmaste chefen. Den/de rapporterande
                kommer att underrättas på lämpligt sätt av ECO med respekt för
                den/de anklagades personuppgifter. Därefter avslutas ärendet och
                all inspelad information och dokumentation raderas så snart som
                möjligt och senast (2) år efter att ärendet är avslutat i
                enlighet med GDPR.
            </Paragraph>
        </PolicyContainer>
    )
}

const NorwegianPolicy = () => {
    return (
        <PolicyContainer>
            <Paragraph>
                XXL er oppsatt på å legge høyeste standard til grunn når det
                gjelder transparens, ærlighet og ansvarlighet i alt vi foretar
                oss. Vi er fokusert på å bygge en speak-up kultur der alle føler
                seg trygge og komfortable med å stille spørsmål eller å ta opp
                bekymringer. Varslingspolicy’en vår understreker muligheten til
                å varsle bekymringer eller klager om angivelige brudd på
                gjeldende lovgivning, kritikkverdige forhold ved XXLs virksomhet
                eller på XXLs arbedeidsplasser, eller brudd på etiske normer som
                det er generell tilslutning til i samfunnet.
            </Paragraph>
            <Paragraph>
                Klager eller bekymringer kan rapporteres bl.a. gjennom denne XXL
                Whistleblowing Channel. ITsystemet som støtter XXL
                Whistleblowing Channel, er forvaltet av Walor ApS. Walor ApS er
                en uavhengig tredjepart som garanterer for tryggheten og
                sikkerheten av systemet. Systemet logger ikke IP-adresser eller
                maskin-ID’er og krypterer alle data som behandles. Kun XXL’s
                eksterne Ethics & Compliance Officer (ECO), som behandler alle
                innsendte varslinger, har tilgang til disse.
            </Paragraph>
            <Paragraph>
                Hvis du er i tvil om du skal varsle om noe du har opplevd,
                observert eller mistenker er et brudd som beskrevet ovenfor,
                oppfordres du til å rapportere. Alle varslinger vil bli besvart
                og behandlet.
            </Paragraph>
            <Paragraph>
                Alle som varsler om brudd i god tro, er beskyttet mot alle
                former for represalier. Dette betyr at ingen som sender inn
                varslinger med klager eller bekymringer om slike saker i god
                tro, vil bli sanksjonert selv om det viser seg at den
                rapporterte saken viser seg ikke å være virkelig.
            </Paragraph>

            <Paragraph>
                Varlinger kan formidles skriftlig eller muntlig, åpent eller
                anonymt. Alle varslinger behandles strengt konfidensielt og i
                henhold til gjeldende lovgivning.
            </Paragraph>

            <Paragraph>
                Alle spørsmål vedrørende XXLs Varslingspolicy eller XXL
                Whistleblowing Channel kan sendes på e-post til
                compliance.officer@xxl.no.
            </Paragraph>
            <Heading>Hvem kan varsle?</Heading>
            <Paragraph>
                XXLs Varslingspolicy gjelder for et bredt spekter av personer
                som har informasjoner som de har fått gjennom sitt forretnings-
                eller arbeidsforhold med XXL. Følgende personer med relasjon til
                XXL, kan varsle:
            </Paragraph>
            <ul style={{ fontSize: '1.4rem', marginBottom: '1rem' }}>
                <Li>Ansatte</Li>
                <Li>Tidligere ansatte (ansettelsesforholdet har opphørt) </Li>
                <Li>Ledere på alle nivåer </Li>
                <Li>Jobbsøkere</Li>
                <Li>Betalte eller ubetalte trainee’er</Li>
                <Li>Frivillige</Li>
                <Li>Selvstendig næringsdrivende</Li>
                <Li>Aksjonærer</Li>
                <Li>Styremedlemmer</Li>
                <Li>
                    Personer som arbeider under tilsyn og ledelse av
                    virksomheter XXL har kontrakt med og virksomheter i deres
                    leverandørkjede, og leverandører og underleverandører til
                    alle selskapene i XXL Group
                </Li>
                <Li>Kunder</Li>
            </ul>
            <Heading>
                Hva kan det varsles om – og hva skal det ikke varsles om?{' '}
            </Heading>
            <Paragraph>
                Alle klager eller bekymringer vedrørende mulige brudd på
                gjeldende lovgivning, kollektivavtaler, kritikkverdige forhold
                ved XXLs virksomhet eller på XXLs arbeidsplasser, eller brudd på
                ethiske normer som det er generell tilslutning til i samfunnet,
                kan rapporteres. «Gjeldende lovgivning” inkluderer alle typer
                lover, ikke bare varslings-lovgivningen.
            </Paragraph>
            <Paragraph>
                En person som uttrykker f.eks. sine personlige oppfatninger
                eller synspunkter, misnøye med sitt ansettelsesforhold f.eks.
                lønn, mindre disputter med kollegaer, uenigheter, profesjonelle
                stridigheter e.l., betraktes ikke som varslinger og skal ikke
                rapporteres. Videre er en klage eller bekymring som reises av en
                enkelt person og som kun vedrører den ene personen, heller ikke
                å betrakte som en varsling med mindre det dreier seg om brudd på
                gjeldende lovgivning, f.eks. diskriminering eller
                trakassering/mobbing. Slike klager eller bekymringer vil alltid
                bli behandlet som varslinger.
            </Paragraph>
            <Heading>Beskyttelse av varslere</Heading>
            <Paragraph>
                En XXL-ansatt som varsler om forhold som beskrevet ovenfor i god
                tro, er beskyttet mot alle former for represalier eller
                sanksjoner. Denne regelen gjelder også for tillitsvalgte og
                verneombud som støtter en varsler, evt. varslerens slektninger
                som arbeider for eller er engasjert av XXL, XXL-ansatte, og
                virksomheter som varsleren eier, arbeider for eller på annen
                måte har en tilknytning til.
            </Paragraph>
            <Heading>Hvordan behandles varslinger? </Heading>
            <Paragraph>
                Alle varslinger blir behandlet av ECO som er den eneste som har
                fullmakt til å gjennomføre undersøkelser. I enkelte tilfeller
                kan ECOs undersøkelser utføres ved hjelp av det aktuelle lokale
                XXL-selskapets Ethics & Compliance Associate (ECA), eller
                unntaksvis av advokat eller revisor.
            </Paragraph>
            <Heading>Hva skjer med en varsling?</Heading>
            <Paragraph>
                En varsler vil motta en bekreftelse på mottakelsen av varslingen
                innen 7 dager. Den omvarslede vil få informasjon vedørende saken
                innen 1 måned etter at bekreftelsen på mottakelsen er varslingen
                er sendt. Varselet vil ikke bli formidlet til den omvarslede.
                Informasjon om hvem varsleren er vil ikke bli gitt til den
                omvarslede, verken direkte eller indirekte, selv om varsleren
                ikke har valgt å varsle anonymt.
            </Paragraph>
            <Paragraph>
                ECO vil gjennomføre en forundersøkelse av varselet. Hvis
                varselet viser seg ikke å kunne defineres som et varsel, vil
                innsenderen bli informert om at varselet er blitt avvist, hva
                som er bakgrunnen for avvisningen og, hvis aktuelt, hvem
                vedkommende kan rette saken til. På den annen side, hvis
                forundersøkelsen viser at varslingen er velbegrunnet, vil ECO
                initiere en intern undersøkelse. ECO vil senest i løpet av 3
                måneder gi varsleren tilbakemelding om fremdriften i
                internundersøkelsen og fortrinnsvis angi et tentativt
                avslutningstidspunkt.
            </Paragraph>
            <Paragraph>
                Etter å ha gjennomført alle nødvendige undersøkelser, inkludert
                kontradiksjonsmøtet med omvarslet, vil ECO utferdige en
                Resultatrapport og sende denne til øverste leder for den
                aktuelle XXL-enheten. Basert på ECOs rapport og i samråd med den
                omvarsledes linjeleder og HR Business Partner vil øverste leder
                konkludere saken og beslutte om det skal iverksettes tiltak og i
                tilfellet hvilke(t). Omvarslet vil bli underrettet om
                konklusjonen og evt. tiltak av nærmeste overordnede. Varsler vil
                bli informert av ECO på en måte som også tar hensyn til
                personvernrettighetene til omvarslet. Varslingssaken vil så bli
                lukket og all arkivert informasjon og dokumentasjon vil bli
                slettet så snart som mulig, senest 2 år etter at saken er
                avsluttet, ref. bestemmelsene i GDPR.
            </Paragraph>
        </PolicyContainer>
    )
}

const EnglishPolicy: React.FC = () => {
    return (
        <PolicyContainer>
            <Paragraph>
                XXL is committed to the highest possible standards when it comes
                to transparency, honesty, and accountability in everything we
                do. We are focused on building a speak-up culture where everyone
                feels safe and comfortable asking questions or raising concerns.
                The Policy states everyone's opportunity to report any alleged
                concerns or complaints regarding infringements of applicable
                legislation, censurable conditions within XXL's enterprises or
                workplaces, or breaches of ethical norms that are commonly
                endorsed in society.
            </Paragraph>
            <Paragraph>
                Complaints or concerns can be reported, among others, through
                this XXL Whistleblowing Channel. The IT-system that supports the
                Whistleblowing Channel is operated by Walor ApS, which is an
                independent third party who guarantees the safety and security
                of the system. The system does not log IP addresses and
                machine-IDs and will encrypt all data processed. Only XXL’s
                external Ethics and Compliance Officer (ECO), who processes all
                filed reports, has access to the reports.
            </Paragraph>
            <Paragraph>
                If in doubt whether an experience, observation or suspicion
                should be reported or not, you are encouraged to do so. All
                reports will be answered and processed.
            </Paragraph>
            <Paragraph>
                Anyone who reports matters as described above in good faith is
                protected from any form of retaliation. This means that no one
                reporting complaints or concerns in good faith will be
                retaliated or sanctioned even if the reported issue turns out
                not to be true.
            </Paragraph>

            <Paragraph>
                Reports may be filed in writing or verbally; openly, or
                anonymously. All reports are treated strictly confidentially in
                accordance with applicable legislation.
            </Paragraph>

            <Paragraph>
                All questions about XXL’s Whistleblowing Policy or the XXL
                Whistleblowing Channel can be directed to
                compliance.officer@xxl.no.
            </Paragraph>
            <Heading>Who can report?</Heading>
            <Paragraph>
                XXL Whistlebowing Policy applies to a broad specter of
                individuals who have information about XXL acquired in the
                course of their business or work relationship with the Company.
                Reports may be filed by:
            </Paragraph>
            <ul style={{ fontSize: '1.4rem', marginBottom: '1rem' }}>
                <Li>Employees</Li>
                <Li>Previous employees (employment has ceased)</Li>
                <Li>Managers at all levels</Li>
                <Li>Job applicants</Li>
                <Li>Paid or unpaid trainees</Li>
                <Li>Volunteers</Li>
                <Li>Self-employed persons</Li>
                <Li>Shareholders</Li>
                <Li>Members of the Board of Directors</Li>
                <Li>
                    Persons working under the supervision and direction of XXL
                    contractors, sub-contractors, suppliers, and sub-suppliers
                    of any company in XXL Group
                </Li>
                <Li>Customers</Li>
            </ul>
            <Heading>What to report - and what not to report?</Heading>
            <Paragraph>
                Any alleged concerns or complaints regarding infringements of
                applicable legislation, collective agreement, censurable
                conditions within XXL's enterprises or workplaces, or breaches
                of ethical norms that are commonly endorsed in society should be
                reported. “Applicable legislation” includes all kinds of laws,
                not only the whistleblowing law.
            </Paragraph>
            <Paragraph>
                A person expressing, e.g.,their individual opinions or
                discontent, opinions regarding employment issues, such as
                dissatisfaction with salaries, minor collegial issues,
                disagreements, professional disputes, etc., are not considered
                whistleblowing and should not be reported. Also, a complaint or
                concern raised by any one person regarding conditions that
                concern only that one person, is not considered whistleblowing
                unless the report addresses infringements of laws, like
                discrimination and harassment/bullying. Such reports are always
                considered as whistleblowing.
            </Paragraph>
            <Heading>Protection of whistleblowers</Heading>
            <Paragraph>
                Any XXL employee who reports matters as described above in good
                faith is protected from any form of retaliation. This rule also
                applies for employees’ representatives or safety delegates who
                support the reporter, any relative that works for or is
                contracted by XXL, any XXL employee, or any corporate body that
                the reporting person owns, works for, or otherwise has a
                connection with.
            </Paragraph>
            <Heading>How are the reports processed?</Heading>
            <Paragraph>
                All filed reports are processed by the ECO who is the only
                authorized body to conduct investigations. However, in some
                cases, the investigation may be carried out with the help of the
                local XXL company’s Ethics & Compliance Associate (ECA), or in
                exceptional cases by lawyers or auditors.
            </Paragraph>
            <Heading>What happens with a report?</Heading>
            <Paragraph>
                Reporters shall receive confirmation of receipt of their report
                within 7 days. The accused person will be provided with
                information about the event within 1 month after the
                confirmation of receipt of the report has been sent. The actual
                report will not be passed on to the accused person. No
                information, whether direct or indirect, will be provided to the
                accused person even if the reporter has chosen not to report
                anonymously.
            </Paragraph>
            <Paragraph>
                The ECO will conduct an initial examination of the report. If
                the report evidently turns out not to be a cause for
                whistleblowing, the reporter will be notified, informed about
                the reason the report was rejected and, if appliable, where to
                address their issue. On the other hand, if the initial
                examination determines that the report is notably founded, the
                ECO will initiate further internal investigations. The ECO shall
                provide feedback to the reporter about the progress of the
                investigation and preferably a tentative finalization date
                within a maximum period of 3 months.
            </Paragraph>
            <Paragraph>
                After having conducted all necessary investigations, including
                the contradiction meeting with the accused party(ies), the ECO
                will draft a Statement to be forwarded to the senior executive
                of the relevant XXL unit. The executive will consult with the
                relevant line managers and HR Business Partner based on ECO’s
                Statement, conclude the case, and decide appropriate sanctions,
                if any. The accused party(ies) will be notified about the
                conclusion and sanctions, if any, by their immediate manager.
                The reporter(s) will be appropriately notified by the ECO with
                due respect to the personal protection rights of the accused
                party(ies). Then the case will be closed and all recorded
                information and documentation deleted as soon as possible, at
                the latest 2 years after the case has been resolved in
                accordance with the provisions of the GDPR.
            </Paragraph>
        </PolicyContainer>
    )
}

const XxlPolicy: React.FC = () => {
    const { companyName, organizationalCode } = useTypedSelector(
        (state) => state.whistleblower
    )

    const history = useHistory()

    const translation = useTranslation()

    const { selectedTranslation } = useTypedSelector(
        (state) => state.translation
    )

    const renderPolicy = () => {
        switch (selectedTranslation) {
            case 'en':
                return <EnglishPolicy />
            case 'no':
                return <NorwegianPolicy />
            case 'se':
                return <SwedishPolicy />
            case 'fi':
                return <FinishPolicy />
            default:
                return <EnglishPolicy />
        }
    }
    return (
        <>
            <BackToHomeScreen>
                <NavigationArrowLink
                    onClickCallback={() => {
                        history.push(
                            `/organization/${organizationalCode}/whistleblower`
                        )
                    }}
                    linkText={
                        translation.whistleblowerPolicyPage.backToHomescreen
                    }
                />
            </BackToHomeScreen>
            <Container>
                <NavigationLinkContainer>
                    <NavigationLink active={true}>{companyName}</NavigationLink>
                    <NavigationLinkDivider />
                    <NavigationLink active={false}>
                        XXL Whistleblowing Policy
                    </NavigationLink>
                </NavigationLinkContainer>
                {renderPolicy()}
            </Container>
        </>
    )
}

export default XxlPolicy

import styled from 'styled-components'
import ellipse from './ellipse.svg'
import largeEllipse from './large-ellipse.svg'
import radio from './radio.svg'
import { AiFillInfoCircle } from 'react-icons/ai'
import { FC } from 'react'

interface TimelineWidth {
    width: string
}

interface ProgressBarWidth {
    width?: string
    isRoundedBorder: boolean
}

const Timeline = styled.div`
    width: 100%;
    height: 8.9rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 3.8rem 0;

    @media (max-width: 600px) {
        width: 100%;
        min-width: 25rem;
    }

    @media (max-width: 1000px) {
        padding: 1.5rem 1.5rem 0;
    }
`

const TimelineBar = styled.div<TimelineWidth>`
    width: ${({ width }) => width};
    height: 3px;
    background-image: url(${ellipse});
    background-repeat: space;
    display: flex;
    align-items: center;
`

const Circle = styled.img`
    color: #247dd0;
`

const Radio = styled.img`
    color: #247dd0;
`

const Breakpoint = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 1.5rem;
`

const DateContainer = styled.div`
    height: 2.4rem;
    margin-bottom: 0.4rem;

    & > p {
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        line-height: normal;
    }
`

const IconWrap = styled.div`
    height: 2.4rem;
    margin-top: 0.4rem;
`

const Info = styled(AiFillInfoCircle)`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.greyText};
    cursor: pointer;
`

const ProgressBar = styled.div<ProgressBarWidth>`
    height: 3px;
    background-color: ${({ theme }) => theme.blue};
    width: ${({ width }) => width};
    border-radius: ${({ isRoundedBorder }) =>
        isRoundedBorder ? ' 0 0.5rem 0.5rem 0' : '0'};
`

interface ProgressiveTimelineProps {
    breakpoints: { month: string; date: string }[]
    calcWidthSegmentOne: () => string
    calcWidthSegmentTwo: () => string | undefined
    isSecondBreakpoint: boolean
    isThirdBreakpoint: boolean
    setHoveredIcon: (value: 'first' | 'second' | 'third' | null) => void
}

const ProgressiveTimeline: FC<ProgressiveTimelineProps> = ({
    breakpoints,
    calcWidthSegmentOne,
    calcWidthSegmentTwo,
    isSecondBreakpoint,
    isThirdBreakpoint,
    setHoveredIcon,
}) => {
    return (
        <Timeline>
            <Breakpoint>
                <DateContainer>
                    <p>{breakpoints[0].month}</p>
                    <p>{breakpoints[0].date}</p>
                </DateContainer>
                <Circle src={largeEllipse} />
                <IconWrap>
                    <Info
                        onMouseOver={() => setHoveredIcon('first')}
                        onMouseOut={() => setHoveredIcon(null)}
                    />
                </IconWrap>
            </Breakpoint>
            <TimelineBar width="25%">
                <ProgressBar
                    width={calcWidthSegmentOne()}
                    isRoundedBorder={!isSecondBreakpoint}
                />
            </TimelineBar>
            <Breakpoint>
                <DateContainer>
                    <p>{breakpoints[1].month}</p>
                    <p>{breakpoints[1].date}</p>
                </DateContainer>
                {isSecondBreakpoint ? (
                    <Circle src={largeEllipse} />
                ) : (
                    <Radio src={radio} />
                )}
                <IconWrap>
                    <Info
                        onMouseOver={() => setHoveredIcon('second')}
                        onMouseOut={() => setHoveredIcon(null)}
                    />
                </IconWrap>
            </Breakpoint>
            <TimelineBar width="75%">
                <ProgressBar
                    width={calcWidthSegmentTwo()}
                    isRoundedBorder={!isThirdBreakpoint}
                />
            </TimelineBar>
            <Breakpoint>
                <DateContainer>
                    <p>{breakpoints[2].month}</p>
                    <p>{breakpoints[2].date}</p>
                </DateContainer>
                {isThirdBreakpoint ? (
                    <Circle src={largeEllipse} />
                ) : (
                    <Radio src={radio} />
                )}
                <IconWrap>
                    <Info
                        onMouseOver={() => setHoveredIcon('third')}
                        onMouseOut={() => setHoveredIcon(null)}
                    />
                </IconWrap>
            </Breakpoint>
        </Timeline>
    )
}

export default ProgressiveTimeline

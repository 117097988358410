import { useEffect } from 'react'
import { useActions, useTypedSelector } from '../../../../../hooks'
import { PersonalInformation } from '../../newest-register/NewestRegisterPage'

interface ResolveRegisterIntegrationDataProps {
    integrationId: string | null
    setPersonalInformation: (personalInformation: PersonalInformation) => void
    personalInformation: PersonalInformation
}

const ResolveRegisterIntegrationData: React.FC<
    ResolveRegisterIntegrationDataProps
> = ({ integrationId, setPersonalInformation, personalInformation }) => {
    const { getIdentityThroughIntegration } = useActions()

    const { getIdentity } = useTypedSelector((state) => state.identity)

    useEffect(() => {
        if (!integrationId) return

        getIdentityThroughIntegration(Number(integrationId))

        // eslint-disable-next-line
    }, [integrationId])

    useEffect(() => {
        if (!getIdentity) return

        setPersonalInformation({
            ...personalInformation,
            firstName: getIdentity.first_name || '',
            lastName: getIdentity.last_name || '',
            email: getIdentity.email || '',
            phoneNumber: getIdentity.phone?.phone || '',
        })

        // eslint-disable-next-line
    }, [getIdentity])

    return <></>
}

export default ResolveRegisterIntegrationData

import { useTypedSelector } from '../../../../hooks'
import WhistleblowerMagicLogin from '../../redirects/WhistleblowerMagicLogin'
import ReportingChannelHomepage from './ReportingChannelHomepage'

interface ReportingChannelProps {}

const ReportingChannel: React.FC<ReportingChannelProps> = () => {
    const { whistleblowerLoading } = useTypedSelector(
        (state) => state.whistleblower
    )

    return (
        <>
            {whistleblowerLoading ? (
                <WhistleblowerMagicLogin />
            ) : (
                <ReportingChannelHomepage />
            )}
        </>
    )
}

export default ReportingChannel

import axios from 'axios'
import { CaseworkerOnboardingActionType } from '../action-types'
import config from '../../config'
import { Dispatch } from 'redux'
import { PersonalInformation } from '../actions'
import { AdditionalInfo } from '../../components/pages/setup-account/SetupAccountPage'

const { apiUrl } = config

interface OnboardingSuccess {
    data: {
        userId: number
        email: string
        onboarding_step: 'personal_information' | 'additional_information'
    }
}

export const startOnboarding =
    (token: string) => async (dispatch: Dispatch) => {
        dispatch(setOnboardingLoading())
        try {
            const res = await axios.get(
                `${apiUrl}/v1/caseworker-onboarding/${token}`
            )

            if (res.status !== 200) {
                return dispatch(setOnboardingError(res.data))
            }

            if (res.status === 200) {
                return dispatch(setOnboardingSuccess(res.data.data))
            }
        } catch (error: any) {
            if (error.response.status === 409) {
                const msg = error.response.data.message
                return dispatch(setOnboardingError(msg))
            }
            return dispatch(setOnboardingError('An error occurred during onboarding.'))
        }
    }

export const setOnboardingLoading = () => ({
    type: CaseworkerOnboardingActionType.ONBOARDING_LOADING,
})

export const setOnboardingError = (error: string) => ({
    type: CaseworkerOnboardingActionType.ONBOARDING_ERROR,
    payload: error,
})

export const setOnboardingSuccess = (data: OnboardingSuccess) => ({
    type: CaseworkerOnboardingActionType.ONBOARDING_SUCCESS,
    payload: { data },
})

export const personalInformationRequest = () => ({
    type: CaseworkerOnboardingActionType.PERSONAL_INFORMATION_REQUEST,
})

export const personalInformationLoading = () => ({
    type: CaseworkerOnboardingActionType.PERSONAL_INFORMATION_LOADING,
})

export const personalInformationError = (error: string) => ({
    type: CaseworkerOnboardingActionType.PERSONAL_INFORMATION_ERROR,
    payload: error,
})

export const personalInformationSuccess = () => ({
    type: CaseworkerOnboardingActionType.PERSONAL_INFORMATION_SUCCESS,
})

export const submitPersonalInformation =
    (token: string, data: PersonalInformation) =>
    async (dispatch: Dispatch) => {
        dispatch(personalInformationLoading())
        const payload = {
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            position: data.position,
            country_id: data.countryId,
            phone: data.phone,
            password: data.password,
            password_confirmation: data.password,
        }
        try {
            const res = await axios.post(
                `${apiUrl}/v1/caseworker-onboarding/${token}/personal-information`,
                payload
            )

            if (res.status === 200) {
                dispatch(personalInformationSuccess())
            }
        } catch (error: any) {
            if (error.response && error.response.data.errors) {
                const errorMessages = Object.values(
                    error.response.data.errors
                ).flat()
                const errorMessage = errorMessages[0] as string
                dispatch(personalInformationError(errorMessage))
            } else if (error.response.data.message) {
                const errorMessage = error.response.data.message
                dispatch(personalInformationError(errorMessage))
            } else {
                dispatch(
                    personalInformationError(
                        'An error occurred. Please, try again later.'
                    )
                )
            }
        }
    }

export const additionalInformationRequest = () => ({
    type: CaseworkerOnboardingActionType.ADDITIONAL_INFORMATION_REQUEST,
})

export const additionalInformationError = (error: string) => ({
    type: CaseworkerOnboardingActionType.ADDITIONAL_INFORMATION_ERROR,
    payload: error,
})

export const additionalInformationSuccess = () => ({
    type: CaseworkerOnboardingActionType.ADDITIONAL_INFORMATION_SUCCESS,
})

export const submitAdditionalInformation =
    (token: string, data: AdditionalInfo) => async (dispatch: Dispatch) => {
        dispatch(additionalInformationRequest())

        const payload = new FormData()

        data.languages.forEach((language, index) => {
            if (language.id) {
                payload.append(
                    `languages[${index}][language_id]`,
                    language.id.toString()
                )
            }
        })
        payload.append('department', data.department)
        if (data.note) {
            payload.append('note', data.note)
        }
        if (data.profileImg) {
            payload.append('profile_img', data.profileImg)
        }

        payload.append(
            'is_terms_accepted',
            data.termsAndConditionsChecked.toString()
        )

        try {
            await axios.post(
                `${apiUrl}/v1/caseworker-onboarding/${token}/additional-information`,
                payload
            )

            dispatch(additionalInformationSuccess())
        } catch (error: any) {
            if (error.response && error.response.data.errors) {
                const errorMessages = Object.values(
                    error.response.data.errors
                ).flat()
                const errorMessage = errorMessages[0] as string
                dispatch(additionalInformationError(errorMessage))
            } else {
                dispatch(
                    additionalInformationError(
                        'An error occurred. Please, try again later.'
                    )
                )
            }
        }
    }

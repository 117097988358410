import produce from 'immer'
import en from '../../translations/en'
import dk from '../../translations/dk'
import no from '../../translations/no'
import se from '../../translations/se'
import fi from '../../translations/fi'

import { TranslationActionType } from '../action-types'
import { TranslationAction } from '../actions/'
import Translation from '../state-types/TranslationStateType'
import config from '../../config'

const { env } = config

interface TranslationState {
    selectedTranslation: 'en' | 'dk' | 'se' | 'no' | 'fi'
    // | 'nl' | 'fr'
    translation: Translation
}

const initialState: TranslationState = {
    selectedTranslation: env === 'production' ? 'dk' : 'en',
    // @ts-ignore
    translation: env === 'production' ? dk : en,
}

const reducer = produce(
    (state: TranslationState = initialState, action: TranslationAction) => {
        switch (action.type) {
            case TranslationActionType.SWITCH_LANGUAGE:
                const translations = {
                    en,
                    dk,
                    se,
                    no,
                    fi,
                }

                state.selectedTranslation = action.payload
                // @ts-ignore
                state.translation = translations[action.payload]

                return state
            default:
                return state
        }
    }
)

export default reducer

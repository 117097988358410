import axios, { AxiosError } from 'axios'
import { InviteCaseworkerActionType } from '../action-types'
import config from '../../config'
import { Dispatch } from 'redux'
import { FlashMessageInterface } from '../reducers/lineOfContactReducer'

const { apiUrl } = config

export const inviteCaseworkerByEmail =
    (id: number, email: string) => async (dispatch: Dispatch) => {
        // Using setTimeout in the 'ClearFlashMessage'-style of action creators was producing a type error
        // moved it to its own scoped function here
        const clearFlashMessage = () =>
            setTimeout(
                () => dispatch(inviteCaseworkerClearFlashMessage()),
                5000
            )
        dispatch(inviteCaseworkerLoading())
        await axios
            .post(`${apiUrl}/v1/clients/${id}/contacts/invite`, { email })
            .then((data) => {
                dispatch(inviteCaseworkerSuccess())
                dispatch(
                    inviteCaseworkerFlashMessage({
                        message: 'Invitation has been sent!',
                        type: 'success',
                    })
                )
                clearFlashMessage()

                return data
            })
            // Using Axios' built-in error handling/error types
            .catch((error: AxiosError) => {
                dispatch(
                    inviteCaseworkerError(
                        error.response?.data.message ?? error.message
                    )
                )
                dispatch(
                    inviteCaseworkerFlashMessage({
                        message: error.response?.data.message
                            ? error.response.data.message
                            : 'An error occurred. Please, try again later.',
                        type: 'error',
                    })
                )
                clearFlashMessage()
            })
    }

export const inviteCaseworkerLoading = () => ({
    type: InviteCaseworkerActionType.INVITE_CASEWORKER_LOADING,
})

export const inviteCaseworkerError = (error: string) => ({
    type: InviteCaseworkerActionType.INVITE_CASEWORKER_ERROR,
    payload: error,
})

export const inviteCaseworkerSuccess = () => ({
    type: InviteCaseworkerActionType.INVITE_CASEWORKER_SUCCESS,
})

export const inviteCaseworkerFlashMessage = (
    message: FlashMessageInterface
) => ({
    type: InviteCaseworkerActionType.INVITE_CASEWORKER_FLASH_MESSAGE,
    payload: message,
})

export const inviteCaseworkerClearFlashMessage = () => ({
    type: InviteCaseworkerActionType.INVITE_CASEWORKER_CLEAR_FLASH_MESSAGE,
})

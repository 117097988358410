import styled from 'styled-components'
import AvatarEditor from 'react-avatar-editor'
import { Card, CardHeader } from '../../../../styled/cards'
import { CancelButton } from '../../../../reusable/form/MiniPersonForm'
import { useTranslation } from '../../../../../hooks'

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 502;
`

const Modal = styled.div`
    height: 50rem;
    width: 42rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 501;
`

const AvatarEditorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    padding-block-start: 6rem;
`

const SaveButton = styled.div`
    height: 2.7rem;
    padding: 0 1.6rem;

    display: flex;
    align-items: center;
    background: ${({ theme }) => `${theme.black}50`};
    color: ${({ theme }) => theme.white};
    font-size: 1.4rem;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.black};
    }
`

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const RangeInput = styled.input`
    width: 30rem;
    cursor: pointer;
`

interface ProfileImageEditorModalProps {
    onSave?: () => void
    onClose: () => void
    onChange: (value: any) => void
    editorRef?: React.RefObject<AvatarEditor>
    image: string
    value: number
    scale: number
}

const ProfileImageEditorModal: React.FC<ProfileImageEditorModalProps> = ({
    onSave,
    onClose,
    onChange,
    editorRef,
    image,
    value,
    scale,
}) => {
    const translation = useTranslation()

    return (
        <ModalContainer>
            <Modal>
                <Card>
                    <CardHeader>
                        {translation.clientView.editProfilePicture}
                        <ButtonsContainer>
                            <CancelButton onClick={onClose}>
                                {translation.reusable.cancel}
                            </CancelButton>
                            <SaveButton onClick={onSave}>
                                {translation.reusable.save}
                            </SaveButton>
                        </ButtonsContainer>
                    </CardHeader>
                    <AvatarEditorWrapper>
                        <AvatarEditor
                            image={image}
                            width={250}
                            height={250}
                            scale={scale}
                            ref={editorRef}
                            borderRadius={125}
                        />
                        <RangeInput
                            type="range"
                            value={value}
                            onChange={onChange}
                            min={1}
                            max={10}
                            step={0.1}
                        />
                    </AvatarEditorWrapper>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default ProfileImageEditorModal

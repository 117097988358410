import styled from 'styled-components'
import { DynamicHeightSaveButton } from '../../../../styled/buttons'
import { useTranslation, useTypedSelector } from '../../../../../hooks'
import { formatDateWithTime } from '../../../../../helpers/date'
import { capitalize } from 'lodash'
import { CategoryInterface } from '../../../../../state/reducers/categoryReducer'

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

const InformationFlex = styled.div`
    display: flex;
    gap: 1.4rem;
    align-items: center;
    height: 2rem;
    margin-bottom: 2.2rem;
`

const InformationText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.greyText};
    margin-bottom: 1rem;
    margin-top: 1rem;
`

const Dot = styled.div`
    height: 0.2rem;
    width: 0.2rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.greyText};
`

const Heading = styled.h3`
    font-size: 2rem;
    color: ${({ theme }) => theme.primary};
    font-weight: medium;
    margin-bottom: 5.5rem;
`

const ScoreContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 4.2rem;
`

const MinMaxText = styled.span`
    font-size: 1rem;
    color: ${({ theme }) => `${theme.primary}50`};
`

const ScoreBox = styled.div`
  height: 4.5rem;
  width 27rem;
  background: ${({ theme }) => theme.white};
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
`

const CommentBox = styled.div`
    height: 21.4rem;
    width: 50rem;
    border: 1px solid ${({ theme }) => theme.greyText};
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 5rem;
`

const CommentText = styled.p`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
`

interface IsActiveProp {
    isActive: boolean
}

const ScoreNumber = styled.h3<IsActiveProp>`
    font-size: 1.8rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.primary : `${theme.primary}20`};
`

interface ForwardReportPreviewProps {
    category: CategoryInterface
    score: null | number
    contact: any
    comment: null | string
    onForwardCase: () => void
}

const ForwardReportPreview: React.FC<ForwardReportPreviewProps> = ({
    category,
    score,
    contact,
    comment,
    onForwardCase,
}) => {
    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const translation = useTranslation()

    const handleForwardCase = () => {
        if (category && score && contact && comment) {
            onForwardCase()
        }
    }

    return (
        <>
            {openReport && (
                <Container>
                    <InformationFlex>
                        <InformationText>
                            {openReport.readable_id}
                        </InformationText>
                        <Dot />
                        <InformationText>
                            {formatDateWithTime(openReport.created_at)}
                        </InformationText>
                        <Dot />
                        <InformationText>
                            {openReport.assigned.data.first_name +
                                ' ' +
                                openReport.assigned.data.last_name}
                        </InformationText>
                    </InformationFlex>
                    <Heading>{openReport.title}</Heading>
                    <InformationText>
                        {translation.reusable.category}
                    </InformationText>
                    <Heading>
                        {category
                            ? capitalize(category.name)
                            : translation.forwardReportPage.uncategorized}
                    </Heading>
                    <InformationText>
                        {translation.forwardReportPage.severityScore}
                    </InformationText>
                    <ScoreContainer>
                        <MinMaxText>
                            {translation.forwardReportPage.minimumAbbreviated.toLowerCase()}
                        </MinMaxText>
                        <ScoreBox>
                            {[1, 2, 3, 4, 5, 6].map((i) => (
                                <ScoreNumber key={i} isActive={i === score}>
                                    {i}
                                </ScoreNumber>
                            ))}
                        </ScoreBox>
                        <MinMaxText>
                            {translation.forwardReportPage.maximumAbbreviated.toLowerCase()}
                        </MinMaxText>
                    </ScoreContainer>
                    <InformationText>
                        {translation.forwardReportPage.lineOfContact}
                    </InformationText>
                    <Heading>{contact?.name}</Heading>
                    <InformationText>
                        {translation.forwardReportPage.comment}
                    </InformationText>
                    <CommentBox>
                        <CommentText>{comment}</CommentText>
                    </CommentBox>
                    <DynamicHeightSaveButton
                        fontSize="1.8rem"
                        height="4.5rem"
                        width="38.6rem"
                        isActive={category && score && contact && comment}
                        onClick={handleForwardCase}
                    >
                        {translation.forwardReportPage.forwardCase}
                    </DynamicHeightSaveButton>
                </Container>
            )}
        </>
    )
}

export default ForwardReportPreview

import styled from 'styled-components'
import { Card, CardHeader } from '../../../../styled/cards'
import {
    ErrorMessage,
    FormField,
    FormInput40,
    InputFieldIcon,
    PrimaryLabel,
} from '../../../../styled/formElements'
import { useState, useEffect } from 'react'
import {
    useTranslation,
    useActions,
    useTypedSelector,
} from '../../../../../hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'
import { ChangePasswordInterface } from '../../../../../ts/interfaces/ChangePasswordInterface'
import PasswordValidationChecklistV2 from '../../../../PasswordValidationChecklist.V2'
import { BlackButton } from '../../../../reusable/buttons/SaveButton'
import { CancelButton } from '../../../../reusable/buttons/CancelButton'
import {
    StyledCancelIcon,
    StyledCheckIcon,
    FeedbackMessage,
} from '../../../../styled/formElements'
import { EditSection } from './MyAccount'

const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-block-start: 2.7rem;
    padding-inline: 4rem;
    margin-bottom: 1.3rem;
`

const FormSection = styled.div`
    width: 80%;
`

const HeaderButtonFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const StyledCard = styled(Card)`
    height: fit-content;
`

interface ChangePasswordProps {
    setEditSection: React.Dispatch<React.SetStateAction<EditSection>>
    editSection: EditSection
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
    setEditSection,
    editSection,
}) => {
    const { user } = useTypedSelector((state) => state.caseworker)
    const { updatePassword } = useActions()

    const { identityLoading, passwordErrors, passwordSuccess } =
        useTypedSelector((state) => state.identity)
    const translation = useTranslation()

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [showPassword, setShowPassword] = useState(true)
    const [showConfirmPassword, setShowConfirmPassword] = useState(true)
    const [isFormValidated, setIsFormValidated] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [focused, setFocused] = useState(false)

    const handleFormValidation = (isValid: boolean) => {
        setIsFormValidated(isValid)
    }

    useEffect(() => {
        let timeoutId: NodeJS.Timeout

        if (showMessage) {
            timeoutId = setTimeout(() => {
                setShowMessage(false)
            }, 5000)
        }
        return () => clearTimeout(timeoutId)
    }, [showMessage])

    const handleShowPasswordCheckboxChanged = () => {
        setShowPassword(!showPassword)
    }
    const handleShowConfirmPasswordCheckboxChanged = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const handleSavePassword = async () => {
        if (!isFormValidated) {
            return
        }

        const updateUserPassword: ChangePasswordInterface = {
            old_password: oldPassword,
            password: newPassword,
            password_confirmation: passwordConfirm,
        }

        const success = await updatePassword(user.userId, updateUserPassword)
        if (!success) {
            return
        }

        setShowMessage(true)
        setOldPassword('')
        setNewPassword('')
        setPasswordConfirm('')
        setIsFormValidated(false)

        setEditSection('password')
    }

    const handlerCancel = () => {
        setOldPassword('')
        setNewPassword('')
        setPasswordConfirm('')
    }

    return (
        <div>
            <StyledCard>
                <CardHeader>
                    Password
                    {user && (
                        <HeaderButtonFlex>
                            <CancelButton onClick={handlerCancel}>
                                Cancel
                            </CancelButton>
                            <BlackButton
                                onClick={handleSavePassword}
                                isActive={
                                    isFormValidated && oldPassword.length > 0
                                }
                            >
                                {identityLoading &&
                                editSection === 'password' ? (
                                    <ButtonSpinnerWhite />
                                ) : (
                                    'Save'
                                )}
                            </BlackButton>
                        </HeaderButtonFlex>
                    )}
                </CardHeader>
                <FormContainer>
                    <FormSection>
                        <FormField>
                            <PrimaryLabel htmlFor="old-password">
                                {translation.settingsPartner.oldPassword}
                            </PrimaryLabel>
                            <FormInput40
                                id="old-password"
                                name="old-password"
                                value={oldPassword || ''}
                                onChange={(e) => setOldPassword(e.target.value)}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                            />
                            {passwordErrors && !focused && (
                                <ErrorMessage>{passwordErrors}</ErrorMessage>
                            )}
                        </FormField>
                        <FormField>
                            <PrimaryLabel htmlFor="new-password">
                                {translation.settingsPartner.newPassword}
                            </PrimaryLabel>
                            <FormInput40
                                id="new-password"
                                name="newpassword"
                                type={showPassword ? 'text' : 'password'}
                                value={newPassword || ''}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <InputFieldIcon
                                onClick={handleShowPasswordCheckboxChanged}
                            >
                                {!showPassword ? eye : eyeSlash}
                            </InputFieldIcon>
                        </FormField>
                        <FormField>
                            <PrimaryLabel htmlFor="password-confirm">
                                {translation.reusable.confirmPassword}
                            </PrimaryLabel>
                            <FormInput40
                                id="password-confirm"
                                name="password-confirm"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={passwordConfirm || ''}
                                onChange={(e) =>
                                    setPasswordConfirm(e.target.value)
                                }
                            />
                            <InputFieldIcon
                                onClick={
                                    handleShowConfirmPasswordCheckboxChanged
                                }
                            >
                                {!showConfirmPassword ? eye : eyeSlash}
                            </InputFieldIcon>
                        </FormField>
                    </FormSection>

                    <PasswordValidationChecklistV2
                        password={newPassword}
                        passwordConfirm={passwordConfirm}
                        isFormValidated={handleFormValidation}
                    />
                </FormContainer>
                {showMessage && (
                    <FeedbackMessage
                        isSuccess={!passwordSuccess ? 'failed' : null}
                        showMessage={showMessage}
                    >
                        {!passwordSuccess ? (
                            <StyledCancelIcon />
                        ) : (
                            <StyledCheckIcon />
                        )}
                        <p>
                            {!passwordSuccess
                                ? 'Something went wrong. Please try again.'
                                : 'Your changes have been saved successfully'}
                        </p>
                    </FeedbackMessage>
                )}
            </StyledCard>
        </div>
    )
}

export default ChangePassword

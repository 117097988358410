import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

const ReportingChannelRedirect: React.FC = () => {
    const { id } = useParams<{ id: string }>()

    const history = useHistory()

    useEffect(() => {
        if (id) {
            history.push(`/organization/${id}/whistleblower`)
        }

        // eslint-disable-next-line
    }, [id])

    return <></>
}

export default ReportingChannelRedirect

import produce from 'immer'
import { StatisticsOverview, StatisticsOverviewAction } from '../actions'
import { StatisticsOverviewActionType } from '../action-types'

interface StatisticsOverviewState {
    isStatisticsLoading: boolean
    statisticsError: null | string
    statisticsOverview: null | StatisticsOverview
}

const initialState: StatisticsOverviewState = {
    isStatisticsLoading: false,
    statisticsError: null,
    statisticsOverview: null,
}

const reducer = produce(
    (
        state: StatisticsOverviewState = initialState,
        action: StatisticsOverviewAction
    ) => {
        switch (action.type) {
            case StatisticsOverviewActionType.STATISTICS_OVERVIEW_REQUEST:
                state.isStatisticsLoading = true
                state.statisticsError = null
                state.statisticsOverview = null
                return state

            case StatisticsOverviewActionType.STATISTICS_OVERVIEW_ERROR:
                state.isStatisticsLoading = false
                state.statisticsError = action.payload
                state.statisticsOverview = null
                return state

            case StatisticsOverviewActionType.STATISTICS_OVERVIEW_SUCCESS:
                state.isStatisticsLoading = false
                state.statisticsError = null
                state.statisticsOverview = action.payload
                return state

            default:
                return state
        }
    }
)

export default reducer

/*
 * Page for resolving an integration
 * For HR-ON we need to verify that the authorization_code is valid
 * And then we can redirect to login or register depending on integration type
 */

import { useLocation, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useActions, useTypedSelector } from '../../../hooks'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import Spinner from '../../layout/spinner/Spinner'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 8.5rem);
`

const ResolveIntegrationPage: React.FC = () => {
    const history = useHistory()
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search)
    const clientSecret = queryParams.get('data')
    const employeeId = queryParams.get('employeeId')
    const clientId = queryParams.get('clientId')
    const redirectUrl = queryParams.get('redirectUrl')

    const { uuid } = useParams<{ uuid: string }>()

    const { getIntegrationByClientId, storeIntegrationData } = useActions()

    const { activeIntegration } = useTypedSelector(
        (state) => state.hrOnIntegration
    )

    useEffect(() => {
        if (!clientId) return

        if (!activeIntegration) {
            getIntegrationByClientId(clientId)
        }

        // eslint-disable-next-line
    }, [getIntegrationByClientId, clientId])

    useEffect(
        () => {
            if (!employeeId || !clientSecret) {
                alert('ClientId or secret missing')
                history.push('/')
            }

            if (!activeIntegration) return

            if (typeof clientSecret !== 'string') return

            const decodedClientSecret = clientSecret.replace(/ /g, '+')

            const handleStoreIntegrationData = async () => {
                if (!activeIntegration) return

                const data: unknown = await storeIntegrationData(
                    uuid,
                    clientId as string,
                    decodedClientSecret
                )

                if (typeof data === 'number') {
                    if (data === 409) {
                        alert(
                            'You have already registered with these account details. If you want to connect it to hr-on go back to hr-on and choose the option to connect an existing account.' +
                                '' +
                                '' +
                                '' +
                                'If you have registered an account with us make sure you have verified your email address.'
                        )

                        window.location.replace(redirectUrl as string)
                    }
                } else {
                    type IntegrationData = {
                        integration_id: string
                    }

                    const integrationId = (data as IntegrationData)
                        .integration_id

                    const walorRedirectUrl = `/${activeIntegration.integration_type}/integrations?integrationId=${integrationId}`
                    history.push(walorRedirectUrl)
                }
            }

            handleStoreIntegrationData()
        },
        // eslint-disable-next-line
        [
            uuid,
            employeeId,
            clientSecret,
            history,
            storeIntegrationData,
            activeIntegration,
            redirectUrl,
        ]
    )

    return (
        <Container>
            <Spinner />
        </Container>
    )
}

export default ResolveIntegrationPage

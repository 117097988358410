import { FC } from 'react'
import { useTranslation } from '../../../../../hooks'
import {
    Card,
    CardHeader,
    CaseDescriptionParagraph,
} from '../../../../styled/cards'
import styled from 'styled-components'

const CardDescription = styled(Card)`
    height: 44.2rem;
    margin-top: 1.2rem;
    min-width: 25.5rem;
    max-width: 65.5rem;
    @media (max-width: 1000px) {
        height: auto;
    }
`

const AudioContainer = styled.div`
    position: relative;
    margin-left: 14rem;
`

const AudioContainerLabel = styled.div`
    position: absolute;
    height: 3.6rem;
    background-color: ${({ theme }) => theme.blue};
    color: #fff;
    left: -15rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 18rem;
    border-radius: 2rem;
`

interface DescriptionCardProps {
    description: string
    openReportVoiceRecording: any
}

const DescriptionCard: FC<DescriptionCardProps> = ({
    description,
    openReportVoiceRecording,
}) => {
    const translation = useTranslation()

    return (
        <CardDescription>
            <CardHeader>{translation.accessCaseFlow.description}</CardHeader>
            <CaseDescriptionParagraph>
                {openReportVoiceRecording && (
                    <AudioContainer>
                        <AudioContainerLabel>
                            {translation.reusable.voiceRecording}
                        </AudioContainerLabel>
                        <audio
                            controls
                            style={{
                                height: '3.6rem',
                            }}
                        >
                            <source src={openReportVoiceRecording} />
                        </audio>
                    </AudioContainer>
                )}
                {description}
            </CaseDescriptionParagraph>
        </CardDescription>
    )
}

export default DescriptionCard

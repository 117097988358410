import { addDays, addWeeks, isWithinInterval } from 'date-fns'
import { MessageInterface } from '../../components/reusable/messages/Messages'

export const getReceiptAndFeedbackDates = (
    openReport: any,
    messages: MessageInterface[]
) => {
    const initialDate = new Date(openReport.created_at)
    const weekDeadline = addWeeks(initialDate, 1)

    const messageFromCaseworker = messages.find(
        (message) => message.source === 'caseworker'
    )

    const closedCaseDate = new Date(openReport.closed_at)
    const feedbackDate = openReport.closed_at
        ? closedCaseDate
        : getFeedbackDate(weekDeadline)

    //if no message from caseworker
    if (!messageFromCaseworker) {
        return {
            receiptDate: weekDeadline,
            feedbackDate,
        }
    }

    const firstReply = new Date(messageFromCaseworker.created_at)
    const repliedWithinWeek = isWithinInterval(firstReply, {
        start: initialDate,
        end: addWeeks(initialDate, 1),
    })

    //if there is a message from caseworker
    if (repliedWithinWeek) {
        const newFeedbackDate = openReport.closed_at
            ? closedCaseDate
            : addDays(firstReply, 92)

        return {
            receiptDate: firstReply,
            feedbackDate: newFeedbackDate,
            repliedWithinWeek,
        }
    } else {
        return { receiptDate: weekDeadline, feedbackDate, repliedWithinWeek }
    }
}

const getFeedbackDate = (date: Date) => {
    return addDays(date, 92)
}

import produce from 'immer'
import { CaseworkerOnboardingActionType } from '../action-types'
import { CaseworkerOnboardingActions } from '../actions'

interface OnboardingSuccess {
    data: {
        userId: number
        email: string
        onboarding_step: 'personal_information' | 'additional_information'
        organization_name: string
    }
}

interface OnboardingState {
    onboardingLoading: boolean
    onboardingError: null | string
    onboardingSuccess: null | OnboardingSuccess

    personalInfoRequest: boolean
    personalInfoLoading: boolean
    personalInfoError: null | string
    personalInfoSuccess: boolean

    additionalInfoRequest: boolean
    additionalInfoLoading: boolean
    additionalInfoError: null | string
    additionalInfoSuccess: boolean
}

const initialState: OnboardingState = {
    onboardingLoading: false,
    onboardingError: null,
    onboardingSuccess: null,

    personalInfoRequest: false,
    personalInfoLoading: false,
    personalInfoError: null,
    personalInfoSuccess: false,

    additionalInfoRequest: false,
    additionalInfoLoading: false,
    additionalInfoError: null,
    additionalInfoSuccess: false,
}

const reducer = produce(
    (
        state: OnboardingState = initialState,
        action: CaseworkerOnboardingActions
    ) => {
        switch (action.type) {
            case CaseworkerOnboardingActionType.ONBOARDING_LOADING:
                state.onboardingLoading = true
                state.onboardingError = null
                state.onboardingSuccess = null

                return state
            case CaseworkerOnboardingActionType.ONBOARDING_ERROR:
                state.onboardingLoading = false
                state.onboardingError = action.payload
                state.onboardingSuccess = null

                return state
            case CaseworkerOnboardingActionType.ONBOARDING_SUCCESS:
                state.onboardingLoading = false
                state.onboardingError = null
                state.onboardingSuccess = action.payload

                return state
            case CaseworkerOnboardingActionType.PERSONAL_INFORMATION_REQUEST:
                state.personalInfoRequest = true
                state.personalInfoLoading = true
                state.personalInfoError = null
                state.personalInfoSuccess = false

                return state
            case CaseworkerOnboardingActionType.PERSONAL_INFORMATION_LOADING:
                state.personalInfoRequest = false
                state.personalInfoLoading = true
                state.personalInfoError = null
                state.personalInfoSuccess = false

                return state
            case CaseworkerOnboardingActionType.PERSONAL_INFORMATION_ERROR:
                state.personalInfoRequest = false
                state.personalInfoLoading = false
                state.personalInfoError = action.payload
                state.personalInfoSuccess = false

                return state
            case CaseworkerOnboardingActionType.PERSONAL_INFORMATION_SUCCESS:
                state.personalInfoRequest = false
                state.personalInfoLoading = false
                state.personalInfoError = null
                state.personalInfoSuccess = true

                return state
            case CaseworkerOnboardingActionType.ADDITIONAL_INFORMATION_REQUEST:
                state.additionalInfoRequest = true
                state.additionalInfoLoading = true
                state.additionalInfoError = null
                state.additionalInfoSuccess = false

                return state
            case CaseworkerOnboardingActionType.ADDITIONAL_INFORMATION_LOADING:
                state.additionalInfoRequest = false
                state.additionalInfoLoading = true
                state.additionalInfoError = null
                state.additionalInfoSuccess = false

                return state
            case CaseworkerOnboardingActionType.ADDITIONAL_INFORMATION_ERROR:
                state.additionalInfoRequest = false
                state.additionalInfoLoading = false
                state.additionalInfoError = action.payload
                state.additionalInfoSuccess = false

                return state
            case CaseworkerOnboardingActionType.ADDITIONAL_INFORMATION_SUCCESS:
                state.additionalInfoRequest = false
                state.additionalInfoLoading = false
                state.additionalInfoError = null
                state.additionalInfoSuccess = true

                return state
            default:
                return state
        }
    }
)

export default reducer

import { combineReducers } from 'redux'
import modalReducer from './modalReducer'
import themeReducer from './themeReducer'
import caseStatisticReducer from './caseStatisticReducer'
import translationReducer from './translationReducer'
import whistleblowerReducer from './whistleblowerReducer'
import caseworkerReducer from './caseworkerReducer'
import authReducer from './authReducer'
import adminReducer from './adminReducer'
import contactUserReducer from './contactUserReducer'
import countriesReducer from './countriesReducer'
import categoryReducer from './categoryReducer'
import clientReducer from './clientReducer'
import lineOfContactReducer from './lineOfContactReducer'
import notificationReducer from './notificationReducer'
import reportInternalNoteReducer from './reportInternalNoteReducer'
import reportHistoryLogReducer from './reportHistoryLogReducer'
import resetPasswordReducer from './resetPasswordReducer'
import caseworkerOpenReportReducer from './caseworkerOpenReportReducer'
import customDesignReducer from './customDesignReducer'
import paymentReducer from './paymentReducer'
import reportQuestionReducer from './reportQuestionReducer'
import organizationReducer from './organizationReducer'
import identityReducer from './identityReducer'
import availableLanguagesRducer from './availableLanguagesReducer'
import hrOnIntegrationReducer from './hrOnIntegrationReducer'
import autoReplyReducer from './autoReplyReducer'
import inviteCaseworkerReducer from './inviteCaseworkerReducer'
import caseworkerOnboardingReducer from './caseworkerOnboardingReducer'
import translateTextReducer from './translateTextReducer'
import translateDescriptionReducer from './translateDescriptionReducer'
import whitelabelReducer from './whitelabelReducer'
import statisticsOverviewReducer from './statisticsOverviewReducer'
import spokenLanguagesReducer from './spokenLanguagesReducer'
import deletedCaseReducer from './deletedCaseReducer'

const reducers = combineReducers({
    theme: themeReducer,
    translation: translationReducer,
    caseStatistic: caseStatisticReducer,
    modal: modalReducer,
    whistleblower: whistleblowerReducer,
    caseworker: caseworkerReducer,
    auth: authReducer,
    admin: adminReducer,
    contactUser: contactUserReducer,
    country: countriesReducer,
    category: categoryReducer,
    client: clientReducer,
    lineOfContact: lineOfContactReducer,
    notification: notificationReducer,
    reportInternalNote: reportInternalNoteReducer,
    reportHistoryLog: reportHistoryLogReducer,
    resetPassword: resetPasswordReducer,
    caseworkerOpenReport: caseworkerOpenReportReducer,
    customDesign: customDesignReducer,
    payment: paymentReducer,
    reportQuestions: reportQuestionReducer,
    organization: organizationReducer,
    identity: identityReducer,
    availableLanguages: availableLanguagesRducer,
    hrOnIntegration: hrOnIntegrationReducer,
    autoReply: autoReplyReducer,
    inviteCaseworker: inviteCaseworkerReducer,
    caseworkerOnboarding: caseworkerOnboardingReducer,
    translateText: translateTextReducer,
    translateDescription: translateDescriptionReducer,
    whitelabel: whitelabelReducer,
    statisticsOverview: statisticsOverviewReducer,
    spokenLanguages: spokenLanguagesReducer,
    deletedCase: deletedCaseReducer,
})

export default reducers

export type RootState = ReturnType<typeof reducers>

import produce from 'immer'
import { LineOfContactActionType } from '../action-types'
import { LineOfContactActions } from '../actions'

export interface FlashMessageInterface {
    type: 'success' | 'error'
    message: string
}

// TODO make reusable
export interface ValidationErrorInterface {
    errors: {
        'contact.email'?: string[]
        'contact.phone'?: string[]
    }
    message: string
}

export interface AddContactErrorsInterface {
    'contact.email'?: string[]
    'contact.phone'?: string[]
}

interface LineOfContactState {
    // Loading
    lineOfContactLoading: boolean
    lineOfContactUpdatePriorityLoading: boolean
    lineOfContactDeleteLoading: boolean
    lineOfContactUpdateLoading: boolean

    lineOfContactError: null | string
    lineOfContacts: any[]
    addLineOfContactErrors: string[]
    addContactErrors: AddContactErrorsInterface | null
    updateContactErrors: null | ValidationErrorInterface
    deleteContactErrors: string[]
    addSelfContactErrors: string[]

    // Flash message state
    flashMessage: null | FlashMessageInterface
}

const InitialState: LineOfContactState = {
    lineOfContactLoading: false,
    lineOfContactUpdatePriorityLoading: false,
    lineOfContactDeleteLoading: false,
    lineOfContactUpdateLoading: false,
    lineOfContactError: null,
    lineOfContacts: [],
    addLineOfContactErrors: [],
    addContactErrors: null,
    updateContactErrors: null,
    deleteContactErrors: [],
    addSelfContactErrors: [],
    flashMessage: null,
}

const reducer = produce(
    (
        state: LineOfContactState = InitialState,
        action: LineOfContactActions
    ) => {
        switch (action.type) {
            case LineOfContactActionType.GET_LINE_OF_CONTACTS:
                state.lineOfContactLoading = false
                state.lineOfContactError = null
                state.lineOfContacts = action.payload

                return state

            case LineOfContactActionType.SHOW_ADD_LINE_OF_CONTACT_ERRORS:
                state.lineOfContactLoading = false
                state.addLineOfContactErrors = action.payload

                return state

            case LineOfContactActionType.CLEAR_ADD_LINE_OF_CONTACT_ERRORS:
                state.addLineOfContactErrors = []

                return state

            case LineOfContactActionType.ADD_CONTACT:
                state.lineOfContactLoading = false
                state.lineOfContacts = action.payload

                return state

            case LineOfContactActionType.SHOW_ADD_CONTACT_ERRORS:
                state.lineOfContactLoading = false
                state.addContactErrors = action.payload

                return state

            case LineOfContactActionType.CLEAR_ADD_CONTACT_ERRORS:
                state.addContactErrors = null

                return state

            case LineOfContactActionType.UPDATE_CONTACT_LIST_IN_STATE:
                // Reset all loading and error states
                state.lineOfContactLoading = false
                state.lineOfContactError = null
                state.lineOfContactDeleteLoading = false
                state.lineOfContactUpdatePriorityLoading = false
                state.lineOfContactUpdateLoading = false

                // Update the line of contacts
                state.lineOfContacts = action.payload

                return state

            case LineOfContactActionType.SHOW_UPDATE_CONTACT_ERRORS:
                state.lineOfContactLoading = false
                state.updateContactErrors = action.payload
                state.lineOfContactUpdateLoading = false

                return state

            case LineOfContactActionType.CLEAR_UPDATE_CONTACT_ERRORS:
                state.updateContactErrors = null

                return state

            case LineOfContactActionType.DELETE_CONTACT:
                state.lineOfContactLoading = false
                state.lineOfContactError = null
                state.lineOfContacts = action.payload

                return state

            case LineOfContactActionType.SHOW_DELETE_CONTACT_ERRORS:
                state.lineOfContactLoading = false
                state.deleteContactErrors = action.payload

                return state

            case LineOfContactActionType.CLEAR_DELETE_CONTACT_ERRORS:
                state.deleteContactErrors = []

                return state

            case LineOfContactActionType.ADD_SELF:
                state.lineOfContactLoading = false
                state.lineOfContactError = null
                state.lineOfContacts = action.payload

                return state

            case LineOfContactActionType.SHOW_ADD_SELF_ERRORS:
                state.lineOfContactLoading = false
                state.addSelfContactErrors = action.payload

                return state

            case LineOfContactActionType.CLEAR_ADD_SELF_ERRORS:
                state.addSelfContactErrors = []

                return state

            case LineOfContactActionType.SET_LINE_OF_CONTACT_DELETE_LOADING:
                state.lineOfContactDeleteLoading = true

                return state

            case LineOfContactActionType.SET_LINE_OF_CONTACTS_LOADING:
                state.lineOfContactLoading = true

                return state

            case LineOfContactActionType.SET_LINE_OF_CONTACT_UPDATE_PRIORITY_LOADING:
                state.lineOfContactUpdatePriorityLoading = true

                return state
            case LineOfContactActionType.ADD_PRIORITY_OF_CONTACTS:
                state.lineOfContactUpdatePriorityLoading = false
                state.lineOfContactError = null

                return state

            case LineOfContactActionType.SHOW_FLASH_MESSAGE:
                state.flashMessage = action.payload

                return state

            case LineOfContactActionType.LOC_CLEAR_FLASH_MESSAGE:
                state.flashMessage = null

                return state

            case LineOfContactActionType.SET_LINE_OF_CONTACT_UPDATE_LOADING:
                state.lineOfContactUpdateLoading = true
                return state

            case LineOfContactActionType.RESET_LINE_OF_CONTACT_LOADING:
                state.lineOfContactDeleteLoading = false
                return state

            default:
                return state
        }
    }
)

export default reducer

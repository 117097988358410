import styled from 'styled-components'
import { Card, CardHeader } from '../../../../../styled/cards'
import { useTranslation } from '../../../../../../hooks'
import { BsFillPersonFill } from 'react-icons/bs'

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

const Modal = styled.div`
    height: 40rem;
    width: 55.6rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 501;
    text-align: center;

    @media screen and (max-width: 480px) {
        width: 35rem;
    }
`

const CancelButton = styled.div`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
    padding: 1rem;
`

const Divider = styled.div`
    height: 60%;
    margin-top: 4rem;
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const TextRegular = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}80`};
    margin: 2rem;
`

const style = { fontSize: '1.6rem' }

interface IAppProps {
    closeModal: () => void
}

const ReporterInfoAnonymModal: React.FC<IAppProps> = ({ closeModal }) => {
    const translation = useTranslation()

    return (
        <ModalContainer>
            <Modal>
                <Card>
                    <CardHeader>
                        <div />
                        <CancelButton onClick={closeModal}>
                            {translation.reusable.cancel}
                        </CancelButton>
                    </CardHeader>

                    <Divider>
                        <TextRegular>
                            {translation.caseworkerOpenReport.thisReportHasBeen}
                        </TextRegular>
                        <BsFillPersonFill style={style} />
                        <TextRegular>
                            {translation.reusable.anonymous}
                        </TextRegular>
                    </Divider>
                </Card>
            </Modal>
        </ModalContainer>
    )
}
export default ReporterInfoAnonymModal

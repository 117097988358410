import styled from 'styled-components'

const OuterContainer = styled.div`
    height: 3rem;
    width: 3rem;
    display: grid;
    place-items: center;
    position: relative;
    margin: 0.5rem;
`

const Circle = styled.div`
    height: 1.5rem;
    width: 1.5rem;
    background-color: ${({ theme }) => theme.green};
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);

    @keyframes scaleIn {
        from {
            transform: scale(0.5, 0.5);
            opacity: 0.5;
        }
        to {
            transform: scale(2.5, 2.5);
            opacity: 0;
        }
    }
`

const Circle3 = styled(Circle)`
    animation-delay: -3s;
`

const Circle2 = styled(Circle)`
    animation-delay: -2s;
`

const Circle1 = styled(Circle)`
    animation-delay: -1s;
`

const Circle0 = styled(Circle)`
    animation-delay: 0s;
`

const Ripple = () => {
    return (
        <OuterContainer>
            <Circle3 />
            <Circle2 />
            <Circle1 />
            <Circle0 />
        </OuterContainer>
    )
}

export default Ripple

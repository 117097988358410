import styled from 'styled-components'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Cell,
} from 'recharts'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from '../../../../../hooks'

const StyledResponsiveContainer = styled(ResponsiveContainer)`
    .recharts-yAxis.yAxis .recharts-cartesian-axis-tick:first-child line {
        stroke: none;
    }

    .recharts-yAxis.yAxis .recharts-cartesian-axis-tick:first-child text {
        font-size: 0;
    }
    div.recharts-legend-wrapper {
        border-bottom: 1px solid ${({ theme }) => theme.lineGrey};
        margin-top: -5.5rem;
        width: 100% !important;
        left: 0;

        @media screen and (max-width: 500px) {
            margin-top: -2.5rem;
        }
    }
`
interface StackedBarCasesProps {
    data: {
        month: string
        new?: number
        open?: number
        closed?: number
    }[]
}
const StackedBarCases: FC<StackedBarCasesProps> = ({ data }) => {
    const translation = useTranslation()
    const [threshold, setThreshold] = useState(5)

    const cashedUpdateThreshold = useCallback(() => {
        const increasedThreshold = Math.round(1.25 * threshold) //increasing threshold by 25%, rounded up
        if (
            data.some(
                //check if the total amount of cases reached 80% from prev threshold
                (entry) =>
                    (entry.new ?? 0) +
                        (entry.open ?? 0) +
                        (entry.closed ?? 0) >=
                    threshold * 0.8
            )
        ) {
            setThreshold(increasedThreshold)
        }
    }, [data, threshold])

    const renderTicks = () => {
        if (threshold === 5) {
            return [0, 1, 2, 3, 4, 5]
        }

        if (threshold === 10) {
            return [0, 2, 4, 6, 8, 10]
        }

        if (threshold % 3 === 0) {
            return [0, threshold / 3, (threshold * 2) / 3, threshold]
        }
        return [
            0,
            Math.ceil(threshold * 0.25),
            Math.ceil(threshold * 0.5),
            Math.ceil(threshold * 0.75),
            threshold,
        ]
    }

    useEffect(() => {
        cashedUpdateThreshold()
    }, [cashedUpdateThreshold])

    return (
        <StyledResponsiveContainer width="100%" height="85%">
            <BarChart
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                barSize={58}
            >
                <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                    stroke="rgba(14, 38, 61, 0.2)"
                />
                <XAxis
                    dataKey="month"
                    tickSize={0}
                    tickMargin={10}
                    fontSize={14}
                />
                <YAxis
                    stroke="0"
                    tickSize={0}
                    tickMargin={10}
                    fontSize={14}
                    domain={[0, threshold]}
                    ticks={renderTicks()}
                    tick={{ fill: '#666' }}
                />
                <Tooltip />
                <Legend
                    align={'right'}
                    verticalAlign="top"
                    wrapperStyle={{
                        paddingBottom: '2rem',
                    }}
                    iconType="circle"
                    formatter={(value) => (
                        <span style={{ color: '#0E263D' }}>
                            {value === 'new'
                                ? translation.reusable.new
                                : value === 'open'
                                ? translation.dashboardPartner.yourStatistics
                                      .open
                                : value === 'closed' &&
                                  translation.dashboardPartner.yourStatistics
                                      .closed}
                        </span>
                    )}
                />
                <Bar
                    dataKey="new"
                    stackId="a"
                    fill="rgba(106, 124, 145, 0.8)"
                    radius={[5, 5, 0, 0]}
                    cursor="pointer"
                >
                    {data.map((entry, index) =>
                        entry.open || entry.closed ? (
                            <Cell key={`cell-new-${index}`} radius={0} />
                        ) : (
                            <Cell key={`cell-new-${index}`} />
                        )
                    )}
                </Bar>
                <Bar
                    dataKey="open"
                    stackId="a"
                    fill="rgba(141, 154, 159, 0.8)"
                    radius={[5, 5, 0, 0]}
                    cursor="pointer"
                >
                    {data.map((entry, index) =>
                        entry.closed ? (
                            <Cell key={`cell-open-${index}`} radius={0} />
                        ) : (
                            <Cell key={`cell-open-${index}`} />
                        )
                    )}
                </Bar>
                <Bar
                    dataKey="closed"
                    stackId="a"
                    fill="rgba(49, 60, 77, 0.8)"
                    radius={[5, 5, 0, 0]}
                    cursor="pointer"
                />
            </BarChart>
        </StyledResponsiveContainer>
    )
}

export default StackedBarCases

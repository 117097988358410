import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Card, CardHeader } from '../../../../styled/cards'
import { Form } from './frames/styled'
import {
    ErrorMessage,
    FormField,
    FormInput40,
    PrimaryLabel,
} from '../../../../styled/formElements'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'
import {
    useTranslation,
    useTypedSelector,
    useActions,
} from '../../../../../hooks'
import { checkEmail, isValidEmail } from '../../../../../helpers/validation'

interface IsActiveProp {
    isActive: boolean
}

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

const Modal = styled.div`
    height: 30rem;
    width: 55.6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 501;
`

const CancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
`

const FormContent = styled.div`
    width: 35rem;
    margin: 2rem auto 0 auto;
`

const Button = styled.div<IsActiveProp>`
    height: 5rem;
    width: 100%;
    background-color: ${({ theme, isActive }) =>
        isActive ? `${theme.primary}` : `${theme.primary}50`};
    color: ${({ theme }) => theme.white};
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    cursor: pointer;
`

const ButtonWrapper = styled.div`
    padding: 0 1.4rem;
    margin-top: 2rem;
`

interface InviteContactEmailProps {
    closeModal: () => void
    isLoading: boolean
    error: null | string
}

const InviteContactByEmail: React.FC<InviteContactEmailProps> = ({
    closeModal,
    isLoading,
    error,
}) => {
    const translation = useTranslation()
    const { client } = useTypedSelector((state) => state.client)
    const { inviteCaseworkerSuccess } = useTypedSelector(
        (state) => state.inviteCaseworker
    )
    const { inviteCaseworkerByEmail } = useActions()

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [emailSuccess, setEmailSuccess] = useState(false)

    const emailOnFocus = () => {
        setEmailError('')
        setEmailSuccess(false)
    }

    const emailValidation = () => {
        setEmailError(checkEmail(email, translation))
        setEmailSuccess(emailError.length === 0)
    }

    const isFormValidated = () => {
        return isValidEmail(email)
    }

    const handleClick = () => {
        isFormValidated() && inviteCaseworkerByEmail(client.id, email)
    }

    useEffect(() => {
        error && setEmailError(error)
    }, [error])

    useEffect(() => {
        inviteCaseworkerSuccess && closeModal()
        // eslint-disable-next-line
    }, [inviteCaseworkerSuccess])

    useEffect(() => {
        setEmailError('')
    }, [])

    return (
        <ModalContainer>
            <Modal>
                <Card>
                    <CardHeader>
                        {translation.inviteCaseworkerModal.inviteNewCaseworker}
                        <CancelButton onClick={() => closeModal()}>
                            {translation.reusable.cancel}
                        </CancelButton>
                    </CardHeader>
                    <Form>
                        <FormContent>
                            <FormField>
                                <PrimaryLabel
                                    isError={!!emailError}
                                    isSuccess={emailSuccess}
                                >
                                    {translation.reusable.email} *
                                </PrimaryLabel>
                                <FormInput40
                                    type="text"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    isSuccess={emailSuccess}
                                    isError={emailError.length > 0}
                                    onBlur={emailValidation}
                                    onFocus={emailOnFocus}
                                />
                                {emailError.length > 0 && (
                                    <ErrorMessage>{emailError}</ErrorMessage>
                                )}
                            </FormField>
                        </FormContent>
                        <ButtonWrapper>
                            <Button
                                isActive={isFormValidated()}
                                onClick={handleClick}
                            >
                                {isLoading ? (
                                    <ButtonSpinnerWhite />
                                ) : (
                                    <>
                                        {
                                            translation.inviteCaseworkerModal
                                                .sendInvitation
                                        }
                                    </>
                                )}
                            </Button>
                        </ButtonWrapper>
                    </Form>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default InviteContactByEmail

import styled from 'styled-components'
import {
    BigCard,
    Block,
    Flex,
    NoData,
    Title,
    TotalNumber,
} from '../StatisticsPage'
import { useTranslation, useTypedSelector } from '../../../../../hooks'

const Info = styled.p`
    font-size: 1.8rem;
    margin: 0 0 0 0.5rem;
    padding: 2rem 0;
`

const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    margin-bottom: 4rem;
`

const ActionStats = () => {
    const translation = useTranslation()
    const { statisticsOverview } = useTypedSelector(
        (state) => state.statisticsOverview
    )
    return (
        <BigCard>
            <Title>{translation.statisticsPage.actionStats}</Title>
            {statisticsOverview?.averages.toClose &&
            statisticsOverview?.averages.toRespond ? (
                <CenterContainer>
                    <Block>
                        <Info>
                            {translation.statisticsPage.avgDaysToFirstResponse}
                        </Info>
                        <Flex alignItems="baseline" justifyContent="normal">
                            <TotalNumber>
                                {`${statisticsOverview.averages.toRespond} ${
                                    statisticsOverview.averages.toRespond === 1
                                        ? translation.trialWarning.day
                                        : translation.trialWarning.days
                                } `}
                            </TotalNumber>
                            {/* <UpOrDown color="red">
                                <ArrowUp color="red" />
                                11% {translation.statisticsPage.lastYear}
                            </UpOrDown> */}
                        </Flex>
                    </Block>
                    <Block>
                        <Info>
                            {translation.statisticsPage.avgDaysToClosedCase}
                        </Info>
                        <Flex alignItems="baseline" justifyContent="normal">
                            <TotalNumber>
                                {`${statisticsOverview.averages.toClose} ${
                                    statisticsOverview.averages.toClose === 1
                                        ? translation.trialWarning.day
                                        : translation.trialWarning.days
                                } `}
                            </TotalNumber>
                            {/* <UpOrDown color="green">
                                <ArrowDown color="green" />
                                31% {translation.statisticsPage.lastYear}
                            </UpOrDown> */}
                        </Flex>
                    </Block>
                </CenterContainer>
            ) : (
                <NoData>{translation.statisticsPage.noData}</NoData>
            )}
        </BigCard>
    )
}

export default ActionStats

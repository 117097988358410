import produce from 'immer'
import { DeletedCaseAction } from '../actions/deletedCaseAction'
import { DeletedCaseActionType } from '../action-types'

type LogLabel =
    | 'created'
    | 'status_updated'
    | 'automatic_reply_sent'
    | 'internal_note'
    | 'caseworker_new_file'
    | 'category_updated'
    | 'reporter_reply'
    | 'reporter_new_file'
    | 'exported'
    | 'caseworker_added'

interface BaseLog {
    id: number
    label: LogLabel
    createdAt: string
}

interface CreatedLog extends BaseLog {
    label: 'created'
    attributes: {
        reporter: string
    }
}

interface StatusUpdatedLog extends BaseLog {
    label: 'status_updated'
    attributes: {
        by: string
        to: string
        from: string
    }
}

interface AutomaticReplySentLog extends BaseLog {
    label: 'automatic_reply_sent'
    attributes: {
        by: string
    }
}

interface InternalNoteLog extends BaseLog {
    label: 'internal_note'
    attributes: {
        by: string
    }
}

interface CaseworkerNewFileLog extends BaseLog {
    label: 'caseworker_new_file'
    attributes: {
        by: string
    }
}

interface CategoryUpdatedLog extends BaseLog {
    label: 'category_updated'
    attributes: {
        by: string
        to: string | null
        from: string | null
    }
}

interface ReporterReplyLog extends BaseLog {
    label: 'reporter_reply'
    attributes: {
        reporter: string
    }
}

interface ReporterNewFileLog extends BaseLog {
    label: 'reporter_new_file'
    attributes: {
        reporter: string
    }
}

interface ExportedLog extends BaseLog {
    label: 'exported'
    attributes: {
        by: string
    }
}

interface CaseworkerAddedLog extends BaseLog {
    label: 'caseworker_added'
    attributes: {
        by: string
        caseworker: string
    }
}

type Log =
    | CreatedLog
    | StatusUpdatedLog
    | AutomaticReplySentLog
    | InternalNoteLog
    | CaseworkerNewFileLog
    | CategoryUpdatedLog
    | ReporterReplyLog
    | ReporterNewFileLog
    | ExportedLog
    | CaseworkerAddedLog

interface File {
    id: number
    size: number
    extension: string
    createdAt: string
    source: 'caseworker' | 'reporter'
}

interface LoadingState {
    type: 'loading'
}

export type DeletedCase = {
    id: number
    readableId: string
    createdAt: string
    caseType: string
    caseworkers: Array<{ profileImg: string | null }>
    logs: Log[]
    files: { caseworker: File[]; reporter: File[] }
}

interface SuccessState {
    type: 'success'
    case: DeletedCase
}

type DeletedCaseState = LoadingState | SuccessState

const initialState: DeletedCaseState = {
    type: 'loading',
}

const reducer = produce(
    (state: DeletedCaseState = initialState, action: DeletedCaseAction) => {
        switch (action.type) {
            case DeletedCaseActionType.SHOW_DELETED_INCIDENT:
                state = {
                    type: 'success',
                    case: action.payload,
                }

                return state
            default:
                return state
        }
    }
)

export default reducer

import styled from 'styled-components'
import {
    ErrorMessage,
    FormInput40,
    PrimaryLabel,
    SmallFormField,
} from '../../../../../styled/formElements'
import {
    CardElement,
    IbanElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js'
import ButtonSpinnerWhite from '../../../../../layout/spinner/ButtonSpinnerWhite'
import React, { MouseEvent, useEffect, useState } from 'react'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../../hooks'
import {
    CardContainer,
    CityContainer,
    CityZipFlex,
    Form,
    PaymentTypeHeading,
    ZipContainer,
} from './styled'
import { ButtonFullWidth } from '../../../../../styled/buttons'
import {
    checkAddress,
    checkName,
    checkZipCode,
    checkEmail,
    checkCity,
    isValidName,
    isValidEmail,
    isValidAddress,
    isValidCity,
    isValidZipCode,
} from '../../../../../../helpers/validation'
import {
    StripeCardElementChangeEvent,
    StripeIbanElementChangeEvent,
} from '@stripe/stripe-js'

interface AddPaymentMethodFormProps {
    paymentType: 'invoice' | 'card'
    closeModal: () => void
}

const CheckboxLabel = styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`

const CheckBox = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`

const AddPaymentMessage = styled.div`
    font-size: 1.2rem;
    padding-block-start: 1rem;
    text-align: center;
`
interface CheckMarkInterface {
    isChecked: boolean
}

const CheckMark = styled.span<CheckMarkInterface>`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: ${({ theme, isChecked }) =>
        isChecked ? theme.blue : theme.white};
    border: 1px solid ${({ theme }) => `${theme.primary}50`};
    border-radius: 0.5rem;

    :after {
        display: ${({ isChecked }) => (isChecked ? 'block' : 'none')};
        content: '';
        position: absolute;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`

const ErrorMsg = styled(ErrorMessage)`
    bottom: 0;
`

const AddPaymentMethodForm: React.FC<AddPaymentMethodFormProps> = ({
    paymentType,
    closeModal,
}) => {
    const translation = useTranslation()

    const elements = useElements()
    const stripe = useStripe()

    interface PaymentMethodInterface {
        name: string
        email: string
        address: string
        city: string
        zipCode: string
        default: boolean
    }

    const [paymentMethodInformation, setPaymentMethodInformation] =
        useState<PaymentMethodInterface>({
            name: '',
            email: '',
            address: '',
            city: '',
            zipCode: '',
            default: true,
        })

    const [errorState, setErrorState] = useState({
        nameError: '',
        emailError: '',
        addressError: '',
        cityError: '',
        zipError: '',
    })

    const errorMessageResolver = (errorField: string) => {
        if (errorField === 'nameError') {
            return checkName(paymentMethodInformation.name, translation)
        } else if (errorField === 'emailError') {
            return checkEmail(paymentMethodInformation.email, translation)
        } else if (errorField === 'addressError') {
            return checkAddress(paymentMethodInformation.address, translation)
        } else if (errorField === 'cityError') {
            return checkCity(paymentMethodInformation.city, translation)
        } else if (errorField === 'zipError') {
            return checkZipCode(paymentMethodInformation.zipCode, translation)
        }
    }

    const handleBlur = (errorField: string) => {
        const errorMessage = errorMessageResolver(errorField)

        if (!errorMessage) {
            return
        }

        setErrorState({
            ...errorState,
            [errorField]: errorMessage,
        })
    }

    const handleFocus = (errorField: string) => {
        setErrorState({
            ...errorState,
            [errorField]: '',
        })
    }

    const { user } = useTypedSelector((state) => state.auth)

    const { addPaymentMethodLoading, addPaymentMethodError } = useTypedSelector(
        (state) => state.payment
    )

    const {
        createSetupIntent,
        addPaymentMethod,
        setAddPaymentMethodLoading,
        setAddPaymentMethodError,
    } = useActions()

    const [successState, setSuccessState] = useState({
        name: false,
        email: false,
        address: false,
        city: false,
        zipCode: false,
    })

    const [stripeElementSuccess, setStripeElementSuccess] = useState(false)

    const handleStripeStateCheck = (
        event: StripeCardElementChangeEvent | StripeIbanElementChangeEvent
    ) => {
        setStripeElementSuccess(event.complete)
    }

    useEffect(() => {
        // payment type was changed means stripe element is empty
        // and we have to remove existing success state
        setStripeElementSuccess(false)
    }, [paymentType])

    const successStateResolver: (field: string) => boolean = (field) => {
        if (field === 'name') {
            return isValidName(paymentMethodInformation[field])
        } else if (field === 'email') {
            return isValidEmail(paymentMethodInformation[field])
        } else if (field === 'address') {
            return isValidAddress(paymentMethodInformation[field])
        } else if (field === 'city') {
            return isValidCity(paymentMethodInformation[field])
        } else if (field === 'zipCode') {
            return isValidZipCode(paymentMethodInformation[field])
        }

        return false
    }

    const isFormValidated: () => boolean = () =>
        !(Object.values(successState).indexOf(false) > -1) &&
        stripeElementSuccess

    const handleUpdateForm = (key: string, value: string | boolean) => {
        setPaymentMethodInformation({
            ...paymentMethodInformation,
            [key]: value,
        })

        key !== 'default' && setLatestInputChanged(key)
    }

    const [latestInputChanged, setLatestInputChanged] = useState('')

    useEffect(() => {
        if (latestInputChanged === '') {
            return
        }

        setSuccessState({
            ...successState,
            [latestInputChanged]: successStateResolver(latestInputChanged),
        })

        // eslint-disable-next-line
    }, [paymentMethodInformation, latestInputChanged])

    const handleAddPaymentMethod = async (
        event: MouseEvent<HTMLDivElement>
    ) => {
        if (!isFormValidated()) {
            return
        }

        if (addPaymentMethodLoading) {
            return
        }

        if (!elements) {
            return
        }

        const domElement: any =
            paymentType === 'card' ? CardElement : IbanElement

        const stripeElement = elements.getElement(domElement)

        if (!user || !stripeElement || !stripe) {
            return
        }

        setAddPaymentMethodLoading()

        event.preventDefault()

        const res: any = await createSetupIntent(user.userId)

        if (typeof res === 'undefined') {
            return
        }

        const clientSecret = res.data.client_secret

        const stripeElementKey = paymentType === 'card' ? 'card' : 'sepa_debit'

        const payload: any = {
            payment_method: {
                [stripeElementKey]: stripeElement,
                billing_details: {
                    name: paymentMethodInformation.name,
                    email: paymentMethodInformation.email,
                    address: {
                        city: paymentMethodInformation.city,
                        line1: paymentMethodInformation.address,
                    },
                },
            },
        }

        let res2
        try {
            if (paymentType === 'card') {
                res2 = await stripe.confirmCardSetup(clientSecret, payload)
            } else {
                res2 = await stripe.confirmSepaDebitSetup(clientSecret, payload)
            }

            if (
                typeof res2 === 'undefined' ||
                typeof res2.setupIntent === 'undefined'
            ) {
                return
            }

            const paymentMethod = res2.setupIntent.payment_method

            const success: any = await addPaymentMethod(
                user.userId,
                {
                    ...paymentMethodInformation,
                    paymentMethod,
                },
                'invoice'
            )

            if (!success) {
                return
            }

            if (success) {
                closeModal()
            }
        } catch (error: any) {
            setAddPaymentMethodError(
                'An error occurred. Please, try again later.'
            )
            return
        }
    }

    return (
        <>
            <PaymentTypeHeading>
                {paymentType === 'card'
                    ? translation.settingsPartner.oldAddPaymentMethodFrame
                          .addPaymentMethodModal.cardInformation
                    : translation.settingsPartner.oldAddPaymentMethodFrame
                          .addPaymentMethodModal.bankInformation}
            </PaymentTypeHeading>
            <Form>
                <SmallFormField>
                    <PrimaryLabel isError={errorState.nameError.length > 0}>
                        {
                            translation.settingsPartner.oldAddPaymentMethodFrame
                                .addPaymentMethodModal.fullName
                        }
                    </PrimaryLabel>
                    <FormInput40
                        type="text"
                        name="name"
                        isError={errorState.nameError.length > 0}
                        value={paymentMethodInformation.name}
                        onChange={(event) =>
                            handleUpdateForm('name', event.target.value)
                        }
                        onBlur={() => handleBlur('nameError')}
                        onFocus={() => handleFocus('nameError')}
                    />
                    {errorState.nameError.length > 0 && (
                        <ErrorMsg>{errorState.nameError}</ErrorMsg>
                    )}
                </SmallFormField>
                <SmallFormField>
                    <PrimaryLabel isError={errorState.emailError.length > 0}>
                        {translation.reusable.email}
                    </PrimaryLabel>
                    <FormInput40
                        name="email"
                        type="email"
                        isError={errorState.emailError.length > 0}
                        value={paymentMethodInformation.email}
                        onChange={(event) =>
                            handleUpdateForm('email', event.target.value)
                        }
                        onBlur={() => handleBlur('emailError')}
                        onFocus={() => handleFocus('emailError')}
                    />
                    {errorState.emailError.length > 0 && (
                        <ErrorMsg>{errorState.emailError}</ErrorMsg>
                    )}
                </SmallFormField>
                <SmallFormField>
                    <PrimaryLabel>
                        {paymentType === 'card'
                            ? translation.settingsPartner
                                  .oldAddPaymentMethodFrame
                                  .addPaymentMethodModal.card
                            : 'Iban'}
                    </PrimaryLabel>
                    <CardContainer>
                        {paymentType === 'card' ? (
                            <div>
                                <CardElement
                                    options={{
                                        hidePostalCode: true,
                                    }}
                                    onChange={(event) =>
                                        handleStripeStateCheck(event)
                                    }
                                />
                            </div>
                        ) : (
                            <IbanElement
                                options={{
                                    supportedCountries: ['SEPA'],
                                    placeholderCountry: 'DK',
                                }}
                                onChange={(event) =>
                                    handleStripeStateCheck(event)
                                }
                            />
                        )}
                    </CardContainer>
                </SmallFormField>
                <SmallFormField>
                    <PrimaryLabel isError={errorState.addressError.length > 0}>
                        {translation.reusable.address}
                    </PrimaryLabel>
                    <FormInput40
                        type="text"
                        name="address"
                        isError={errorState.addressError.length > 0}
                        value={paymentMethodInformation.address}
                        onChange={(event) =>
                            handleUpdateForm('address', event.target.value)
                        }
                        onBlur={() => handleBlur('addressError')}
                        onFocus={() => handleFocus('addressError')}
                    />
                    {errorState.addressError.length > 0 && (
                        <ErrorMsg>{errorState.addressError}</ErrorMsg>
                    )}
                </SmallFormField>
                <SmallFormField>
                    <CityZipFlex>
                        <CityContainer>
                            <PrimaryLabel
                                isError={errorState.cityError.length > 0}
                            >
                                {translation.reusable.city}
                            </PrimaryLabel>
                            <FormInput40
                                type="text"
                                name="city"
                                isError={errorState.cityError.length > 0}
                                value={paymentMethodInformation.city}
                                onChange={(event) =>
                                    handleUpdateForm('city', event.target.value)
                                }
                                onBlur={() => handleBlur('cityError')}
                                onFocus={() => handleFocus('cityError')}
                            />
                            {errorState.cityError.length > 0 && (
                                <ErrorMsg>{errorState.cityError}</ErrorMsg>
                            )}
                        </CityContainer>
                        <ZipContainer>
                            <PrimaryLabel
                                isError={errorState.zipError.length > 0}
                            >
                                {translation.reusable.zipCode}
                            </PrimaryLabel>
                            <FormInput40
                                type="text"
                                name="zip"
                                isError={errorState.zipError.length > 0}
                                value={paymentMethodInformation.zipCode}
                                onChange={(event) =>
                                    handleUpdateForm(
                                        'zipCode',
                                        event.target.value
                                    )
                                }
                                onBlur={() => handleBlur('zipError')}
                                onFocus={() => handleFocus('zipError')}
                            />
                            {errorState.zipError.length > 0 && (
                                <ErrorMsg>{errorState.zipError}</ErrorMsg>
                            )}
                        </ZipContainer>
                    </CityZipFlex>
                </SmallFormField>
                <SmallFormField>
                    <PrimaryLabel>
                        {
                            translation.settingsPartner.oldAddPaymentMethodFrame
                                .defaultPaymentMethod
                        }
                    </PrimaryLabel>
                    <br />

                    <CheckboxLabel>
                        <CheckBox
                            type="checkbox"
                            onChange={() =>
                                handleUpdateForm(
                                    'default',
                                    !paymentMethodInformation.default
                                )
                            }
                        />
                        <CheckMark
                            isChecked={paymentMethodInformation.default}
                        />
                    </CheckboxLabel>
                </SmallFormField>
                <SmallFormField
                    style={{
                        marginTop: '2rem',
                    }}
                >
                    <ButtonFullWidth
                        onClick={(event) => handleAddPaymentMethod(event)}
                        isActive={isFormValidated()}
                    >
                        {!addPaymentMethodLoading ? (
                            'Submit'
                        ) : (
                            <ButtonSpinnerWhite />
                        )}
                    </ButtonFullWidth>
                    {addPaymentMethodLoading && (
                        <AddPaymentMessage>
                            {
                                translation.settingsPartner
                                    .oldAddPaymentMethodFrame
                                    .addPaymentMethodModal.loginMessage
                            }
                        </AddPaymentMessage>
                    )}

                    {addPaymentMethodError && !addPaymentMethodLoading && (
                        <AddPaymentMessage style={{ color: 'red' }}>
                            {addPaymentMethodError}
                        </AddPaymentMessage>
                    )}
                </SmallFormField>
            </Form>
        </>
    )
}

export default AddPaymentMethodForm

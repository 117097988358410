import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import { Card, CardHeader } from '../../../../styled/cards'
import categoryTransformer, {
    Category,
    singleCategoryTransformer,
} from '../../../../../state/transformers/CategoryTransformer'
import { BiChevronsDown, BiChevronsUp } from 'react-icons/bi'
import { CategoryInterface } from '../../../../../state/reducers/categoryReducer'
import { PlusIcon } from '../SingleCase'

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

const Modal = styled.div`
    width: 65.3rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 501;
    text-align: center;
    @media screen and (max-width: 480px) {
        width: 35rem;
        height: 50rem;
    }
`

const HeaderButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const HeaderCloseButton = styled.div`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
    padding: 1rem;

    :hover {
        color: ${({ theme }) => theme.primary};
    }
`

const SelectedCategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    font-size: 1.4rem;
    gap: 1.5rem;
`

const SelectedCategory = styled.div`
    height: 3.2rem;
    padding: 0 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.primary};
    border: ${({ theme }) => `1px solid ${theme.greyText}`};
    color: ${({ theme }) => theme.white};
    border-radius: 10rem;
    font-size: 1.4rem;
    margin: auto;
`

const UncategorizedContainer = styled.div`
    height: 3.2rem;
    padding: 0 1.4rem;
    display: flex;
    align-items: center;
    border: ${({ theme }) => `1px solid ${theme.greyText}`};
    color: ${({ theme }) => theme.greyText};
    border-radius: 10rem;
    font-size: 1.4rem;
    margin: auto;
`

export const DividingLine = styled.div`
    height: 1px;
    background-color: ${({ theme }) => theme.greyText};
`

const CategoryText = styled.div`
    font-size: 1.4rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
    margin-top: 2rem;
`

export const ButtonContainer = styled.div`
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.8rem;
    width: 100%;
    max-height: 40rem;
`

interface IsActiveProp {
    isActive: boolean
}

export const CategoryButton = styled.div<IsActiveProp>`
    height: 3.2rem;
    padding: 0.6rem 1.4rem;
    border: ${({ theme }) => `1px solid ${theme.greyText}`};
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};
    border-radius: 10rem;
    font-size: 1.4rem;
    cursor: pointer;
    transition: all 0.3s;
    background: ${({ theme, isActive }) => isActive && theme.primary};
    max-width: 25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    :hover {
        color: ${({ theme }) => theme.white};
        background: ${({ theme }) => theme.primary};
    }
`
const HoverTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const ShowTextOnHover = styled.div`
    background-color: ${({ theme }) => theme.blue900};
    color: ${({ theme }) => theme.white};
    padding: 1rem;
    border-radius: 5rem;
    margin-block: 3.2rem;
    margin-inline: 0;
    position: fixed;
`

export const ViewMoreDrawer = styled.div`
    color: ${({ theme }) => theme.greyText};
    cursor: pointer;
`

export const ViewMoreText = styled.div`
    font-size: 1.2rem;
`

export const ViewMoreIcon = styled(BiChevronsDown)`
    font-size: 3rem;
`

export const ViewLessIcon = styled(BiChevronsUp)`
    font-size: 3rem;
`

interface ChangeCategoryModalProps {
    closeModal: () => void
    selectedCategory: Category
}

const ChangeCategoryModal: React.FC<ChangeCategoryModalProps> = ({
    closeModal,
    selectedCategory,
}) => {
    const THRESHOLD_LENGTH = 35
    const CATEGORY_DISPLAY_NUMBER = 10

    const translation = useTranslation()

    const { categories } = useTypedSelector((state) => state.category)

    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const { updateCategory } = useActions()

    const [localSelectedCategory, setLocalSelectedCategory] =
        useState<Category | null>(null)

    const [isCategoryTextExpanded, setIsCategoryTextExpanded] = useState<
        boolean[]
    >([])

    const [showCategories, setShowCategories] = useState(
        CATEGORY_DISPLAY_NUMBER
    )

    useEffect(() => {
        if (selectedCategory.name) {
            const findCategorySlug = categories?.find(
                (category: { name: string }) =>
                    category.name === selectedCategory.name
            )
            if (findCategorySlug) {
                const transformedCategory = singleCategoryTransformer(
                    findCategorySlug,
                    translation
                )
                setLocalSelectedCategory(transformedCategory)
            }
        }
    }, [selectedCategory, categories, translation])

    const handleSaveChange = async (categoryId: any) => {
        if (!categoryId) return

        const success: any = await updateCategory(openReport.id, {
            category_id: categoryId,
        })

        if (success) {
            setLocalSelectedCategory(categoryId)
            closeModal()
        }
    }

    const handleMouseOver = (index: number) => {
        const showText = [...isCategoryTextExpanded]
        showText[index] = true
        setIsCategoryTextExpanded(showText)
    }

    const handleMouseOut = (index: number) => {
        const showText = [...isCategoryTextExpanded]
        showText[index] = false
        setIsCategoryTextExpanded(showText)
    }

    return (
        <ModalContainer>
            <Modal>
                <Card>
                    <CardHeader>
                        {translation.caseworkerOpenReport.categorizeCase}
                        <HeaderButtonContainer>
                            <HeaderCloseButton onClick={closeModal}>
                                {translation.caseworkerOpenReport.close}
                            </HeaderCloseButton>
                        </HeaderButtonContainer>
                    </CardHeader>
                    <SelectedCategoryContainer>
                        {selectedCategory.name ? (
                            <>
                                {
                                    translation.caseworkerOpenReport
                                        .selectedCategory
                                }
                            </>
                        ) : (
                            <>
                                {
                                    translation.caseworkerOpenReport
                                        .selectCategory
                                }
                            </>
                        )}
                        {localSelectedCategory?.name ? (
                            <SelectedCategory>
                                {localSelectedCategory?.name}
                            </SelectedCategory>
                        ) : !selectedCategory.name ? (
                            <UncategorizedContainer>
                                <PlusIcon />
                                {translation.caseworkerOpenReport.uncategorized}
                            </UncategorizedContainer>
                        ) : (
                            <SelectedCategory>
                                {selectedCategory.name}
                            </SelectedCategory>
                        )}
                    </SelectedCategoryContainer>
                    <DividingLine />
                    <CategoryText>
                        {showCategories === CATEGORY_DISPLAY_NUMBER ? (
                            <>
                                {
                                    translation.caseworkerOpenReport
                                        .frequentCategories
                                }
                            </>
                        ) : (
                            <>
                                {translation.caseworkerOpenReport.allCategories}
                            </>
                        )}
                    </CategoryText>
                    {categories && (
                        <ButtonContainer>
                            {categoryTransformer(categories, translation)
                                .filter((_c, index) => index < showCategories)
                                .map((category: CategoryInterface, index) => {
                                    const shouldShowHoverEffect =
                                        category.name.length > THRESHOLD_LENGTH
                                    return (
                                        <CategoryButton
                                            key={category.id}
                                            onClick={() =>
                                                handleSaveChange(category.id)
                                            }
                                            isActive={
                                                category.id ===
                                                localSelectedCategory?.id
                                            }
                                            onMouseOver={() =>
                                                handleMouseOver(index)
                                            }
                                            onMouseOut={() =>
                                                handleMouseOut(index)
                                            }
                                        >
                                            {isCategoryTextExpanded[index] &&
                                                shouldShowHoverEffect && (
                                                    <HoverTextContainer>
                                                        <ShowTextOnHover>
                                                            {category.name}
                                                        </ShowTextOnHover>
                                                    </HoverTextContainer>
                                                )}
                                            {category.name}
                                        </CategoryButton>
                                    )
                                })}
                        </ButtonContainer>
                    )}
                    {showCategories === CATEGORY_DISPLAY_NUMBER ? (
                        <ViewMoreDrawer
                            onClick={() =>
                                categories && setShowCategories(
                                    categoryTransformer(categories, translation)
                                        .length
                                )
                            }
                        >
                            <ViewMoreText>
                                {
                                    translation.caseworkerOpenReport
                                        .ViewMoreCategories
                                }
                            </ViewMoreText>
                            <ViewMoreIcon />
                        </ViewMoreDrawer>
                    ) : (
                        <ViewMoreDrawer
                            onClick={() =>
                                setShowCategories(CATEGORY_DISPLAY_NUMBER)
                            }
                        >
                            <ViewMoreText>
                                {translation.caseworkerOpenReport.ViewLess}
                            </ViewMoreText>
                            <ViewLessIcon />
                        </ViewMoreDrawer>
                    )}
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default ChangeCategoryModal

import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { HeadingSecondary } from '../../../styled/typography'
import { Card } from '../../../styled/cards'
//import { TfiArrowUp, TfiArrowDown, TfiDownload } from 'react-icons/tfi'
import { FlexContainer } from '../../../reusable/frames/CompanyInformationFrame'
import PieChartCategories from './components/PieChartCategories'
import StackedBarCases from './components/StackedBarCases'
import TotalCases from './components/TotalCases'
import AnonymousConfidential from './components/AnonymousConfidential'
import ActionStats from './components/ActionStats'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import {
    Category,
    singleCategoryTransformer,
} from '../../../../state/transformers/CategoryTransformer'
import LoadingStatisticsPage from '../../loading/LoadingStatisticsPage'

export interface ColorInterface {
    color: string
}

interface TitleProps {
    margin?: string
}

interface BigCardProps {
    padding?: string
}

// interface IsActiveProps {
//     isActive: boolean | undefined
// }

interface SmallCardProps {
    justifyContent?: string
    padding?: string
}

interface FlexProps {
    alignItems: string
    justifyContent: string
}

interface PieChartEntry {
    slag: string
    value: number
    fill: string
    name: string
}

const Statistics = styled.div``

const SecondaryHeading = styled(HeadingSecondary)`
    @media (max-width: 680px) {
        padding: 1rem;
    }
`

const Toolbar = styled.div`
    display: flex;
`

const ToolbarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2.5rem 4.4rem 1.3rem 4rem;
    @media (max-width: 680px) {
        padding-left: 0;
    }
`

// const Font16 = styled.span`
//     font-size: 1.6rem;
// `

// const DownloadButton = styled.div`
//     height: 3.9rem;
//     width: 13.8rem;
//     background-color: ${({ theme }) => theme.black};
//     color: ${({ theme }) => theme.white};
//     font-size: 1.8rem;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 1rem;
//     cursor: pointer;
//     margin: 0 auto;
//     padding: 0.15em 0.5rem 0;

//     & > * {
//         margin: 0.3rem 0.5rem;
//     }
// `

// const DownloadIcon = styled(TfiDownload)`
//     margin-bottom: 0.5em;
// `

// const Label = styled.label`
//     border-radius: 3rem;
//     display: flex;
//     align-items: center;
//     cursor: pointer;
//     gap: 1rem;
// `

// const Switch = styled.div<IsActiveProps>`
//     position: relative;
//     width: 5rem;
//     height: 2.8rem;
//     border: 1px solid
//         ${({ theme, isActive }) =>
//             isActive ? `${theme.blue}` : `${theme.grey300}`};
//     background: ${({ theme, isActive }) =>
//         isActive ? `${theme.blue}` : `${theme.grey300}`};
//     border-radius: 3.2rem;
//     padding: 1rem;
//     transition: 300ms all;
//     &:before {
//         transition: 300ms all;
//         content: '';
//         position: absolute;
//         width: 2.3rem;
//         height: 2.3rem;
//         border-radius: 50%;
//         top: 50%;
//         left: 0.1rem;
//         background: ${({ theme }) => theme.white};
//         transform: translate(0, -50%);
//     }
// `

// const Input = styled.input`
//     opacity: 0;
//     position: absolute;
//     &:checked + ${Switch} {
//         &:before {
//             transform: translate(2.3rem, -50%);
//         }
//     }
// `

const Select = styled.select`
    border: none;
    background-color: transparent;
    font-size: 1.6rem;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    option {
        font-size: 1.2rem;
        cursor: pointer;
    }
`

const ToolWrap = styled.div`
    display: flex;
    margin: 0 1rem;
`

export const Section = styled.section`
    display: flex;
    flex-wrap: wrap;
    padding: 0 3rem;
    @media (max-width: 680px) {
        padding: 1.4rem;
    }

    @media (min-width: 1440px) {
        justify-content: space-between;
    }
`

export const CasesChartSection = styled.section`
    display: flex;
    padding: 0 3rem;
    @media (max-width: 680px) {
        padding: 0 1.4rem;
    }

    @media (min-width: 1440px) {
        margin: 0 auto;
    }
`

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`

export const CasesChartCard = styled(Card)`
    height: 38.4rem;
    width: 100%;
    margin: 1.2rem;
    min-width: 43.3rem;

    @media screen and (max-width: 500px) {
        width: 100%;
        min-width: 25rem;
    }
`

export const SmallCard = styled(Card)<SmallCardProps>`
    width: 43.3rem;
    height: 18rem;
    padding: ${({ padding }) => padding ?? '1rem'};
    margin: 1.2rem 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: ${({ justifyContent }) => justifyContent};

    @media screen and (max-width: 500px) {
        width: auto;
        min-width: 25rem;
    }
`

export const Title = styled.p<TitleProps>`
    font-size: 2.4rem;
    font-weight: 500;
    margin: ${({ margin }) => margin ?? '0.5rem 0 0 0.5rem'};
`

export const CardBody = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1rem 0 0 3.5rem;
`

export const TotalNumber = styled.p`
    font-size: 6rem;
    font-weight: 500;
`

export const CasesOverview = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15rem;
    max-width: 20rem;
`

export const Flex = styled(FlexContainer)<FlexProps>`
    justify-content: ${({ justifyContent }) => justifyContent};
    align-items: ${({ alignItems }) => alignItems};
`

export const CasesStatus = styled.p`
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
`

export const CasesNumber = styled.p`
    font-size: 2rem;
    padding-top: 0.5rem;
`

// export const UpOrDown = styled.p<ColorInterface>`
//     font-size: 1.4rem;
//     margin-left: 1rem;
//     padding: 0 0 1.5rem 0;
//     color: ${({ color }) => color};
// `

// export const ArrowUp = styled(TfiArrowUp)`
//     margin-right: 0.5rem;
//     padding-top: 0.5rem;
//     font-size: 2rem;
// `

// export const ArrowDown = styled(TfiArrowDown)`
//     margin-right: 0.5rem;
//     padding-top: 0.5rem;
//     font-size: 2rem;
// `

export const BigCard = styled(Card)<BigCardProps>`
    width: 43.3rem;
    min-width: 43.3rem;
    height: 38.4rem;
    padding: ${({ padding }) => padding ?? '1rem'};
    margin: 1.2rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 500px) {
        width: 100%;
        min-width: 25rem;
    }
`

export const Block = styled.div``

export const NoData = styled(CardBody)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 500;
    margin: 0;
`

const StatisticsPage = () => {
    const translation = useTranslation()

    const { statisticsOverview, isStatisticsLoading } = useTypedSelector(
        (state) => state.statisticsOverview
    )
    const { categories } = useTypedSelector((state) => state.category)
    const [selectedYear, setSelectedYear] = useState('')

    const { getStatisticsOverview } = useActions()

    useEffect(() => {
        getStatisticsOverview(selectedYear)
        // eslint-disable-next-line
    }, [selectedYear])

    useEffect(() => {
        const latestYear = statisticsOverview?.availableYears?.at(-1)
        latestYear && setSelectedYear(latestYear)
    }, [statisticsOverview])

    //for stacked bar chart
    const months = Object.values(translation.statisticsPage.months)
    const stackedBarData = months.map((month, index) => ({
        month,
        ...statisticsOverview?.months[(index + 1).toString()],
    }))

    //for pie chart
    const mapPieChartData = (): PieChartEntry[] => {
        let mappedData: PieChartEntry[] = []
        if (statisticsOverview) {
            // calc total amount of cases
            const totalCases = Object.values(
                statisticsOverview.categories
            ).reduce((acc, val) => acc + val, 0)

            mappedData = Object.entries(statisticsOverview.categories)
                // calc each category distribution in %
                .map((entry) => ({
                    slag: entry[0],
                    value: (entry[1] * 100) / totalCases,
                }))
                // filter out categories < 1% fraction
                .filter((entry) => entry.value > 1)
                // display categories in pie chart in DESC
                .sort((a, b) => b.value - a.value)
                // set color for each pie chart category slice
                // translate each category
                .map((entry, index) => {
                    let translatedName = ''

                    const colors = [
                        '#2a65bd',
                        '#ff8962',
                        '#4ecdc4',
                        '#ef5350',
                        '#795548',
                        '#a3248e',
                        '#cddc39',
                        '#2bbb89',
                        '#ffc107',
                        '#2196f3',
                        '#8bc34a',
                        '#ff5734',
                        '#009688',
                        '#e91e63',
                        '#ffeb3b',
                        '#795548',
                        '#4caf50',
                        '#ff9800',
                        '#3f51b5',
                        '#ff6347',
                        '#6e718b',
                        '#90caf9',
                        '#cddc39',
                        '#ff4081',
                        '#d0ff3a',
                    ]

                    if (entry.slag === 'category-missing') {
                        translatedName =
                            translation.caseworkerOpenReport.uncategorized
                    } else {
                        if (categories) {
                            const categoryItem = categories.find(
                                (category) => category.slug === entry.slag
                            ) as Category

                            translatedName = singleCategoryTransformer(
                                categoryItem,
                                translation
                            ).name
                        }
                    }

                    return {
                        ...entry,
                        fill: colors[index],
                        name: translatedName,
                    }
                })
        }
        return mappedData
    }

    return (
        <Statistics>
            <ToolbarWrapper>
                <SecondaryHeading>
                    {translation.statisticsPage.overview}
                </SecondaryHeading>
                {statisticsOverview && (
                    <Toolbar>
                        {/* <ToolWrap>
                            <Label>
                                <Font16>
                                    {
                                        translation.statisticsPage
                                            .compareToLastPeriod
                                    }
                                </Font16>
                                <Input
                                    checked={isChecked}
                                    type="checkbox"
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                                <Switch isActive={isChecked} />
                            </Label>
                        </ToolWrap> */}
                        <ToolWrap>
                            <Select
                                value={selectedYear}
                                onChange={(event) =>
                                    setSelectedYear(event.target.value)
                                }
                            >
                                {statisticsOverview?.availableYears.map(
                                    (year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    )
                                )}
                            </Select>
                        </ToolWrap>
                        {/* <DownloadButton>
                    {translation.addNewClientPartner.download}
                    <DownloadIcon />
                </DownloadButton> */}
                    </Toolbar>
                )}
            </ToolbarWrapper>
            {isStatisticsLoading ? (
                <LoadingStatisticsPage />
            ) : (
                <>
                    <Section>
                        <FlexColumn>
                            <TotalCases />
                            <AnonymousConfidential />
                        </FlexColumn>
                        <ActionStats />
                        <BigCard padding={'0 0.2rem'}>
                            <Title margin={'1.5rem 0 0 1.5rem'}>
                                {
                                    translation.statisticsPage
                                        .distributionOfCategories
                                }
                            </Title>
                            {!statisticsOverview ||
                            Object.keys(statisticsOverview?.categories)
                                .length === 0 ? (
                                <NoData>
                                    {translation.statisticsPage.noData}
                                </NoData>
                            ) : (
                                <PieChartCategories data={mapPieChartData()} />
                            )}
                        </BigCard>
                    </Section>
                    <CasesChartSection>
                        <CasesChartCard>
                            <Title margin={'1.5rem 0 1.5rem 1.5rem'}>
                                {translation.statisticsPage.casesByMonth}
                            </Title>
                            <StackedBarCases data={stackedBarData} />
                        </CasesChartCard>
                    </CasesChartSection>
                </>
            )}
        </Statistics>
    )
}

export default StatisticsPage

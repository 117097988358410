import { Card, CardHeader } from '../../../styled/cards'
import { Modal, ModalContainer } from '../../../styled/modals'
import { useTranslation, useTypedSelector } from '../../../../hooks'
import styled from 'styled-components'

interface CommentModalProps {
    closeModal: () => void
}

const CancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    padding: 1rem;
    cursor: pointer;
`

const CommentBox = styled.div`
    width: 51.4rem;
    height: 23rem;
    margin: 0;
    color: black;
    border: 3px solid ${({ theme }) => `${theme.grey}80`};
    padding: 1.6rem 2rem;
    margin: 2.7rem 2.1rem;
    font-size: 1.2rem; 
    Line height: 1.4rem; 
`

interface CommentModalProps {
    closeModal: () => void
}

const CommentModal: React.FC<CommentModalProps> = ({ closeModal }) => {
    const translation = useTranslation()
    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    return (
        <ModalContainer>
            <Modal height="34.6rem" width="55.6rem">
                <Card>
                    <CardHeader>
                        {translation.caseworkerOpenReport.comment}
                        <CancelButton onClick={closeModal}>
                            {translation.reusable.cancel}
                        </CancelButton>
                    </CardHeader>

                    <CommentBox>
                        <div>{openReport?.closed_comment}</div>
                    </CommentBox>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default CommentModal

import { MessageInterface } from './Messages'
import styled from 'styled-components'
import config from '../../../config'
import { FiDownload } from 'react-icons/fi'
import axios from 'axios'
import { useTranslation, useTypedSelector } from '../../../hooks'
import { useEffect, useState } from 'react'
const { apiUrl } = config

interface IsMineProp {
    isMine: boolean
}

const MessageContainer = styled.div<IsMineProp>`
    width: 100%;
    padding: 0 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: ${({ isMine }) => (isMine ? 'end' : 'start')};

    :last-child {
        padding-bottom: 2rem;
    }
`

const MessageInformationContainer = styled.div<IsMineProp>`
    display: flex;
    gap: 0.5rem;
    margin: 1rem 0;
    margin-left: ${({ isMine }) => isMine && 'auto'};
`

const MessageAuthor = styled.span`
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
    display: block;
`

const MessageDate = styled.span`
    font-size: 1rem;
    opacity: 0.5;
    color: ${({ theme }) => theme.primary};
`

const StyledMessage = styled.div<IsMineProp>`
    width: 50%;
    padding: 1rem 1.4rem;
    background: ${({ theme, isMine }) =>
        isMine ? `${theme.blue}15` : `${theme.grey}45`};
    font-size: 1.4rem;
    color: ${({ theme }) => theme.secondaryDark};
    border-radius: 0.5rem;
    margin-left: ${({ isMine }) => isMine && 'auto'};
    white-space: pre-wrap;
`

const MessageFiles = styled.div<IsMineProp>``

const File = styled.div<IsMineProp>`
    background: ${({ theme }) => theme.lightGrey};
    border-radius: 10rem;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    display: flex;
    align-items: center;
    transition: background 0.3s;
    cursor: pointer;

    :hover {
        background: ${({ theme }) => `${theme.blue}15`};
    }
`

const DownloadIcon = styled(FiDownload)`
    font-size: 1.2rem;
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.primary};
`

interface MessageProp {
    message: MessageInterface
    userType: string
}

const Message: React.FC<MessageProp> = ({ message, userType }) => {
    const translation = useTranslation()
    const { user } = useTypedSelector((state) => state.auth)

    const getMessageSource = () => {
        const userName = `${user?.firstName} ${user?.lastName}`
        if (message.source === 'caseworker') {
            return message.caseworker_name === userName
                ? translation.you.you
                : message.caseworker_name
        } else if (message.source === 'reporter') {
            return !user ? translation.you.you : 'Whistleblower'
        }
    }

    // TODO
    // Take a look at this component again. It should probably not contain so much logic
    // that has nothing to with the layout component itself

    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const wbOpenReport = useTypedSelector(
        (state) => state.whistleblower.openReport
    )

    const download = async (file: any, filename: string) => {
        let url

        if (userType === 'caseworker') {
            url = `${apiUrl}/v1/incidents/${openReport.id}/download/${file.id}`
        } else {
            url = `${apiUrl}/v1/whistleblower/incidents/${wbOpenReport.whistleblower_code}/download/${file.id}`
        }

        const res: any = await axios.get(url, {
            method: 'GET',
            responseType: 'blob',
            // @ts-ignore
            Accept: 'application/pdf',
        })

        // @ts-ignore
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file.name
        link.click()
    }

    const downloadFile = async (url: string) => {
        await download(url, 'test')
    }

    const calculateTimeAgo = (timestamp: string) => {
        const now = new Date().getTime()
        const sentTime = new Date(timestamp).getTime()
        const diffInSeconds = Math.floor((now - sentTime) / 1000)

        if (diffInSeconds < 60) {
            return 'just now'
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60)
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`
        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600)
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`
        } else {
            const days = Math.floor(diffInSeconds / 86400)
            return `${days} day${days !== 1 ? 's' : ''} ago`
        }
    }

    const [timeAgo, setTimeAgo] = useState(calculateTimeAgo(message.created_at))

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeAgo(calculateTimeAgo(message.created_at))
        }, 60000)

        return () => {
            clearInterval(interval)
        }
    }, [message.created_at])

    return (
        <MessageContainer isMine={message.source === userType}>
            <MessageInformationContainer isMine={message.source === userType}>
                <MessageAuthor>{getMessageSource()}</MessageAuthor>
                <MessageDate>{timeAgo}</MessageDate>
            </MessageInformationContainer>
            {message.content && (
                <StyledMessage
                    isMine={message.source === userType}
                    dangerouslySetInnerHTML={{ __html: message.content }}
                ></StyledMessage>
            )}
            {message.files.data.length > 0 && (
                <MessageFiles isMine={message.source === userType}>
                    {message.files.data.map((file: any) => (
                        <File
                            onClick={() => downloadFile(file)}
                            isMine={message.source === userType}
                        >
                            {file.name}
                            <DownloadIcon />
                        </File>
                    ))}
                </MessageFiles>
            )}
        </MessageContainer>
    )
}

export default Message

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FiCheck, FiThumbsUp } from 'react-icons/fi'
import { NewCasePageFrame, NewCasePageFrameHeading } from '../styled'
import CopyToClipboard from 'react-copy-to-clipboard'
import { NextButton } from '../../../../reusable/buttons'
import { FormInput50 } from '../../../../styled/formElements'
import {
    Code,
    CodeBox,
    CopyIcon,
    CopyLink,
    CopyLinkText,
} from '../../../../styled/misc'
import WhistleblowerCodeModal from '../modals/WhistleblowerCodeModal'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import { useHistory } from 'react-router'
import { checkEmail, isValidEmail } from '../../../../../helpers/validation'
import CheckBlue from '../../../../../assets/images/check-blue.svg'
import Ripple from '../components/Ripple'

const FormContainer = styled.div`
    margin-top: -2.4rem;
    max-width: 75rem;
    width: 42vw;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: 600px) {
        width: 90%;
    }
`

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 3.3rem;
    width: 3.3rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.green};
    margin-bottom: 2rem;
`

const CodeHeading = styled.h3`
    font-size: 1.8rem;
    font-weight: 700;
    color: ${({ theme }) => theme.darkColor};
    padding-top: 4rem;
`

const ShareEmailHeading = styled.h3`
    font-size: 1.8rem;
    color: ${({ theme }) => theme.primary};
    padding-top: 4.5rem;
    font-weight: 600;
`

const LightTagline = styled.h3`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.8;
    padding-top: 2.5rem;
    font-weight: 300;
`

const EmailForm = styled.div`
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 4rem;
`

interface ErrorProps {
    isError: boolean
    isSuccess: boolean
}

const Input = styled(FormInput50)<ErrorProps>`
    border-radius: 10rem;
    margin-bottom: 6rem;
    border-color: ${({ theme, isError }) =>
        isError ? theme.red : theme.lineGrey};
    width: 30.4rem;
    background-image: ${({ isSuccess }) => isSuccess && `url(${CheckBlue})`};
    background-repeat: no-repeat;
    background-position: 26rem;
`

const ErrorMessage = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.red};
    position: absolute;
    left: 0;
    top: 8rem;
`

const CheckIcon = styled(FiCheck)`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.green};
`

const CopyTextWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 4rem;
`

interface ReportCompletedProps {
    reportEmail: string | undefined
}

const ReportCompleted: React.FC<ReportCompletedProps> = ({ reportEmail }) => {
    const { newReport } = useTypedSelector((state) => state.whistleblower)
    const translation = useTranslation()
    const [isModalActive, setIsModalActive] = useState(false)
    const [email, setEmail] = useState(reportEmail || '')
    const [emailError, setEmailError] = useState('')

    const [isHoveringCode, setIsHoveringCode] = useState(false)
    const [isCopied, setIsCopied] = useState(false)

    const { addReporterEmail } = useActions()

    const { organizationalCode } = useTypedSelector(
        (state) => state.whistleblower
    )

    const history = useHistory()

    useEffect(() => {
        setTimeout(() => setIsCopied(false), 5000)
    }, [isCopied])

    const handleModalFinish = async () => {
        if (email.length > 1) {
            if (newReport.id && newReport.code) {
                await addReporterEmail(newReport.id, email, newReport.code.whistleblower_code_unhashed)
            }
            setIsModalActive(false)
            history.push(`/organization/${organizationalCode}/whistleblower`)
        }
        setIsModalActive(false)
        history.push(`/organization/${organizationalCode}/whistleblower`)
    }

    const isEmailValidated = () => {
        if (email.length === 0) {
            return true
        } else return isValidEmail(email)
    }

    const checkEmailError = () => {
        if (email.length === 0) {
            setEmailError('')
        } else {
            setEmailError(checkEmail(email, translation))
        }
    }

    return (
        <NewCasePageFrame>
            {isModalActive && (
                <WhistleblowerCodeModal
                    setIsModalActive={setIsModalActive}
                    handleFinish={handleModalFinish}
                    isCopied={isCopied}
                    setIsCopied={setIsCopied}
                />
            )}
            <FormContainer>
                <IconContainer>
                    <FiThumbsUp color="white" size={18} />
                </IconContainer>
                <NewCasePageFrameHeading>
                    {translation.newCaseFlow.theReportHasBeenReceived}
                </NewCasePageFrameHeading>
                <LightTagline>
                    {translation.newCaseFlow.inOrderToAccessYourCaseAgain}
                </LightTagline>
                <LightTagline>
                    {translation.newCaseFlow.keepItSafe}
                </LightTagline>
                <div style={{ width: '100%' }}>
                    <CodeHeading>
                        {translation.newCaseFlow.whistleblowerCode}
                    </CodeHeading>
                    <CopyToClipboard
                        text={newReport.code?.whistleblower_code_unhashed || ''}
                        onCopy={() => setIsCopied(true)}
                    >
                        <CodeBox
                            className="code-box"
                            isActive={isHoveringCode || isCopied}
                            onMouseEnter={() => setIsHoveringCode(true)}
                            onMouseLeave={() => setIsHoveringCode(false)}
                        >
                            <Code
                                isActive={isHoveringCode || isCopied}
                                className="code-box-text"
                            >
                                {newReport.code?.whistleblower_code_unhashed}
                            </Code>
                        </CodeBox>
                    </CopyToClipboard>
                </div>
                {!isCopied ? (
                    <CopyTextWrapper>
                        <Ripple />
                        <CopyToClipboard
                            text={newReport.code?.whistleblower_code_unhashed || ''}
                            onCopy={() => setIsCopied(true)}
                        >
                            <CopyLink
                                isActive={isHoveringCode || isCopied}
                                className="copy-code-link"
                                onMouseEnter={() => setIsHoveringCode(true)}
                                onMouseLeave={() => setIsHoveringCode(false)}
                            >
                                <CopyLinkText>
                                    {translation.newCaseFlow.copyToClipboard}
                                </CopyLinkText>
                                <CopyIcon />
                            </CopyLink>
                        </CopyToClipboard>
                    </CopyTextWrapper>
                ) : (
                    <CopyTextWrapper>
                        <CopyToClipboard
                            text={newReport.code?.whistleblower_code_unhashed || ''}
                            onCopy={() => setIsCopied(true)}
                        >
                            <CopyLink isActive={true}>
                                <CopyLinkText>
                                    {translation.copiedText.copied}
                                </CopyLinkText>
                                <CheckIcon />
                            </CopyLink>
                        </CopyToClipboard>
                    </CopyTextWrapper>
                )}
                <ShareEmailHeading>
                    {translation.newCaseFlow.stayUpdated}
                </ShareEmailHeading>
                <LightTagline>
                    {`${translation.newCaseFlow.youHaveTheOpportunityToReceiveAMail}
                    
                        ${translation.newCaseFlow.itIsImportantToMention}`}
                </LightTagline>
                <LightTagline>
                    {translation.newCaseFlow.theWhistleblowerCodeWillNot}
                </LightTagline>

                <EmailForm>
                    <Input
                        type="email"
                        placeholder={translation.reusable.email}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={checkEmailError}
                        isError={!isEmailValidated()}
                        isSuccess={email.length > 0 && isEmailValidated()}
                        onFocus={() => setEmailError('')}
                    />
                    {emailError.length > 0 && (
                        <ErrorMessage>{emailError}</ErrorMessage>
                    )}
                    <NextButton
                        text={translation.finishButton.finish}
                        handleOnClick={() => setIsModalActive(true)}
                        isActive={isEmailValidated()}
                        width="220px"
                    />
                </EmailForm>
            </FormContainer>
        </NewCasePageFrame>
    )
}

export default ReportCompleted

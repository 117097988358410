import { useState } from 'react'
import styled from 'styled-components'
import { useActions, useTypedSelector } from '../../../hooks'
import danishFlag from './flags/danish.svg'
import ukFlag from './flags/uk.svg'
import seFlag from './flags/se.png'
import noFlag from './flags/no.svg'
import fiFlag from './flags/fi.svg'

interface ActivePropInterface {
    isActive: boolean
}

const Select = styled.div<ActivePropInterface>`
    width: 3rem;
    position: relative;
    z-index: 2;
    background: ${({ theme, isActive }) => (isActive ? theme.white : '')};
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.2rem;
    height: 100%;
    margin-top: 25px;
    padding-bottom: 10.5rem;
`

const Language = styled.div`
    margin: 0.6rem 0;
    cursor: pointer;
`

const LanguageIcon = styled.img`
    height: 2.3rem;
    width: 2.3rem;
`

const Dropdown = styled.div<ActivePropInterface>`
    visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
`

const SelectLanguage: React.FC = () => {
    const [isDropdownActive, setIsDropdownActive] = useState(false)
    const { changeLanguage } = useActions()
    const { selectedTranslation } = useTypedSelector(
        (state) => state.translation
    )

    const toggleActive = () => {
        setIsDropdownActive(!isDropdownActive)
    }

    const countries = {
        dk: {
            name: 'Denmark',
            key: 'dk',
            icon: danishFlag,
        },
        en: {
            name: 'England',
            key: 'en',
            icon: ukFlag,
        },
        se: {
            name: 'Sweden',
            key: 'se',
            icon: seFlag,
        },
        no: {
            name: 'Norway',
            key: 'no',
            icon: noFlag,
        },
        fi: {
            name: 'Finland',
            key: 'fi',
            icon: fiFlag,
        },
    }

    const handleLangSelect = (lang: string) => {
        changeLanguage(lang)
        toggleActive()
    }

    return (
        <Select isActive={isDropdownActive}>
            <Language onClick={toggleActive}>
                <LanguageIcon
                    // @ts-ignore
                    src={
                        selectedTranslation
                            ? countries[selectedTranslation].icon
                            : ''
                    }
                />
            </Language>
            <Dropdown isActive={isDropdownActive}>
                {Object.values(countries).map(
                    (country) =>
                        country.key !== selectedTranslation && (
                            <Language
                                key={country.key}
                                onClick={() => handleLangSelect(country.key)}
                            >
                                <LanguageIcon
                                    // @ts-ignore
                                    src={countries[country.key].icon}
                                />
                            </Language>
                        )
                )}
            </Dropdown>
        </Select>
    )
}

export default SelectLanguage

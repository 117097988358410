import styled from 'styled-components'
import { Card, CardHeader } from '../../../../styled/cards'
import {
    Modal,
    ModalContainer,
    ModalCancelButton,
} from '../../../../styled/modals'
import { useTranslation } from '../../../../../hooks'

const Text = styled.p`
    font-size: 1.8rem;
    padding: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
`

interface PartnerProgramInformationModalProps {
    closeModal: () => void
}

const PartnerProgramInformationModal: React.FC<
    PartnerProgramInformationModalProps
> = ({ closeModal }) => {
    const translation = useTranslation()

    return (
        <ModalContainer>
            <Modal height="20rem" width="60rem">
                <Card>
                    <CardHeader>
                        {translation.registerPage.partnerprogrammodal}
                        <ModalCancelButton onClick={closeModal}>
                            {translation.reusable.cancel}
                        </ModalCancelButton>
                    </CardHeader>
                    <Text>
                        {translation.registerPage.ifYouAreLookingFor}{' '}
                        fb@walor.io
                    </Text>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default PartnerProgramInformationModal

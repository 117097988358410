import { useEffect, useState } from 'react'
import { FiAlertCircle, FiPaperclip, FiSend, FiTrash2 } from 'react-icons/fi'
import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../hooks'
import ButtonSpinnerWhite from '../../layout/spinner/ButtonSpinnerWhite'
import Vapor from 'laravel-vapor'
import config from '../../../config'
import axios from 'axios'
import { StreamedFile } from '../../pages/whistleblower/new-case/NewCasePage'

const NewMessageContainer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.grey};
    padding: 1rem 0;
`

const NewMessageContent = styled.div`
    margin: 0 1.4rem;
`
const Textarea = styled.textarea`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 0.5rem;
    height: 12.4rem;
    padding: 1rem;
    font-family: 'NeueMontreal';
    resize: none;
`

const ButtonContainer = styled.div`
    padding: 1rem 0;
    display: flex;
    align-items: center;
`

const PaperClipIcon = styled(FiPaperclip)`
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    cursor: pointer;
    font-size: 1.4rem;
`

interface SendButtonProps {
    isActive: boolean
    isDisabled?: boolean
}

const SendButton = styled.div<SendButtonProps>`
    height: 2.5rem;
    width: 12rem;
    border-radius: 20rem;
    background: ${({ theme, isActive }) => isActive && theme.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
    border: ${({ theme, isActive }) =>
        !isActive && `1px solid ${theme.greyText}`};

    opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
`

const SendIcon = styled(FiSend)<SendButtonProps>`
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};
    font-size: 1.4rem;
`

const SendText = styled.span<SendButtonProps>`
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};

    font-size: 1.4rem;
    margin-left: 0.5rem;
`

const HiddenFileInput = styled.input`
    display: none;
`

interface FilePreviewProps {
    progress: number
}

const FilePreviewContainer = styled.div`
    height: 2.5rem;
    position: relative;
`
const FilePreviewLoading = styled.div<FilePreviewProps>`
    position: absolute;
    height: 100%;
    width: ${({ progress }) => `calc(${progress}% - 1rem)`};
    background-color: green;
    border-radius: 2rem;
    z-index: 3;
    opacity: 0.1;
    left: 1rem;
`

const FilePreview = styled.div`
    padding-left: 1rem;
    background: ${({ theme }) => `${theme.primary}10`};

    display: flex;
    align-items: center;
    border-radius: 2rem;
    margin-left: 1rem;
`

const FilePreviewText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    margin-right: 1rem;
`

const FilePreviewIconContainer = styled.div`
    height: 2.5rem;
    width: 2.5rem;
    background: ${({ theme }) => theme.lineGrey};

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
`

const FilePreviewIcon = styled(FiTrash2)`
    color: ${({ theme }) => `${theme.primary}50`};
    font-size: 1.2rem;
`

const FileSizeError = styled.div`
    height: 2.5rem;
    padding: 0 1rem;
    background: ${({ theme }) => `${theme.red}10`};
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.red};
    font-size: 1.2rem;
    gap: 0.5rem;
    margin-left: 1rem;
`

const AlertIcon = styled(FiAlertCircle)`
    color: ${({ theme }) => theme.red};
    font-size: 1.4rem;
`

interface MessageFormProps {
    message: string
    setMessage: (e: any) => void
    files?: StreamedFile[]
    setFiles?: React.Dispatch<React.SetStateAction<StreamedFile[]>>
    handleAddMessage: () => void
    placeholder?: string
    isLoading?: boolean
    buttonText?: null | string
    isReportClosed?: boolean
}

const MessageForm: React.FC<MessageFormProps> = ({
    message,
    setMessage,
    files = null,
    setFiles = () => {},
    handleAddMessage,
    placeholder = null,
    isLoading = false,
    buttonText = null,
    isReportClosed = false,
}) => {
    const { backendUrl, env } = config

    const [token, setToken] = useState<null | string>(null)

    useEffect(() => {
        if (!token) {
            setToken(axios.defaults.headers.common['Authorization'])
        }
    }, [token])

    /*
     * Currently we have to remove the global Authorization header before streaming a file
     * This is just a fallback to add it back if someone leaves before the file streaming ends
     * And we don't get to reset the header before the component unmounts
     */
    useEffect(() => {
        return () => {
            if (
                token &&
                axios.defaults.headers.common['Authorization'] === undefined
            ) {
                axios.defaults.headers.common['Authorization'] = token
            }
        }
    }, [token])

    interface LocalFile {
        file: File
        progress: number
        awsKey: null | string
        extension: null | string
        key: string
    }

    const [isUploading, setIsUploading] = useState(false)

    const handleUploadFiles = (newFiles: FileList) => {
        const formattedFiles = Array.from(newFiles).map((file) => ({
            file,
            key: Math.random().toString(36).substr(2, 9),
            progress: 0,
            awsKey: null,
            extension: null,
        }))

        if (localFiles) {
            setLocalFiles((prevFiles) => [...prevFiles, ...formattedFiles])
        } else {
            setLocalFiles(formattedFiles)
        }
    }

    const [localFiles, setLocalFiles] = useState<LocalFile[]>([])

    const streamFile = async (file: LocalFile) => {
        if (file.progress !== 0) {
            return
        }

        const bucket = env === 'local' ? 'walor-staging' : 'walor-prod-api'

        const fileObj = file.file

        const cleanedBlob = new Blob([fileObj.slice(0, fileObj.size)], {
            type: fileObj.type,
        })

        // Create a new File object from the cleaned Blob. Remove some metadata this way
        const cleanedFile = new File([cleanedBlob], fileObj.name, {
            type: fileObj.type,
        })

        const response = await Vapor.store(cleanedFile, {
            progress: (progress) => {
                setLocalFiles((prevLocalFiles) => {
                    if (!prevLocalFiles) return prevLocalFiles

                    return prevLocalFiles.map((f) => {
                        if (f.key === file.key) {
                            return {
                                ...f,
                                progress: Math.round(progress * 100),
                            }
                        }
                        return f
                    })
                })
            },
            baseURL: backendUrl,
            bucket: bucket,
            visibility: 'private',
            options: {
                bucket: bucket,
                visibility: 'private',
            },
        })

        const { key, extension } = response

        const streamedFile: StreamedFile = {
            awsKey: key,
            extension,
            name: file.file.name,
        }

        if (files) {
            setFiles((prevFiles) => [...prevFiles, streamedFile])
        }

        setLocalFiles((previousFiles) =>
            previousFiles.map((f) => {
                if (f.key === file.key) {
                    return { ...f, awsKey: key, extension }
                }

                return f
            })
        )
    }

    useEffect(() => {
        if (!localFiles.length) return

        if (!localFiles.find((file) => file.progress === 0)) return

        if (isUploading) return

        const handleStreamFiles = async () => {
            setIsUploading(true)

            for (const file of localFiles) {
                if (file.progress !== 0) continue // Don't bother doing anything if the current file is streaming

                /*
                 * We need to remove the global Authorization header or the Vapor request will fail
                 * This is not an ideal way to handle this, but it seems to work
                 * We reset it at the end of each request and also when the user navigates away from the screen
                 */
                delete axios.defaults.headers.common['Authorization']

                await streamFile(file)
            }

            if (axios.defaults.headers.common['Authorization'] === undefined) {
                axios.defaults.headers.common[
                    'Authorization'
                ] = `Bearer ${token}`
            }

            setIsUploading(false)
        }

        handleStreamFiles()
        // eslint-disable-next-line
    }, [localFiles, token, isUploading])

    const translation = useTranslation()

    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const isCaseClosed = openReport?.closed_at

    useEffect(() => {
        if (files && files.length > 10) {
            setFileError('You cannot add more then 10 files at ones')
            setFiles([])
            return
        }

        files?.forEach((file: any) => {
            if (file.size > 2000000000) {
                setFileError(
                    'This file is too big. The file must be under 2 GB'
                )
                setFiles([])
                setLocalFiles([])

                return
            }
        })
        // eslint-disable-next-line
    }, [files])

    const [fileError, setFileError] = useState('')

    const removeFile = (key: string) => {
        if (!files) {
            return
        }

        setLocalFiles(localFiles.filter((file) => file.key !== key))
    }

    const addMessageClick = async () => {
        if (isLoading) {
            return
        }

        if (!message && !files?.length) {
            return
        }

        if (isUploading) {
            return
        }

        await handleAddMessage()
        setMessage('')
        setFiles([])
        setLocalFiles([])
    }

    return (
        <NewMessageContainer>
            <NewMessageContent>
                <Textarea
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={
                        placeholder
                            ? placeholder
                            : translation.accessCaseFlow.enterMessage
                    }
                    value={message}
                    disabled={isReportClosed}
                />
                <ButtonContainer>
                    {!isCaseClosed && (
                        <label htmlFor="file-input">
                            <PaperClipIcon />
                        </label>
                    )}
                    {fileError.length > 1 && (
                        <FileSizeError>
                            <AlertIcon />
                            {fileError}
                        </FileSizeError>
                    )}

                    {localFiles?.map((file) => (
                        <FilePreviewContainer>
                            <FilePreviewLoading progress={file.progress} />
                            <FilePreview>
                                <FilePreviewText>
                                    {file.file.name}
                                </FilePreviewText>
                                <FilePreviewIconContainer
                                    onClick={() => removeFile(file.key)}
                                >
                                    <FilePreviewIcon />
                                </FilePreviewIconContainer>
                            </FilePreview>
                        </FilePreviewContainer>
                    ))}

                    <HiddenFileInput
                        onChange={(e: any) => handleUploadFiles(e.target.files)}
                        type="file"
                        id="file-input"
                        multiple
                    />
                    <SendButton
                        onClick={!isCaseClosed ? addMessageClick : undefined}
                        isDisabled={isCaseClosed}
                        isActive={
                            message.length > 0 ||
                            (!!(files && files.length > 0) && !isUploading)
                        }
                    >
                        {!isLoading ? (
                            <>
                                <SendIcon
                                    isActive={
                                        !!(message || files?.length) &&
                                        !isUploading
                                    }
                                />
                                <SendText
                                    isActive={
                                        !!(message || files?.length) &&
                                        !isUploading
                                    }
                                >
                                    {buttonText
                                        ? buttonText
                                        : translation.accessCaseFlow.send}
                                </SendText>
                            </>
                        ) : (
                            <ButtonSpinnerWhite />
                        )}
                    </SendButton>
                </ButtonContainer>
            </NewMessageContent>
        </NewMessageContainer>
    )
}

export default MessageForm

import { FC, useState } from 'react'
import {
    Card,
    CardHeader,
    CaseInformationRow,
    CaseInformationRowInfo,
    CaseInformationRowTitle,
} from '../../../../styled/cards'
import { useTranslation } from '../../../../../hooks'
import styled from 'styled-components'
import { CountryInterface } from '../../../../../state/reducers/countriesReducer'
import ImageFrame from './ImageFrame'
import CaseworkerTooltip from './CaseworkerTooltip'

const CardInfo = styled(Card)`
    width: 100%;
    overflow: visible;
    max-height: none;

    & > :last-child {
        border: none;
    }
`

const CaseworkerRow = styled(CaseInformationRow)`
    position: relative;
`

const CaseworkerWrapper = styled.div`
    position: relative;
    overflow-x: auto;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;

    &::-webkit-scrollbar {
        height: 0.5rem;
    }
`

interface InfoCardProps {
    uuid: string
    date: string
    caseType: string
    assignedTo: AssignedTo[]
}

export interface AssignedTo {
    id: number
    name: string
    profile_img: string
    note: string
    position: string
    languages: Language[]
}

interface Language extends Pick<CountryInterface, 'id' | 'flag' | 'name'> {}

const InfoCard: FC<InfoCardProps> = ({ uuid, date, caseType, assignedTo }) => {
    const translation = useTranslation()
    const [showTooltip, setShowTooltip] = useState(false)
    const [caseworkerPreview, setCaseworkerPreview] = useState<null | number>(
        null
    )

    return (
        <CardInfo onMouseEnter={() => setShowTooltip(false)}>
            <CardHeader>{translation.accessCaseFlow.info}</CardHeader>
            <CaseInformationRow>
                <CaseInformationRowTitle>
                    {translation.accessCaseFlow.id}
                </CaseInformationRowTitle>
                <CaseInformationRowInfo>{uuid}</CaseInformationRowInfo>
            </CaseInformationRow>
            <CaseInformationRow>
                <CaseInformationRowTitle>
                    {translation.accessCaseFlow.date}
                </CaseInformationRowTitle>
                <CaseInformationRowInfo>{date}</CaseInformationRowInfo>
            </CaseInformationRow>
            <CaseInformationRow>
                <CaseInformationRowTitle>
                    {translation.accessCaseFlow.appearance}
                </CaseInformationRowTitle>
                <CaseInformationRowInfo>{caseType}</CaseInformationRowInfo>
            </CaseInformationRow>
            <CaseworkerRow onMouseLeave={() => setShowTooltip(false)}>
                <CaseInformationRowTitle>
                    {translation.accessCaseFlow.caseworkers}
                </CaseInformationRowTitle>
                {showTooltip &&
                    assignedTo
                        .filter(
                            (caseworker) => caseworker.id === caseworkerPreview
                        )
                        .map((activeCaseworker) => (
                            <CaseworkerTooltip
                                assignedTo={activeCaseworker}
                                setShowTooltip={setShowTooltip}
                            />
                        ))}
                <CaseworkerWrapper>
                    {assignedTo.map((caseworker) => (
                        <ImageFrame
                            id={caseworker.id}
                            profileImg={caseworker.profile_img}
                            setCaseworkerPreview={setCaseworkerPreview}
                            setShowTooltip={setShowTooltip}
                        />
                    ))}
                </CaseworkerWrapper>
            </CaseworkerRow>
        </CardInfo>
    )
}

export default InfoCard

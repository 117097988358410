import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    checkEmail,
    checkPassword,
    isValidEmail,
    isValidPassword,
} from '../../../../../helpers/validation'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
// import { useActions } from '../../../../../hooks/useActions'
import { NextButton } from '../../../../reusable/buttons'
import {
    ErrorMessage,
    FormField,
    FormInput50,
    InputFieldIcon,
    PrimaryLabel,
} from '../../../../styled/formElements'
import { HeadingPrimary } from '../../../../styled/typography'
import { LoginInfo } from '../LoginPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { getUrlAfterAppSubdomain } from '../../../../../helpers/routing'
import config from '../../../../../config'
import { useLocation } from 'react-router-dom'

const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

const Container = styled.div`
    width: 35rem;
    margin: 0 auto;
    text-align: left;
    padding-top: 22rem;

    @media screen and (max-width: 600px) {
        padding-block-start: 12rem;
    }
`

const Form = styled.form`
    padding-top: 5rem;
    width: 100%;
`

const ForgotPasswordLink = styled.span`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    padding-top: 0.5rem;
    cursor: pointer;
`

const NextButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 4rem;
`

interface LoginFormProps {
    setCurrentPageNumber: (pageNumber: number) => void
    loginInfo: LoginInfo
    updateLoginInfo: (key: string, value: string) => void
}

const LoginForm: React.FC<LoginFormProps> = ({
    setCurrentPageNumber,
    loginInfo,
    updateLoginInfo,
}) => {
    const translation = useTranslation()

    const location = useLocation()

    const queryParams = new URLSearchParams(location.search)

    const integrationId = queryParams.get('integrationId')

    const { email, password } = loginInfo

    const { env } = config

    const [isFormValidated, setIsFormValidated] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [emailSuccess, setEmailSuccess] = useState(false)
    const [passwordError, setPasswordError] = useState('')
    const [passwordSuccess, setPasswordSuccess] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const { authLoading, dynamicLoginError } = useTypedSelector(
        (state) => state.auth
    )

    const { verifyLoginCredentials } = useActions()

    // Email
    const handleEmailValidation = () => {
        const validationError = checkEmail(email, translation)

        !validationError && setEmailSuccess(true)
        setEmailError(validationError)
    }

    const handleEmailOnFocus = () => {
        setEmailError('')
        setEmailSuccess(false)
    }

    // Password
    const handlePasswordValidation = () => {
        const validationError = checkPassword(password, translation)

        !validationError && setPasswordSuccess(true)
        setPasswordError(validationError)
    }

    const handlePasswordOnFocus = () => {
        setPasswordError('')
        setPasswordSuccess(false)
    }

    useEffect(() => {
        dynamicLoginError?.errors?.email &&
            setEmailError(dynamicLoginError.errors.email)

        dynamicLoginError?.errors?.login &&
            setPasswordError(dynamicLoginError.errors.login)
    }, [dynamicLoginError])

    useEffect(() => {
        if (!isValidEmail(email) || !isValidPassword(password)) {
            return setIsFormValidated(false)
        }

        setIsFormValidated(true)
    }, [email, password])

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (isFormValidated) {
            const res: any = await verifyLoginCredentials(
                email,
                password,
                integrationId
            )

            if (res.status !== 200) {
                return
            }

            const user = res.data.payload

            if (user.magicLoginCode) {
                /*     const subdomain =
                    user.userType === 'caseworker' ? 'partner' : user.userType*/

                let subdomain

                if (
                    user.roles.some(
                        (role: string) =>
                            role === 'caseworker' || role === 'partner'
                    )
                ) {
                    subdomain = 'partner'
                } else if (
                    user.roles.includes('contact') &&
                    user.temp === null
                ) {
                    // Contact is always the leading subdomain even if it is also a client user
                    subdomain = 'contact'
                } else if (user.roles.includes('client')) {
                    subdomain = 'client'
                } else {
                    subdomain = 'admin'
                }

                if (env === 'local' || env === 'staging') {
                    localStorage.setItem('isLoggedIn', 'true')
                    localStorage.setItem('roles', JSON.stringify(user.roles))

                    // eslint-disable-next-line
                    const url = `http://app.${subdomain}.${getUrlAfterAppSubdomain()}/magic-login/${
                        user.magicLoginCode
                    }`
                    return window.location.replace(url)
                }
            }

            updateLoginInfo('phone', user.phoneNumber)
        }
    }

    const handleShowPasswordCheckboxChanged = () => {
        setShowPassword(!showPassword)
    }

    return (
        <Container>
            <HeadingPrimary>{translation.loginFlow.login}</HeadingPrimary>

            <Form onSubmit={(e) => handleSubmit(e)}>
                <FormField>
                    <PrimaryLabel
                        isError={emailError ? true : false}
                        isSuccess={emailSuccess}
                        htmlFor="email"
                    >
                        {translation.reusable.email}
                    </PrimaryLabel>
                    <FormInput50
                        onFocus={handleEmailOnFocus}
                        onBlur={handleEmailValidation}
                        className={
                            emailError
                                ? 'border-error'
                                : emailSuccess
                                ? 'border-success'
                                : ''
                        }
                        placeholder={translation.reusable.email}
                        id="email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) =>
                            updateLoginInfo('email', e.target.value)
                        }
                    />
                    {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
                </FormField>
                <FormField>
                    <PrimaryLabel
                        isError={passwordError ? true : false}
                        isSuccess={passwordSuccess}
                        htmlFor="password"
                    >
                        {translation.reusable.password}
                    </PrimaryLabel>

                    <FormInput50
                        onFocus={handlePasswordOnFocus}
                        onBlur={handlePasswordValidation}
                        className={
                            passwordError
                                ? 'border-error'
                                : passwordSuccess
                                ? 'border-success'
                                : ''
                        }
                        placeholder={translation.reusable.password}
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) =>
                            updateLoginInfo('password', e.target.value)
                        }
                    />
                    <InputFieldIcon onClick={handleShowPasswordCheckboxChanged}>
                        {!showPassword ? eye : eyeSlash}
                    </InputFieldIcon>

                    {passwordError && (
                        <ErrorMessage>{passwordError}</ErrorMessage>
                    )}
                </FormField>

                <ForgotPasswordLink onClick={() => setCurrentPageNumber(2)}>
                    {translation.loginFlow.forgotPassword}
                </ForgotPasswordLink>

                <NextButtonContainer>
                    <NextButton
                        text={translation.loginFlow.login}
                        isActive={isFormValidated}
                        width="133px"
                        icon={false}
                        isLoading={authLoading}
                    />
                </NextButtonContainer>
            </Form>
        </Container>
    )
}

export default LoginForm

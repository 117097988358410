import Translation from '../state-types/TranslationStateType'

const notificationTransformer = (
    notifications: any[],
    translation: Translation
) => {
    const newNotifications = notifications.map((notification: any) => {
        const date =
            typeof notification.human_diff !== 'undefined'
                ? notification.human_diff
                : null

        if (notification.type === 'incident.caseWasTaken') {
            return {
                ...notification,
                title: translation.notifications.reportUnassigned,
                date,
            }
        } else if (notification.type === 'incident.caseWasGiven') {
            return {
                ...notification,
                title: translation.notifications.reportAssigned,
                date,
            }
        } else if (notification.type === 'incident.deadlineReached') {
            return {
                ...notification,
                title: translation.notifications.deadlineAlert,
                date,
            }
        } else if (notification.type === 'incident.newMessageInCase') {
            return {
                ...notification,
                title: translation.notifications.newMessage,
                date,
            }
        } else if (notification.type === 'incident.caseWasDistributed') {
            return {
                ...notification,
                title: translation.notifications.newReport,
                date,
            }
        } else {
            return false
        }
    })

    return newNotifications
}

export default notificationTransformer

import styled from 'styled-components'
import noProfileImage from '../../../../../assets/images/no-profile-image.svg'
import { FC } from 'react'

const Image = styled.img`
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.platformGrey};
    cursor: pointer;
`
interface ImageFrameProps {
    id: number
    profileImg: string
    setCaseworkerPreview?: (value: number) => void
    setShowTooltip?: (value: boolean) => void
}

const ImageFrame: FC<ImageFrameProps> = ({
    id,
    profileImg,
    setCaseworkerPreview,
    setShowTooltip,
}) => {
    return (
        <Image
            src={profileImg || noProfileImage}
            alt="caseworker-image"
            onMouseOver={() => {
                setShowTooltip && setShowTooltip(true)
                id && setCaseworkerPreview && setCaseworkerPreview(id)
            }}
        />
    )
}

export default ImageFrame

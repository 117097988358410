import styled from 'styled-components'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import { useEffect, useState } from 'react'
import { EditButton } from '../../../../styled/buttons'
import UpdatedSettingsModal from './components/UpdatedSettingsModal'
import { ReactComponent as CloseIcon } from '../../../../../assets/images/x-icon.svg'
import { ReactComponent as checkIcon } from '../../../../../assets/images/check-icon-circle.svg'
import { CountryInterface } from '../../../../../state/reducers/countriesReducer'
import PersonalInformationForm from './components/PersonalInformationForm'
import CaseworkerInputDisplay from './components/CaseworkerInputDisplay'
import ChangePassword from './ChangePassword'
import { useSaveIdentity } from '../../../../reusable/functions/useSaveIdentity'
import SkeletonCard from '../../../../reusable/skeletons/SkeletonCard'

const TempCardContainer = styled.div`
    margin-bottom: 2rem;
    flex: 1 1 0;
    width: 0;
`

export const UpdateStettings = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`

export const CancelIcon = styled(CloseIcon)`
    width: 0.8rem;
`

export const CancelButton = styled.button`
    color: ${({ theme }) => theme.red};
    background-color: ${({ theme }) => `${theme.red}20`};
    font-size: 1.2rem;
    cursor: pointer;
    border: none;
    height: 2.9rem;
    width: 12rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    &:hover {
        background-color: ${({ theme }) => `${theme.red}30`};
    }
`

export const Edit = styled(EditButton)`
    &:hover {
        background-color: ${({ theme }) => `${theme.yellow}20`};
    }
`

export const BaseMessageComponent = styled.div`
    width: 27.8rem;
    height: 4rem;
    padding: 1.4rem;
    font-size: 1.2rem;
    border-radius: 0.2rem;
    margin-inline: 1.4rem;
    margin-block-end: 1.4rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
`

export const SuccessMessage = styled(BaseMessageComponent)`
    background-color: ${({ theme }) => `${theme.blue}50`};
    color: ${({ theme }) => theme.blue};
`

export const UpdateIdentityErrorMessage = styled(BaseMessageComponent)`
    background-color: ${({ theme }) => `${theme.red}50`};
    color: ${({ theme }) => theme.red};
`

export const CheckSavedMessageIcon = styled(checkIcon)`
    width: 1.3rem;

    && .check-icon-circle {
        stroke: ${({ theme }) => theme.blue};
    }
`

const Container = styled.div``

const SecondSection = styled.div`
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
`

const SkeletonFlexContainer = styled.div`
    display: flex;
    gap: 2rem;
    margin-block: 1.8rem;
`

export interface User {
    languages: Number[]
    contact_information: any
    selected_language: any
    userId: number
    firstName: string
    lastName: string
    email: string
    activeCases: number
    phone: string
    country: CountryInterface
}

export type EditSection = 'personal' | 'caseworker' | 'password' | null

const MyAccount: React.FC = () => {
    const { updateIdentityStatus, saveIdentity } = useSaveIdentity()
    const { logout, getCompanyInformation } = useActions()
    const translation = useTranslation()

    const [showModal, setShowModal] = useState(false)
    const [countdown, setCountDown] = useState(8)
    const identity = useTypedSelector((state) => state.identity.getIdentity)
    const [editSection, setEditSection] = useState<EditSection>(null)

    const { getIdentity } = useActions()

    useEffect(() => {
        getIdentity()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getCompanyInformation()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (updateIdentityStatus.success && updateIdentityStatus.shouldLogout) {
            setShowModal(true)
        }
    }, [updateIdentityStatus])

    useEffect(() => {
        if (showModal) {
            let timer: NodeJS.Timeout | undefined = undefined
            if (countdown > 0) {
                timer = setInterval(() => {
                    setCountDown(countdown - 1)
                }, 1000)
            } else {
                clearInterval(timer)
                logout()
                setShowModal(false)
            }
            return () => clearInterval(timer)
        }
        // eslint-disable-next-line
    }, [showModal, countdown])

    return (
        <>
            {showModal && (
                <UpdatedSettingsModal
                    title={translation.settingsPartner.emailUpdated}
                    tagline={`${translation.settingsPartner.verificationLinkSent} ${countdown} ${translation.settingsPartner.seconds}`}
                />
            )}
            {!identity ? (
                <>
                    <SkeletonCard
                        height="36.8rem"
                        width="100%"
                        border="0.3rem"
                    />
                    <SkeletonFlexContainer>
                        <SkeletonCard
                            height="44rem"
                            width="50%"
                            border="0.3rem"
                        />
                        <SkeletonCard
                            height="44rem"
                            width="50%"
                            border="0.3rem"
                        />
                    </SkeletonFlexContainer>
                </>
            ) : (
                <Container>
                    <PersonalInformationForm
                        saveIdentity={saveIdentity}
                        updateIdentityStatus={updateIdentityStatus}
                        setEditSection={setEditSection}
                        editSection={editSection}
                    />
                    <SecondSection>
                        <TempCardContainer>
                            <CaseworkerInputDisplay
                                setEditSection={setEditSection}
                                editSection={editSection}
                            />
                        </TempCardContainer>
                        <TempCardContainer>
                            <ChangePassword
                                setEditSection={setEditSection}
                                editSection={editSection}
                            />
                        </TempCardContainer>
                    </SecondSection>
                </Container>
            )}
        </>
    )
}

export default MyAccount

import React, { useEffect } from 'react'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import styled from 'styled-components'
import { HeadingSecondary } from '../../../styled/typography'
import Cases from '../../../reusable/cases/Cases'
import NotificationBox from '../../partners/dashboard/components/NotificationBox'
import ReportStatistics from '../../../reusable/statistics/ReportStatistics'
import LoadingSkeletonContactDashBoardPage from '../../loading/LoadingContactDashboardPage'
import FlashMessage from '../../../reusable/feedback/FlashMessage'

export const Container = styled.div`
    width: 100%;
    padding: 4rem;
`

export const FlexContainer = styled.div`
    display: flex;
    gap: 2.2rem;
    padding-top: 2rem;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
`

// TODO re-use card

export const CasesContainer = styled.div`
    width: 100%;
    height: 36rem;

    @media screen and (max-width: 600px) {
        height: 20rem;
    }
`

interface ContactDashboardPageProps {}

const ContactDashboardPage: React.FC<ContactDashboardPageProps> = () => {
    const translation = useTranslation()

    const { selfReports, contactUserLoading } = useTypedSelector(
        (state) => state.contactUser
    )

    const { flashMessage } = useTypedSelector((state) => state.hrOnIntegration)

    const { contactGetSelfReports, getDashboardStatistics } = useActions()

    useEffect(() => {
        contactGetSelfReports()
        getDashboardStatistics()
        // eslint-disable-next-line
    }, [])

    return (
        <Container>
            {flashMessage && <FlashMessage message={flashMessage} />}
            <HeadingSecondary>
                {translation.dashboardPartner.headlineDashboard}
            </HeadingSecondary>
            {contactUserLoading ? (
                <LoadingSkeletonContactDashBoardPage />
            ) : (
                <>
                    <FlexContainer>
                        {selfReports && (
                            <CasesContainer>
                                <Cases
                                    cases={selfReports}
                                    title={
                                        translation.dashboardPartner.yourcases
                                    }
                                    isAllClosed={false}
                                    height="27.2rem"
                                />
                            </CasesContainer>
                        )}
                        <NotificationBox />
                    </FlexContainer>
                    <ReportStatistics />
                </>
            )}
        </Container>
    )
}

export default ContactDashboardPage

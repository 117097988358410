import {
    Modal,
    ModalCancelButton,
    ModalContainer,
} from '../../../styled/modals'
import { Card, CardBody, CardHeader } from '../../../styled/cards'
import { useEffect, useState } from 'react'
import Vapor from 'laravel-vapor'
import config from '../../../../config'
import styled from 'styled-components'
import { FiMic, FiStopCircle } from 'react-icons/fi'
import { DynamicHeightBlueButton } from '../../../styled/buttons'
import { VoiceRecording } from '../../whistleblower/new-case/NewCasePage'
import { useTranslation } from '../../../../hooks'

const { apiUrl, backendUrl, env } = config

const Container = styled.div`
    text-align: center;
    padding-top: 5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
`

const Heading = styled.h3`
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 2.4rem;
    color: ${({ theme }) => theme.primary};
`

const Text = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
    margin-bottom: 1rem;
`

const MicButton = styled.button`
    border: none;
    height: 4.2rem;
    width: 4.2rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.blue};

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4.6rem;
    cursor: pointer;

    transition: opacity 0.3s;

    :hover {
        opacity: 0.8;
    }
`

const MicIcon = styled(FiMic)`
    font-size: 2.4rem;
    color: #fff;
`

const LightText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.6;
    margin-top: 1.4rem;
`

const StopContainer = styled.div`
    position: relative;
`
const StopIcon = styled(FiStopCircle)`
    color: #fa3d1e;
    opacity: 0.5;
    font-size: 4rem;
    margin-top: 4.6rem;
    cursor: pointer;
`

const Timer = styled.p`
    position: absolute;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
    top: 3rem;
`

const Audio = styled.audio`
    height: 3rem;
    width: 42rem;
`

interface RecordVoiceMessageModalProps {
    updateReport: (key: string, value: any) => void
    close: () => void
}

export const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, '0')}:${String(
        remainingSeconds
    ).padStart(2, '0')}`
}

const RecordVoiceMessModal: React.FC<RecordVoiceMessageModalProps> = ({
    updateReport,
    close,
}) => {
    const [audio, setAudio] = useState<null | string>(null)
    const [isRecording, setIsRecording] = useState(false)

    const [timeRemaining, setTimeRemaining] = useState(600)

    const translation = useTranslation()

    useEffect(() => {
        let timer: NodeJS.Timeout

        if (isRecording && timeRemaining > 0) {
            timer = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1)
            }, 1000)
        }

        return () => {
            clearInterval(timer)
        }
    }, [isRecording, timeRemaining])

    const setupAudio = async () => {
        setIsRecording(true)

        if (navigator.mediaDevices) {
            navigator.mediaDevices
                .getUserMedia({
                    audio: true,
                })
                .then(SetUpStream)
                .catch((err) => console.error(err))
        }
    }

    const [recorder, setRecorder] = useState<null | MediaRecorder>(null)

    const [voiceRecording, setVoiceRecording] = useState<any>(null)

    const [uploadProgress, setUploadProgress] = useState(0)

    const upload = async (file: any) => {
        const bucket = env === 'local' ? 'walor-staging' : 'walor-prod-api'

        const response = await Vapor.store(file, {
            progress: (progress) => {
                setUploadProgress(Math.round(progress * 100))
            },
            baseURL: backendUrl,
            bucket: bucket,
            visibility: 'private',
            options: {
                bucket: bucket,
                visibility: 'private',
            },
        })

        const { key } = response

        const recordingLength = 600 - timeRemaining

        const reportVoiceRecording: VoiceRecording = {
            awsKey: key,
            length: recordingLength,
            previewUrl: audio as string,
        }

        updateReport('voiceRecording', reportVoiceRecording)

        close()
    }

    function SetUpStream(stream: MediaStream) {
        const chunks: Blob[] = []

        const recorder = new MediaRecorder(stream)

        recorder.ondataavailable = (e) => {
            chunks.push(e.data)
        }

        recorder.onstop = async (e) => {
            const blob = new Blob(chunks, { type: 'audio/wav' })

            const file = new File([blob], 'recording.wav', {
                type: 'audio/wav',
            })

            const data = new FormData()

            data.append('voice_recording', file)

            const res = await fetch(`${apiUrl}/v1/distort-voice`, {
                method: 'POST',
                body: data,
            })

            const distortedBlob = await res.blob()
            const url = URL.createObjectURL(distortedBlob)

            setAudio(url)

            const { headers } = res

            const voiceRecordingID = headers.get('X-Voice-Recording-Id')

            updateReport('voiceRecordingId', voiceRecordingID)

            const distortedFile = new File(
                [distortedBlob],
                'distorted-audio.wav',
                { type: 'audio/wav' }
            )

            setVoiceRecording(distortedFile)
        }

        setRecorder(recorder)

        recorder.start()

        setIsRecording(true)
    }

    function stopRecording() {
        if (!recorder) {
            return
        }

        recorder?.stop()

        setIsRecording(false)
    }

    return (
        <ModalContainer>
            <Modal height="33.4rem" width="64.3rem">
                <Card>
                    <CardHeader>
                        {translation.newCaseFlow.voiceMessage}
                        <ModalCancelButton onClick={close}>
                            {translation.reusable.cancel}
                        </ModalCancelButton>
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <Heading>
                                {translation.newCaseFlow.recordYourVoice}
                            </Heading>
                            <Text>
                                {
                                    translation.newCaseFlow
                                        .yourVoiceIsAutomatically
                                }
                            </Text>
                            <Text>
                                {translation.newCaseFlow.listenToYourRecording}
                            </Text>

                            {audio ? (
                                <div
                                    style={{
                                        marginTop: '3.2rem',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '1.6rem',
                                    }}
                                >
                                    <Audio controls>
                                        <source src={audio}></source>
                                    </Audio>
                                    <DynamicHeightBlueButton
                                        height="3.7rem"
                                        width="13rem"
                                        fontSize="1.6rem"
                                        onClick={() => upload(voiceRecording)}
                                    >
                                        {uploadProgress
                                            ? `${uploadProgress}%`
                                            : translation.newCaseFlow
                                                  .addRecording}
                                    </DynamicHeightBlueButton>
                                </div>
                            ) : (
                                <>
                                    {!isRecording ? (
                                        <MicButton onClick={setupAudio}>
                                            <MicIcon />
                                        </MicButton>
                                    ) : (
                                        <>
                                            <StopContainer>
                                                <Timer>
                                                    {formatTime(timeRemaining)}
                                                </Timer>
                                                <StopIcon
                                                    onClick={stopRecording}
                                                />
                                            </StopContainer>
                                        </>
                                    )}
                                    <LightText>
                                        {!isRecording
                                            ? translation.newCaseFlow
                                                  .clickToStartRecording
                                            : translation.newCaseFlow
                                                  .stopRecording}
                                    </LightText>
                                </>
                            )}
                        </Container>
                    </CardBody>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default RecordVoiceMessModal

import styled from 'styled-components'
import TranslateButton from '../../../../../../reusable/buttons/translate-button/TranslateButton'
import { useEffect, useState } from 'react'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../../../hooks'
import { FiBookOpen } from 'react-icons/fi'

const Description = styled.p`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    padding: 1.6rem 2.8rem;
    white-space: pre-wrap;
`

const DescriptionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const AudioContainer = styled.div`
    position: relative;
`

const AudioContainerLabel = styled.div`
    position: absolute;
    height: 3.6rem;
    background-color: ${({ theme }) => theme.blue};
    color: #fff;
    left: -15rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 18rem;
    border-radius: 2rem;
`

const TranscriptionButton = styled.div`
    height: 3.6rem;
    width: 3.6rem;
    background-color: ${({ theme }) => theme.blue};
    border-radius: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    z-index: 1;
    cursor: pointer;
`

const BookIcon = styled(FiBookOpen)`
    font-size: 1.8rem;
    color: #fff;
    font-weight: bold;
`

interface DescriptionFrameProps {
    description: string
    recording: {
        url: string
        transcription: string
    }
    openTranscriptionModal: () => void
}

const DescriptionFrame: React.FC<DescriptionFrameProps> = ({
    description,
    recording,
    openTranscriptionModal,
}) => {
    const translation = useTranslation()

    const [isTranslateBtnActive, setIsTranslateBtnActive] = useState(false)
    const [translatedDescription, setTranslatedDescription] = useState('')

    const {
        translateDescriptionLoading,
        translateDescriptionError,
        translateDescriptionSuccess,
    } = useTypedSelector((state) => state.translateDescription)
    const { selectedTranslation } = useTypedSelector(
        (state) => state.translation
    )

    const { translateDescription } = useActions()

    const translateReportDescription = async () => {
        try {
            const translatedText = await translateDescription(
                description,
                selectedTranslation
            )

            const translatedDescription = translatedText.toString()
            setTranslatedDescription(translatedDescription)
        } catch (error) {
            translateDescriptionError && setTranslatedDescription(description)
        }
    }

    useEffect(() => {
        if (isTranslateBtnActive && !translatedDescription) {
            translateReportDescription()
        }
        // eslint-disable-next-line
    }, [isTranslateBtnActive])

    return (
        <Description>
            <DescriptionHeader>
                {description?.length > 0 && (
                    <TranslateButton
                        isActive={isTranslateBtnActive}
                        handleClick={() =>
                            setIsTranslateBtnActive(!isTranslateBtnActive)
                        }
                        isLoading={translateDescriptionLoading}
                        margin="0 0 1.6rem 0"
                    />
                )}
                {recording && (
                    <>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <AudioContainer>
                                <AudioContainerLabel>
                                    {translation.reusable.voiceRecording}
                                </AudioContainerLabel>
                                <audio
                                    controls
                                    style={{ height: '3.6rem', zIndex: 2 }}
                                >
                                    <source src={recording.url} />
                                </audio>
                            </AudioContainer>
                            <TranscriptionButton
                                onClick={openTranscriptionModal}
                            >
                                <BookIcon />
                            </TranscriptionButton>
                        </div>
                    </>
                )}
            </DescriptionHeader>
            {isTranslateBtnActive && translateDescriptionSuccess ? (
                <span>{translatedDescription}</span>
            ) : (
                <span>{description}</span>
            )}
        </Description>
    )
}

export default DescriptionFrame

import styled from 'styled-components'
import { FC } from 'react'
import { TransparentButtonSmall } from '../../../pages/whistleblower/new-case/styled'
import TranslateLogo from './translate-logo.svg'
import TranslatedLogo from './translated-logo.svg'
import ButtonSpinnerWhite from '../../../layout/spinner/ButtonSpinnerWhite'
import { useTranslation } from '../../../../hooks'

interface ButtonProps {
    isActive: boolean
    margin?: string
}

const Button = styled(TransparentButtonSmall)<ButtonProps>`
    width: 11rem;
    height: 3.2rem;
    margin: ${({ margin }) => margin ?? '1.5rem auto'};
    font-size: 1.4rem;
    background-color: ${({ isActive }) =>
        isActive && 'rgb(36, 125, 208, 0.2 )'};
    color: ${({ isActive }) => isActive && 'rgb(36, 125, 208)'};
    box-shadow: ${({ isActive }) =>
        isActive && '0 2px 2px 0 rgba(0, 0, 0, 0.25)'};
    border: ${({ isActive }) => isActive && 'none'};
`

const ButtonText = styled.span`
    margin-right: 0.5rem;
`

const TranslateIcon = styled.img``

interface TranslateButtonProps {
    handleClick: () => void
    isActive: boolean
    isLoading: boolean
    margin?: string
}

const TranslateButton: FC<TranslateButtonProps> = ({
    handleClick,
    isActive,
    isLoading,
    margin,
}) => {
    const translation = useTranslation()

    const buttonText =
        !isActive || isLoading
            ? translation.accessCaseFlow.translate
            : translation.accessCaseFlow.translated
    const iconSrc = isActive ? TranslatedLogo : TranslateLogo
    return (
        <Button onClick={handleClick} isActive={isActive} margin={margin}>
            <ButtonText>{buttonText}</ButtonText>
            {isLoading ? (
                <ButtonSpinnerWhite overrideColor="#247DD0" />
            ) : (
                <TranslateIcon src={iconSrc} alt="" />
            )}
        </Button>
    )
}
export default TranslateButton

import { useTranslation, useTypedSelector } from '../../hooks'
import {
    DropdownContent,
    Option,
    OptionScroll,
    SearchIcon,
    SearchInput,
    SearchInputContainer,
    SmallFlag,
    Dropdown,
    Select,
} from './form/CountryCodeDropdown'
import { SpokenLanguagesInterface } from '../../state/reducers/spokenLanguagesReducer'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

interface Disabled {
    disabled?: boolean
    border?: string
    width?: string
}

const DropdownText = styled.div<Disabled>`
    font-size: 1.3rem;
    color: ${({ theme, disabled }) =>
        disabled ? '#6d6d6d' : theme.secondaryDark};
`

const DropdownIcon = css`
    font-size: 1.3rem;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
`

const UpIcon = styled(FiChevronUp)`
    ${DropdownIcon}
`

const DownIcon = styled(FiChevronDown)<Disabled>`
    ${DropdownIcon}
    color: ${({ theme, disabled }) =>
        disabled ? '#6d6d6d' : theme.secondaryDark};
`

const SelectWrapper = styled.div`
    position: relative;
`

const SelectLanguage = styled.div`
    & ${Select} {
        border: 2px solid ${({ theme }) => theme.grey};
        height: 28rem;
        top: 5rem;
    }
`

interface LanguageDropdownProps {
    selectDropdownLanguage: (spokenLanguage: SpokenLanguagesInterface) => void
    disabled?: boolean
    border?: string
    width?: string
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
    selectDropdownLanguage,
    disabled = false,
    border,
    width,
}) => {
    const { spokenLanguages } = useTypedSelector(
        (state) => state.spokenLanguages
    )
    const [isDropdownActive, setIsDropdownActive] = useState<boolean>(false)
    const [selectedLanguage, setSelectedLanguage] =
        useState<SpokenLanguagesInterface | null>(null)
    const [searchedLanguages, setSearchedLanguages] = useState<
        SpokenLanguagesInterface[] | null | undefined
    >(spokenLanguages)
    const ref = useRef<HTMLDivElement>(null)
    const translation = useTranslation()

    useEffect(() => {
        if (!spokenLanguages) {
            return
        }

        spokenLanguages?.forEach((language) => {
            setSelectedLanguage(language)
        })

        setSearchedLanguages(spokenLanguages)
    }, [spokenLanguages])

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown)
        return () => {
            document.removeEventListener('mousedown', closeDropdown)
        }
    }, [ref])

    const closeDropdown = (e: MouseEvent) => {
        const target = e.target as HTMLInputElement
        if (target && target.classList.contains('keep-open')) {
            return
        }

        if (e.button === 0) {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                setTimeout(() => setIsDropdownActive(false), 100)
            }
        }
    }

    const handleDropdown = () => {
        !disabled && setIsDropdownActive(!isDropdownActive)
    }

    const handleSelectLanguage = (language: SpokenLanguagesInterface) => {
        selectDropdownLanguage(language)
        setSelectedLanguage(language)
        setIsDropdownActive(false)
    }

    const handleSearchLanguages = (e: React.ChangeEvent<HTMLInputElement>) => {
        const languageValue = e.target.value

        if (!languageValue) {
            setSearchedLanguages(spokenLanguages)
        }

        const searchResult = spokenLanguages?.filter(
            (language: { name: string }) =>
                language.name.toLowerCase().includes(languageValue)
        )
        setSearchedLanguages(searchResult)
    }

    return (
        <SelectWrapper>
            <Dropdown
                disabled={disabled}
                data-testid="language-dropdown"
                onClick={handleDropdown}
                ref={ref}
                border={border}
                width={width}
            >
                {selectedLanguage && (
                    <DropdownContent>
                        <DropdownText disabled={disabled}>
                            {translation.singleClientViewPartner.addLanguage}
                        </DropdownText>
                        {isDropdownActive ? (
                            <UpIcon />
                        ) : (
                            <DownIcon disabled={disabled} />
                        )}
                    </DropdownContent>
                )}
            </Dropdown>

            {isDropdownActive && (
                <SelectLanguage>
                    <Select data-testid={'open-dropdown'}>
                        <SearchInputContainer>
                            <SearchInput
                                type="text"
                                placeholder={
                                    translation.singleClientViewPartner
                                        .searchLanguage
                                }
                                onChange={handleSearchLanguages}
                                className="keep-open"
                            />
                            <SearchIcon className="keep-open" />
                        </SearchInputContainer>
                        <OptionScroll>
                            {searchedLanguages &&
                            searchedLanguages.length > 0 ? (
                                searchedLanguages.map(
                                    (language: SpokenLanguagesInterface) => (
                                        <Option
                                            key={language.id}
                                            onClick={() =>
                                                handleSelectLanguage(language)
                                            }
                                            data-testid="language-option"
                                            style={{ gap: '1rem' }}
                                        >
                                            <SmallFlag src={language?.flag} />
                                            <DropdownText>
                                                {language.name}
                                            </DropdownText>
                                        </Option>
                                    )
                                )
                            ) : (
                                <DropdownText>
                                    {
                                        translation.singleClientViewPartner
                                            .languageNotFound
                                    }
                                </DropdownText>
                            )}
                        </OptionScroll>
                    </Select>
                </SelectLanguage>
            )}
        </SelectWrapper>
    )
}

export default LanguageDropdown

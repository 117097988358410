import styled from 'styled-components'
import logo from './logo-white.svg'
import { NavLink as Link } from 'react-router-dom'
import SelectLanguage from './SelectLanguage'
import { useEffect, useRef, useState } from 'react'
import { useIsPartner, useTypedSelector } from '../../../hooks'
import { useHistory } from 'react-router'
import { FiBell, FiFolder, FiGrid, FiUser, FiTool } from 'react-icons/fi'
import { BsBarChartLine } from 'react-icons/bs'
import initials from '../../../helpers/string/initials'
import NotificationDropdown from './components/NotificationDropdown'
import TrialWarning from '../TrialWarning'
import { VscMenu } from 'react-icons/vsc'
import MobileHeader from './MobileHeader'

interface HeaderContainerProps {
    hexCode: null | string
}

const HeaderContainer = styled.div<HeaderContainerProps>`
    height: 85px;
    max-width: 100vw;
    background: ${({ theme, hexCode }) => (hexCode ? hexCode : theme.primary)};
`

const HedeaderContent = styled.div`
    max-width: 160rem;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    padding: 0 4rem;

    @media screen {
        justify-content: space-between;
    }
`

const Logo = styled.img`
    height: 2.6rem;
    cursor: pointer;
    fill: green;
`

const NavigationList = styled.ul`
    padding-left: 12rem;
    display: flex;
    list-style: none;
    height: 100%;
    position: relative;
    @media screen and (max-width: 1000px) {
        display: none;
    }
`

const NavigationListItem = styled.li`
    padding-right: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

const NavigationLink = styled(Link)`
    color: #fff;
    opacity: 0.5;
    font-size: 1.8rem;
    height: 100%;
    display: flex;
    align-items: center;

    :hover {
        opacity: 1 !important;
    }
`

/*
const UserNameText = styled.p`
    color: ${({ theme }) => theme.white};
    opacity: 0.5;
    font-size: 1.8rem;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 2rem;
    :hover {
        opacity: 1 !important;
    }
    cursor: pointer;
`
*/

export const AvatarWrapper = styled.div`
    display: flex;
    width: 4rem;
    height: 4rem;
    background-color: #7e9ab4;
    border-radius: 50%;
    margin-right: 1.5rem;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
`
export const AvatarInitials = styled.div`
    letter-spacing: -0.5px;
    color: ${({ theme }) => theme.white};
    opacity: 0.65;
`

const IconWrapper = styled.div`
    padding-right: 1rem;
`

const SecondNavigationList = styled.ul`
    display: flex;
    margin-left: auto;
    align-items: center;
    list-style: none;
    position: relative;
    height: 100%;

    @media screen and (max-width: 1000px) {
        display: none;
    }
`

const SecondNavigationListItem = styled.li`
    padding-right: 3rem;
    height: 100%;
    align-items: center;
`

const NotificationContainer = styled.div`
    @media screen and (max-width: 1000px) {
        display: none;
    }
`

const NotificationIconContainer = styled.div`
    margin-right: 2rem;
    cursor: pointer;
    position: relative;
`

const NotificationActiveIndocation = styled.div`
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.red};

    position: absolute;
    top: 0.2rem;
    right: 0;
`

const NotificationCount = styled.p`
    color: ${({ theme }) => theme.white};
    font-size: 0.9rem;

    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
`

const BellIcon = styled(FiBell)`
    font-size: 1.8rem;
    color: ${({ theme }) => theme.white};
    margin-top: 0.5rem;
`
const MobileNavigation = styled.div`
    display: none;

    @media screen and (max-width: 1000px) {
        display: flex;
    }
`

const MenuIcon = styled(VscMenu)`
    color: white;
    font-size: 3rem;
`

const Header: React.FC = () => {
    const isPartner = useIsPartner()
    const history = useHistory()
    let bell = useRef<HTMLDivElement>(null)

    const {
        translation: { translation },
        whistleblower: { isWhistleblowerLoggedIn },
        auth: { isLoggedIn, user },
    } = useTypedSelector((state) => state)

    const { shouldShowTrialInformation } = useTypedSelector(
        (state) => state.payment
    )

    const { organizationalCode } = useTypedSelector(
        (state) => state.whistleblower
    )

    const [isPartnerOrCaseworker, setisPartnerOrCaseworker] = useState(false)
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)

    useEffect(() => {
        if (isLoggedIn && user) {
            if (
                user.roles.includes('partner') ||
                user.roles.includes('caseworker')
            ) {
                return setisPartnerOrCaseworker(true)
            } else {
                return setisPartnerOrCaseworker(false)
            }
        }

        return setisPartnerOrCaseworker(false)
    }, [user, isLoggedIn])

    const [isNotificationsOpen, setIsNotificationsOpen] =
        useState<Boolean>(false)

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleClickOutside = (e: any) => {
        if (bell.current && !bell.current.contains(e.target)) {
            setIsNotificationsOpen(false)
        }
    }

    const handleBellIconClick = () => {
        setIsNotificationsOpen((prevState) => !prevState)
    }

    const { notificationCount } = useTypedSelector(
        (state) => state.notification
    )

    const { isWhitelabled, hexCode } = useTypedSelector(
        (state) => state.whitelabel
    )

    const whitelabelLogo = useTypedSelector((state) => state.whitelabel.logo)

    // const { colorHexCode } = useTypedSel

    return (
        <HeaderContainer hexCode={hexCode}>
            <HedeaderContent>
                {!isWhitelabled ? (
                    <Logo
                        onClick={() => {
                            if (organizationalCode) {
                                history.push(
                                    `/organization/${organizationalCode}/whistleblower`
                                )
                            } else {
                                history.push('/')
                            }
                        }}
                        src={logo}
                        alt="walor logo"
                    />
                ) : (
                    <Logo
                        onClick={() => {
                            if (organizationalCode) {
                                history.push(
                                    `/organization/${organizationalCode}/whistleblower`
                                )
                            } else {
                                history.push('/')
                            }
                        }}
                        src={whitelabelLogo}
                        alt="Whitelabled logo"
                    />
                )}
                {isLoggedIn &&
                    user?.roles.includes('contact') &&
                    user &&
                    !user.temp && (
                        <NavigationList>
                            <NavigationListItem>
                                <NavigationLink
                                    exact
                                    to=""
                                    activeClassName="active-nav-link"
                                >
                                    <IconWrapper>
                                        <FiGrid color="white" size={18} />
                                    </IconWrapper>
                                    {translation.header.dashboard}
                                </NavigationLink>
                            </NavigationListItem>

                            <NavigationListItem>
                                <NavigationLink
                                    exact
                                    to="/cases"
                                    activeClassName="active-nav-link"
                                >
                                    <IconWrapper>
                                        <FiFolder color="white" size={18} />
                                    </IconWrapper>
                                    {translation.header.cases}
                                </NavigationLink>
                            </NavigationListItem>

                            {user?.roles.includes('client') && (
                                <>
                                    <NavigationListItem>
                                        <NavigationLink
                                            exact
                                            to="/organization"
                                            activeClassName="active-nav-link"
                                        >
                                            <IconWrapper>
                                                <FiTool
                                                    color="white"
                                                    size={18}
                                                />
                                            </IconWrapper>
                                            {translation.reusable.organization}
                                        </NavigationLink>
                                    </NavigationListItem>
                                    <NavigationListItem>
                                        <NavigationLink
                                            exact
                                            to="/statistics"
                                            activeClassName="active-nav-link"
                                        >
                                            <IconWrapper>
                                                <BsBarChartLine
                                                    color="white"
                                                    size={18}
                                                />
                                            </IconWrapper>
                                            {
                                                translation.statisticsPage
                                                    .statistics
                                            }
                                        </NavigationLink>
                                    </NavigationListItem>
                                </>
                            )}
                        </NavigationList>
                    )}
                {isLoggedIn && isPartnerOrCaseworker && user && !user.temp && (
                    <NavigationList>
                        <NavigationListItem>
                            <NavigationLink
                                exact
                                to="/"
                                activeClassName="active-nav-link"
                            >
                                <IconWrapper>
                                    <FiGrid color="white" size={18} />
                                </IconWrapper>

                                {translation.header.dashboard}
                            </NavigationLink>
                        </NavigationListItem>
                        <NavigationListItem>
                            <NavigationLink
                                to="/cases"
                                activeClassName="active-nav-link"
                            >
                                <IconWrapper>
                                    <FiFolder color="white" size={18} />
                                </IconWrapper>
                                {translation.header.cases}
                            </NavigationLink>
                        </NavigationListItem>

                        {/* <NavigationListItem>
                        <NavigationLink
                            to="/statistics"
                            activeClassName="active-nav-link"
                        >
                            Statistics
                        </NavigationLink>
                    </NavigationListItem> */}
                        {isPartner && (
                            <NavigationListItem>
                                <NavigationLink
                                    to="/clients"
                                    activeClassName="active-nav-link"
                                >
                                    <IconWrapper>
                                        <FiUser color="white" size={18} />
                                    </IconWrapper>
                                    {translation.header.clients}
                                </NavigationLink>
                            </NavigationListItem>
                        )}
                    </NavigationList>
                )}

                <SecondNavigationList>
                    {!isWhistleblowerLoggedIn && !isLoggedIn && (
                        <>
                            {/* <SecondNavigationListItem>
                            <NavigationLink
                                activeClassName="active-nav-link"
                                to="/login"
                            >
                                Login
                            </NavigationLink>
                        </SecondNavigationListItem> */}
                            {/* <SecondNavigationListItem>
                            <NavigationLink
                                activeClassName="active-nav-link"
                                to="/onboarding"
                            >
                                Onboarding
                            </NavigationLink>
                        </SecondNavigationListItem> */}
                        </>
                    )}

                    {isLoggedIn && user && !user.temp && (
                        <>
                            {user?.roles.some(
                                (role: string) =>
                                    role === 'partner' ||
                                    role === 'caseworker' ||
                                    role === 'contact' ||
                                    role === 'client'
                            ) && (
                                <>
                                    {/*
                                <SecondNavigationListItem>
                                    <NavigationLink
                                        to="/settings"
                                        activeClassName="active-nav-link"
                                    >
                                        <IconWrapper>
                                            <FiSettings
                                                color="white"
                                                size={18}
                                            />
                                        </IconWrapper>
                                        {translation.header.settings}
                                    </NavigationLink>
                                </SecondNavigationListItem> */}
                                    {shouldShowTrialInformation && (
                                        <TrialWarning />
                                    )}
                                    <NotificationContainer>
                                        <NotificationIconContainer
                                            onClick={handleBellIconClick}
                                            ref={bell}
                                        >
                                            {notificationCount > 0 && (
                                                <NotificationActiveIndocation>
                                                    <NotificationCount>
                                                        {notificationCount < 9
                                                            ? notificationCount
                                                            : '+9'}
                                                    </NotificationCount>
                                                </NotificationActiveIndocation>
                                            )}
                                            <BellIcon />
                                        </NotificationIconContainer>
                                        {isNotificationsOpen && (
                                            <NotificationDropdown
                                                closeDropdown={() =>
                                                    setIsNotificationsOpen(
                                                        false
                                                    )
                                                }
                                            />
                                        )}
                                    </NotificationContainer>

                                    <NavigationLink
                                        to="/settings"
                                        activeClassName="active-nav-link"
                                    >
                                        <AvatarWrapper>
                                            <AvatarInitials>
                                                {initials(
                                                    user?.firstName +
                                                        ' ' +
                                                        user?.lastName
                                                )}
                                            </AvatarInitials>
                                        </AvatarWrapper>
                                    </NavigationLink>
                                </>
                            )}
                        </>
                    )}
                    <SecondNavigationListItem>
                        {!isLoggedIn && <SelectLanguage />}
                    </SecondNavigationListItem>
                </SecondNavigationList>
                {isLoggedIn && user && !user.temp && (
                    <MobileNavigation onClick={() => setMobileDrawerOpen(true)}>
                        {!mobileDrawerOpen && <MenuIcon />}
                    </MobileNavigation>
                )}
                {mobileDrawerOpen && (
                    <MobileHeader
                        isOpen={mobileDrawerOpen}
                        close={() => setMobileDrawerOpen(false)}
                        isPartnerOrCaseworker={isPartnerOrCaseworker}
                    />
                )}
            </HedeaderContent>
        </HeaderContainer>
    )
}

export default Header

import { FC } from 'react'
import { useTranslation } from '../../../../../hooks'
import { Card, CardHeader, CaseFilePreview } from '../../../../styled/cards'
import styled from 'styled-components'
import { format } from 'date-fns'

const CardFiles = styled(Card)`
    height: auto;
    min-width: 20rem;
    max-width: 30rem;
    padding-bottom: 1rem;
`

const FilePreview = styled(CaseFilePreview)`
    justify-content: flex-start;
    border-radius: 0.5rem;
    margin: 0.5rem 1.4rem;
    padding: 0.5rem 1rem;
    height: 4rem;
`

const Subheader = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: ${({ theme }) => theme.greyText};
    margin: 0.9rem 1.4rem 0;
`

const FileInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
`

const RowWrap = styled.div`
    font-size: 0.6rem;
    fornt-weight: 400;
    color: ${({ theme }) => theme.greyText};
    & > span:last-child {
        margin-left: 1rem;
    }
`

interface FilesCardProps {
    report: any
    handleDownloadFile: (file: any) => void
}

const FilesCard: FC<FilesCardProps> = ({ report, handleDownloadFile }) => {
    const translation = useTranslation()

    const getFileUploadingDate = (date: Date) => {
        const uploadingDate = format(new Date(date), 'dd.MM.yyyy')
        return uploadingDate
    }

    const getFileSize = (bytes: number) => {
        const kilobytes = bytes / 1024

        if (kilobytes < 1024) {
            return `${kilobytes.toFixed(0)} KB`
        }

        if (kilobytes >= 1024) {
            return `${(kilobytes / 1024).toFixed(0)} MB`
        }
    }

    return (
        <CardFiles>
            <CardHeader>{translation.accessCaseFlow.files}</CardHeader>
            <Subheader>From whistleblower</Subheader>
            {report?.files.map((file: any) => (
                <FilePreview
                    key={file.path}
                    onClick={() => handleDownloadFile(file)}
                >
                    <FileInfo>
                        <p>{file.name}</p>
                        <RowWrap>
                            <span>{getFileUploadingDate(file.created_at)}</span>
                            <span>{getFileSize(file.size)}</span>
                        </RowWrap>
                    </FileInfo>
                </FilePreview>
            ))}
        </CardFiles>
    )
}

export default FilesCard

import styled from 'styled-components'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useEffect } from 'react'
import { useActions, useTypedSelector } from '../../../../../../hooks'
import { AvailableLanguagesInterface } from '../../../../../../state/reducers/availableLanguagesReducer'
import {
    Identity,
    PersonalInfoTypes,
} from '../../../../../../state/state-types/IdentityTypes'

const LanguageSelect = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 2px solid ${({ theme }) => theme.lineGrey};
    width: 100%;
    margin-top: 0.5rem;
`

const LanguageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    cursor: pointer;
`

const DropdownBox = styled.div`
    position: absolute;
    border: 2px solid ${({ theme }) => theme.lineGrey};
    margin-block-start: 0.2rem;
    padding-block-end: 1rem;
    border-radius: 0.2rem;
    width: 100%;
    background: ${({ theme }) => theme.white};
`

const LanguageText = styled.p`
    padding-top: 1rem;
    width: 3rem;
    font-size: 1.5rem;
    cursor: pointer;
`
const DropdownIcon = styled.div`
    font-size: 1.4rem;
    color: black;
    right: 2rem;
`

const ArrowDownIcon = styled(FiChevronDown)`
    ${DropdownIcon}
`

const ArrowUpIcon = styled(FiChevronUp)`
    ${DropdownIcon}
`

const LanguageTextWrapper = styled.div`
    padding-inline: 0.5rem;

    &:hover {
        background-color: ${({ theme }) => theme.grey200};
    }
`
interface DefaultLanguageProps {
    personalInfo: PersonalInfoTypes
    handlerUpdatePersonalInfo: <Key extends keyof Identity>(
        field: Key,
        value: Identity[Key]
    ) => void
    setIsDropdownActive: React.Dispatch<React.SetStateAction<boolean>>
    isDropdownActive: boolean
}

const DefaultLanguageV2: React.FC<DefaultLanguageProps> = ({
    personalInfo,
    handlerUpdatePersonalInfo,
    setIsDropdownActive,
    isDropdownActive,
}) => {
    const { availableLanguages } = useTypedSelector(
        (state) => state.availableLanguages
    )
    const { changeLanguage, getAvailableLanguages } = useActions()

    useEffect(() => {
        getAvailableLanguages()
        // eslint-disable-next-line
    }, [])

    const toggleDropdown = () => {
        // if (isEditing) {
        setIsDropdownActive((prev) => !prev)
        // }
    }

    const handleChangeLanguage = (language: AvailableLanguagesInterface) => {
        setIsDropdownActive(false)
        changeLanguage(language.code)
    }

    return (
        <div>
            <LanguageSelect>
                <LanguageContainer onClick={toggleDropdown}>
                    <p>{personalInfo?.selected_language?.data?.name}</p>

                    {isDropdownActive ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </LanguageContainer>
            </LanguageSelect>
            {isDropdownActive && (
                <DropdownBox>
                    {availableLanguages?.map((language) => {
                        if (
                            language.code !==
                            personalInfo?.selected_language.data.code
                        ) {
                            return (
                                <div
                                    key={language.code}
                                    onClick={() => {
                                        handlerUpdatePersonalInfo(
                                            'selected_language',
                                            {
                                                data: { ...language },
                                            }
                                        )

                                        handleChangeLanguage(language)
                                    }}
                                >
                                    <LanguageTextWrapper>
                                        <LanguageText>
                                            {language.name}
                                        </LanguageText>
                                    </LanguageTextWrapper>
                                </div>
                            )
                        }
                        return null
                    })}
                </DropdownBox>
            )}
        </div>
    )
}

export default DefaultLanguageV2

import styled from 'styled-components'
import { FiDownload, FiFile } from 'react-icons/fi'

interface File {
    isError?: boolean
    progress?: number
}

export const FileBox = styled.div`
    height: 5.7rem;
    width: 100%;
    background: ${({ theme }) => theme.lightGrey};
    border-radius: 1rem;
    margin-bottom: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    transition: background 0.3s;

    :hover {
        background: ${({ theme }) => theme.lightBlue};
    }

    :hover .file-icon {
        color: ${({ theme }) => theme.primary};
    }

    :hover .file-download-icon {
        opacity: 1;
    }
`

export const FileIcon = styled(FiFile)`
    color: ${({ theme }) => theme.greyText};
    font-size: 2rem;
    margin-right: 1rem;
    transition: color 0.3s;
`

export const FilePreview = styled.div<File>`
    height: 2.5rem;
    width: 100%;
    // background: ${({ theme }) => theme.lineGrey};
    margin-top: 1rem;
    border-radius: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border: ${({ isError, theme }) => isError && `1px solid ${theme.red}`};
    color: ${({ isError, theme }) => isError && `${theme.red} !important`};
    position: relative;
    z-index: 2;
`

interface FileProgressProps {
    progress: number
}

export const FileProgressBox = styled.div<FileProgressProps>`
    z-index: 1;
    background-color: ${({ theme, progress }) =>
        progress === 100 ? `${theme.green}80` : `${theme.orange}80`};
    width: ${({ progress }) => progress}%;
    position: absolute;
    height: 100%;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    border-top-right-radius: ${({ progress }) =>
        progress === 100 ? '1.5rem' : '0'};
    border-bottom-right-radius: ${({ progress }) =>
        progress === 100 ? '1.5rem' : '0'};
    left: 0;
`

export const FileName = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
    flex-grow: 3;
    text-align: left;
    padding-left: 0.5rem;
    z-index: 3;

    @media screen and (max-width: 600px) {
        font-size: 1.2rem;
    }
`

export const FileInformationText = styled.p`
    margin-top: 0.5rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.greyText};
`

export const FileDownload = styled.div`
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.blue};

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    opacity: 0;
    transition: opacity 0.3s;
`

export const FileDownloadIcon = styled(FiDownload)`
    color: ${({ theme }) => theme.white};
    font-size: 1.8rem;
    margin-top: -0.3rem;
`

import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import profilePlaceholder from '../../../../../assets/images/profile.svg'
import { FiCamera, FiSmile, FiSettings } from 'react-icons/fi'
import { AiFillInfoCircle, AiOutlineDollarCircle } from 'react-icons/ai'
import { FaBalanceScale } from 'react-icons/fa'
import { HiOutlineSpeakerphone } from 'react-icons/hi'
import { RiQuestionnaireLine } from 'react-icons/ri'
import {
    PrimaryLabel,
    ErrorMessage,
    ConsentAgreementContainer,
    ConsentAgreementLabel,
    ConsentAgreementRadio,
    ConsentLink,
} from '../../../../styled/formElements'
import {
    checkNotes,
    isValidNotes,
} from '../../../../../helpers/validation/note'
import ProfileImageEditorModal from '../../../shared/settings/modals/ProfileImageEditorModal'
import AvatarEditor from 'react-avatar-editor'
import { useTranslation } from '../../../../../hooks'
import LanguageDropdown from '../../../../reusable/LanguageDropdown'
import { SpokenLanguagesInterface } from '../../../../../state/reducers/spokenLanguagesReducer'

interface PrimaryLabelProps {
    isError?: boolean
    isSuccess?: boolean
}

const Container = styled.div`
    border: ${({ theme }) => `1px solid ${theme.grey}`};
    margin-top: 3rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FormTextarea = styled.textarea<PrimaryLabelProps>`
    width: 34rem;
    border: 1px solid
        ${({ theme, isError, isSuccess }) =>
            isError ? theme.red : isSuccess ? 'green' : theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 0.4rem;
    padding: 1rem;
    font-family: 'NeueMontreal';
`

const TopSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media (max-width: 1250px) {
        grid-template-columns: 1fr;
    }

    @media (max-width: 1024px) {
        display: block;
    }
`

const PictureWrap = styled.div`
    margin-top: 3rem;
`

const PictureContainer = styled(Container)`
    margin-top: 0.4rem;
    padding: 1rem;
    height: 16.1rem;
`

const PictureSection = styled.div`
    text-align: center;
    position: relative;
`

const Image = styled.img`
    width: 9rem;
    height: 9rem;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 100%;
`

const PositionNotesLanguagesRow = styled.div`
    display: flex;
    gap: 2rem;
    flex-direction: column;

    @media (max-width: 1024px) {
        margin: 1rem;
    }
`

const NotesAndError = styled.div`
    display: block;
`

const NotesWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const LanguagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const LanguagesWrapper = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    gap: 1rem;
`

const LanguagesSpoken = styled.div`
    border: 1px solid ${({ theme }) => theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 0.4rem;
    padding: 0 1rem;
    height: 4rem;
    width: 10rem;
    display: flex;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
`

const CountryFlag = styled.img`
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    border: 1px solid black;
`

const GreyLine = styled.hr`
    border: none;
    height: 1px;
    background: ${({ theme }) => theme.grey};
    width: 100%;
    margin: 2rem 0 2rem 0;
`

const Frame = styled.form`
    margin-top: 7.5rem;
    width: 70%;
    @media (max-width: 768px) {
        width: 95%;
    }
`

const BlueCircle = styled.button`
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${({ theme }) => theme.blue};
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
`

const CameraIcon = styled(FiCamera)`
    color: ${({ theme }) => theme.white};
    position: relative;
`

const HiddenImgInput = styled.label`
    background-color: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
    border-radius: 2rem;
    cursor: pointer;
    width: 11.6rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500px;
    font-size: 1.2rem;
`

const GridCheckboxes = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding-bottom: 3rem;

    @media (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

const CheckboxWrap = styled.div`
    border: ${({ theme }) => `1px solid ${theme.grey}`};
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
`

const CustomCheckbox = styled.input`
    vertical-align: middle;
    position: relative;
    bottom: 0.08em;
    margin-right: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
`

const CheckboxLabel = styled(PrimaryLabel)`
    cursor: pointer;
`

const CustomText = styled.p`
    font-size: 1.2rem;
    margin-bottom: 1rem;
`

const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
`

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
`

//icons
const Info = styled(AiFillInfoCircle)`
    color: ${({ theme }) => theme.lineGrey};
    font-size: 1.2rem;
    margin-left: 0.5rem;
    cursor: pointer;
`

const Smile = styled(FiSmile)`
    color: ${({ theme }) => theme.blue};
    font-size: 2.4rem;
`

const Scale = styled(FaBalanceScale)`
    color: ${({ theme }) => theme.blue};
    font-size: 2.4rem;
`

const Dollar = styled(AiOutlineDollarCircle)`
    color: ${({ theme }) => theme.blue};
    font-size: 2.4rem;
`

const Settings = styled(FiSettings)`
    color: ${({ theme }) => theme.blue};
    font-size: 2.4rem;
`

const Speaker = styled(HiOutlineSpeakerphone)`
    color: ${({ theme }) => theme.blue};
    font-size: 2.4rem;
`

const Unknown = styled(RiQuestionnaireLine)`
    color: ${({ theme }) => theme.blue};
    font-size: 2.4rem;
`

const HoverContainer = styled.div`
    position: relative;
    width: 100%;
`

const HiddenHoverMsg = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.greyText};
    background-color: ${({ theme }) => theme.white};
    border: ${({ theme }) => `${theme.greyText}50 1px solid`};
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 1rem;
    max-width: 50rem;
    padding: 1rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    z-index: 1;
    position: absolute;
    bottom: 0.8rem;
    left: 1rem;
`

/*
 * Guessing a lot of these props could be moved to the component level
 *
 */
interface AdditionalInformationProps {
    note: string
    setNote: (value: string) => void
    department: string
    setDepartment: (value: string) => void
    selectedLanguages: SpokenLanguagesInterface[]
    setSelectedLanguages: (value: SpokenLanguagesInterface[]) => void
    onEditedImageFileChange: (value: File | null) => void

    termsAndConditionsChecked: boolean
    setTermsAndConditionsChecked: (value: boolean) => void

    handleSubmit: () => void
}

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({
    note,
    setNote,
    department,
    setDepartment,
    selectedLanguages,
    setSelectedLanguages,
    onEditedImageFileChange,
    termsAndConditionsChecked,
    setTermsAndConditionsChecked,
    handleSubmit,
}) => {
    const fileInputRef = React.createRef<HTMLInputElement>()
    const editor = useRef<AvatarEditor>(null)
    const [noteError, setNoteError] = useState('')
    const [noteSuccess, setNoteSuccess] = useState(false)
    const [profilePicture, setProfilePicture] = useState<{
        image: string
        zoom: number
    }>({
        image: '',
        zoom: 1,
    })
    const [isProfileImageModalOpen, setProfileImageModalOpen] = useState(false)
    const [editedImageFile, setEditedImageFile] = useState<File | null>(null)
    const [showNotesHoverMsg, setShowNotesHoverMsg] = useState(false)
    const [showLanguagesHoverMsg, setShowLanguagesHoverMsg] = useState(false)

    const translation = useTranslation()

    const handleFileInput = (files: FileList | null) => {
        const file = files ? files[0] : null
        if (file) {
            const imageUrl = URL.createObjectURL(file)
            setProfilePicture({ image: imageUrl, zoom: 1 })
            setProfileImageModalOpen(true)
        }
    }

    const handleImageFileChange = (imageFile: File | null) => {
        setEditedImageFile(imageFile)
        onEditedImageFileChange(imageFile)
    }

    const handleSaveEditedProfilePicture = async () => {
        if (editor.current) {
            const canvasScaled = editor.current.getImageScaledToCanvas()
            const dataUrl = canvasScaled.toDataURL()
            const result = await fetch(dataUrl)
            const blob = await result.blob()
            const imageFile = new File([blob], 'profileImg')
            handleImageFileChange(imageFile)
        }
        setProfileImageModalOpen(false)
    }

    const handleProfilePictureZoom = (value: number) => {
        setProfilePicture({
            ...profilePicture,
            zoom: value,
        })
    }

    const handleFocus = (event: React.FocusEvent<HTMLFormElement>) => {
        const name = event.target.name

        if (name === 'note') {
            setNoteError('')
        }
    }

    const handleBlur = (event: React.FocusEvent<HTMLFormElement>) => {
        const name = event.target.name

        if (name === 'note') {
            if (!isValidNotes(note)) {
                return setNoteError(checkNotes(note))
            }
            return setNoteSuccess(true)
        }
    }

    return (
        <Frame onBlur={handleBlur} onFocus={handleFocus}>
            <TopSection>
                <PictureWrap>
                    <PrimaryLabel
                        style={{ display: 'block', height: '1.44rem' }}
                    >
                        {translation.caseworkerSetupAccountPage.profilePicture}
                    </PrimaryLabel>
                    <PictureContainer>
                        <PictureSection>
                            <Image
                                src={
                                    editedImageFile
                                        ? URL.createObjectURL(editedImageFile)
                                        : profilePlaceholder
                                }
                                alt="img placeholder"
                            />
                            <BlueCircle>
                                <CameraIcon size={15} />
                            </BlueCircle>
                        </PictureSection>
                        <RightSide>
                            <CustomText style={{ textAlign: 'center' }}>
                                {
                                    translation.caseworkerSetupAccountPage
                                        .thisPictureWillAppear
                                }
                                .
                            </CustomText>
                            <HiddenImgInput>
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept="image/jpeg, image/jpg, image/png, image/svg+xml"
                                    onChange={(e) =>
                                        handleFileInput(e.target.files)
                                    }
                                    ref={fileInputRef}
                                    hidden
                                />
                                {
                                    translation.caseworkerSetupAccountPage
                                        .addPicture
                                }
                            </HiddenImgInput>
                        </RightSide>
                        {isProfileImageModalOpen && (
                            <ProfileImageEditorModal
                                onSave={handleSaveEditedProfilePicture}
                                onClose={() => {
                                    setProfileImageModalOpen(false)
                                }}
                                editorRef={editor}
                                image={profilePicture.image}
                                onChange={(e) =>
                                    handleProfilePictureZoom(e.target.value)
                                }
                                value={profilePicture.zoom}
                                scale={profilePicture.zoom}
                            />
                        )}
                    </PictureContainer>
                </PictureWrap>
                <Container
                    style={{
                        border: 'none',
                        paddingRight: '0',
                    }}
                >
                    <PositionNotesLanguagesRow>
                        <NotesAndError>
                            <NotesWrapper>
                                <LabelContainer>
                                    <PrimaryLabel isSuccess={noteSuccess}>
                                        {
                                            translation
                                                .caseworkerSetupAccountPage
                                                .notes
                                        }
                                    </PrimaryLabel>
                                    <HoverContainer>
                                        <Info
                                            onMouseOver={() =>
                                                setShowNotesHoverMsg(true)
                                            }
                                            onMouseOut={() =>
                                                setShowNotesHoverMsg(false)
                                            }
                                        />
                                        {showNotesHoverMsg && (
                                            <HiddenHoverMsg>
                                                {
                                                    translation
                                                        .caseworkerSetupAccountPage
                                                        .notesHover
                                                }
                                            </HiddenHoverMsg>
                                        )}
                                    </HoverContainer>
                                </LabelContainer>
                                <FormTextarea
                                    onBlur={handleSubmit}
                                    value={note}
                                    name="note"
                                    onChange={(e) => setNote(e.target.value)}
                                    maxLength={400}
                                    placeholder={
                                        translation.caseworkerSetupAccountPage
                                            .typeNoteHere
                                    }
                                    rows={4}
                                    isSuccess={noteSuccess}
                                    isError={noteError.length > 0}
                                />
                            </NotesWrapper>
                            {noteError && (
                                <ErrorMessage>{noteError}</ErrorMessage>
                            )}
                        </NotesAndError>
                        <LanguagesContainer>
                            <LabelContainer>
                                <PrimaryLabel>
                                    {
                                        translation.caseworkerSetupAccountPage
                                            .languages
                                    }
                                </PrimaryLabel>
                                <HoverContainer>
                                    <Info
                                        onMouseOver={() =>
                                            setShowLanguagesHoverMsg(true)
                                        }
                                        onMouseOut={() =>
                                            setShowLanguagesHoverMsg(false)
                                        }
                                    />
                                    {showLanguagesHoverMsg && (
                                        <HiddenHoverMsg>
                                            {
                                                translation
                                                    .caseworkerSetupAccountPage
                                                    .languagesHover
                                            }
                                        </HiddenHoverMsg>
                                    )}
                                </HoverContainer>
                            </LabelContainer>
                            <LanguagesWrapper>
                                <LanguagesSpoken>
                                    {selectedLanguages?.map((language) => {
                                        return (
                                            <CountryFlag
                                                key={language.id}
                                                src={language.flag}
                                                alt="country flag"
                                            />
                                        )
                                    })}
                                </LanguagesSpoken>
                                {selectedLanguages.length < 4 && (
                                    <LanguageDropdown
                                        selectDropdownLanguage={(language) => {
                                            setSelectedLanguages([
                                                ...selectedLanguages,
                                                language,
                                            ])
                                        }}
                                        border="1px solid"
                                        width="14rem"
                                    />
                                )}
                            </LanguagesWrapper>
                        </LanguagesContainer>
                    </PositionNotesLanguagesRow>
                </Container>
            </TopSection>
            <GreyLine />
            <CustomText style={{ fontWeight: '600' }}>
                {translation.caseworkerSetupAccountPage.whichDepartmentAreYou}
            </CustomText>
            <CustomText style={{ color: 'grey', fontWeight: '600' }}>
                {translation.caseworkerSetupAccountPage.chooseOneOption}
            </CustomText>
            <GridCheckboxes>
                <CheckboxWrap onClick={() => setDepartment('Human Resources')}>
                    <CheckboxLabel>
                        <CustomCheckbox
                            name="hr"
                            type="checkbox"
                            checked={department === 'Human Resources'}
                            onChange={() => setDepartment('Human Resources')}
                        />
                        {translation.caseworkerSetupAccountPage.humanResources}
                    </CheckboxLabel>
                    <Smile />
                </CheckboxWrap>
                <CheckboxWrap onClick={() => setDepartment('Compliance')}>
                    <CheckboxLabel>
                        <CustomCheckbox
                            name="compliance"
                            type="checkbox"
                            checked={department === 'Compliance'}
                            onChange={() => setDepartment('Compliance')}
                        />
                        {translation.caseworkerSetupAccountPage.compliance}
                    </CheckboxLabel>
                    <Scale />
                </CheckboxWrap>
                <CheckboxWrap onClick={() => setDepartment('Finance')}>
                    <CheckboxLabel>
                        <CustomCheckbox
                            name="finance"
                            type="checkbox"
                            checked={department === 'Finance'}
                            onChange={() => setDepartment('Finance')}
                        />
                        {translation.caseworkerSetupAccountPage.finance}
                    </CheckboxLabel>
                    <Dollar />
                </CheckboxWrap>
                <CheckboxWrap onClick={() => setDepartment('Operations')}>
                    <CheckboxLabel>
                        <CustomCheckbox
                            name="operations"
                            type="checkbox"
                            checked={department === 'Operations'}
                            onChange={() => setDepartment('Operations')}
                        />
                        {translation.caseworkerSetupAccountPage.operations}
                    </CheckboxLabel>
                    <Settings />
                </CheckboxWrap>
                <CheckboxWrap onClick={() => setDepartment('Marketing')}>
                    <CheckboxLabel>
                        <CustomCheckbox
                            name="marketing"
                            type="checkbox"
                            checked={department === 'Marketing'}
                            onChange={() => setDepartment('Marketing')}
                        />
                        {translation.caseworkerSetupAccountPage.marketing}
                    </CheckboxLabel>
                    <Speaker />
                </CheckboxWrap>
                <CheckboxWrap onClick={() => setDepartment('Other')}>
                    <CheckboxLabel>
                        <CustomCheckbox
                            name="other"
                            type="checkbox"
                            checked={department === 'Other'}
                            onChange={() => setDepartment('Other')}
                        />
                        {translation.caseworkerSetupAccountPage.other}
                    </CheckboxLabel>
                    <Unknown />
                </CheckboxWrap>
            </GridCheckboxes>

            <ConsentAgreementContainer>
                <ConsentAgreementLabel>
                    <ConsentAgreementRadio
                        type="checkbox"
                        name="terms-and-conditions"
                        value={'terms-and-conditions'}
                        onChange={() =>
                            setTermsAndConditionsChecked(
                                !termsAndConditionsChecked
                            )
                        }
                        checked={termsAndConditionsChecked}
                    />
                    <p>
                        {translation.registerPage.iHaveAgreedToWalor}{' '}
                        <ConsentLink
                            target="_blank"
                            href="https://www.walor.io/terms-conditions"
                        >
                            {translation.registerPage.termsAndConditions}
                        </ConsentLink>{' '}
                        {translation.registerPage.and}{' '}
                        <ConsentLink
                            target="_blank"
                            href="https://www.walor.io/privacy-and-cookie-policy"
                        >
                            {translation.registerPage.privacyAndCookiePolicy}
                        </ConsentLink>
                    </p>
                </ConsentAgreementLabel>
            </ConsentAgreementContainer>
        </Frame>
    )
}

export default AdditionalInformation

import { useState } from 'react'
import { FiArchive, FiXCircle } from 'react-icons/fi'
import styled from 'styled-components'
import { useTheme, useTranslation, useTypedSelector } from '../../../hooks'
import { Card, CardHeader } from '../../styled/cards'
import SearchInput from '../form/SearchInput'

interface IsNewProp {
    isNew?: boolean
}

const RemoveFilterButton = styled.div<IsNewProp>`
    height: 1.8rem;
    border-radius: 2rem;
    padding: 0 1.4rem;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.lightBlue};
    cursor: pointer;
`

const RemoveFilterButtonText = styled.p<IsNewProp>`
    color: ${({ theme }) => theme.primary};
    font-size: 1rem;
    margin-top: 0.2rem;
    margin-left: 0.5rem;
`

const FilterButtonContainer = styled.div`
    padding-top: 1rem;
    margin: 0 1.4rem;
    min-height: 7rem;
`

interface ActiveProp {
    isActive: boolean
}

const FilterButton = styled.div<ActiveProp>`
    height: 2.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 1.4rem;
    border-radius: 0.5rem;
    margin: 1rem 0;
    font-size: 1.2rem;
    cursor: ${({ isActive }) => !isActive && 'pointer'};
    transition: all 0.2s;

    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : theme.lightBlue};

    color: ${({ theme, isActive }) => (isActive ? theme.white : theme.blue)};

    :hover {
        background: ${({ isActive, theme }) => !isActive && `${theme.blue}90`};
        color: ${({ theme }) => theme.white};
    }
`

const StatusContainer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.grey};
    border-bottom: 1px solid ${({ theme }) => theme.grey};
    height: 19.5rem;
    padding-left: 1.4rem;
`

const StatusHeading = styled.p`
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
    padding-top: 2rem;
    padding-bottom: 1rem;
`

const StatusFlex = styled.div<ActiveProp>`
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    cursor: pointer;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    transition: opacity 0.2s;

    :hover {
        opacity: 1;
    }
`

const StatusCircle = styled.div`
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.primary};
`

const StatusCircleInner = styled.div<ActiveProp>`
    height: 0.7rem;
    width: 0.7rem;
    background: ${({ theme }) => theme.blue};
    border-radius: 50%;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
`

const StatusText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    transition: opacity 0.2s;
`

const ClosedCasesLinkFlex = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-left: 1.4rem;
    opacity: 0.5;
    margin-top: 2rem;
    transition: opacity 0.2s;

    :hover {
        opacity: 0.8;
    }
`

const CLosedCasesLinkText = styled.span`
    color: ${({ theme }) => theme.blue};
    font-size: 1.2rem;
    margin-left: 1rem;
`

interface CasesFilterBoxProps {
    onBottomLinkClick: (argument?: any) => void
    handleSearchChange: (searchValue: string) => void
    handleStatusFilterClick: (status: string) => void
    handleCaseTypeFilterClick: (caseType: 'all' | 'mine') => void
    handleClearSearch: () => void
    showRemoveFilterBtn: boolean
    handleRemoveFiltersClick: () => void
    searchText: string
}

const CasesFilterBox: React.FC<CasesFilterBoxProps> = ({
    onBottomLinkClick,
    handleSearchChange,
    handleStatusFilterClick,
    handleCaseTypeFilterClick,
    handleClearSearch,
    handleRemoveFiltersClick,
    showRemoveFilterBtn,
    searchText,
}) => {
    const theme = useTheme()

    const translation = useTranslation()

    const { user } = useTypedSelector((state) => state.auth)

    const [activeStatus, setActiveStatus] = useState<null | string>(null)

    const [activeCaseType, setActiveCaseType] = useState('all')

    const handleSetActiveStatus = (status: string) => {
        if (activeStatus === status) {
            setActiveStatus(null)
        } else {
            setActiveStatus(status)
        }
        if (status === 'new') {
            handleStatusFilterClick('new')
        } else if (status === 'action-required') {
            handleStatusFilterClick('action required')
        } else if (status === 'awaiting-response') {
            handleStatusFilterClick('awaiting response')
        }
    }

    const handeCaseTypeFilter = (type: 'all' | 'mine') => {
        if (activeCaseType !== type) {
            setActiveCaseType(type)
            handleCaseTypeFilterClick(type)
        }
    }

    const handleRemoveFilters = () => {
        handleRemoveFiltersClick()
        setActiveStatus(null)
        setActiveCaseType('all')
    }

    return (
        <Card>
            <CardHeader>
                {translation.casesPartner.filter}
                {showRemoveFilterBtn && (
                    <RemoveFilterButton onClick={handleRemoveFilters}>
                        <FiXCircle color={theme.primary} size={14} />
                        <RemoveFilterButtonText>
                            {translation.casesPartner.removeFilter}
                        </RemoveFilterButtonText>
                    </RemoveFilterButton>
                )}
            </CardHeader>
            <FilterButtonContainer>
                <SearchInput
                    placeholder={translation.casesPartner.searchCases}
                    onChangeCB={handleSearchChange}
                    onClearSearch={handleClearSearch}
                    text={searchText}
                />
                {!user?.roles.includes('contact') && (
                    <>
                        <FilterButton
                            isActive={activeCaseType === 'all'}
                            onClick={() => handeCaseTypeFilter('all')}
                        >
                            {translation.casesPartner.allCases}
                        </FilterButton>
                        <FilterButton
                            isActive={activeCaseType === 'mine'}
                            onClick={() => handeCaseTypeFilter('mine')}
                        >
                            {translation.casesPartner.myCases}
                        </FilterButton>
                    </>
                )}
            </FilterButtonContainer>
            <StatusContainer>
                <StatusHeading>{translation.casesPartner.status}</StatusHeading>
                <StatusFlex
                    onClick={() => handleSetActiveStatus('new')}
                    isActive={activeStatus === 'new'}
                >
                    <StatusCircle>
                        <StatusCircleInner isActive={activeStatus === 'new'} />
                    </StatusCircle>
                    <StatusText>{translation.reusable.new}</StatusText>
                </StatusFlex>

                <StatusFlex
                    onClick={() => handleSetActiveStatus('action-required')}
                    isActive={activeStatus === 'action-required'}
                >
                    <StatusCircle>
                        <StatusCircleInner
                            isActive={activeStatus === 'action-required'}
                        />
                    </StatusCircle>
                    <StatusText>
                        {translation.casesPartner.actionRequired}
                    </StatusText>
                </StatusFlex>

                <StatusFlex
                    onClick={() => handleSetActiveStatus('awaiting-response')}
                    isActive={activeStatus === 'awaiting-response'}
                >
                    <StatusCircle
                        onClick={() => setActiveStatus('awaiting-response')}
                    >
                        <StatusCircleInner
                            isActive={activeStatus === 'awaiting-response'}
                        />
                    </StatusCircle>
                    <StatusText>
                        {translation.casesPartner.awaitingResponse}
                    </StatusText>
                </StatusFlex>
            </StatusContainer>
            <ClosedCasesLinkFlex onClick={() => onBottomLinkClick()}>
                <FiArchive color={theme.blue} size={18} />
                <CLosedCasesLinkText>
                    {!user?.roles.includes('contact')
                        ? translation.casesPartner.viewForwardedCases
                        : translation.casesPartner.seeClosedCases}
                </CLosedCasesLinkText>
            </ClosedCasesLinkFlex>
        </Card>
    )
}

export default CasesFilterBox

import styled from 'styled-components'
import { FaCheckCircle } from 'react-icons/fa'
import { MdCancel } from 'react-icons/md'

export const Input = styled.input<PrimaryLabelProps>`
    width: 100%;
    border: 2px solid
        ${({ theme, isError, isSuccess }) =>
            isError ? theme.red : isSuccess ? 'green' : theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 0.4rem;
    padding: 0 1rem;
`
export const FormTextarea = styled.textarea<PrimaryLabelProps>`
    width: 100%;
    border: 2px solid
        ${({ theme, isError, isSuccess }) =>
            isError ? theme.red : isSuccess ? 'green' : theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 0.4rem;
    padding: 1rem;
    font-family: 'NeueMontreal';
    resize: none;
`

export const FormInput40 = styled(Input)`
    height: 4rem;
`

export const FormInput50 = styled(Input)`
    height: 5rem;
`

// labels
interface PrimaryLabelProps {
    isError?: boolean
    isSuccess?: boolean
}

export const PrimaryLabel = styled.label<PrimaryLabelProps>`
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 0.2rem;
    color: ${({ theme, isError, isSuccess }) =>
        isError ? 'red' : isSuccess ? 'green' : theme.secondaryDark};
`

export const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.red};
    position: absolute;
    font-size: 1rem;
`

// form fields

export const FormField = styled.div`
    width: 100%;
    padding-bottom: 2.8rem;
    position: relative;
`

export const SmallFormField = styled(FormField)`
    padding-bottom: 1rem;
    position: relative;
`

export const PhoneRow = styled.div`
    display: flex;
    width: 100%;
    gap: 1.6rem;
    flex: 5;
    position: relative;
`

export const FormFieldSmallBetween = styled.div`
    width: 100%;
    padding-bottom: 1.6rem;
    position: relative;
`
// Password field extras
export const ShowPasswordText = styled.text`
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 0.2rem;
`

export const InputFieldIcon = styled.i`
    position: absolute;
    top: 37%;
    right: 5%;
    font-size: 15px;

    &:hover {
        color: ${({ theme }) => theme.blue};
        cursor: pointer;
    }
`
// feedback message from api
interface FeedbackMessageProps {
    isSuccess: string | null
    showMessage: boolean | null
}

export const FeedbackMessage = styled.div<FeedbackMessageProps>`
    background-color: ${({ theme, isSuccess }) =>
        isSuccess ? `${theme.red}50` : `${theme.blue}50`};
    color: ${({ theme, isSuccess }) => (isSuccess ? theme.red : theme.blue)};
    width: 27.8rem;
    height: 4rem;
    font-size: 1.2rem;
    border-radius: 0.2rem;
    display: ${({ showMessage }) => (showMessage ? 'flex' : 'none')};
    align-items: center;
    margin: 0 2rem 2rem 2rem;
    padding-left: 1rem;
`

export const StyledCheckIcon = styled(FaCheckCircle)`
    width: 1.3rem;
    margin-right: 1rem;
    && .check-icon-circle {
        stroke: ${({ theme }) => theme.blue};
    }
`
export const StyledCancelIcon = styled(MdCancel)`
    width: 1.3rem;
    margin-right: 1rem;
    && .check-icon-circle {
        stroke: ${({ theme }) => theme.red};
    }
`

//Consent Related Fields

export const ConsentAgreementContainer = styled.div`
    font-size: 1.2rem;
    line-height: 2.8rem;
    font-weight: 400;
    color: ${({ theme }) => theme.black};
    display: flex;
    margin-bottom: 1.6rem;
    flex-direction: column;
    justify-content: center;
`

export const ConsentAgreementLabel = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const ConsentAgreementRadio = styled.input`
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.5rem;
    cursor: pointer;
    &:checked &::after {
        content: '\f00c';
        color: ${({ theme }) => theme.blue};
        font-family: 'FontAwesome';
    }
`

export const ConsentLink = styled.a`
    color: ${({ theme }) => theme.blue};
`

import { useState, useEffect } from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import {
    isValidPassword,
    isValidPasswordConfirm,
    passwordLengthCheck,
    passwordLowerCaseCheck,
    passwordNumberCheck,
    passwordsMatchCheck,
    passwordSpecialCharCheck,
    passwordUppercaseCheck,
} from '../helpers/validation'
import styled from 'styled-components'
import { PasswordValidationCheckInterface } from '../ts/interfaces/PasswordValidationCheckInterface'

const Container = styled.div`
    display: grid;
    grid-template-columns: fit-content(50%) fit-content(50%) 1fr;
    gap: 1rem;
    margin-bottom: 4rem;
`

const CheckStyled = styled(AiFillCheckCircle)<CheckedStyledProps>`
    font-size: 2rem;
    margin-right: 1rem;
    color: ${({ isValidated }) => (isValidated ? '#B8EEBE' : 'gray')};
`

const Row = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    p {
        color: ${({ theme }) => theme.primary};
        font-size: 1.2rem;
        text-align: center;
    }
`
interface CheckedStyledProps {
    isValidated: boolean
}

const PasswordValidationChecklistV2: React.FC<
    PasswordValidationCheckInterface
> = ({ password, passwordConfirm, isFormValidated }) => {
    const [isLongEnough, setIsLongEnough] = useState<boolean>(false)
    const [hasUppercaseLetter, setHasUppercaseLetter] = useState<boolean>(false)
    const [hasLowercaseLetter, setHasLowercaseLetter] = useState<boolean>(false)
    const [hasNumber, setHasNumber] = useState<boolean>(false)
    const [hasSpecialChar, setHasSpecialChar] = useState<boolean>(false)
    const [passwordsMatch, setPaswordsMatch] = useState<boolean>(false)

    useEffect(() => {
        setIsLongEnough(passwordLengthCheck(password))
        setHasUppercaseLetter(passwordUppercaseCheck(password))
        setHasLowercaseLetter(passwordLowerCaseCheck(password))
        setHasNumber(passwordNumberCheck(password))
        setHasSpecialChar(passwordSpecialCharCheck(password))
        if (passwordConfirm.length >= 8) {
            setPaswordsMatch(passwordsMatchCheck(password, passwordConfirm))
        } else {
            setPaswordsMatch(false)
        }
        if (
            isValidPassword(password) &&
            isValidPasswordConfirm(password, passwordConfirm)
        ) {
            isFormValidated(true)
        } else {
            isFormValidated(false)
        }
    }, [password, passwordConfirm, isFormValidated])

    return (
        <Container>
            <Row>
                <CheckStyled isValidated={isLongEnough} />
                <p>8 characters</p>
            </Row>
            <Row>
                <CheckStyled isValidated={hasUppercaseLetter} />
                <p>1 upper case (A-Z)</p>
            </Row>
            <Row>
                <CheckStyled isValidated={hasSpecialChar} />
                <p>1 special charater (!”#€%&/-_)</p>
            </Row>
            <Row>
                <CheckStyled isValidated={hasNumber} />
                <p>1 number (0-9)</p>
            </Row>
            <Row>
                <CheckStyled isValidated={hasLowercaseLetter} />
                <p>1 lower case (a-z)</p>
            </Row>
            <Row>
                <CheckStyled isValidated={passwordsMatch} />
                <p>Password matches</p>
            </Row>
        </Container>
    )
}

export default PasswordValidationChecklistV2

import styled from 'styled-components'
import { Modal, ModalContainer } from '../../../../../styled/modals'
import { Card } from '../../../../../styled/cards'
import { DynamicHeightBlueButton } from '../../../../../styled/buttons'
import { useTranslation } from '../../../../../../hooks'

interface CaseClosedModalProps {
    closeModal: () => void
}

const Title = styled.h4`
    color: ${({ theme }) => theme.primary};
    font-weight: bold;
    font-size: 1.8rem;
    padding-top: 3.5rem;
`
const GDPRDeletionNotice = styled.p`
    font-size: 1.6rem;
    padding-top: 1.5rem;
    opacity: 0.8;
`

const GDPRDInfo = styled.p`
    font-size: 1.2rem;
    padding: 0.8rem;
    opacity: 0.8;
`

const TimeFrame = styled.p`
    color: ${({ theme }) => `${theme.red}`};
    font-weight: bold;
    font-size: 2.4rem;
    padding: 3rem;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 1.5rem;
`

const CaseCloseModal: React.FC<CaseClosedModalProps> = ({ closeModal }) => {
    const translation = useTranslation()
    return (
        <ModalContainer>
            <Modal height="32.4rem" width="55.6rem">
                <Card
                    style={{
                        textAlign: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Title>{translation.caseworkerOpenReport.caseClosed}</Title>
                    <GDPRDeletionNotice>
                        {translation.caseworkerOpenReport.toEnsureGDPR}
                    </GDPRDeletionNotice>
                    <TimeFrame>
                        60 {translation.caseworkerOpenReport.days}, 23{' '}
                        {translation.caseworkerOpenReport.hours}
                    </TimeFrame>
                    <GDPRDInfo>
                        {
                            translation.caseworkerOpenReport
                                .theOptionToExportTheCase
                        }
                    </GDPRDInfo>
                    <GDPRDInfo>
                        {
                            translation.caseworkerOpenReport
                                .theDeletionOfTheCaseWillNot
                        }
                    </GDPRDInfo>
                    <ButtonContainer>
                        <DynamicHeightBlueButton
                            width="14rem"
                            height="3.2rem"
                            fontSize="1.6rem"
                            onClick={closeModal}
                        >
                            {translation.finishButton.finish}
                        </DynamicHeightBlueButton>
                    </ButtonContainer>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default CaseCloseModal

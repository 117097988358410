import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { AiOutlineCamera } from 'react-icons/ai'

const StyledCameraIcon = styled(AiOutlineCamera)`
    font-size: 3rem;
    background: ${({ theme }) => theme.blue};
    border-radius: 50%;
    padding: 0.5rem;
    color: ${({ theme }) => `${theme.grey}70`};

    cursor: pointer;
`

const HiddenProfileImgInput = styled.input`
    display: none;
`

interface ProfilePictureType {
    image: string
    zoom: number
    id: number | null
}

interface ChangeImageProps {
    setProfilePicture: React.Dispatch<React.SetStateAction<ProfilePictureType>>
    setIsImageEditorOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ChangeImage: React.FC<ChangeImageProps> = ({
    setProfilePicture,
    setIsImageEditorOpen,
}) => {
    const handlerChangeProfileImage = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setIsImageEditorOpen(true)

        if (!event.target.files) return

        const image = event.target.files[0]

        setProfilePicture((prev: any) => ({
            ...prev,
            image: image,
        }))
    }

    return (
        <div
            style={{
                position: 'absolute',
                top: '5%',
                right: '0%',
            }}
        >
            <label htmlFor="file-input">
                <StyledCameraIcon />
            </label>
            <HiddenProfileImgInput
                type="file"
                id="file-input"
                accept="image/jpeg, image/jpg, image/png, image/svg+xml"
                onChange={handlerChangeProfileImage}
            />
        </div>
    )
}

export default ChangeImage

import { Card, CardHeader } from '../../styled/cards'
import styled from 'styled-components'
import {
    FormField,
    FormInput40,
    PrimaryLabel,
    FormTextarea,
} from '../../styled/formElements'
import { RoundDeleteButton, DeleteIcon } from '../../styled/buttons'
import { useTranslation, useTypedSelector, useActions } from '../../../hooks'
import CountryCodeDropdown from './CountryCodeDropdown'
import { useRef, useState } from 'react'
import ButtonSpinnerWhite from '../../layout/spinner/ButtonSpinnerWhite'
import { UpdateContactInterface } from '../../../state/action-creators'
import profilePlaceholder from '../../../assets/images/profile.svg'
import { SpokenLanguagesInterface } from '../../../state/reducers/spokenLanguagesReducer'
import { FiX } from 'react-icons/fi'
import LanguageDropdown from '../LanguageDropdown'
import _ from 'lodash'

const HeaderButtonFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`
const ContactPhotoCircle = styled.div`
    height: 10rem;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 100%;
`

const ProfileImg = styled.label`
    height: 15rem;
    width: 15rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    gap: 1rem;
`

const Image = styled.img`
    width: 12rem;
    height: 12rem;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 100%;
`

const HiddenProfileImgInput = styled.input`
    height: 15rem;
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FormBody = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 2rem;
    max-width: 100%;
`

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    padding: 0 3rem 0 0;
`

const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

export const CancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}60`};
    cursor: pointer;
    padding: 1.4rem;
`

const BlackButton = styled.div<BlackButtonProps>`
    height: 2.7rem;
    padding: 0 1.6rem;

    display: flex;
    align-items: center;
    background: ${({ theme, isActive }) =>
        !isActive ? `${theme.black}50` : theme.black};
    color: ${({ theme }) => theme.white};
    font-size: 1.4rem;
    border-radius: 0.5rem;
    pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};

    :hover {
        cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
    }
`

const NameEmailPhoneRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
`

const InfoBelowDisplayedWhistleblowerLink = styled.p`
    border-top: 2px solid ${({ theme }) => theme.lineGrey};
    border-top-style: dotted;
    padding: 1rem 0 2rem 0;
    color: ${({ theme }) => theme.greyText};
`

const PositionNotesLanguagesRow = styled.div`
    display: flex;
    gap: 2rem;
`

const LeftSide = styled.div`
    flex: 33%;
    display: flex;
    flex-direction: column;
`
const RightSide = styled.div`
    flex: 66%;
    display: flex;
    flex-direction: column;
`

const NotesWrapper = styled.div`
    width: 100%;
    height: 14rem;
    display: flex;
    flex-direction: column;
`

const LanguagesWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
`
export const DeleteLanguagesIcon = styled(FiX)`
    cursor: pointer;
    display: none;
    color: ${({ theme }) => theme.red};
    z-index: 3;
    position: absolute;
    top: 0.1rem;
    right: 0.6rem;
    font-size: 1.8rem;
    stroke-width: 3;
`

export const DeleteIconAndFlagContainerWrapper = styled.div<IsEditing>`
    position: relative;
    &:hover ${DeleteLanguagesIcon} {
        display: ${({ isEditing }) => (isEditing ? 'block' : 'none')};
    }
`

const LanguagesSpoken = styled.div<IsEditing>`
    border: 2px solid ${({ theme }) => theme.lineGrey};
    border-radius: 0.5rem;
    background-color: ${({ isEditing }) =>
        isEditing ? '' : 'rgba(239, 239, 239, 0.3)'};
    margin-top: 0.4rem;
    padding-inline-start: 0.2rem;
    height: 4rem;
    width: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const CountryFlag = styled.img<IsEditing>`
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    border: 1px solid black;
    object-fit: cover;
    transition: opacity 0.2s ease-in-out;
    cursor: ${({ isEditing }) => (isEditing ? 'pointer' : 'not-allowed')};

    ${DeleteIconAndFlagContainerWrapper}:hover && {
        opacity: ${({ isEditing }) => (isEditing ? '0.2' : 'none')};
    }
`

interface BlackButtonProps {
    isActive: boolean
}

interface IsEditing {
    isEditing: boolean
}

interface MiniPersonFormProps {
    contact: any
    contactNumber: Number
    setContactToDelete: (contact: any) => void
    openIsDeleteContactModal: () => void
    cancelEditingCallback: () => void
    handleChangeContact: (contactId: number, field: string, value: any) => void
    profileImgSrc?: string
    isEditing: boolean
    setIsEditModeActive: React.Dispatch<React.SetStateAction<boolean>>
    setContactToEdit: React.Dispatch<any>
    openProfileImageEditorModal: () => void
    setContactToEditProfileImage: (contactId: number, image: string) => void
}

const MiniPersonForm: React.FC<MiniPersonFormProps> = ({
    contact,
    contactNumber,
    setContactToDelete,
    openIsDeleteContactModal,
    cancelEditingCallback,
    handleChangeContact,
    profileImgSrc,
    isEditing,
    setIsEditModeActive,
    setContactToEdit,
    openProfileImageEditorModal,
    setContactToEditProfileImage,
}) => {
    const translation = useTranslation()

    const { client } = useTypedSelector((state) => state.client)

    const { lineOfContactLoading, lineOfContactUpdateLoading } =
        useTypedSelector((state) => state.lineOfContact)

    const { updateContact, deleteSpokenLanguage } = useActions()

    const originalContact = useRef(contact)
    const { spokenLanguages } = useTypedSelector(
        (state) => state.spokenLanguages
    )
    const [languagesToBeDeleted, setLanguagesToBeDeleted] = useState<number[]>(
        []
    )

    const handleClickDelete = () => {
        setContactToDelete(contact)
        openIsDeleteContactModal()
    }

    const enableEditMode = () => {
        setContactToEdit(contact)
        setIsEditModeActive(true)
    }

    const disableEditMode = () => {
        setIsEditModeActive(false)
    }

    const handleSaveContactChanges = async () => {
        enableEditMode()

        const newContactInfo: UpdateContactInterface = {
            contact: {},
            contact_information: {},
            languages: { data: [] },
        }

        const contactKeys: (keyof UpdateContactInterface['contact'])[] = [
            'first_name',
            'last_name',
            'email',
        ]

        const contactInformationKeys: (keyof UpdateContactInterface['contact_information'])[] =
            ['position', 'priority', 'note']

        contactKeys.forEach((key) => {
            if (contact[key] !== originalContact.current[key]) {
                newContactInfo.contact[key] = contact[key]
            }
        })

        if (
            contact.phone.phone !== originalContact.current.phone.phone ||
            contact.country_id !== originalContact.current.country_id
        ) {
            newContactInfo.contact.phone = contact.phone.phone
            newContactInfo.contact.country_id = contact.country_id
        }

        contactInformationKeys.forEach((key) => {
            if (
                contact.contact_information[key] !==
                originalContact.current.contact_information[key]
            ) {
                newContactInfo.contact_information[key] =
                    contact.contact_information[key]
            }
        })

        if (contact.languages !== originalContact.current.languages) {
            newContactInfo.languages.data = contact.languages.data.map(
                (language: SpokenLanguagesInterface) => ({
                    language_id: language.id,
                })
            )
        }

        if (contact.contact_information.profile_img instanceof File) {
            newContactInfo.contact_information.profile_img =
                contact.contact_information.profile_img
        }

        Promise.all(
            languagesToBeDeleted.map((language) =>
                deleteSpokenLanguage(language)
            )
        )

        await updateContact(client.id, contact.id, newContactInfo, translation)

        originalContact.current = contact

        disableEditMode()
    }

    const checkIfFormValidated = () => {
        const hasFormChanged =
            contact.first_name !== originalContact.current.first_name ||
            contact.last_name !== originalContact.current.last_name ||
            contact.phone.phone !== originalContact.current.phone.phone ||
            contact.country_id !== originalContact.current.country_id ||
            contact.email !== originalContact.current.email ||
            contact.contact_information?.position !==
                originalContact.current.contact_information?.position ||
            contact.contact_information?.priority !==
                originalContact.current.contact_information?.priority ||
            contact.contact_information?.note !==
                originalContact.current.contact_information?.note ||
            languagesToBeDeleted.length > 0

        const hasProfileChanged =
            typeof contact.contact_information?.profile_img !== 'string' &&
            contact.contact_information.profile_img

        const hasLanguageChanged = _.isEqual(
            contact.languages,
            originalContact.current.languages
        )

        return hasFormChanged || hasProfileChanged || !hasLanguageChanged
    }

    const profileImageSrc = contact.contact_information.profile_img
    const isInstanceOfFile = profileImageSrc instanceof File
    const htmlForValue = `profileImgFile${contact.id}`
    const imgAlt = isInstanceOfFile ? 'img selected' : 'profile img'

    return (
        <>
            <Card overflow="visible">
                {contact && (
                    <>
                        <CardHeader>
                            Person {contactNumber}
                            <HeaderButtonFlex>
                                <CancelButton
                                    onClick={() => {
                                        cancelEditingCallback()
                                        disableEditMode()
                                        setLanguagesToBeDeleted([])
                                    }}
                                >
                                    {translation.reusable.cancel}
                                </CancelButton>
                                <BlackButton
                                    onClick={handleSaveContactChanges}
                                    isActive={checkIfFormValidated()}
                                >
                                    {lineOfContactLoading ? (
                                        <ButtonSpinnerWhite />
                                    ) : (
                                        <>
                                            {lineOfContactUpdateLoading &&
                                            isEditing ? (
                                                <ButtonSpinnerWhite />
                                            ) : (
                                                translation
                                                    .singleClientViewPartner
                                                    .buttonSave
                                            )}
                                        </>
                                    )}
                                </BlackButton>
                                {client.id !== contact.id && (
                                    <RoundDeleteButton
                                        onClick={handleClickDelete}
                                    >
                                        {lineOfContactLoading ? (
                                            <ButtonSpinnerWhite />
                                        ) : (
                                            <DeleteIcon size={14} />
                                        )}
                                    </RoundDeleteButton>
                                )}
                            </HeaderButtonFlex>
                        </CardHeader>
                        <FormBody>
                            <ContactPhotoCircle>
                                <ProfileImg htmlFor={htmlForValue}>
                                    {profileImageSrc ? (
                                        <Image
                                            src={
                                                isInstanceOfFile
                                                    ? URL.createObjectURL(
                                                          profileImageSrc
                                                      )
                                                    : profileImageSrc
                                            }
                                            alt={imgAlt}
                                        />
                                    ) : (
                                        <Image
                                            src={profilePlaceholder}
                                            alt="img placeholder"
                                        />
                                    )}
                                    <p>
                                        {!profileImageSrc
                                            ? `${translation.clientView.addPhoto}`
                                            : `${translation.clientView.changePhoto}`}
                                    </p>
                                </ProfileImg>
                                <HiddenProfileImgInput
                                    type="file"
                                    id={`profileImgFile${contact.id}`}
                                    accept="image/jpeg, image/jpg, image/png, image/svg+xml"
                                    onChange={(e: any) => {
                                        const image = URL.createObjectURL(
                                            e.target.files[0]
                                        )
                                        setContactToEditProfileImage(
                                            contact.id,
                                            image
                                        )
                                        openProfileImageEditorModal()
                                    }}
                                />
                            </ContactPhotoCircle>
                            <FormContainer>
                                <NameEmailPhoneRow>
                                    <FormField>
                                        <PrimaryLabel htmlFor="first_name">
                                            {translation.reusable.firstName}
                                        </PrimaryLabel>
                                        <FormInput40
                                            type="text"
                                            id="first_name"
                                            name="first_name"
                                            value={contact.first_name}
                                            onChange={(e) => {
                                                handleChangeContact(
                                                    contact.id,
                                                    'first_name',
                                                    e.target.value
                                                )
                                            }}
                                        />
                                    </FormField>
                                    <FormField>
                                        <PrimaryLabel htmlFor="last_name">
                                            {translation.reusable.lastName}
                                        </PrimaryLabel>
                                        <FormInput40
                                            type="text"
                                            id="last_name"
                                            name="last_name"
                                            value={contact.last_name}
                                            onChange={(e) =>
                                                handleChangeContact(
                                                    contact.id,
                                                    'last_name',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </FormField>
                                    <FormField>
                                        <PrimaryLabel htmlFor="email">
                                            {translation.reusable.workEmail}
                                        </PrimaryLabel>
                                        <FormInput40
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={contact.email}
                                            onChange={(e) =>
                                                handleChangeContact(
                                                    contact.id,
                                                    'email',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </FormField>
                                    <FormField>
                                        <PrimaryLabel>
                                            {translation.reusable.phone}
                                        </PrimaryLabel>
                                        <FlexInputContainer>
                                            <CountryCodeDropdown
                                                previouslySelectedCountryCode={
                                                    contact?.phone.country_code
                                                }
                                                error={false}
                                                success={false}
                                                selectDropdownCB={(country) => {
                                                    handleChangeContact(
                                                        contact.id,
                                                        'country_id',
                                                        country.id
                                                    )
                                                }}
                                                width="9.8rem"
                                            />
                                            <FormInput40
                                                value={contact.phone.phone}
                                                onChange={(e) =>
                                                    handleChangeContact(
                                                        contact.id,
                                                        'phone',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </FlexInputContainer>
                                    </FormField>
                                </NameEmailPhoneRow>
                                <InfoBelowDisplayedWhistleblowerLink>
                                    {
                                        translation.clientView
                                            .willBeDisplayedReportLink
                                    }
                                </InfoBelowDisplayedWhistleblowerLink>
                                <PositionNotesLanguagesRow>
                                    <LeftSide>
                                        <FormField>
                                            <PrimaryLabel htmlFor="position">
                                                {translation.reusable.position}
                                            </PrimaryLabel>
                                            <FormInput40
                                                value={
                                                    contact.contact_information
                                                        .position
                                                }
                                                id="position"
                                                name="position"
                                                onChange={(e) =>
                                                    handleChangeContact(
                                                        contact.id,
                                                        'position',
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Please add your position"
                                            />
                                        </FormField>
                                        <PrimaryLabel>
                                            {translation.clientView.languages}
                                        </PrimaryLabel>
                                        <LanguagesWrapper>
                                            <LanguagesSpoken
                                                isEditing={isEditing}
                                            >
                                                {spokenLanguages &&
                                                    contact.languages?.data.map(
                                                        (
                                                            language: SpokenLanguagesInterface
                                                        ) => {
                                                            if (
                                                                languagesToBeDeleted.includes(
                                                                    language.id
                                                                )
                                                            ) {
                                                                return null
                                                            }
                                                            return (
                                                                <DeleteIconAndFlagContainerWrapper
                                                                    isEditing={
                                                                        isEditing
                                                                    }
                                                                    key={
                                                                        language.id
                                                                    }
                                                                >
                                                                    <DeleteLanguagesIcon
                                                                        onClick={() =>
                                                                            setLanguagesToBeDeleted(
                                                                                [
                                                                                    ...languagesToBeDeleted,
                                                                                    language.id,
                                                                                ]
                                                                            )
                                                                        }
                                                                    />

                                                                    <CountryFlag
                                                                        src={
                                                                            language.flag
                                                                        }
                                                                        alt="country flag"
                                                                        isEditing={
                                                                            isEditing
                                                                        }
                                                                    />
                                                                </DeleteIconAndFlagContainerWrapper>
                                                            )
                                                        }
                                                    )}
                                            </LanguagesSpoken>

                                            {contact.languages.data.length <
                                                4 && (
                                                <LanguageDropdown
                                                    selectDropdownLanguage={(
                                                        language
                                                    ) => {
                                                        handleChangeContact(
                                                            contact.id,
                                                            'languages',
                                                            language
                                                        )
                                                    }}
                                                    width="14rem"
                                                    border="2px solid"
                                                />
                                            )}
                                        </LanguagesWrapper>
                                    </LeftSide>

                                    <RightSide>
                                        <NotesWrapper>
                                            <PrimaryLabel htmlFor="note">
                                                {translation.clientView.notes}
                                            </PrimaryLabel>
                                            <FormTextarea
                                                id="note"
                                                name="note"
                                                maxLength={400}
                                                placeholder="Type a note here ..."
                                                value={
                                                    contact.contact_information
                                                        .note
                                                }
                                                onChange={(e) =>
                                                    handleChangeContact(
                                                        contact.id,
                                                        'note',
                                                        e.target.value
                                                    )
                                                }
                                                rows={8}
                                            />
                                        </NotesWrapper>
                                    </RightSide>
                                </PositionNotesLanguagesRow>
                            </FormContainer>
                        </FormBody>
                    </>
                )}
            </Card>
        </>
    )
}

export default MiniPersonForm

import { useEffect } from 'react'
import styled from 'styled-components'
import { useActions } from '../../../hooks'
import Spinner from '../../layout/spinner/Spinner'
import { getTime } from 'date-fns'
import { useHistory } from 'react-router'

const Container = styled.div`
    width: 100%;
    height: calc(100vh - 8.5rem);
    display: flex;
    justify-content: center;
    align-items: center;
`
const WhistleblowerLoading: React.FC = () => {
    const { whistleblowerLogin } = useActions()
    const history = useHistory()

    useEffect(() => {
        const isWhisleblowerLoggedInStorage = !!localStorage.getItem(
            'isWhistleblowerLoggedIn'
        )
        if (isWhisleblowerLoggedInStorage) {
            const whistleblowerTimeout = localStorage.getItem(
                'whistleblowerTimeout'
            )
            // Session is timed out
            if (
                whistleblowerTimeout &&
                parseInt(whistleblowerTimeout) < getTime(new Date())
            ) {
                localStorage.removeItem('isWhistleblowerLoggedIn')
                localStorage.removeItem('whistleblowerOrganizationalCode')
                localStorage.removeItem('whistleblowerTimeout')

                setTimeout(() => history.push('/not-assigned-to-company'), 1500)
            } else {
                const organizationalCode = localStorage.getItem(
                    'whistleblowerOrganizationalCode'
                )

                if (organizationalCode) {
                    login(organizationalCode)
                }
            }
        } else {
            setTimeout(() => history.push('/not-assigned-to-company'), 1500)
        }
        // eslint-disable-next-line
    }, [])

    const login = async (organizationalCode: string) => {
        const success: any = await whistleblowerLogin(organizationalCode)
        if (!success) {
            return history.push('/not-found/organizational-code')
        }

        return history.push(`/organization/${organizationalCode}/whistleblower`)
    }

    return (
        <Container>
            <Spinner />
        </Container>
    )
}

export default WhistleblowerLoading

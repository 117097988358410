import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { isValidWhistleblowerCode } from '../../../../../helpers/validation'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import { NextButton } from '../../../../reusable/buttons'
import { HeadingPrimary } from '../../../../styled/typography'
import { TaglineLight } from '../../new-case/styled'
import { FiArrowLeft } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
    padding-top: 13rem;
    margin: 0 auto;
    width: 100%;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 1000px) {
        padding-inline: 15rem;
    }

    @media screen and (max-width: 600px) {
        padding-inline: 2rem;
    }
`

const RowWrap = styled.div``

const GoBackContainer = styled.div`
    cursor: pointer;
`

const ArrowLeft = styled(FiArrowLeft)`
    position: absolute;
    left: 4.3rem;
    top: 19rem;
    font-size: 2.4rem;
    color: ${({ theme }) => theme.darkColor};
`

const BoldText = styled.p`
    font-size: 1.4rem;
    margin-top: 3rem;
    font-weight: 700;
`

const Form = styled.form`
    width: 50rem;
    padding-bottom: 4rem;
    margin: 0 auto;

    @media (max-width: 600px) {
        width: 90%;
    }
`

const CodeHeading = styled.h3`
    font-size: 1.8rem;
    font-weight: 700;
    color: ${({ theme }) => theme.darkColor};
    padding-top: 4rem;
    padding-bottom: 2rem;
`

const CodeInput = styled.input`
    height: 6rem;
    width: 100%;
    border: 0;
    border-radius: 10rem;
    font-size: 2.2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    :focus {
        border: none;
        outline: none;
        border: 1px solid ${({ theme }) => theme.lineGrey};
    }

    &::placeholder {
        font-size: 1.2rem;
    }
`

const ErrorMessage = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.red};
    padding-bottom: 2rem;
`

interface EnterWhistleblowerCodeProps {}

const EnterWhistleblowerCode: React.FC<EnterWhistleblowerCodeProps> = () => {
    const { whistleblowerLoading, whistleblowerError } = useTypedSelector(
        (state) => state.whistleblower
    )

    const history = useHistory()

    const { getReport } = useActions()

    const translation = useTranslation()

    const [code, setCode] = useState('')
    const [isValidated, setIsValidated] = useState(false)

    useEffect(() => {
        if (!isValidWhistleblowerCode(code)) {
            return setIsValidated(false)
        }

        return setIsValidated(true)
    }, [code])

    const handleGetReport = async () => {
        await getReport(code)
    }
    return (
        <Container>
            <RowWrap>
                <GoBackContainer onClick={() => history.goBack()}>
                    <ArrowLeft />
                </GoBackContainer>
                <HeadingPrimary style={{ marginBottom: '2rem' }}>
                    {translation.accessCaseFlow.accessExistingReport}
                </HeadingPrimary>
            </RowWrap>
            <>
                <TaglineLight>
                    {translation.accessCaseFlow.inOrderToAccessYourCase}
                </TaglineLight>
                <BoldText>{translation.accessCaseFlow.missingCode}</BoldText>
                <TaglineLight>
                    {translation.accessCaseFlow.createNewRaport}
                </TaglineLight>
                <Form>
                    <CodeHeading>
                        {translation.accessCaseFlow.enterYourWhistleblowerCode}
                    </CodeHeading>
                    <CodeInput
                        value={code}
                        placeholder={translation.accessCaseFlow.enterCode}
                        onChange={(e) => setCode(e.target.value)}
                    />
                </Form>
                {whistleblowerError && (
                    <ErrorMessage>{whistleblowerError}</ErrorMessage>
                )}

                <NextButton
                    isActive={isValidated}
                    handleOnClick={handleGetReport}
                    isLoading={whistleblowerLoading}
                />
            </>
        </Container>
    )
}

export default EnterWhistleblowerCode

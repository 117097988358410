import styled from 'styled-components'
import { Card, CardHeader } from '../../styled/cards'
import { useEffect, useState } from 'react'
import ReportStatisticsGraph from './ReportStatisticsGraph'
import { useTranslation, useTypedSelector } from '../../../hooks'

export const StatisticsContainer = styled.div`
    width: 100%;
    padding-top: 2rem;

    @media screen and (max-width: 600px) {
        display: none;
    }
`

const StatisticBaseCircle = styled.div`
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
`

const NewCircle = styled(StatisticBaseCircle)`
    background-color: #849bb5;
`

const OpenCircle = styled(StatisticBaseCircle)`
    background-color: #b0c1c7;
`

const ClosedOrForwardedCircle = styled(StatisticBaseCircle)`
    background-color: #0e263d;
`

const StatisticInformation = styled.div`
    display: flex;
    align-items: center;
    margin: 0 1rem;
`

const StatisticsFlexContainer = styled.div`
    display: flex;
`

const StatisticsGraph = styled.div`
    width: 80%;
`

const StatisticsGraphNumbers = styled.div`
    height: 25rem;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 0 2.8rem;
`

interface HighestMonthProp {
    highestMonth: number
}

const StatisticsGrid = styled.div<HighestMonthProp>`
    display: grid;
    grid-template-rows: ${({ highestMonth }) =>
        `repeat(${highestMonth}, 3.2rem)`};
`

const StatisticsRow = styled.div`
    border-top: 1px dotted ${({ theme }) => theme.grey};
    width: 100%;
    height: 2rem;
    display: grid;
`
const StatisticNumber = styled.div`
    margin-left: -1.4rem;
`

const MonthGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    height: 4.4rem;
    border-top: 1px solid ${({ theme }) => theme.primary};
    margin: 0 2.8rem;
    justify-content: center;
    justify-items: center;
    width: 95.2%;
`

const Month = styled.p``

const TotalStatisticsContainer = styled.div`
    width: 20%;
`

const TotalStatisticBox = styled.div`
    height: calc(100% / 3);
    padding: 0 1.5rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
`

const TotalStatisticHeading = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
`

const TotalStatisticsStatistic = styled.h3`
    font-size: 2rem;
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
`

const NotificationInformationContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`

const NotificationInformationText = styled.span`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    margin-left: 0.4rem;
`

const NotificationDateText = styled.span`
    font-size: 1rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    margin-left: 1rem;
`

const ReportStatistics: React.FC = () => {
    const { dashboardStatistics } = useTypedSelector(
        (state) => state.caseStatistic
    )
    const { user } = useTypedSelector((state) => state.auth)

    const translation = useTranslation()

    useEffect(() => {
        if (dashboardStatistics) {
            const newStats = Object.values(dashboardStatistics.months).map(
                (statObj: any) =>
                    statObj.new_cases +
                    statObj.active_cases +
                    statObj.forwarded_cases +
                    statObj.closed_cases
            )

            setHighestMonth(
                Math.max.apply(Math, newStats) >= 10
                    ? Math.max.apply(Math, newStats)
                    : 10
            )
        }
    }, [dashboardStatistics])

    const [highestMonth, setHighestMonth] = useState(0)
    const [numbers, setNumbers] = useState<number[]>([])

    useEffect(() => {
        const tempNumbers = []
        if (highestMonth) {
            for (let i = 1; i <= highestMonth; i++) {
                tempNumbers.push(i)
            }

            setNumbers(tempNumbers.reverse())
        }
    }, [highestMonth])

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    useEffect(() => {
        const statisticsBox = document?.getElementById('statistics-grid')

        if (statisticsBox && dashboardStatistics) {
            statisticsBox.scrollTo(0, 99999999)
        }

        // eslint-disable-next-line
    }, [highestMonth])

    return (
        <StatisticsContainer>
            <Card>
                <CardHeader>
                    {translation.dashboardPartner.yourStatistics.yourStatistics}
                    <NotificationDateText>
                        {new Date().getFullYear()}
                    </NotificationDateText>
                    <NotificationInformationContainer>
                        <StatisticInformation>
                            <NewCircle />
                            <NotificationInformationText>
                                {translation.reusable.new}
                            </NotificationInformationText>
                        </StatisticInformation>
                        <StatisticInformation>
                            <OpenCircle />
                            <NotificationInformationText>
                                {
                                    translation.dashboardPartner.yourStatistics
                                        .open
                                }
                            </NotificationInformationText>
                        </StatisticInformation>
                        <StatisticInformation>
                            <ClosedOrForwardedCircle />
                            <NotificationInformationText>
                                {user?.roles.some(
                                    (role) =>
                                        role === 'caseworker' ||
                                        role === 'partner'
                                )
                                    ? translation.dashboardPartner
                                          .yourStatistics.forwarded
                                    : translation.dashboardPartner
                                          .yourStatistics.closed}
                            </NotificationInformationText>
                        </StatisticInformation>
                    </NotificationInformationContainer>
                </CardHeader>
                <StatisticsFlexContainer>
                    <StatisticsGraph>
                        <StatisticsGraphNumbers id="statistics-grid">
                            <StatisticsGrid highestMonth={highestMonth}>
                                {numbers.map((number) => (
                                    <StatisticsRow key={number}>
                                        <StatisticNumber>
                                            {number}
                                        </StatisticNumber>
                                    </StatisticsRow>
                                ))}
                            </StatisticsGrid>
                            <ReportStatisticsGraph />
                        </StatisticsGraphNumbers>
                        <MonthGrid>
                            {months.map((month) => (
                                <Month key={month}>{month.slice(0, 3)}</Month>
                            ))}
                        </MonthGrid>
                    </StatisticsGraph>
                    <TotalStatisticsContainer>
                        <TotalStatisticBox>
                            <TotalStatisticHeading>
                                {
                                    translation.dashboardPartner.yourStatistics
                                        .totalCases
                                }
                            </TotalStatisticHeading>
                            <TotalStatisticsStatistic>
                                {dashboardStatistics?.total_cases}
                            </TotalStatisticsStatistic>
                        </TotalStatisticBox>
                        <TotalStatisticBox>
                            <TotalStatisticHeading>
                                {
                                    translation.dashboardPartner.yourStatistics
                                        .activeCases
                                }
                            </TotalStatisticHeading>
                            <TotalStatisticsStatistic>
                                {dashboardStatistics?.active_cases &&
                                    dashboardStatistics?.new_cases &&
                                    dashboardStatistics.active_cases +
                                        dashboardStatistics.new_cases}
                            </TotalStatisticsStatistic>
                        </TotalStatisticBox>
                        <TotalStatisticBox>
                            <TotalStatisticHeading>
                                {user?.roles.some(
                                    (role) =>
                                        role === 'caseworker' ||
                                        role === 'partner'
                                )
                                    ? translation.dashboardPartner
                                          .yourStatistics.forwardedCases
                                    : translation.dashboardPartner
                                          .yourStatistics.closedCases}
                            </TotalStatisticHeading>
                            <TotalStatisticsStatistic>
                                {dashboardStatistics?.forwarded_cases ||
                                    dashboardStatistics?.closed_cases}
                            </TotalStatisticsStatistic>
                        </TotalStatisticBox>
                    </TotalStatisticsContainer>
                </StatisticsFlexContainer>
            </Card>
        </StatisticsContainer>
    )
}

export default ReportStatistics

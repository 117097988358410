import styled from 'styled-components'
import { FlashMessageInterface } from '../../../state/reducers/lineOfContactReducer'
import { FiAlertCircle, FiPlusCircle } from 'react-icons/fi'
import { useEffect, useState } from 'react'

interface MessageProps {
    type: FlashMessageInterface['type']
}

const Message = styled.div<MessageProps>`
    width: 42rem;
    height: 4rem;
    padding: 1.4rem;
    font-size: 1.2rem;
    border-radius: 0.2rem;
    margin: 1.4rem 0;
    align-items: center;

    ${({ type, theme }) => {
        if (type === 'success') {
            return `
            background-color: ${theme.blue}50;
            color: ${theme.blue};
        `
        } else if (type === 'error') {
            return `
            background-color: ${theme.red}50;
            color: ${theme.red};
        `
        }
    }}
`

const SuccessIcon = styled(FiPlusCircle)`
    color: ${({ theme }) => theme.blue};
    font-size: 1.2rem;
    margin-right: 0.5rem;
    margin-bottom: -0.2rem;
`

const AlertIcon = styled(FiAlertCircle)`
    color: ${({ theme }) => theme.red};
    font-size: 1.2rem;
    margin-right: 0.5rem;
    margin-bottom: -0.2rem;
`

interface FlashMessageProps {
    message: FlashMessageInterface
}

const FlashMessage: React.FC<FlashMessageProps> = ({ message }) => {
    const [icon, setIcon] = useState<JSX.Element | null>(null)

    useEffect(() => {
        if (message.type === 'success') {
            setIcon(<SuccessIcon />)
        } else if (message.type === 'error') {
            setIcon(<AlertIcon />)
        }
    }, [message])

    return (
        <Message type={message.type}>
            {icon}
            {message.message}
        </Message>
    )
}

export default FlashMessage

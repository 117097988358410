import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import styled from 'styled-components'
import { Card, CardBody, CardHeader } from '../../../styled/cards'
import noProfileImage from '../../../../assets/images/no-profile-image.svg'
import { formatDate } from '../../../../helpers/date'
import historyLogTransformer from '../../../../state/transformers/HistoryLogTransformer'
import capitilize from '../../../../helpers/string/capitilize'

const Container = styled.div`
    padding: 4rem;
    display: flex;
    gap: 2.4rem;
    align-items: flex-start;
`

const InfoBox = styled.div`
    flex: 1;
    height: 24rem;
`

const FilesBox = styled.div`
    flex: 1;
    min-height: 24rem;
    max-height: calc(48vh + 5rem);
`

const HistoryLogBox = styled.div`
    flex: 2.5;
    max-height: 80vh;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: ${({ theme }) => `1px solid ${theme.grey}`};
    height: 4.8rem;
    padding: 1.4rem;
    align-items: center;
`

const Label = styled.p`
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => `${theme.primary}`};
    opacity: 0.7;
`

const Value = styled.p`
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => `${theme.primary}`}:;
`
const CaseworkerImg = styled.img`
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
`

const FilesLabel = styled.p`
    font-size: 1rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;

    padding: 1rem 0;
`

const File = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.grey};
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
`

const FileExtension = styled.h3`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 0.5rem;
`

const FileInfo = styled.div`
    display: flex;
    gap: 1.4rem;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
`

const Logs = styled.div`
    max-height: 100%;
    overflow-y: scroll;
`

const Log = styled.div`
    padding: 2rem 1.4rem;
    border-bottom: ${({ theme }) => `1px solid ${theme.grey}`};
    display: flex;
    gap: 3.4rem;
`

const LogTime = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
`

const LogHeading = styled(FileExtension)`
    text-transform: none;
    padding-bottom: 0.5rem;
`

const LogText = styled(LogHeading)`
    font-weight: normal;
`

const DeletedCasePage: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { showDeletedCase } = useActions()

    const translation = useTranslation()

    const deletedCaseState = useTypedSelector((state) => state.deletedCase)

    useEffect(() => {
        console.log({ deletedCaseState })
    }, [deletedCaseState])

    const getFileSize = (bytes: number) => {
        const kilobytes = bytes / 1024

        if (kilobytes < 1024) {
            return `${kilobytes.toFixed(0)} KB`
        }

        if (kilobytes >= 1024) {
            return `${(kilobytes / 1024).toFixed(0)} MB`
        }
    }

    useEffect(() => {
        if (id) {
            showDeletedCase(parseInt(id))
        }

        // eslint-disable-next-line
    }, [id])

    return (
        <Container>
            {deletedCaseState.type === 'success' && (
                <>
                    <InfoBox>
                        <Card>
                            <CardHeader>
                                {translation.accessCaseFlow.info}
                            </CardHeader>
                            <Row>
                                <Label>{translation.accessCaseFlow.id}</Label>
                                <Value>
                                    {deletedCaseState.case.readableId}
                                </Value>
                            </Row>
                            <Row>
                                <Label>{translation.accessCaseFlow.date}</Label>
                                <Value>
                                    {formatDate(
                                        deletedCaseState.case.createdAt
                                    )}
                                </Value>
                            </Row>
                            <Row>
                                <Label>
                                    {translation.accessCaseFlow.appearance}
                                </Label>
                                <Value>
                                    {capitilize(deletedCaseState.case.caseType)}
                                </Value>
                            </Row>
                            <Row>
                                <Label>
                                    {translation.accessCaseFlow.caseworkers}
                                </Label>
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    {deletedCaseState.case.caseworkers.map(
                                        (c) => (
                                            <CaseworkerImg
                                                src={
                                                    c.profileImg ||
                                                    noProfileImage
                                                }
                                            />
                                        )
                                    )}
                                </div>
                            </Row>
                        </Card>
                    </InfoBox>
                    <FilesBox>
                        <Card>
                            <CardHeader>
                                {translation.accessCaseFlow.files}
                            </CardHeader>

                            <CardBody
                                style={{
                                    padding: '0 1.4rem',
                                    overflowY: 'scroll',
                                    maxHeight: '48vh',
                                }}
                            >
                                {deletedCaseState.case.files.reporter?.length >
                                    0 && (
                                    <div>
                                        <FilesLabel>
                                            {
                                                translation.accessCaseFlow
                                                    .fromWhistleblower
                                            }
                                        </FilesLabel>
                                        {deletedCaseState.case.files.reporter.map(
                                            (file) => (
                                                <File>
                                                    <FileExtension>
                                                        {file.extension}
                                                    </FileExtension>
                                                    <FileInfo>
                                                        <p>
                                                            {formatDate(
                                                                file.createdAt
                                                            )}
                                                        </p>
                                                        <p>
                                                            {getFileSize(
                                                                file.size
                                                            )}
                                                        </p>
                                                    </FileInfo>
                                                </File>
                                            )
                                        )}
                                    </div>
                                )}

                                {deletedCaseState.case.files.caseworker
                                    ?.length > 0 && (
                                    <>
                                        <FilesLabel>
                                            {
                                                translation.accessCaseFlow
                                                    .fromCaseworker
                                            }
                                        </FilesLabel>

                                        {deletedCaseState.case.files.reporter?.map(
                                            (file) => (
                                                <File>
                                                    <FileExtension>
                                                        {file.extension}
                                                    </FileExtension>
                                                    <FileInfo>
                                                        <p>
                                                            {formatDate(
                                                                file.createdAt
                                                            )}
                                                        </p>
                                                        <p>
                                                            {getFileSize(
                                                                file.size
                                                            )}
                                                        </p>
                                                    </FileInfo>
                                                </File>
                                            )
                                        )}
                                    </>
                                )}
                            </CardBody>
                        </Card>
                    </FilesBox>
                    <HistoryLogBox>
                        <Card>
                            <CardHeader>
                                {translation.caseworkerOpenReport.historyLog}
                            </CardHeader>
                            <CardBody>
                                <Logs>
                                    {historyLogTransformer(
                                        deletedCaseState.case.logs,
                                        translation
                                    ).map((log: any) => (
                                        <Log>
                                            <LogTime>{log.createdAt}</LogTime>
                                            <div>
                                                <LogHeading>
                                                    {log.title}
                                                </LogHeading>
                                                <LogText>
                                                    {log.description}
                                                </LogText>
                                            </div>
                                        </Log>
                                    ))}
                                </Logs>
                            </CardBody>
                        </Card>
                    </HistoryLogBox>
                </>
            )}
        </Container>
    )
}

export default DeletedCasePage

import styled from 'styled-components'
import { Card, CardHeader } from '../../styled/cards'
import { FormField, FormInput40, PrimaryLabel } from '../../styled/formElements'
import { useTranslation } from '../../../hooks'
import config from '../../../config'
import CountryCodeDropdown from '../form/CountryCodeDropdown'
import React, { useState } from 'react'
import ReportingChanelQRmodal from '../../pages/whistleblower/new-case/modals/ReportingChannelQRModal'
import QRCode from 'qrcode'
import ReportingChannelLanguage from '../../pages/shared/settings/frames/components/ReportingChannelLanguage'
import ReportingChannelLinkCards from '../ReportingChannelLinkCards'

export const FlexContainer = styled.div`
    display: flex;
    gap: 2.2rem;
`

export const LeftContainer = styled.div`
    width: 30%;
`

export const RightContainer = styled.div`
    width: 75%;
`

// const CompanyLogoCard = styled(Card)`
//     height: 18rem;
//     margin-bottom: 2rem;
// `

// const LogoCardBody = styled.div`
//     display: flex;
//     align-items: center;
//     height: 13rem;
// `

// const LogoPlaceholder = styled.div`
//     height: 8.4rem;
//     width: 23rem;
//     background: ${({ theme }) => `${theme.blue}10`};

//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     margin-left: 1rem;
// `

// const LogoPlaceholderIcon = styled(FiCamera)`
//     color: ${({ theme }) => theme.blue};
//     font-size: 2.2rem;
//     margin-bottom: 1rem;
//     opacity: 0.5;
//     border-radius: 0.5rem;
// `

// const LinkCard = styled(Card)`
//     height: 24rem;
// `

// const LogoButtonsContainer = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-left: auto;
//     margin-right: 3rem;
// `

// const UploadLogoButton = styled.div`
//     color: ${({ theme }) => theme.blue};
//     font-size: 1.2rem;
//     font-weight: 600;
//     padding: 2rem;
//     cursor: pointer;
// `

// const RemeveLogoButton = styled.div`
//     font-size: 1rem;
//     color: ${({ theme }) => theme.primary};
//     opacity: 0.5;
// `

export const Title = styled.h2`
    font-size: 1.8rem;
    font-weight: 500;

    @media screen and (max-width: 480px) {
        margin-top: 1rem;
    }
`

const FormContainer = styled.div`
    display: flex;
    gap: 2rem;
    padding: 2rem 0;
    width: 69rem;
    margin-left: 1.4rem;
`

const LeftFormContainer = styled.div`
    width: 50%;
`

const RightFormContainer = styled.div`
    width: 50%;
`

const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

const PhoneInputContainer = styled.div`
    width: 85%;
`

const NumberOfEmployeesHeading = styled.h4`
    font-size: 1.6rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
    text-align: left;
    height: 10rem;
    display: flex;
    align-items: center;
`

interface IsActiveProp {
    isActive: boolean
}

const ButtonContainer = styled.div`
    height: 10rem;
    display: flex;
    align-items: center;
    gap: 1rem;
`

const EmployeeNumberButton = styled.div<IsActiveProp>`
    height: 4rem;
    width: 13.3rem;
    border-radius: 10rem;
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : `${theme.primary}05`};
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : `${theme.primary}50`};
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
`

const CityInputContianer = styled.div`
    width: 60%;
`

const ZipInputContianer = styled.div`
    width: 40%;
`

export const EssentialCompanyInfoFrame = styled(LeftContainer)`
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`
export const NotEssentialCompanyInfoFrame = styled(RightContainer)`
    @media screen and (max-width: 1000px) {
        display: none;
    }
`
interface CompanyInformationFrameProps {
    client: any
    clientOrganization: any
    isClientUser?: boolean
}

const CompanyInformationFrame: React.FC<CompanyInformationFrameProps> = ({
    client,
    clientOrganization,
    isClientUser = false,
}) => {
    const [showPolicyLink, setShowPolicyLink] = useState(true)
    const [showWhistleBlowerLink, setShowWhistleBlowerLink] = useState(true)
    const [showTrainingMaterial, setShowTrainingMaterial] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [qrUrl, setQrUrl] = useState('')
    const translation = useTranslation()

    const employeeCountOptions = ['1-49', '50-249', '250-499', '500-999']

    const { appUrlExtension } = config

    const getWhistleBlowerLinkString = () => {
        let link = `http://app.whistleblower.${appUrlExtension}/organization/${clientOrganization?.organizational_code}`

        if (clientOrganization.id !== 169) {
            link += '/whistleblower'
        }
        return link
    }

    const getWhistleBlowerPolicyString = () => {
        return `http://app.whistleblower.${appUrlExtension}/organization/${clientOrganization?.organizational_code}/policy`
    }

    const getTrainingMaterialString = () => {
        return `http://app.whistleblower.${appUrlExtension}/organization/${clientOrganization.organizational_code}/how-it-works`
    }

    const handleWhistleBlowerLinkCardClicked = () => {
        if (!showWhistleBlowerLink) return
        setShowWhistleBlowerLink(false)
        setShowPolicyLink(true)
        const messageCountdown = 3000
        setTimeout(() => {
            setShowWhistleBlowerLink(true)
        }, 3000)
        clearTimeout(messageCountdown)
        navigator.clipboard.writeText(getWhistleBlowerLinkString())
    }
    const handlePolicyCardClicked = () => {
        if (!showPolicyLink) return
        setShowPolicyLink(false)
        setShowWhistleBlowerLink(true)
        const messageCountdown = 3000
        setTimeout(() => {
            setShowPolicyLink(true)
        }, 3000)
        clearTimeout(messageCountdown)
        navigator.clipboard.writeText(getWhistleBlowerPolicyString())
    }

    const handleTrainingMaterialCardClicked = () => {
        if (!showTrainingMaterial) return
        setShowPolicyLink(true)
        setShowWhistleBlowerLink(true)
        setShowTrainingMaterial(false)
        const messageCountdown = 3000
        setTimeout(() => {
            setShowTrainingMaterial(true)
        }, 3000)
        clearTimeout(messageCountdown)
        navigator.clipboard.writeText(getTrainingMaterialString())
    }

    const generateQRcode = (url: string) => {
        QRCode.toDataURL(
            url,
            {
                width: 230,
                margin: 2,
                color: {
                    dark: '#222330',
                    light: '#ffff',
                },
            },
            (err, url) => {
                if (err) return console.error(err)

                console.log(url)
                setQrUrl(url)
            }
        )
    }

    const handleShowModal = () => {
        setShowModal(true)
        generateQRcode(getWhistleBlowerLinkString())
    }

    return (
        <FlexContainer>
            <EssentialCompanyInfoFrame>
                {/* <CompanyLogoCard>
                    <CardHeader>Company logo</CardHeader>
                    <LogoCardBody>
                        <LogoPlaceholder>
                            <LogoPlaceholderIcon />
                            <LogoPlaceholderText>
                                Use a .png or .svg file
                            </LogoPlaceholderText>
                        </LogoPlaceholder>
                        <LogoButtonsContainer>
                            <UploadLogoButton>Upload new logo</UploadLogoButton>
                            <RemeveLogoButton>Remove logo</RemeveLogoButton>
                        </LogoButtonsContainer>
                    </LogoCardBody>
                </CompanyLogoCard> */}
                <div>
                    <Title>{translation.clientView.reportingChannel}</Title>
                    <ReportingChannelLanguage />
                    <ReportingChannelLinkCards
                        handleClick={handleWhistleBlowerLinkCardClicked}
                        title={translation.clientView.reportingChannel}
                        tagline={
                            translation.clientView.copyLinkReportingChannel
                        }
                        showWhistleblowerLink={showWhistleBlowerLink}
                    />
                    <ReportingChannelLinkCards
                        handleClick={handleShowModal}
                        title={translation.clientView.qrCode}
                        tagline={translation.clientView.downloadQRCode}
                        useQrCode={true}
                    />
                    {showModal && (
                        <ReportingChanelQRmodal
                            handleCloseModal={() => setShowModal(false)}
                            url={qrUrl}
                        />
                    )}
                    <div>
                        <Title>
                            {translation.clientView.whistleblowerPolicy}
                        </Title>

                        <ReportingChannelLinkCards
                            title={translation.clientView.whistleblowerPolicy}
                            tagline={
                                translation.clientView
                                    .copyLinkWhistleblowerPolicy
                            }
                            handleClick={handlePolicyCardClicked}
                            showPolicyLink={showPolicyLink}
                        />
                    </div>

                    <div>
                        <Title>{translation.clientView.trainingMaterial}</Title>
                        <ReportingChannelLinkCards
                            title={translation.clientView.trainingMaterial}
                            tagline={
                                translation.clientView
                                    .copyLinkToTrainingMaterial
                            }
                            handleClick={handleTrainingMaterialCardClicked}
                            showTrainingMaterial={showTrainingMaterial}
                        />
                    </div>
                </div>
            </EssentialCompanyInfoFrame>
            <NotEssentialCompanyInfoFrame>
                <Card
                    style={{
                        height: '46rem',
                    }}
                >
                    <CardHeader>
                        {translation.reusable.companyInformation}
                        {/* <EditButton>
                            <EditIcon size={18} />
                        </EditButton> */}
                    </CardHeader>
                    <FormContainer>
                        <LeftFormContainer>
                            <FormField>
                                <PrimaryLabel>
                                    {translation.reusable.companyNumber}
                                </PrimaryLabel>
                                <FormInput40
                                    value={
                                        clientOrganization
                                            ? clientOrganization.vat
                                            : ''
                                    }
                                    disabled
                                />
                            </FormField>
                            <FormField>
                                <PrimaryLabel>
                                    {translation.reusable.workEmail}
                                </PrimaryLabel>
                                <FormInput40
                                    value={
                                        clientOrganization
                                            ? clientOrganization.email
                                            : ''
                                    }
                                    disabled
                                />
                            </FormField>
                            <FormField>
                                <PrimaryLabel>
                                    {translation.reusable.address}
                                </PrimaryLabel>
                                <FormInput40
                                    value={
                                        clientOrganization
                                            ? clientOrganization.address
                                            : ''
                                    }
                                    disabled
                                />
                            </FormField>
                            <NumberOfEmployeesHeading>
                                {translation.reusable.numberOfEmployees}
                            </NumberOfEmployeesHeading>
                        </LeftFormContainer>
                        <RightFormContainer>
                            <FormField>
                                <PrimaryLabel>
                                    {translation.reusable.companyName}
                                </PrimaryLabel>
                                <FormInput40
                                    value={
                                        clientOrganization
                                            ? clientOrganization.name
                                            : ''
                                    }
                                    disabled
                                />
                            </FormField>

                            <FormField>
                                <PrimaryLabel>
                                    {translation.reusable.phone}
                                </PrimaryLabel>
                                <FlexInputContainer>
                                    <CountryCodeDropdown
                                        error={false}
                                        success={false}
                                        selectDropdownCB={() => {}}
                                        disabled
                                        /* TODO add country code when u have it*/
                                    />
                                    <PhoneInputContainer>
                                        <FormInput40
                                            value={
                                                clientOrganization
                                                    ? clientOrganization.phone_number
                                                    : ''
                                            }
                                            disabled
                                        />
                                    </PhoneInputContainer>
                                </FlexInputContainer>
                            </FormField>
                            <FormField>
                                <FlexInputContainer>
                                    <CityInputContianer>
                                        <PrimaryLabel>
                                            {translation.reusable.city}
                                        </PrimaryLabel>
                                        <FormInput40
                                            disabled
                                            value={
                                                clientOrganization
                                                    ? clientOrganization.city
                                                    : ''
                                            }
                                        />
                                    </CityInputContianer>
                                    <ZipInputContianer>
                                        <PrimaryLabel>
                                            {translation.reusable.zipCode}
                                        </PrimaryLabel>
                                        <FormInput40
                                            disabled
                                            value={
                                                clientOrganization?.postal_code
                                            }
                                        />
                                    </ZipInputContianer>
                                </FlexInputContainer>
                            </FormField>

                            <ButtonContainer>
                                {employeeCountOptions.map((option) => (
                                    <EmployeeNumberButton
                                        key={option}
                                        isActive={
                                            option ===
                                            clientOrganization?.employee_count
                                        }
                                    >
                                        {option}
                                    </EmployeeNumberButton>
                                ))}
                            </ButtonContainer>
                        </RightFormContainer>
                    </FormContainer>
                </Card>
            </NotEssentialCompanyInfoFrame>
        </FlexContainer>
    )
}

export default CompanyInformationFrame

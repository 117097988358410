import produce from 'immer'
import { WhitelabelType } from '../actions/whitelabelActions'
import { WhitelabelActionType } from '../action-types'

interface WhitelabelState {
    isWhitelabled: boolean
    hexCode: null | string
    logo: null | string
}

const initialState: WhitelabelState = {
    isWhitelabled: false,
    hexCode: null,
    logo: null,
}

const reducer = produce((state = initialState, action: WhitelabelType) => {
    switch (action.type) {
        case WhitelabelActionType.GET_WHITELABEL:
            state = { ...action.payload, isWhitelabled: true }

            return state

        default:
            return state
    }
})

export default reducer

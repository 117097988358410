import produce from 'immer'
import { AutoReplyActionType } from '../action-types'
import { AutoReplyActions } from '../actions'

export interface AutoReplyInterface {
    createdAt: string
    updatedAt: string
    isActive: number
    id: number
    reply: string
}

interface AutoReplyState {
    autoReplyLoading: boolean
    autoReply: AutoReplyInterface | null
    autoReplyError: string | null
    isAutomaticReplyActive: number | null
}

const initialState: AutoReplyState = {
    autoReplyLoading: false,
    autoReply: null,
    autoReplyError: null,
    isAutomaticReplyActive: null,
}

const reducer = produce(
    (state: AutoReplyState = initialState, action: AutoReplyActions) => {
        switch (action.type) {
            case AutoReplyActionType.AUTO_REPLY_LOADING:
                state.autoReplyLoading = true

                return state
            case AutoReplyActionType.CREATE_AUTO_REPLY:
                state.autoReplyLoading = false
                state.autoReply = action.payload
                state.isAutomaticReplyActive = action.payload.isActive

                return state

            case AutoReplyActionType.AUTO_REPLY_ERROR:
                state.autoReplyLoading = false
                state.autoReplyError = action.payload.message

                return state

            case AutoReplyActionType.GET_AUTO_REPLY:
                state.autoReplyLoading = false
                state.autoReply = action.payload
                state.isAutomaticReplyActive = action.payload.isActive

                return state

            case AutoReplyActionType.UPDATE_AUTO_REPLY:
                state.autoReplyLoading = false
                state.autoReply = action.payload
                state.isAutomaticReplyActive = action.payload.isActive

                return state

            case AutoReplyActionType.CLEAR_AUTO_REPLY_ERROR_MESSAGE:
                state.autoReplyError = null

                return state
            default:
                return state
        }
    }
)

export default reducer

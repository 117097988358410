import { Dispatch } from 'redux'
import config from '../../config'
import axios from 'axios'
import { DeletedCase } from '../reducers/deletedCaseReducer'
import { DeletedCaseActionType } from '../action-types'

const { apiUrl } = config

export const showDeletedCase = (id: number) => async (dispatch: Dispatch) => {
    const res = await axios.get<{ data: DeletedCase }>(
        `${apiUrl}/v1/deleted-incidents/${id}`
    )

    return dispatch({
        type: DeletedCaseActionType.SHOW_DELETED_INCIDENT,
        payload: res.data.data,
    })
}

import produce from 'immer'
import { TranslateTextAction } from '../actions'
import { TranslateTextActionType } from '../action-types'

interface TranslateTextState {
    translateTextLoading: boolean
    translateTextError: null | string
    translateTextSuccess: boolean
}

const InitialState: TranslateTextState = {
    translateTextLoading: false,
    translateTextError: null,
    translateTextSuccess: false,
}

const reducer = produce(
    (
        state: TranslateTextState = InitialState,
        action: TranslateTextAction
    ) => {
        switch (action.type) {
            case TranslateTextActionType.TRANSLATION_LOADING:
                state.translateTextLoading = true
                state.translateTextError = null
                state.translateTextSuccess = false

                return state

            case TranslateTextActionType.TRANSLATION_ERROR:
                state.translateTextLoading = false
                state.translateTextError = action.payload
                state.translateTextSuccess = false

                return state

            case TranslateTextActionType.TRANSLATION_SUCCESS:
                state.translateTextLoading = false
                state.translateTextError = null
                state.translateTextSuccess = true
                return state

            default:
                return state
        }
    }
)

export default reducer

import { Dispatch } from 'redux'
import { StatisticsOverviewActionType } from '../action-types'
import { StatisticsOverview } from '../actions'
import config from '../../config'
import axios from 'axios'

const { apiUrl } = config

export const getStatisticsOverview = (year: string) => async (dispatch: Dispatch) => {
    dispatch(statisticsOverviewRequest())
    try {
        const res = await axios.get(
            `${apiUrl}/v1/complete-statistics?year=${year}`
        )
        dispatch(statisticsOverviewSuccess(res.data.data))
    } catch (error) {
        dispatch(statisticsOverviewError('Error loading statistics'))
    }
}

const statisticsOverviewRequest = () => ({
    type: StatisticsOverviewActionType.STATISTICS_OVERVIEW_REQUEST,
})

const statisticsOverviewSuccess = (data: StatisticsOverview) => ({
    type: StatisticsOverviewActionType.STATISTICS_OVERVIEW_SUCCESS,
    payload: data,
})

const statisticsOverviewError = (error: string) => ({
    type: StatisticsOverviewActionType.STATISTICS_OVERVIEW_ERROR,
    payload: error,
})

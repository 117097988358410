import { Modal, ModalContainer } from '../../../styled/modals'
import { Card, CardBody, CardHeader } from '../../../styled/cards'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import logo from './hr-on-logo.png'
import { FiLink, FiFileText, FiBookOpen } from 'react-icons/fi'
import { BiBuildings } from 'react-icons/bi'

const Container = styled.div`
    padding: 1.4rem;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
`

const InfoTextLight = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}80`};
    margin-top: 1rem;
    margin-bottom: 2rem;
`

const Logo = styled.img``

const InfoFlex = styled.div`
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
`

const InfoText = styled.p`
    font-size: 1.4rem;
    font-weight: bold;
    color: ${({ theme }) => theme.primary};
`

const LinkIcon = styled(FiLink)`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.4rem;
`

const FileIcon = styled(FiFileText)`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.4rem;
`

const BookIcon = styled(FiBookOpen)`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.4rem;
`

const BuildingIcon = styled(BiBuildings)`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.4rem;
`

const ButtonContainer = styled.div`
    padding-top: 2.8rem;
    display: flex;
    gap: 1.4rem;
`

const Button = styled.div`
    padding: 0.4rem 1.4rem;
    border-radius: 2.4rem;
    font-size: 1.6rem;

    cursor: pointer;
`

const ApproveButton = styled(Button)`
    background-color: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
`

const CancelButton = styled(Button)`
    color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
`

const HrOnModalConfirmIntegrationModal: React.FC = () => {
    const { activeIntegration } = useTypedSelector(
        (state) => state.hrOnIntegration
    )

    const translation = useTranslation()

    const { getIdentity } = useTypedSelector((state) => state.identity)

    const { approveIntegration, cancelIntegration } = useActions()

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (!activeIntegration) return

        const organization = getIdentity?.organization?.data

        if (
            !activeIntegration.is_integration_confirmed &&
            findClient(getIdentity.groups) &&
            isOnboarded(organization)
        ) {
            setIsActive(true)
        }
    }, [activeIntegration, getIdentity])

    const findClient = (groups: any) => {
        return groups.find((group: any) => group.slug === 'client')
    }

    const isOnboarded = (organization: any) => {
        return organization?.onboarded_at
    }

    const handleApproveIntegration = async () => {
        if (!activeIntegration?.uuid || !activeIntegration?.redirect_url) {
            alert(
                'Some crucial information is missing to approve the integration. Please contact support or try again later.'
            )

            return setIsActive(false)
        }

        const success = await approveIntegration(activeIntegration.uuid)

        if (!success) {
            alert(
                'Failed to make the connection to hr-on. Try to go back to hr-on and select "Use existing account" If it still does not work, please contact support.'
            )
        }

        const url = `${activeIntegration.redirect_url}?final=true`
        window.open(url)

        setIsActive(false)
    }

    const handleCancelIntegration = async () => {
        if (!activeIntegration?.uuid || !activeIntegration?.redirect_url) return

        const success = await cancelIntegration(activeIntegration.uuid)

        if (!success) {
            alert('Something went wrong')
        }

        const url = `${activeIntegration.redirect_url}?final=false`
        window.open(url)

        setIsActive(false)
    }

    return (
        <>
            {isActive && (
                <ModalContainer>
                    <Modal height="36rem" width="60rem">
                        <Card>
                            <CardHeader></CardHeader>
                            <CardBody>
                                <Container>
                                    <Logo src={logo} />
                                    <InfoTextLight>
                                        {
                                            translation.integrationModal
                                                .thisAppWouldLikeToAccess
                                        }
                                    </InfoTextLight>
                                    <InfoFlex>
                                        <InfoText>
                                            {
                                                translation.integrationModal
                                                    .thisAppRequires
                                            }{' '}
                                            "
                                            {
                                                translation.integrationModal
                                                    .whistleblowingChannel
                                            }
                                            "
                                        </InfoText>
                                        <LinkIcon />
                                    </InfoFlex>
                                    <InfoFlex>
                                        <InfoText>
                                            {
                                                translation.integrationModal
                                                    .thisAppRequires
                                            }{' '}
                                            "
                                            {
                                                translation.integrationModal
                                                    .whistleblowingPolicy
                                            }
                                            "
                                        </InfoText>
                                        <FileIcon />
                                    </InfoFlex>
                                    <InfoFlex>
                                        <InfoText>
                                            {
                                                translation.integrationModal
                                                    .thisAppRequires
                                            }{' '}
                                            "
                                            {
                                                translation.integrationModal
                                                    .employeeGuides
                                            }
                                            "
                                        </InfoText>
                                        <BookIcon />
                                    </InfoFlex>
                                    <InfoFlex>
                                        <InfoText>
                                            {
                                                translation.integrationModal
                                                    .thisAppRequires
                                            }{' '}
                                            "
                                            {
                                                translation.integrationModal
                                                    .companyName
                                            }
                                            "
                                        </InfoText>
                                        <BuildingIcon />
                                    </InfoFlex>
                                    <ButtonContainer>
                                        <CancelButton
                                            onClick={handleCancelIntegration}
                                        >
                                            {translation.reusable.cancel}
                                        </CancelButton>
                                        <ApproveButton
                                            onClick={handleApproveIntegration}
                                        >
                                            {
                                                translation.integrationModal
                                                    .approve
                                            }
                                        </ApproveButton>
                                    </ButtonContainer>
                                </Container>
                            </CardBody>
                        </Card>
                    </Modal>
                </ModalContainer>
            )}
        </>
    )
}

export default HrOnModalConfirmIntegrationModal

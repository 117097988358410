import {
    Container,
    BackToHomeScreen,
    PolicyContainer,
    Heading,
    Paragraph,
    NavigationLink,
    NavigationLinkContainer,
    NavigationLinkDivider,
    Li,
} from '../styled'
import NavigationArrowLink from '../../../../reusable/buttons/NavigationArrowLink'
import { useHistory } from 'react-router'
import { useTypedSelector } from '../../../../../hooks'

const MikkellerPolicy = () => {
    const history = useHistory()

    const { companyName, organizationalCode } = useTypedSelector(
        (state) => state.whistleblower
    )

    const { lineOfContacts } = useTypedSelector((state) => state.lineOfContact)

    return (
        <>
            <BackToHomeScreen>
                <NavigationArrowLink
                    onClickCallback={() => {
                        history.push(
                            `/organization/${organizationalCode}/whistleblower`
                        )
                    }}
                    linkText={'Back to Home Screen'}
                />
            </BackToHomeScreen>
            <Container>
                <NavigationLinkContainer>
                    <NavigationLink active={true}>{companyName}</NavigationLink>
                    <NavigationLinkDivider />
                    <NavigationLink active={false}>
                        Whistleblower Policy
                    </NavigationLink>
                </NavigationLinkContainer>
                <PolicyContainer>
                    <Heading>Mikkeller's whistleblower policy</Heading>
                    <Paragraph>
                        The purpose of this whistleblower policy is to explain
                        how Mikkeller's whistleblower scheme works. This scheme
                        allows you to make reports about Mikkeller. A good
                        understanding of this scheme can therefore prevent
                        potentially important matters from being left
                        unreported.
                    </Paragraph>
                    <Heading>
                        Introduction to Mikkeller's whistleblower scheme
                    </Heading>
                    <Paragraph>
                        Mikkeller is dedicated to act and do business in a way
                        that is right to our employees, customers, partners and
                        society in general. In order to continuously make sure
                        that Mikkeller does just that, Mikkeller encourages that
                        concerns are shared.
                    </Paragraph>
                    <Paragraph>
                        Mikkeller employees and volunteers may always and is
                        strongly supported to share concerns with their direct
                        manager or with People & Culture. However, concerns may
                        rise which an employee does not find suitable to raise
                        through those channels. Hence, the objective of the
                        whistleblower scheme is to ensure that all Mikkeller
                        employees as well as customers, partners and others have
                        a channel where they can report any potential breaches
                        of laws or regulations applicable to the Mikkeller
                        Group, as well as breaches of any of the internal
                        policies or standards of the Mikkeller Group.
                    </Paragraph>
                    <Paragraph>
                        Mikkeller's whistleblower scheme ensures protection for
                        individuals who report serious offenses and matters.
                        This scheme can be used if you experience, are aware of
                        or have reasons to suspect the occurrence of such
                        breaches in Mikkeller both in the past, present or near
                        future. Reports can be filed either anonymously or
                        confidential.
                    </Paragraph>
                    <Heading>Who can use this Whistleblowing scheme?</Heading>
                    <Paragraph>
                        Mikkeller's whistleblower scheme can be used by anyone
                        who has information about Mikkeller which they have
                        acquired in the course of their work- related activities
                        with Mikkeller.
                    </Paragraph>
                    <Paragraph>
                        The categories of people who can use this whistleblowing
                        scheme are broad. Reports can thus be filed by:
                    </Paragraph>
                    <Paragraph>
                        <ul>
                            <Li>
                                Employees. This entails both full and part-time
                                employees as well as employees working under an
                                unlimited or fixed-term contract.
                            </Li>
                            <Li>Job applicants</Li>
                            <Li>Paid or unpaid trainees</Li>
                            <Li>Volunteers</Li>
                            <Li>Self-employed persons</Li>
                            <Li>Shareholders</Li>
                            <Li>
                                Members of the management, supervisory board, or
                                other governing board of IO Interactive
                            </Li>
                            <Li>
                                Persons working under the supervision and
                                direction of contractors, subcontractors, and
                                suppliers of IO Interactive
                            </Li>
                            <Li>Customers</Li>
                            <Li>Suppliers</Li>
                            <Li>Other business partners</Li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        Persons falling under these categories can make use of
                        the whistleblowing scheme regardless of whether their
                        employment relationship with Mikkeller has ceased, is
                        still ongoing or has not yet begun.
                    </Paragraph>
                    <Heading>What can you report?</Heading>
                    <Paragraph>
                        The whistleblower scheme can be used to address any
                        information about serious violations of the law,
                        attempts to conceal such violations or other serious
                        matters that have occurred or are likely to occur in
                        Mikkeller. Also in case of reasonable suspicion of the
                        occurrence of these violations or matters, this
                        information can be reported. It is required however that
                        reports are submitted in good faith, and that this
                        reporting scheme is not used for unfounded accusations
                        towards innocent people.
                    </Paragraph>
                    <Paragraph>
                        The violations which can be reported about through the
                        whistleblower scheme relate to specific breaches of EU
                        law or other serious offenses and matters according to
                        Danish law. Less serious matters or ordinary employment
                        issues, such as dissatisfaction with salary or minor
                        collegial issues are not meant to be reported through
                        this whistleblower scheme.
                    </Paragraph>
                    <Paragraph>
                        Activities that can be reported through this scheme
                        relate for example to corruption, protection of the
                        environment, public health, privacy and personal data or
                        sexual harassment and sexual abuse. These are only
                        examples. Also other matters or violations of the law of
                        a serious nature can be reported.
                    </Paragraph>
                    <Paragraph>
                        If you are in doubt about whether your observation or
                        suspicion is of a serious nature and should be reported
                        or not, you are encouraged to do so. All inquiries will
                        be answered and processed.
                    </Paragraph>
                    <Heading>Protection of the whistleblower</Heading>
                    <Paragraph>
                        Anyone who reports matters as described above in good
                        faith is protected from any form of retaliation. Actions
                        of retaliation entail direct or indirect actions or
                        omissions which occur in a work-related context as a
                        result from the report and which cause or may cause
                        unjustified harm to the whistleblower. Such actions can
                        take multiple forms, such as for example dismissal,
                        change of location of work, reduction in wages or
                        harming the reputation of the whistleblower.
                    </Paragraph>
                    <Paragraph>
                        If a whistleblower suffers any form of retaliation by
                        Mikkeller, they will be compensated for this. In case of
                        dismissal, the dismissal shall be annulled and the
                        employment relationship shall be maintained or
                        re-established if the whistleblowers so wishes.
                        Mikkeller may also be subject to other sanctions.
                    </Paragraph>
                    <Heading>How to report?</Heading>
                    <Paragraph>
                        Reports that lie within the scope of this policy are
                        processed by:
                    </Paragraph>
                    <Paragraph>
                        <ul>
                            {lineOfContacts.map((lineOfContact) => (
                                <Li
                                    key={lineOfContact.id}
                                    style={{ paddingBottom: '1rem' }}
                                >
                                    {lineOfContact.first_name}{' '}
                                    {lineOfContact.last_name}
                                    {' - '}
                                    {lineOfContact.contact_information.position}
                                </Li>
                            ))}
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        Only these persons are authorised to investigate.
                        However, in some cases, the investigation may be carried
                        out with the help of external parties, such as lawyers
                        or auditors.
                    </Paragraph>
                    <Heading>How are the reports processed?</Heading>
                    <Paragraph>
                        You will receive an acknowledgement of receival of the
                        report within seven days. After this, an initial
                        examination of the report will be conducted. If the
                        report turns out to be notably unfounded, it will be
                        rejected. The person who reported the case will be
                        notified of this as soon as possible. However, if the
                        initial examination determines that the report cannot be
                        characterized as notably unfounded, it will be further
                        investigated. The case will then be processed
                        internally. The whistleblower will receive feedback on
                        any updates or outcomes as soon as possible and under no
                        circumstances later than 3 months from the
                        whistlerblower has received the acknowledgement of
                        receival of the report. This internal investigation may
                        have consequences for the person or persons who are
                        involved. If the case is of particular serious nature,
                        it may also be passed on to the police for further
                        investigation. After conducting all investigations and
                        imposing potential sanctions, the case will be closed.
                        It will then be deleted from the system.
                    </Paragraph>
                    <Heading>
                        Subsequent clarification and collection of additional
                        documentation
                    </Heading>
                    <Paragraph>
                        When you create a report, you get the opportunity to log
                        in to the system and see if the caseworker has asked
                        additional questions about the case or requests
                        additional documentation. Any subsequent dialogue
                        depends solely on the whistleblower's willingness to log
                        in to the system and answer the caseworker's questions.
                    </Paragraph>
                    <Heading>Confidentiality and IT security</Heading>
                    <Paragraph>
                        A report can be made either anonymous or confidential.
                        Whereas the former entails that no personal information
                        is shared, the latter includes the sharing of personal
                        information by the whistleblower. If personal
                        information is shared in your report, only those who are
                        authorised to investigate the case will have access
                        thereto. The confidentiality of the submitted report
                        will therefore in any case be respected.
                    </Paragraph>
                    <Paragraph>
                        The whistleblower reporting channel is operated by Walor
                        ApS, which is an independent third party who guarantees
                        the safety and security of the system. The system does
                        not log IP addresses and machine-IDs and will encrypt
                        all data processed. Only the responsible caseworker has
                        access to the report.
                    </Paragraph>
                    <Heading>Notice of person concerned</Heading>
                    <Paragraph>
                        The recipient of the report might be obliged to provide
                        the person being reported with information about the
                        reported event. In each individual case, an assessment
                        will be made of when this notice can be passed on, so
                        that the notice will have no consequences for the
                        collection of evidence.
                    </Paragraph>
                    <Paragraph>
                        No information, either direct or indirect, will be
                        specified about who made the report towards the person
                        concerned, even if the reporter has chosen to report
                        confidentially.
                    </Paragraph>
                    <Heading>Questions</Heading>
                    <Paragraph>
                        All questions about the whistleblower scheme can be
                        directed to Mikkeller's Head of P&C or Mikkeller’s
                        General Counsel.
                    </Paragraph>
                </PolicyContainer>
            </Container>
        </>
    )
}

export default MikkellerPolicy

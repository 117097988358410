import { useState, useEffect } from 'react'
import { useTranslation } from '../../../hooks'
import {
    checkFirstName,
    checkLastName,
    checkEmail,
    checkPhone,
    checkPosition,
} from '../../../helpers/validation'
import { UpdateIdentityType } from '../../../state/state-types/IdentityTypes'

const useCheckValidation = () => {
    const translation = useTranslation()

    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [positionError, setPositionError] = useState('')
    const [
        isPersonalInfomationFormValidated,
        setIsPersonalInfomationFormValidated,
    ] = useState(true)

    const [
        isCaseworkerInputDisplayFromValidated,
        setIsCaseworkerInputDisplayFromValidated,
    ] = useState(true)

    const isFormValidated = <Key extends keyof UpdateIdentityType>(
        field: Key,
        value: UpdateIdentityType[Key]
    ) => {
        if (field)
            switch (field) {
                case 'first_name':
                    setFirstNameError(checkFirstName(value, translation))
                    break
                case 'last_name':
                    setLastNameError(checkLastName(value, translation))
                    break
                case 'email':
                    setEmailError(checkEmail(value, translation))
                    break
                case 'phone':
                    setPhoneError(checkPhone(value, translation))
                    break
                case 'position':
                    setPositionError(checkPosition(value, translation))
                    break
            }
    }

    useEffect(() => {
        setIsPersonalInfomationFormValidated(
            !(
                firstNameError.length > 0 ||
                lastNameError.length > 0 ||
                emailError.length > 0 ||
                emailError.length > 0 ||
                phoneError.length > 0
            )
        )

        setIsCaseworkerInputDisplayFromValidated(!(positionError.length > 0))
    }, [firstNameError, lastNameError, emailError, phoneError, positionError])

    return {
        firstNameError,
        lastNameError,
        emailError,
        phoneError,
        positionError,
        setFirstNameError,
        setLastNameError,
        setEmailError,
        setPhoneError,
        setPositionError,

        isFormValidated,
        isPersonalInfomationFormValidated,
        isCaseworkerInputDisplayFromValidated,
    }
}

export default useCheckValidation

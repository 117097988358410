import { useState } from 'react'
import {
    UpdateIdentityType,
    UpdateIdentityStatus,
    PersonalInfoTypes,
    Language,
} from '../../../state/state-types/IdentityTypes'
import User from '../../../state/state-types/UserStateType'
import { useActions } from '../../../hooks'
import { isEqual } from 'lodash'
import useCheckValidation from './useCheckValidation'
import { UpdateUserIdentityInterface } from '../../../ts/interfaces/UserIdentityInterface'

export const useSaveIdentity = () => {
    const { updateIdentity } = useActions()

    const [updateIdentityStatus, setUpdateIdentityStatus] =
        useState<UpdateIdentityStatus>({ success: null, shouldLogout: null })

    const {
        isPersonalInfomationFormValidated,
        isCaseworkerInputDisplayFromValidated,
    } = useCheckValidation()

    const comparePersonalInfo = (
        personalInfo: PersonalInfoTypes,
        getIdentity: PersonalInfoTypes
    ): { payload: FormData; hasChanges: boolean } => {
        let hasChanges = false
        const payload: FormData = new FormData()
        payload.append('_method', 'PUT')

        const previousIdentity: UpdateIdentityType = {
            first_name: personalInfo.first_name,
            last_name: personalInfo.last_name,
            email: personalInfo.email,
            phone: personalInfo.phone.phone,
            country_id: personalInfo.country_id,
            selected_language_id: personalInfo.selected_language.data.id,
            position: personalInfo.contact_information.position,
            note: personalInfo.contact_information.note,
            languages: personalInfo.languages.data.map(
                (language: Language) => ({
                    language_id: language.id,
                })
            ),
            profile_img: personalInfo.contact_information.profile_img,
        }

        const initialIdentity: UpdateIdentityType = {
            first_name: getIdentity.first_name,
            last_name: getIdentity.last_name,
            email: getIdentity.email,
            phone: getIdentity.phone.phone,
            country_id: getIdentity.country_id,
            selected_language_id: getIdentity.selected_language.data.id,
            position: getIdentity.contact_information.position,
            note: getIdentity.contact_information.note,
            languages: getIdentity.languages.data.map((language: Language) => ({
                language_id: language.id,
            })),
            profile_img: getIdentity.contact_information.profile_img,
        }

        Object.keys(previousIdentity).forEach((property) => {
            const hasChanged = !isEqual(
                initialIdentity[property as keyof UpdateIdentityType],
                previousIdentity[property as keyof UpdateIdentityType]
            )

            if (hasChanged) {
                hasChanges = true
                if (property === 'languages') {
                    previousIdentity.languages.forEach(
                        (language: any, index: number) => {
                            if (language.language_id) {
                                payload.append(
                                    `languages[${index}][language_id]`,
                                    language.language_id.toString()
                                )
                            }
                        }
                    )
                } else {
                    payload.append(
                        property,
                        previousIdentity[property as keyof UpdateIdentityType]
                    )
                }
            }
        })

        return { payload, hasChanges }
    }

    const saveIdentity = async (
        user: User,
        personalInfo: PersonalInfoTypes,
        getIdentity: PersonalInfoTypes
    ): Promise<void> => {
        if (!user) return

        const { payload } = comparePersonalInfo(personalInfo, getIdentity)

        if (
            isPersonalInfomationFormValidated ||
            isCaseworkerInputDisplayFromValidated
        ) {
            const response = (await updateIdentity(
                user.userId,
                payload as UpdateUserIdentityInterface
            )) as unknown as UpdateIdentityStatus
            setUpdateIdentityStatus(response)
        }
    }

    return {
        updateIdentityStatus,
        saveIdentity,
        comparePersonalInfo,
    }
}

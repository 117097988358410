import React, { useState } from 'react'
import styled from 'styled-components'
import { NextButton } from '../../../../reusable/buttons'
import { Modal, ModalContainer } from '../../../../styled/modals'
import {
    GreyLine,
    PopupButton,
    PopupButtonContainer,
    PopupContainer,
    PopupContainerBody,
    PopupContainerTitle,
    PopupTitleClose,
    PopupTitleText,
} from '../../../whistleblower/new-case/components/NewCaseContactPopup'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import { AvatarWrapperSmaller } from './InformationBar'
import { AvatarInitials } from '../../../../layout/header/Header'
import initials from '../../../../../helpers/string/initials'
import {
    DividingLine,
    ViewLessIcon,
    ViewMoreDrawer,
    ViewMoreIcon,
    ViewMoreText,
} from './ChangeCategoryModal'
import { FiUsers } from 'react-icons/fi'

export const SmallTitleText = styled.p`
    display: flex;
    justify-content: center;
    font-size: 1.575rem;
`

const SelectedCaseworkerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
`

export const SelectedCaseworkers = styled.div`
    height: 4rem;
    padding: 0 1rem 0 0.5rem;
    display: flex;
    align-items: center;
    border: ${({ theme }) => `1px solid ${theme.greyText}`};
    border-radius: 10rem;
    font-size: 1.4rem;
    gap: 1rem;
`

export const Name = styled.div`
    color: ${({ theme }) => theme.primary};
    font-size: 1.8rem;
`

const LocalSelectedCaseworkerContainer = styled.div`
    position: relative;
    display: flex;
`

const NewFlag = styled.div`
    position: absolute;
    top: 50%;
    right: -1rem;
    transform: translate(100%, -50%);
    text-align: center;
    padding: 0.3rem;
    height: 2rem;
    width: 3.5rem;
    border-radius: 13rem;
    background: ${({ theme }) => `${theme.blue400}20`};
    font-size: 1.2rem;
    font-weight: 900;
    color: ${({ theme }) => theme.blue};
`

const PopupDividingLine = styled(DividingLine)`
    margin-top: 2rem;
`

const AddCaseworkersDrawer = styled(ViewMoreDrawer)`
    margin-top: 2rem;
    text-align: center;
`

const CaseworkersContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
`

const NoCaseworkerIcon = styled(FiUsers)`
    margin: 0 auto;
    color: ${({ theme }) => theme.lineGrey};
    font-size: 3rem;
`

const NoCaseworkerText = styled.div`
    color: ${({ theme }) => theme.lineGrey};
    font-size: 1.2rem;
`

const CaseworkerButton = styled.div`
    height: 4rem;
    padding: 0 1rem 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border: ${({ theme }) => `1px solid ${theme.greyText}`};
    border-radius: 10rem;
    font-size: 1.4rem;
    cursor: pointer;
    transition: all 0.3s;

    :hover {
        background: ${({ theme }) => theme.primary};
    }
`

const CaseworkerButtonName = styled(Name)`
    ${CaseworkerButton}:hover & {
        color: ${({ theme }) => theme.white};
    }
`

export type Caseworker = {
    id: number | null
    first_name: string | null
    last_name: string | null
}

interface CaseworkersPopupProps {
    closeModal: () => void
}

const defaultSelectedCaseworker = {
    id: null,
    first_name: null,
    last_name: null,
}

const CaseworkersPopup: React.FC<CaseworkersPopupProps> = ({ closeModal }) => {
    const translation = useTranslation()

    const { addCaseworkerToExistingCase } = useActions()

    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )
    const { user } = useTypedSelector((state) => state.auth)
    const [localSelectedCaseworker, setLocalSelectedCaseworker] =
        useState<Caseworker>(defaultSelectedCaseworker)
    const [showCaseworkers, setShowCaseworkers] = useState<boolean>(false)

    const selectedCaseworkers = openReport?.caseworkers?.data
    const selectedCaseworkersIDs = selectedCaseworkers.map(
        (selectedCaseworker: Caseworker) => selectedCaseworker.id
    )

    const allCaseworkers = openReport?.organization?.data?.contact_lines?.data
    const otherCaseworkers = allCaseworkers?.filter(
        (allCaseworker: Caseworker) =>
            !selectedCaseworkersIDs?.includes(allCaseworker.id)
    )

    const handleSaveChange = async () => {
        if (user && openReport && localSelectedCaseworker.id) {
            const incidentId = openReport.id
            const userId = user.userId
            const newCaseworkerId = localSelectedCaseworker.id
            await addCaseworkerToExistingCase(
                incidentId,
                userId,
                newCaseworkerId,
                localSelectedCaseworker
            )
            setLocalSelectedCaseworker(defaultSelectedCaseworker)
            setShowCaseworkers(false)
        }
    }

    return (
        <ModalContainer>
            <Modal height="28.4rem" width="48rem">
                <PopupContainer>
                    <PopupContainerTitle>
                        <PopupTitleText>
                            {
                                translation.caseworkerOpenReport.caseworkerPopup
                                    .editCaseworker
                            }
                        </PopupTitleText>
                        <PopupTitleClose onClick={closeModal}>
                            {
                                translation.caseworkerOpenReport.caseworkerPopup
                                    .close
                            }
                        </PopupTitleClose>
                    </PopupContainerTitle>
                    <GreyLine />
                    <PopupContainerBody>
                        <SmallTitleText>
                            {
                                translation.caseworkerOpenReport.caseworkerPopup
                                    .caseworkerInCharge
                            }
                        </SmallTitleText>
                        <SelectedCaseworkerContainer>
                            {selectedCaseworkers.map(
                                (selectedCaseworker: Caseworker) => (
                                    <SelectedCaseworkers>
                                        <AvatarWrapperSmaller>
                                            <AvatarInitials>
                                                {' '}
                                                {initials(
                                                    selectedCaseworker?.first_name +
                                                        ' ' +
                                                        selectedCaseworker?.last_name
                                                )}
                                            </AvatarInitials>
                                        </AvatarWrapperSmaller>
                                        <Name>
                                            {selectedCaseworker?.first_name +
                                                ' ' +
                                                selectedCaseworker?.last_name}
                                        </Name>
                                    </SelectedCaseworkers>
                                )
                            )}
                            {localSelectedCaseworker.id && (
                                <LocalSelectedCaseworkerContainer>
                                    <SelectedCaseworkers>
                                        <AvatarWrapperSmaller>
                                            <AvatarInitials>
                                                {' '}
                                                {initials(
                                                    localSelectedCaseworker?.first_name +
                                                        ' ' +
                                                        localSelectedCaseworker?.last_name
                                                )}
                                            </AvatarInitials>
                                        </AvatarWrapperSmaller>
                                        <Name>
                                            {localSelectedCaseworker?.first_name +
                                                ' ' +
                                                localSelectedCaseworker?.last_name}
                                        </Name>
                                    </SelectedCaseworkers>
                                    <NewFlag>
                                        {
                                            translation.caseworkerOpenReport
                                                .caseworkerPopup.newFlag
                                        }
                                    </NewFlag>
                                </LocalSelectedCaseworkerContainer>
                            )}
                        </SelectedCaseworkerContainer>

                        {/* PopupDividingLine, AddMore+Icon, the caseworkers not assigned to this case, and button are analysed seperately.*/}

                        {/* PopupDividingLine */}
                        {!localSelectedCaseworker.id && <PopupDividingLine />}

                        {/* AddMore+Icon */}
                        {otherCaseworkers.length ? (
                            showCaseworkers ? (
                                !localSelectedCaseworker.id && (
                                    <AddCaseworkersDrawer
                                        onClick={() =>
                                            setShowCaseworkers(false)
                                        }
                                    >
                                        <ViewLessIcon />
                                        <ViewMoreText>
                                            {
                                                translation.caseworkerOpenReport
                                                    .caseworkerPopup.close
                                            }
                                        </ViewMoreText>
                                    </AddCaseworkersDrawer>
                                )
                            ) : (
                                <AddCaseworkersDrawer
                                    onClick={() => setShowCaseworkers(true)}
                                >
                                    <ViewMoreText>
                                        {
                                            translation.caseworkerOpenReport
                                                .caseworkerPopup.addCaseworkers
                                        }
                                    </ViewMoreText>
                                    <ViewMoreIcon />
                                </AddCaseworkersDrawer>
                            )
                        ) : (
                            <CaseworkersContainer>
                                <NoCaseworkerIcon />
                                <NoCaseworkerText>
                                    {
                                        translation.caseworkerOpenReport
                                            .caseworkerPopup
                                            .noCaseworkerAvailable
                                    }
                                </NoCaseworkerText>
                            </CaseworkersContainer>
                        )}

                        {/* the caseworkers not assigned to this case */}
                        {!localSelectedCaseworker.id && showCaseworkers && (
                            <CaseworkersContainer>
                                {otherCaseworkers.map(
                                    (otherCaseworker: Caseworker) => (
                                        <CaseworkerButton
                                            key={otherCaseworker.id}
                                            onClick={() =>
                                                setLocalSelectedCaseworker(
                                                    otherCaseworker
                                                )
                                            }
                                        >
                                            <AvatarWrapperSmaller>
                                                <AvatarInitials>
                                                    {' '}
                                                    {initials(
                                                        otherCaseworker?.first_name +
                                                            ' ' +
                                                            otherCaseworker?.last_name
                                                    )}
                                                </AvatarInitials>
                                            </AvatarWrapperSmaller>
                                            <CaseworkerButtonName>
                                                {otherCaseworker?.first_name +
                                                    ' ' +
                                                    otherCaseworker?.last_name}
                                            </CaseworkerButtonName>
                                        </CaseworkerButton>
                                    )
                                )}
                            </CaseworkersContainer>
                        )}
                    </PopupContainerBody>

                    {/* button */}
                    {localSelectedCaseworker.id && (
                        <PopupButtonContainer>
                            <PopupButton
                                onClick={() =>
                                    setLocalSelectedCaseworker(
                                        defaultSelectedCaseworker
                                    )
                                }
                            >
                                {
                                    translation.caseworkerOpenReport
                                        .caseworkerPopup.discardChanges
                                }
                            </PopupButton>
                            <NextButton
                                handleOnClick={handleSaveChange}
                                text={'Save changes'}
                                icon={false}
                                isActive={!!localSelectedCaseworker}
                            />
                        </PopupButtonContainer>
                    )}
                </PopupContainer>
            </Modal>
        </ModalContainer>
    )
}

export default CaseworkersPopup

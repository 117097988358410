import styled from 'styled-components'
import { ColorInterface, NoData, SmallCard, Title } from '../StatisticsPage'
import SingleStackedBar from './SingleStackedBar'
import { useTranslation, useTypedSelector } from '../../../../../hooks'

const Color = styled.div<ColorInterface>`
    background-color: ${({ color }) => color};
    border-radius: 50%;
    border: none;
    width: 1rem;
    height: 1rem;
    margin: 0.2em 0.5rem 0;
`

const Font14 = styled.p`
    font-size: 1.4rem;
`

const Legend = styled.div`
    display: flex;
    margin: 0 0 1rem;
    justify-content: center;
`

const FieldWrap = styled.div`
    display: flex;
    margin: 0 1rem;
`

const AnonymousConfidential = () => {
    const translation = useTranslation()
    const { statisticsOverview } = useTypedSelector(
        (state) => state.statisticsOverview
    )

    return (
        <SmallCard justifyContent="space-between">
            <Title>{translation.statisticsPage.anonymousVsConfidential}</Title>
            {statisticsOverview?.totalCases.all ? (
                <>
                    <SingleStackedBar
                        value1={statisticsOverview.caseTypeSplit.anonymous}
                        value2={statisticsOverview.caseTypeSplit.confidential}
                    />
                    <Legend>
                        <FieldWrap>
                            <Color color="#B0C1C7" />
                            <Font14>{translation.reusable.anonymous}</Font14>
                        </FieldWrap>
                        <FieldWrap>
                            <Color color="#849BB5" />
                            <Font14>
                                {translation.newCaseFlow.confidential}
                            </Font14>
                        </FieldWrap>
                    </Legend>
                </>
            ) : (
                <NoData>{translation.statisticsPage.noData}</NoData>
            )}
        </SmallCard>
    )
}

export default AnonymousConfidential

import axios from 'axios'
import config from '../../config'
import { Dispatch } from 'redux'
import { HrOnIntegrationActionType, IdentityActionType } from '../action-types'
import { UpdateUserIdentityInterface } from '../../ts/interfaces/UserIdentityInterface'
import { ChangePasswordInterface } from '../../ts/interfaces/ChangePasswordInterface'
import { IntegrationInterface } from '../reducers/hrOnIntegrationReducer'
import { getWhitelabel } from './whitelabels'

const { apiUrl } = config

export const getIdentity = () => async (dispatch: Dispatch) => {
    try {
        const res = await axios.get(
            `${apiUrl}/v1/auth/identity?with=integration`
        )

        if (res.status !== 200) {
            return false
        }

        dispatch({
            type: IdentityActionType.GET_IDENTITY,
            payload: res.data.data,
        })

        dispatch({
            type: IdentityActionType.UPDATE_SELECTED_LANGUAGE,
            payload: res.data.data.selected_language.data.code,
        })

        if (res.data.data.integration) {
            dispatch({
                type: HrOnIntegrationActionType.GET_INTEGRATION_DATA,
                payload: res.data.data.integration.data as IntegrationInterface,
            })
        }

        if (res.data.data.contact_information?.whitelabel?.logo) {
            const { logo, hex_code }: { logo: string; hex_code: string } =
                res.data.data.contact_information.whitelabel

            const payload = {
                logo,
                hexCode: hex_code,
            }

            dispatch(getWhitelabel(payload))
        }

        return true
    } catch (error: any) {}
}

export const getIdentityThroughIntegration =
    (integrationId: number) => async (dispatch: Dispatch) => {
        try {
            const res = await axios.get(
                `${apiUrl}/v1/integrations/${integrationId}/users`
            )

            if (res.status !== 200) {
                return false
            }

            dispatch({
                type: IdentityActionType.GET_IDENTITY_THROUGH_INTEGRATION,
                payload: res.data.data,
            })

            return true
        } catch (error: any) {}
    }

export const updateIdentity =
    (userId: number, payload: UpdateUserIdentityInterface) =>
    async (dispatch: Dispatch) => {
        dispatch(identityLoading())
        try {
            const res = await axios.post(
                `${apiUrl}/v1/users/${userId}`,
                payload
            )

            if (res.status !== 200) {
                dispatch(
                    setUpdateIdentityError(
                        'Something went wrong, please try again later.'
                    )
                )

                return {
                    success: false,
                    shouldLogout: false,
                }
            }

            if (res.data.message) {
                return {
                    success: true,
                    shouldLogout: false,
                }
            }

            if (!res.data.data.email_verified_at) {
                return {
                    success: true,
                    shouldLogout: true,
                }
            }
            dispatch({
                type: IdentityActionType.UPDATE_IDENTITY,
                payload: res.data.data,
            })

            dispatch({
                type: IdentityActionType.UPDATE_SELECTED_LANGUAGE,
                payload: res.data.data.selected_language.data.code,
            })

            //@ts-ignore
            dispatch(clearIdentityFlashMessage('saveChangesMessage'))

            return {
                success: true,
                shouldLogout: false,
            }
        } catch (err: any) {
            dispatch(
                setUpdateIdentityError(
                    'Something went wrong, please try again later.'
                )
            )

            return {
                success: false,
                shouldLogout: false,
            }
        }
    }

export const identityLoading = () => {
    return {
        type: IdentityActionType.IDENTITY_LOADING,
    }
}

export const setUpdateIdentityError = (errorMessage: string) => ({
    type: IdentityActionType.UPDATE_IDENTITY_ERROR,
    payload: {
        message: errorMessage,
    },
})

export const clearUpdateIdentityError = () => ({
    type: IdentityActionType.CLEAR_UPDATE_IDENTITY_ERROR,
})

export const clearIdentityFlashMessage =
    (message: string) => (dispatch: any) => {
        return setTimeout(() => {
            dispatch({
                type: IdentityActionType.CLEAR_IDENTITY_FLASH_MESSAGE,
                payload: {
                    message,
                },
            })
        }, 5000)
    }

export const updatePassword =
    (userId: number, payload: ChangePasswordInterface) =>
    async (dispatch: Dispatch) => {
        dispatch(identityLoading())
        try {
            const res = await axios.patch(
                `${apiUrl}/v1/users/${userId}/password`,
                payload
            )
            if (res.status !== 200) {
                dispatch({
                    type: IdentityActionType.UPDATE_PASSWORD_ERROR,
                    payload: res.data.message,
                })

                return false
            }

            dispatch({
                type: IdentityActionType.UPDATE_PASSWORD,
                payload: res.data,
            })

            if (res.status === 200) {
                dispatch({
                    type: IdentityActionType.UPDATE_PASSWORD_SUCCESS,
                    payload: res.data.message,
                })
            }

            return true
        } catch (err: any) {
            dispatch({
                type: IdentityActionType.UPDATE_PASSWORD_ERROR,
                payload: err.response.data.message,
            })

            return false
        }
    }

export const PasswordUpdateSuccess = () => {
    return {
        type: IdentityActionType.UPDATE_PASSWORD_SUCCESS,
        payload: false,
    }
}

export const newEmailVerification =
    (verification_token: string) => async (dispatch: Dispatch) => {
        dispatch(identityLoading())
        try {
            const res = await axios.post(
                `${apiUrl}/v1/email/verify-changed/${verification_token}`
            )

            if (res.status === 204) {
                dispatch({
                    type: IdentityActionType.SET_VERIFY_EMAIL_SUCCESS,
                })
            } else {
                dispatch(
                    setVerifyEmailError(
                        'Something went wrong, please try again.'
                    )
                )
            }
        } catch (err: any) {
            dispatch(
                setVerifyEmailError('Something went wrong, please try again.')
            )
        }
    }

export const setVerifyEmailError = (errorMessage: string) => ({
    type: IdentityActionType.SET_VERIFY_EMAIL_ERROR,
    payload: {
        message: errorMessage,
    },
})

export const deleteProfileImage =
    (userId: any) => async (dispatch: Dispatch) => {
        dispatch({
            type: IdentityActionType.CLEAR_UPDATE_IDENTITY_ERROR,
        })

        try {
            const res = await axios.delete(
                `${apiUrl}/v1/users/${userId}/profile-image`
            )

            if (res.status !== 204) {
                dispatch(
                    setUpdateIdentityError(
                        'Something went wrong, please try again later.'
                    )
                )
            }
        } catch (error) {
            dispatch(
                setUpdateIdentityError(
                    'Something went wrong, please try again later.'
                )
            )
        }
    }

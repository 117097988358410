export const isValidNotes = (note: string) => {
    if (note.length === 1 || note.length > 400) {
        return false
    } else {
        return true
    }
}

export const checkNotes = (note: string) => {
    if (note.length === 1) {
        return 'The note cannot be shorter than 1 character'
    } else if (note.length > 400) {
        return 'The note cannot be longer than 400 characters'
    } else {
        return ''
    }
}

import { useState } from 'react'

import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import Spinner from '../../../layout/spinner/Spinner'
import { NextButton } from '../../../reusable/buttons'
import {
    FormInput50,
    InputFieldIcon,
    PrimaryLabel,
} from '../../../styled/formElements'
import { HeadingPrimary } from '../../../styled/typography'
import EmailVerifiedModal from './modals/EmailVerifiedModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import PasswordValidationChecklist from '../../../PasswordValidationChecklist'

const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

const Container = styled.div`
    width: 35rem;
    margin: 0 auto;
    text-align: left;
    padding-top: 22rem;
`
const Form = styled.form`
    padding-top: 5rem;
    width: 100%;
`
const FormField = styled.div`
    width: 100%;
    padding-bottom: 2.8rem;
    position: relative;
`
const NextButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 4rem;
`
const ErrorMessage = styled.p`
    color: red;
    position: absolute;
`
const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 8rem;
`

const VerifyEmailPage: React.FC = () => {
    const { verifyEmail } = useActions()
    const { authLoading, authError, successResponse } = useTypedSelector(
        (state) => state.auth
    )
    const translation = useTranslation()
    const { requestNumber, expires, hash, signature } = useParams<{
        requestNumber: string
        expires: string
        hash: string
        signature: string
    }>()
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const [showPassword, setShowPassword] = useState(true)
    const [showConfirmPassword, setShowConfirmPassword] = useState(true)

    const [isFormValid, setIsFormValid] = useState(false)

    const handleFormValidation = (isValid: boolean) => {
        setIsFormValid(isValid)
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!isFormValid) return

        await verifyEmail(
            requestNumber,
            expires,
            hash,
            signature,
            password,
            passwordConfirm
        )
    }
    const handleShowPasswordCheckboxChanged = () => {
        setShowPassword(!showPassword)
    }
    const handleShowConfirmPasswordCheckboxChanged = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }
    return (
        <>
            {successResponse && <EmailVerifiedModal />}
            <Container>
                <HeadingPrimary>
                    {translation.verifyEmail.verifyEmail}
                </HeadingPrimary>
                {!authLoading ? (
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <FormField>
                            <PrimaryLabel htmlFor="password">
                                {translation.reusable.password}
                            </PrimaryLabel>
                            <FormInput50
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <InputFieldIcon
                                onClick={handleShowPasswordCheckboxChanged}
                            >
                                {!showPassword ? eye : eyeSlash}
                            </InputFieldIcon>
                        </FormField>
                        <FormField>
                            <PrimaryLabel htmlFor="password2">
                                {translation.reusable.confirmPassword}
                            </PrimaryLabel>
                            <FormInput50
                                id="password2"
                                name="password2"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={passwordConfirm}
                                onChange={(e) =>
                                    setPasswordConfirm(e.target.value)
                                }
                            />
                            <InputFieldIcon
                                onClick={
                                    handleShowConfirmPasswordCheckboxChanged
                                }
                            >
                                {!showConfirmPassword ? eye : eyeSlash}
                            </InputFieldIcon>
                            {authError && (
                                <ErrorMessage>{authError}</ErrorMessage>
                            )}
                        </FormField>
                        <PasswordValidationChecklist
                            password={password}
                            passwordConfirm={passwordConfirm}
                            isFormValidated={handleFormValidation}
                        />
                        <NextButtonContainer>
                            <NextButton
                                text={translation.verifyEmail.verifyEmail}
                                isActive={isFormValid}
                                width="133px"
                                icon={false}
                            />
                        </NextButtonContainer>
                    </Form>
                ) : (
                    <SpinnerContainer>
                        <Spinner />
                    </SpinnerContainer>
                )}
            </Container>
        </>
    )
}
export default VerifyEmailPage

import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Card, CardHeader, CardBody } from '../../../../../styled/cards'
import {
    FormField,
    PrimaryLabel,
    FormInput40,
    FormTextarea,
} from '../../../../../styled/formElements'
import SpokenLanguageOption from '../../../../../reusable/spoken-language/SpokenLanguageOption'
import { useActions, useTypedSelector } from '../../../../../../hooks'
import useCheckValidation from '../../../../../reusable/functions/useCheckValidation'
import { useSaveIdentity } from '../../../../../reusable/functions/useSaveIdentity'
import {
    Identity,
    PersonalInfoTypes,
} from '../../../../../../state/state-types/IdentityTypes'
import ButtonSpinnerWhite from '../../../../../layout/spinner/ButtonSpinnerWhite'
import {
    FeedbackMessage,
    StyledCheckIcon,
    StyledCancelIcon,
} from '../../../../../styled/formElements'
import { EditSection } from '../MyAccount'

const StyledCardBody = styled(CardBody)`
    margin: 2.7rem 8.6rem 2.7rem 8.6rem;
    overflow: hidden;
`

const ErrorMessage = styled.p`
    color: ${({ theme }) => theme.red};
`

const HeaderButtonFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const CancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}60`};
    cursor: pointer;
    padding: 1.4rem;
`

const BlackButton = styled.div<BlackButtonProps>`
    height: 2.7rem;
    padding: 0 1.6rem;
    display: flex;
    align-items: center;
    background: ${({ theme, isActive }) =>
        !isActive ? `${theme.black}50` : theme.black};
    color: ${({ theme }) => theme.white};
    font-size: 1.4rem;
    border-radius: 0.5rem;
    pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};
    cursor: pointer;
`

const StyledFormFieldPosition = styled(FormField)`
    padding: 0;
    margin-bottom: 2.7rem;
`

const StyledFormFieldNote = styled(FormField)`
    margin-top: 2.7rem;
`

interface BlackButtonProps {
    isActive: boolean
}

interface CaseworkerInputDisplayProps {
    setEditSection: React.Dispatch<React.SetStateAction<EditSection>>
    editSection: EditSection
}

const CaseworkerInputDisplay: React.FC<CaseworkerInputDisplayProps> = ({
    setEditSection,
    editSection,
}) => {
    const { getIdentity, identityLoading, identityErrors } = useTypedSelector(
        (state) => state.identity
    )
    const { user } = useTypedSelector((state) => state.auth)
    const { deleteSpokenLanguage } = useActions()

    const [personalInfo, setPersonalInfo] =
        useState<PersonalInfoTypes>(getIdentity)
    const [focused, setFocused] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [canBeSave, setCanBeSave] = useState(false)

    const [languagesToBeDeleted, setLanguagesToBeDeleted] = useState<number[]>(
        []
    )

    const { updateIdentityStatus, saveIdentity, comparePersonalInfo } =
        useSaveIdentity()

    const {
        isFormValidated,
        positionError,
        setPositionError,
        isCaseworkerInputDisplayFromValidated,
    } = useCheckValidation()

    useEffect(() => {
        setPersonalInfo(getIdentity)
    }, [getIdentity])

    useEffect(() => {
        if (updateIdentityStatus.success === true) {
            setShowMessage(true)
            setCanBeSave(false)
        } else if (updateIdentityStatus.success === false) {
            setShowMessage(true)
            setCanBeSave(true)
        }
    }, [updateIdentityStatus])

    useEffect(() => {
        const { hasChanges } = comparePersonalInfo(personalInfo, getIdentity)
        setCanBeSave(hasChanges)
        // eslint-disable-next-line
    }, [personalInfo])

    useEffect(() => {
        let timeoutId: NodeJS.Timeout

        if (showMessage) {
            timeoutId = setTimeout(() => {
                setShowMessage(false)
            }, 5000)
        }
        return () => clearTimeout(timeoutId)
    }, [showMessage])

    useEffect(() => {
        if (languagesToBeDeleted.length > 0) {
            setCanBeSave(true)
        }
    }, [languagesToBeDeleted])

    const handlerUpdatePersonalInfo = <Key extends keyof Identity>(
        field: Key,
        value: Identity[Key]
    ) => {
        setPersonalInfo({ ...personalInfo, [field]: value })
    }

    const handlerSaveIdentity = () => {
        const { hasChanges } = comparePersonalInfo(personalInfo, getIdentity)

        if (user && hasChanges) {
            saveIdentity(user, personalInfo, getIdentity)
        }

        Promise.all(
            languagesToBeDeleted.map((language) =>
                deleteSpokenLanguage(language)
            )
        )

        setEditSection('caseworker')
    }

    const handlerCancelUpdateIdentity = () => {
        setPersonalInfo(getIdentity)
        setPositionError('')
        setLanguagesToBeDeleted([])
    }

    return (
        personalInfo && (
            <Card style={{ height: 'fit-content' }}>
                <CardHeader>
                    Information displayed to the whistleblowers
                    {user && (
                        <HeaderButtonFlex>
                            <CancelButton onClick={handlerCancelUpdateIdentity}>
                                Cancel
                            </CancelButton>
                            <BlackButton
                                onClick={handlerSaveIdentity}
                                isActive={
                                    canBeSave &&
                                    isCaseworkerInputDisplayFromValidated
                                }
                            >
                                {identityLoading &&
                                editSection === 'caseworker' ? (
                                    <ButtonSpinnerWhite />
                                ) : (
                                    'Save'
                                )}
                            </BlackButton>
                        </HeaderButtonFlex>
                    )}
                </CardHeader>

                <StyledCardBody>
                    <StyledFormFieldPosition>
                        <PrimaryLabel>Position</PrimaryLabel>
                        <FormInput40
                            value={personalInfo.contact_information.position}
                            onChange={(event) => {
                                handlerUpdatePersonalInfo(
                                    'contact_information',
                                    {
                                        ...personalInfo.contact_information,
                                        position: event.target.value,
                                    }
                                )
                                isFormValidated('position', event.target.value)
                            }}
                            onBlur={() => {
                                setFocused(false)
                            }}
                            onFocus={() => setFocused(true)}
                        />
                        <ErrorMessage>{!focused && positionError}</ErrorMessage>
                    </StyledFormFieldPosition>

                    <SpokenLanguageOption
                        personalInfo={personalInfo}
                        handlerUpdatePersonalInfo={handlerUpdatePersonalInfo}
                        onDeleteLanguage={(languageId) =>
                            setLanguagesToBeDeleted([
                                ...languagesToBeDeleted,
                                languageId,
                            ])
                        }
                        languagesToBeDeleted={languagesToBeDeleted}
                    />

                    <StyledFormFieldNote>
                        <PrimaryLabel>Notes</PrimaryLabel>
                        <FormTextarea
                            id="note"
                            name="note"
                            maxLength={400}
                            placeholder="Type a note here ..."
                            value={personalInfo.contact_information.note || ''}
                            rows={6}
                            onChange={(event) =>
                                handlerUpdatePersonalInfo(
                                    'contact_information',
                                    {
                                        ...personalInfo.contact_information,
                                        note: event.target.value,
                                    }
                                )
                            }
                        />
                    </StyledFormFieldNote>
                </StyledCardBody>

                {showMessage && (
                    <FeedbackMessage
                        isSuccess={identityErrors}
                        showMessage={showMessage}
                    >
                        {identityErrors ? (
                            <StyledCancelIcon />
                        ) : (
                            <StyledCheckIcon />
                        )}
                        <p>
                            {identityErrors
                                ? 'Something went wrong. Please try again.'
                                : 'Your changes have been saved successfully'}
                        </p>
                    </FeedbackMessage>
                )}
            </Card>
        )
    )
}

export default CaseworkerInputDisplay

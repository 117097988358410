import produce from 'immer'
import { InviteCaseworkerAction } from '../actions'
import { InviteCaseworkerActionType } from '../action-types'
import { FlashMessageInterface } from './lineOfContactReducer'

interface InviteCaseworkerState {
    inviteCaseworkerLoading: boolean
    inviteCaseworkerError: null | string
    inviteCaseworkerSuccess: boolean
    inviteFlashMessage: null | FlashMessageInterface
}

const initialState: InviteCaseworkerState = {
    inviteCaseworkerLoading: false,
    inviteCaseworkerError: null,
    inviteCaseworkerSuccess: false,
    inviteFlashMessage: null,
}

const reducer = produce(
    (
        state: InviteCaseworkerState = initialState,
        action: InviteCaseworkerAction
    ) => {
        switch (action.type) {
            case InviteCaseworkerActionType.INVITE_CASEWORKER_LOADING:
                state.inviteCaseworkerLoading = true
                state.inviteCaseworkerError = null
                state.inviteCaseworkerSuccess = false
                state.inviteFlashMessage = null

                return state

            case InviteCaseworkerActionType.INVITE_CASEWORKER_ERROR:
                state.inviteCaseworkerLoading = false
                state.inviteCaseworkerError = action.payload
                state.inviteCaseworkerSuccess = false
                state.inviteFlashMessage = null

                return state

            case InviteCaseworkerActionType.INVITE_CASEWORKER_SUCCESS:
                state.inviteCaseworkerLoading = false
                state.inviteCaseworkerError = null
                state.inviteCaseworkerSuccess = true
                state.inviteFlashMessage = null

                return state

            case InviteCaseworkerActionType.INVITE_CASEWORKER_FLASH_MESSAGE:
                state.inviteCaseworkerLoading = false
                state.inviteCaseworkerError = null
                state.inviteCaseworkerSuccess = false
                state.inviteFlashMessage = action.payload
                return state

            case InviteCaseworkerActionType.INVITE_CASEWORKER_CLEAR_FLASH_MESSAGE:
                state.inviteCaseworkerLoading = false
                state.inviteCaseworkerError = null
                state.inviteCaseworkerSuccess = false
                state.inviteFlashMessage = null
                return state

            default:
                return state
        }
    }
)

export default reducer

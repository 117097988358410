import axios from 'axios'
import config from '../../config'
import { Dispatch } from 'redux'
import { AutoReplyActionType } from '../action-types'

const { apiUrl } = config

interface CreateAutoReplyPayloadInterface {
    reply: string
}

export const createAutoReply =
    (payload: CreateAutoReplyPayloadInterface) =>
    async (dispatch: Dispatch) => {
        dispatch(autoReplyLoading())

        try {
            const res = await axios.post(
                `${apiUrl}/v1/automatic-replies/`,
                payload
            )

            if (res.status !== 200) {
                dispatch(
                    autoReplyErrorMessage(
                        'Something went wrong. Please try again later.'
                    )
                )

                //@ts-ignore
                dispatch(clearAutoReplyErrorMessage())
                return
            }

            dispatch({
                type: AutoReplyActionType.CREATE_AUTO_REPLY,
                payload: res.data.data,
            })
        } catch (error: any) {
            dispatch(
                autoReplyErrorMessage(
                    'Something went wrong. Please try again later.'
                )
            )
            //@ts-ignore
            dispatch(clearAutoReplyErrorMessage())
        }
    }

export const getAutoReply = () => async (dispatch: Dispatch) => {
    dispatch(autoReplyLoading())
    try {
        const res = await axios.get(`${apiUrl}/v1/automatic-replies/`)

        if (res.status !== 200) {
            dispatch(
                autoReplyErrorMessage(
                    'Something went wrong. Please try again later.'
                )
            )

            //@ts-ignore
            dispatch(clearAutoReplyErrorMessage())
            return
        }

        dispatch({
            type: AutoReplyActionType.GET_AUTO_REPLY,
            payload: res.data.data,
        })
    } catch (error: any) {
        dispatch(
            autoReplyErrorMessage(
                'Something went wrong. Please try again later.'
            )
        )
        //@ts-ignore
        dispatch(clearAutoReplyErrorMessage())
    }
}

interface UpdateAutoReplyPayloadInterface {
    isActive: boolean
}

export const updateAutoReply =
    (id: number, payload: UpdateAutoReplyPayloadInterface) =>
    async (dispatch: Dispatch) => {
        dispatch(autoReplyLoading())
        try {
            const res = await axios.put(
                `${apiUrl}/v1/automatic-replies/${id}`,
                payload
            )

            if (res.status !== 200) {
                dispatch(
                    autoReplyErrorMessage(
                        'Something went wrong. Please try again later.'
                    )
                )
                //@ts-ignore
                dispatch(clearAutoReplyErrorMessage())
                return
            }

            dispatch({
                type: AutoReplyActionType.UPDATE_AUTO_REPLY,
                payload: res.data.data,
            })
        } catch (error: any) {
            dispatch(
                autoReplyErrorMessage(
                    'Something went wrong. Please try again later.'
                )
            )
            //@ts-ignore
            dispatch(clearAutoReplyErrorMessage())
        }
    }

export const autoReplyLoading = () => ({
    type: AutoReplyActionType.AUTO_REPLY_LOADING,
})

export const autoReplyErrorMessage = (message: string) => ({
    type: AutoReplyActionType.AUTO_REPLY_ERROR,
    payload: { message },
})

export const clearAutoReplyErrorMessage = () => (dispatch: any) => {
    return setTimeout(() => {
        dispatch({
            type: AutoReplyActionType.CLEAR_AUTO_REPLY_ERROR_MESSAGE,
        })
    }, 5000)
}

import produce from 'immer'
import { SpokenLanguagesActionType } from '../action-types'
import { SpokenLanguagesAction } from '../actions'

export interface SpokenLanguagesInterface {
    id: number
    name: string
    flag: string
}

interface SpokenLanguagesState {
    spokenLanguageLoading: boolean
    spokenLanguages: SpokenLanguagesInterface[]
    deleteSpokenLanguages: SpokenLanguagesInterface[]
}

const initialState: SpokenLanguagesState = {
    spokenLanguageLoading: false,
    spokenLanguages: [],
    deleteSpokenLanguages: [],
}

const reducer = produce(
    (
        state: SpokenLanguagesState = initialState,
        action: SpokenLanguagesAction
    ) => {
        switch (action.type) {
            case SpokenLanguagesActionType.SPOKEN_LANGUAGE_LOADING:
                state.spokenLanguageLoading = true

                return state

            case SpokenLanguagesActionType.GET_SPOKEN_LANGUAGES:
                state.spokenLanguages = action.payload.data

                return state

            case SpokenLanguagesActionType.DELETE_SPOKEN_LANGUAGE:
                state.spokenLanguageLoading = false
                state.spokenLanguages = state.spokenLanguages.filter(
                    (language) => language.id !== action.payload
                )

                return state
            default:
                return state
        }
    }
)

export default reducer

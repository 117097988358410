import styled from 'styled-components'
import { ReactComponent as LinkIcon } from '../../assets/images/link-icon.svg'
import { ReactComponent as QrCodeIcon } from '../../assets/images/qr-code-icon.svg'
import { FiCheckCircle } from 'react-icons/fi'
import { useTranslation } from '../../hooks'

const LinkContainer = styled.div`
    height: 12.7rem;
    /* width: 100%; */
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 20px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-block: 1.8rem;
    padding: 1.4rem;

    transition: all 0.3s;

    &:hover {
        background-color: ${({ theme }) => theme.blue900};
    }

    &:hover .link-hover {
        background-color: ${({ theme }) => theme.blue400};
    }

    // The .icon-hover class was given to the svg file, so the color would change on hover.
    &:hover .icon-hover {
        stroke: currentColor;
        -webkit-text-stroke-color: ${({ theme }) => theme.white};
    }

    &:hover .link-title-hover {
        color: ${({ theme }) => theme.white};
    }
`

const CardContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const LinkTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 22px;
    /* margin-bottom: 0.5rem; */
    letter-spacing: -0.02rem;
    color: ${({ theme }) => theme.black};
    transition: all 0.3s;
`
const IconBackground = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.grey200};
    width: 35px;
    height: 35px;
    border-radius: 50%;
`

const CopyLink = styled(LinkIcon)`
    width: 2rem;
`

const CopyQRCode = styled(QrCodeIcon)`
    width: 2rem;
`

const LogoPlaceholderText = styled.span`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    word-wrap: break-word;
    display: block;
    word-break: break-all;
`
const CheckIcon = styled(FiCheckCircle)`
    color: ${({ theme }) => theme.green};
    font-size: 1.4rem;
`
const LinkCopied = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    gap: 0.5rem;
`

interface ReportingChannelLinkCardsProps {
    handleClick: () => void
    title: string
    tagline: string
    useQrCode?: boolean
    showWhistleblowerLink?: boolean
    showPolicyLink?: boolean
    showTrainingMaterial?: boolean
}

const ReportingChannelLinkCards: React.FC<ReportingChannelLinkCardsProps> = ({
    handleClick,
    title,
    tagline,
    useQrCode,
    showWhistleblowerLink,
    showPolicyLink,
    showTrainingMaterial,
}) => {
    const translation = useTranslation()
    const showCardContent =
        showWhistleblowerLink ||
        showPolicyLink ||
        useQrCode ||
        showTrainingMaterial

    return (
        <LinkContainer onClick={handleClick}>
            {showCardContent ? (
                <CardContentWrapper>
                    <LinkTitle className="link-title-hover">
                        {title}
                        <LogoPlaceholderText className="link-title-hover">
                            {tagline}
                        </LogoPlaceholderText>
                    </LinkTitle>

                    <IconBackground className="link-hover">
                        {useQrCode ? (
                            <CopyQRCode className="link-title-hover" />
                        ) : (
                            <CopyLink className="link-title-hover" />
                        )}
                    </IconBackground>
                </CardContentWrapper>
            ) : (
                <LinkCopied className="link-title-hover">
                    <CheckIcon />
                    {translation.copiedText.copied}
                </LinkCopied>
            )}
        </LinkContainer>
    )
}

export default ReportingChannelLinkCards

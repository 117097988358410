import SkeletonCard from '../../reusable/skeletons/SkeletonCard'
import {
    BigCard,
    CasesChartCard,
    CasesChartSection,
    FlexColumn,
    Section,
    SmallCard,
} from '../partners/statistics/StatisticsPage'

const LoadingStatisticsPage = () => {
    return (
        <>
            <Section>
                <FlexColumn>
                    <SmallCard padding="0">
                        <SkeletonCard />
                    </SmallCard>
                    <SmallCard padding="0">
                        <SkeletonCard />
                    </SmallCard>
                </FlexColumn>
                <BigCard padding="0">
                    <SkeletonCard />
                </BigCard>
                <BigCard padding="0">
                    <SkeletonCard />
                </BigCard>
            </Section>
            <CasesChartSection>
                <CasesChartCard>
                    <SkeletonCard />
                </CasesChartCard>
            </CasesChartSection>
        </>
    )
}

export default LoadingStatisticsPage

import { formatDateWithTime } from '../../helpers/date'
import capitilize from '../../helpers/string/capitilize'
import Translation from '../../state/state-types/TranslationStateType'

const historyLogTransformer = (logs: any, translation: Translation) => {
    return logs.map((log: any) => {
        if (log.label === 'forwarded') {
            return {
                id: log.id,
                title: translation.historyLog.forwarded,
                description: `${translation.historyLog.caseHasBeenForwarded} ${translation.historyLog.by} ${log.attributes.by} ${translation.historyLog.to} ${log.attributes.to}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'exported') {
            return {
                id: log.id,
                title: translation.historyLog.exported,
                description: `${translation.historyLog.caseHasBeenExported} ${translation.historyLog.by} ${log.attributes.by}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'responsible_changed') {
            return {
                id: log.id,
                title: translation.historyLog.changeResponsible,
                description: `${translation.historyLog.caseHasBeenReassigned} 
                              ${translation.historyLog.to} ${log.attributes.to} 
                              ${translation.historyLog.from} ${log.attributes.from}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'case_viewed') {
            return {
                id: log.id,
                title: translation.historyLog.caseViewed,
                description: `${translation.historyLog.caseHasBeenViewed} ${translation.historyLog.by} ${log.info.by}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (
            log.label === 'category_updated' &&
            log.attributes.from !== null
        ) {
            return {
                id: log.id,
                title: translation.historyLog.categoryIsChanged,
                description: `${translation.historyLog.categoryIsChanged} ${translation.historyLog.to} ${log.attributes.to} ${translation.historyLog.from} ${log.attributes.from} ${translation.historyLog.by} ${log.attributes.by}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (
            log.label === 'category_updated' &&
            log.attributes.from === null
        ) {
            return {
                id: log.id,
                title: translation.historyLog.categoryIsChanged,
                description: `${translation.historyLog.categoryIsChanged} ${translation.historyLog.to} ${log.attributes.to} ${translation.historyLog.from} ${translation.forwardReportPage.uncategorized} ${translation.historyLog.by} ${log.attributes.by}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'file_uploaded') {
            return {
                id: log.id,
                title: translation.historyLog.fileUploaded,
                description: `${translation.historyLog.fileUploaded} ${translation.historyLog.by} ${log.info.by}`,
                createdAt: formatDateWithTime(
                    formatDateWithTime(log.created_at ?? log.createdAt)
                ),
            }
        } else if (
            log.label === 'reporter_reply' &&
            log.attributes.reporter.length > 2
        ) {
            return {
                id: log.id,
                title: translation.historyLog.newMessage,
                description: `${translation.historyLog.messageFrom} ${log.attributes.reporter}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (
            log.label === 'reporter_reply' &&
            log.attributes.reporter.length < 2
        ) {
            return {
                id: log.id,
                title: translation.historyLog.newMessage,
                description: `${translation.historyLog.messageFrom} ${translation.reusable.anonymous}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'status_updated') {
            let title
            let description

            if (log.attributes.from === 'new') {
                title = translation.historyLog.caseViewed
                description =
                    translation.historyLog.caseHasBeenViewed +
                    ` ${translation.historyLog.by} ${log.attributes.by}`
            } else {
                title = translation.historyLog.statusUpdated
                description = `${capitilize(log.attributes.from)} ${
                    translation.historyLog.to
                } ${capitilize(log.attributes.to)}`
            }

            return {
                id: log.id,
                title: title,
                description: description,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'caseworker_assigned') {
            return {
                id: log.id,
                title: translation.historyLog.caseDelegation,
                description: `${translation.historyLog.caseAssigned} ${translation.historyLog.to} ${log.attributes.to}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log && log.label === 'internal_note') {
            return {
                id: log.id,
                title: translation.historyLog.internalNote,
                description: `${translation.historyLog.internalNoteHasBeen} ${log.attributes.by}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'caseworker_added') {
            return {
                id: log.id,
                title: translation.historyLog.caseworkerAdded,
                description: `${log.attributes.caseworker} ${translation.historyLog.wasAddedBy} ${log.attributes.by}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'caseworker_new_file') {
            return {
                id: log.id,
                title: translation.historyLog.newFile,
                description: `${translation.historyLog.newFileWasAdded} ${log.attributes.by}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'reporter_new_file') {
            return {
                id: log.id,
                title: translation.historyLog.newFile,
                description: `${translation.historyLog.newFileWasAdded} ${translation.historyLog.whistleblower}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'automatic_reply_sent') {
            return {
                id: log.id,
                title: translation.historyLog.automaticReplySent,
                description: `${translation.historyLog.automaticReplyWasSent} ${log.attributes.by}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'created') {
            return {
                id: log.id,
                title: translation.historyLog.reportCreated,
                description: `${translation.historyLog.newCase} ${translation.historyLog.from} ${log.attributes.reporter}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'caseworker_reply') {
            return {
                id: log.id,
                title: translation.historyLog.newMessage,
                description: `${translation.historyLog.messageFrom} ${log.attributes.by}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'closed') {
            return {
                id: log.id,
                title: translation.historyLog.caseClosed,
                description: `${translation.historyLog.caseClosedBy} ${log.attributes.by}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else if (log.label === 'reopened') {
            return {
                id: log.id,
                title: translation.historyLog.caseReopened,
                description: `${translation.historyLog.caseReopenedBy} ${log.attributes.by}`,
                createdAt: formatDateWithTime(log.created_at ?? log.createdAt),
            }
        } else {
            return false
        }
    })
}

export default historyLogTransformer

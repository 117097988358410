import React, { useEffect, useState } from 'react'
import { Card, CardHeader } from '../../../../styled/cards'
import styled from 'styled-components'
import { Editor } from '@tinymce/tinymce-react'
import config from '../../../../../config'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import { FiAlertCircle } from 'react-icons/fi'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'
import SkeletonCard from '../../../../reusable/skeletons/SkeletonCard'
import { BaseMessageComponent } from './MyAccount'

interface IsActiveProp {
    isActive: boolean | undefined
}

const AutoReplyErrorMessage = styled(BaseMessageComponent)`
    background-color: ${({ theme }) => `${theme.red}50`};
    color: ${({ theme }) => theme.red};
    position: absolute;
    top: 5%;
    left: 50%;
`

const TitleAndTag = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
`

const AutoReplyTag = styled.div<IsActiveProp>`
    background: ${({ theme, isActive }) =>
        isActive ? `${theme.blue}50` : `${theme.primary}10`};
    color: ${({ theme, isActive }) =>
        isActive ? theme.blue : `${theme.primary}50`};
    padding-inline: 1rem;
    padding-block: 0.5rem;
    border-radius: 5rem;
`

const SwitchControlContainer = styled.div`
    display: flex;
    gap: 1rem;
`

const AutoReplyAlertMessage = styled.div`
    display: flex;
    align-items: center;
    gap: 0.3rem;
    /* margin-inline-start: 8rem; */
`

const AlertIcon = styled(FiAlertCircle)`
    font-size: 2rem;
`

const Label = styled.label`
    height: 3rem;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    cursor: pointer;
`

const Switch = styled.div<IsActiveProp>`
    position: relative;
    width: 5rem;
    height: 2.8rem;
    border: 1px solid
        ${({ theme, isActive }) =>
            isActive ? `${theme.blue}` : `${theme.grey300}`};
    background: ${({ theme, isActive }) =>
        isActive ? `${theme.blue}` : `${theme.grey300}`};
    border-radius: 3.2rem;
    padding: 1rem;
    transition: 300ms all;
    &:before {
        transition: 300ms all;
        content: '';
        position: absolute;
        width: 2.3rem;
        height: 2.3rem;
        border-radius: 50%;
        top: 50%;
        left: 0.1rem;
        background: ${({ theme }) => theme.white};
        transform: translate(0, -50%);
    }
`

const Input = styled.input`
    opacity: 0;
    position: absolute;
    &:checked + ${Switch} {
        &:before {
            transform: translate(2.3rem, -50%);
        }
    }
`

const SaveAutoReplyButton = styled.button`
    height: 2.9rem;
    width: 6rem;
    border-radius: 2rem;
    background-color: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
    border: none;
    padding-inline: 1rem;
    cursor: pointer;
`

const EditorWrapper = styled.div`
    padding: 2rem 2rem 4rem 2rem;
    height: 30rem;
    &&.editor-inactive-bg {
        filter: blur(0.15rem);
        pointer-events: none;
    }
    &&.editor-active-bg {
        filter: none;
        pointer-events: auto;
    }
`

const ReactivateAutoReplyWrapper = styled.div``

const ReactivateAutoReplyButton = styled.button`
    background-color: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
    padding: 2rem;
    width: 25rem;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    position: absolute;
    top: 53%;
    left: 53%;
    &:hover {
        opacity: 0.9;
    }
`

const AutoReply: React.FC = () => {
    const { tinymceKey } = config

    const translation = useTranslation()

    const { createAutoReply, getAutoReply, updateAutoReply, getSelf } =
        useActions()

    const {
        autoReply,
        autoReplyLoading,
        isAutomaticReplyActive,
        autoReplyError,
    } = useTypedSelector((state) => state.autoReply)

    const { caseworkerLoading } = useTypedSelector((state) => state.caseworker)
    const [isToggled, setIsToggled] = useState(false)

    const [autoReplyText, setAutoReplyText] = useState(
        "<p>You haven't activated the automatic reply yet. <br><br>To activate, click on the switch on the top right corner.</p>"
    )
    const [isDirty, setIsDirty] = useState(false)
    const [showReactivateAutoReplyButton, setShowReactivateAutoReplyButton] =
        useState(false)

    useEffect(() => {
        // Using a separate GET request to load the page content due to a specific behavior:
        // When using the same loading state for both page loading and the save button click,
        // the entire page reloads instead of only loading the content within the button.
        getSelf()

        //eslint-disable-next-line
    }, [])
    useEffect(() => {
        getAutoReply()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isAutomaticReplyActive === null) {
            return
        }

        if (typeof isAutomaticReplyActive === 'number') {
            setIsToggled(isAutomaticReplyActive === 1 ? true : false)

            if (isAutomaticReplyActive === 0 && autoReply) {
                setShowReactivateAutoReplyButton(true)
            }
        } else {
            setIsToggled(isAutomaticReplyActive)

            if (isAutomaticReplyActive === false && autoReply) {
                setShowReactivateAutoReplyButton(true)
            }
        }

        if (autoReply?.reply) {
            setAutoReplyText(autoReply.reply)
        }
    }, [isAutomaticReplyActive, autoReply])

    const handleSaveAutoReply = async () => {
        const payload = {
            reply: autoReplyText,
        }

        const success = await createAutoReply(payload)

        setIsDirty(false)

        return success
    }

    const activateOrDeactivateAutoReply = async () => {
        const toggledValue = !isToggled
        const payload = {
            isActive: toggledValue,
        }

        //User has not yet created any auto-reply
        if (!autoReply?.id) {
            if (toggledValue) {
                setAutoReplyText('Write here...')
            }
            setIsToggled(toggledValue)
            return
        }

        setIsToggled(toggledValue)
        setShowReactivateAutoReplyButton(!toggledValue)
        const success = await updateAutoReply(autoReply?.id, payload)

        return success
    }

    return (
        <>
            {autoReplyError && (
                <AutoReplyErrorMessage>{autoReplyError}</AutoReplyErrorMessage>
            )}
            {caseworkerLoading ? (
                <SkeletonCard />
            ) : (
                <Card maxHeight="46rem">
                    <CardHeader>
                        <TitleAndTag>
                            {translation.settingsPartner.automaticReply}
                            <AutoReplyTag isActive={isToggled}>
                                {isToggled
                                    ? `${translation.settingsPartner.active}`
                                    : `${translation.settingsPartner.inactive}`}
                            </AutoReplyTag>
                        </TitleAndTag>
                        <AutoReplyAlertMessage>
                            <AlertIcon />
                            <p style={{ paddingBlockStart: '0.2rem' }}>
                                {
                                    translation.settingsPartner
                                        .autoReplyAlertMessage
                                }
                            </p>
                        </AutoReplyAlertMessage>
                        <SwitchControlContainer>
                            {isDirty && (
                                <SaveAutoReplyButton
                                    onClick={handleSaveAutoReply}
                                >
                                    {autoReplyLoading ? (
                                        <ButtonSpinnerWhite />
                                    ) : (
                                        <div>{translation.reusable.save}</div>
                                    )}
                                </SaveAutoReplyButton>
                            )}

                            <Label>
                                <Input
                                    checked={isToggled}
                                    type="checkbox"
                                    onChange={activateOrDeactivateAutoReply}
                                />
                                <Switch isActive={isToggled} />
                            </Label>
                        </SwitchControlContainer>
                    </CardHeader>
                    <EditorWrapper
                        className={
                            showReactivateAutoReplyButton
                                ? 'editor-inactive-bg'
                                : 'editor-active-bg'
                        }
                    >
                        <Editor
                            apiKey={tinymceKey}
                            onEditorChange={(newValue, editor) => {
                                if (editor.isNotDirty) {
                                    return
                                }
                                setAutoReplyText(newValue)
                                setIsDirty(true)
                            }}
                            init={{
                                textareaName: 'textarea',
                                height: 350,
                                menubar: false,
                                plugins: [
                                    'advlist',
                                    'autolink',
                                    'lists',
                                    'link',
                                    'image',
                                    'charmap',
                                    'preview',

                                    'searchreplace',
                                    'visualblocks',
                                    'fullscreen',
                                    'insertdatetime',
                                    'media',
                                    'table',
                                    'save',
                                ],
                                toolbar:
                                    ' blocks | ' +
                                    'bold italic underline forecolor backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist| ',
                                content_style:
                                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #4e4e4e;}',
                            }}
                            value={autoReplyText}
                            onSaveContent={handleSaveAutoReply}
                            disabled={!isToggled}
                        />
                    </EditorWrapper>
                    {showReactivateAutoReplyButton && (
                        <>
                            <ReactivateAutoReplyWrapper>
                                <ReactivateAutoReplyButton
                                    onClick={activateOrDeactivateAutoReply}
                                >
                                    {autoReplyLoading ? (
                                        <ButtonSpinnerWhite />
                                    ) : (
                                        <div>
                                            {
                                                translation.settingsPartner
                                                    .reactivateAutoReply
                                            }
                                        </div>
                                    )}
                                </ReactivateAutoReplyButton>
                            </ReactivateAutoReplyWrapper>
                        </>
                    )}
                </Card>
            )}
        </>
    )
}

export default AutoReply

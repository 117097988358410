import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../../hooks'
import NavigationArrowLink from '../../../reusable/buttons/NavigationArrowLink'
import { HashLink } from 'react-router-hash-link'
import { useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import Video1EN from '../../../../assets/videos/Whistleblowing_1_(EN).mp4'
import Video1DK from '../../../../assets/videos/Whistleblowing_1_(DK).mp4'
import Video1SE from '../../../../assets/videos/Whistleblowing_1_(SE).mp4'
import Video2EN from '../../../../assets/videos/Whistleblowing_2_(EN).mp4'
import Video2DK from '../../../../assets/videos/Whistleblowing_2_(DK).mp4'
import Video2SE from '../../../../assets/videos/Whistleblowing_2_(SE).mp4'
import Video3EN from '../../../../assets/videos/Whistleblowing_3_(EN).mp4'
import Video3DK from '../../../../assets/videos/Whistleblowing_3_(DK).mp4'
import Video3SE from '../../../../assets/videos/Whistleblowing_3_(SE).mp4'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 3rem 15rem 0 15rem;
    height: 100%;
    @media (max-width: 1200px) {
        padding: 3rem 8rem 0 8rem;
    }

    @media screen and (max-width: 600px) {
        padding: 3rem 4rem 0 4rem;
    }
`
const StickyNav = styled.div`
    position: fixed;
    top: 10rem;
    left: 8%;
    z-index: 100;
    max-width: 35rem;
    max-height: 100vh;

    @media screen and (max-width: 1600px) {
        left: 1.5%;
    }

    @media (max-width: 1200px) {
        display: none;
    }
`

const NavigationLinkContainer = styled.nav`
    background-color: ${({ theme }) => theme.blue}10;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-radius: 15px;
    max-width: 26.5rem;
`
const Dropdown = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    margin-top: 1rem;
    padding: 0 3rem;
`
const ArrowIcon = styled(FiChevronDown)`
    font-size: 1.8rem;
    color: ${({ theme }) => theme.blue};
`

const NavigationLinkTitle = styled.h3`
    font-size: 2rem;
    font-weight: 300;
    color: ${({ theme }) => theme.blue};
`

const NavigationLink = styled(HashLink)`
    font-size: 1.2rem;
    font-weight: 200;
    color: ${({ theme }) => theme.primary};
    margin: 0.5rem 0;
    padding: 0 3rem;
`

const Content = styled.div`
    padding: 5rem 15rem;

    @media (max-width: 600px) {
        padding: 2rem;
    }
`
const SectionContentTitle = styled.h1`
    font-size: 3rem;
    padding: 1.5rem 0 1.5rem 1.5rem;
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
    border-radius: 15px;
    margin-bottom: 2.5rem;

    @media screen and (max-width: 600px) {
        font-size: 2rem;
        padding-block: 1rem;
        padding-inline: 2rem;
    }

    @media screen and (max-width: 400px) {
        font-size: 1.8rem;
    }
`
const ContentTitle = styled.h2`
    font-size: 3rem;
    padding: 1rem 0;
    color: ${({ theme }) => theme.primary};

    @media screen and (max-width: 600px) {
        font-size: 2rem;
    }
`

const ContentText = styled.p`
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: ${({ theme }) => theme.primary};
    margin: 2rem 0;
`
const ContentDivider = styled.div`
    border-bottom: 1.5px solid ${({ theme }) => theme.primary}30;
    width: 100%;
    margin: 5rem 0;
`

const ListItem = styled.li`
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
`

const ListBullet = styled.span`
    font-size: 2rem;
    padding-left: 2rem;
    :before {
        content: '•';
        color: ${({ theme }) => theme.blue};
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
`

const ListContent = styled.span`
    font-size: 2.2rem;
    line-height: 3rem;
    padding-left: 2rem;
    color: ${({ theme }) => theme.primary};

    @media screen and (max-width: 600px) {
        font-size: 2rem;
    }
`

const Video = styled.video`
    width: 90%;
    margin: 0 2rem 2rem 2rem;

    @media screen and (max-width: 1000px) {
        margin-inline: 1rem;
    }
`

const HowItWorksPage: React.FC = () => {
    const history = useHistory()
    const translation = useTranslation()

    const { customWbPolicyName } = useTypedSelector(
        (state) => state.whistleblower
    )

    const [isWGDropdownActive, setIsWGDropdownActive] = useState(false)
    const [isWSDropdownActive, setIsWSDropdownActive] = useState(false)
    const [isWPDropdownActive, setIsWPDropdownActive] = useState(false)

    const { selectedTranslation } = useTypedSelector(
        (state) => state.translation
    )

    const { organizationalCode } = useTypedSelector(
        (state) => state.whistleblower
    )

    return (
        <>
            <StickyNav className="sticky">
                <div
                    style={{
                        marginBottom: '2rem',
                    }}
                >
                    <NavigationArrowLink
                        onClickCallback={() =>
                            history.push(
                                `/organization/${organizationalCode}/whistleblower`
                            )
                        }
                        linkText={
                            translation.whistleblowerPolicyPage.backToHomescreen
                        }
                    />
                </div>

                <NavigationLinkContainer>
                    <Dropdown
                        onClick={() =>
                            setIsWGDropdownActive(!isWGDropdownActive)
                        }
                    >
                        <NavigationLinkTitle>
                            {
                                translation.whistleblowerHowItWorksPage
                                    .whistleblowing
                            }{' '}
                        </NavigationLinkTitle>
                        <ArrowIcon />
                    </Dropdown>
                    {isWGDropdownActive ? (
                        <>
                            <NavigationLink smooth to="#whatIsWhistleblowing">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whatIsWhistleblowing
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#whatAreTheTypes">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whatAreTheTypes
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#whatIsTheImportance">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whatIsTheImportance
                                }
                            </NavigationLink>{' '}
                        </>
                    ) : (
                        ''
                    )}
                    <Dropdown
                        onClick={() =>
                            setIsWSDropdownActive(!isWSDropdownActive)
                        }
                    >
                        <NavigationLinkTitle>
                            {
                                translation.whistleblowerHowItWorksPage
                                    .whistleblowerScheme
                            }
                        </NavigationLinkTitle>
                        <ArrowIcon />
                    </Dropdown>
                    {isWSDropdownActive ? (
                        <>
                            <NavigationLink
                                smooth
                                to="#whatIsWhistleblowerScheme"
                            >
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whatIsWhistleblowerScheme
                                }
                            </NavigationLink>
                            <NavigationLink
                                smooth
                                to="#whyHaveAWhistleBlowerScheme"
                            >
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whyHaveAWhistleBlowerScheme
                                }
                            </NavigationLink>
                            <NavigationLink
                                smooth
                                to="#whoCanUseThisWhistleblowerScheme"
                            >
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whoCanUseThisWhistleblowerScheme
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#whatCanBeReported">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whatCanBeReported
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#whatCantBeReported">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whatCantBeReported
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#howAreYouProtected">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .howAreYouProtected
                                }
                            </NavigationLink>
                        </>
                    ) : (
                        ''
                    )}

                    <Dropdown
                        onClick={() =>
                            setIsWPDropdownActive(!isWPDropdownActive)
                        }
                    >
                        <NavigationLinkTitle>
                            {
                                translation.whistleblowerHowItWorksPage
                                    .howDoesTheProcessWork
                            }
                        </NavigationLinkTitle>
                        <ArrowIcon />
                    </Dropdown>
                    {isWPDropdownActive ? (
                        <>
                            <NavigationLink smooth to="#howDoIReport">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .howDoIReport
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#whatIfIncomplete">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whatIfIncomplete
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#whoIsProccessing">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whoIsProccessing
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#howIsProccessed">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .howIsProccessed
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#whatIfLooseCode">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whatIfLooseCode
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#howLongDoesItTake">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .howLongDoesItTake
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#willIRemainSecret">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .willIRemainSecret
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#willPersonsBeNotified">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .willPersonsBeNotified
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#isSchemeSecured">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .isSchemeSecured
                                }
                            </NavigationLink>
                            <NavigationLink smooth to="#whatIfImDissatisfied">
                                {
                                    translation.whistleblowerHowItWorksPage
                                        .whatIfImDissatisfied
                                }
                            </NavigationLink>
                        </>
                    ) : (
                        ''
                    )}
                </NavigationLinkContainer>
            </StickyNav>

            <Container>
                <Content>
                    <SectionContentTitle>
                        {translation.whistleblowerHowItWorksPage.whistleblowing}
                    </SectionContentTitle>
                    {selectedTranslation === 'en' && (
                        <Video controls>
                            <source src={Video1EN} type="video/mp4" />
                        </Video>
                    )}

                    {selectedTranslation === 'dk' && (
                        <Video width={640} height={480} controls>
                            <source src={Video1DK} type="video/mp4" />
                        </Video>
                    )}

                    {selectedTranslation === 'se' && (
                        <Video width={640} height={480} controls>
                            <source src={Video1SE} type="video/mp4" />
                        </Video>
                    )}
                    <ContentTitle id="whatIsWhistleblowing">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whatIsWhistleblowing
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .whistleblowingDefinition
                        }
                    </ContentText>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.whereItsUseful}
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="whatAreTheTypes">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whatAreTheTypes
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .typesOfWhistleblowing
                        }
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="whatIsTheImportance">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whatIsTheImportance
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .preventionOrReparation
                        }
                    </ContentText>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.ethicCulture}
                    </ContentText>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .whistleblowerSafety
                        }
                    </ContentText>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .whistleblowerImportance
                        }
                    </ContentText>
                    <ContentDivider />
                    <SectionContentTitle>
                        {
                            translation.whistleblowerHowItWorksPage
                                .whistleblowerScheme
                        }
                    </SectionContentTitle>

                    {selectedTranslation === 'en' && (
                        <Video controls>
                            <source src={Video2EN} type="video/mp4" />
                        </Video>
                    )}

                    {selectedTranslation === 'dk' && (
                        <Video width={640} height={480} controls>
                            <source src={Video2DK} type="video/mp4" />
                        </Video>
                    )}

                    {selectedTranslation === 'se' && (
                        <Video width={640} height={480} controls>
                            <source src={Video2SE} type="video/mp4" />
                        </Video>
                    )}
                    <ContentTitle id="whatIsWhistleblowerScheme">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whatIsWhistleblowerScheme
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .whistleblowerIsATool
                        }
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="whyHaveAWhistleBlowerScheme">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whyHaveAWhistleBlowerScheme
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .whistleblowerLaw
                        }
                    </ContentText>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .whistleblowingSchemeTransparency
                        }
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="whoCanUseThisWhistleblowerScheme">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whoCanUseThisWhistleblowerScheme
                        }
                    </ContentTitle>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.anyoneCanUse}
                    </ContentText>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .categoriesOfPeople
                        }
                    </ContentText>
                    {customWbPolicyName !== 'vasik' ? (
                        <ul style={{ listStyle: 'none' }}>
                            <ListItem>
                                <ListBullet />
                                <ListContent>
                                    {
                                        translation.whistleblowerHowItWorksPage
                                            .people1
                                    }
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListBullet />
                                <ListContent>
                                    {
                                        translation.whistleblowerHowItWorksPage
                                            .people2
                                    }
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListBullet />
                                <ListContent>
                                    {
                                        translation.whistleblowerHowItWorksPage
                                            .people3
                                    }
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListBullet />
                                <ListContent>
                                    {
                                        translation.whistleblowerHowItWorksPage
                                            .people4
                                    }
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListBullet />
                                <ListContent>
                                    {
                                        translation.whistleblowerHowItWorksPage
                                            .people5
                                    }
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListBullet />
                                <ListContent>
                                    {
                                        translation.whistleblowerHowItWorksPage
                                            .people6
                                    }
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListBullet />
                                <ListContent>
                                    {
                                        translation.whistleblowerHowItWorksPage
                                            .people7
                                    }
                                </ListContent>
                            </ListItem>
                            <ListItem>
                                <ListBullet />
                                <ListContent>
                                    {
                                        translation.whistleblowerHowItWorksPage
                                            .people8
                                    }
                                </ListContent>
                            </ListItem>
                        </ul>
                    ) : (
                        <ul style={{ listStyle: 'none' }}>
                            <ListItem>
                                <ListBullet />
                                <ListContent>
                                    {
                                        translation.whistleblowerHowItWorksPage
                                            .people1
                                    }
                                </ListContent>
                            </ListItem>

                            <ListItem>
                                <ListBullet />
                                <ListContent>
                                    {
                                        translation.whistleblowerHowItWorksPage
                                            .people7
                                    }
                                </ListContent>
                            </ListItem>
                        </ul>
                    )}

                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .noMatterTheRelationship
                        }
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="whatCanBeReported">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whatCanBeReported
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .seriousViolations
                        }
                    </ContentText>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .violationsOfLaw
                        }
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="whatCantBeReported">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whatCantBeReported
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .lessSeriousMatters
                        }
                    </ContentText>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.inCaseOfDoubt}
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="howAreYouProtected">
                        {
                            translation.whistleblowerHowItWorksPage
                                .howAreYouProtected
                        }
                    </ContentTitle>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.noLiability}
                    </ContentText>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.noRetaliation}
                    </ContentText>
                    <ContentDivider />
                    <SectionContentTitle>
                        {
                            translation.whistleblowerHowItWorksPage
                                .howDoesTheProcessWork
                        }
                    </SectionContentTitle>

                    {selectedTranslation === 'en' && (
                        <Video controls>
                            <source src={Video3EN} type="video/mp4" />
                        </Video>
                    )}

                    {selectedTranslation === 'dk' && (
                        <Video width={640} height={480} controls>
                            <source src={Video3DK} type="video/mp4" />
                        </Video>
                    )}

                    {selectedTranslation === 'se' && (
                        <Video width={640} height={480} controls>
                            <source src={Video3SE} type="video/mp4" />
                        </Video>
                    )}
                    <ContentTitle id="howDoIReport">
                        {translation.whistleblowerHowItWorksPage.howDoIReport}
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .reportingChannel
                        }
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="whatIfIncomplete">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whatIfIncomplete
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .accessAndExpand
                        }
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="whoIsProccessing">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whoIsProccessing
                        }
                    </ContentTitle>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.thirdParty}
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="howIsProccessed">
                        {
                            translation.whistleblowerHowItWorksPage
                                .howIsProccessed
                        }
                    </ContentTitle>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.investigations}
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="whatIfLooseCode">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whatIfLooseCode
                        }
                    </ContentTitle>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.looseAccess}
                    </ContentText>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.unableToUptade}
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="howLongDoesItTake">
                        {
                            translation.whistleblowerHowItWorksPage
                                .howLongDoesItTake
                        }
                    </ContentTitle>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.whithin3Months}
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="willIRemainSecret">
                        {
                            translation.whistleblowerHowItWorksPage
                                .willIRemainSecret
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .anounOrConfidential
                        }
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="willPersonsBeNotified">
                        {
                            translation.whistleblowerHowItWorksPage
                                .willPersonsBeNotified
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .concernedsNotified
                        }
                    </ContentText>
                    <ContentText>
                        {translation.whistleblowerHowItWorksPage.noPersonalInfo}
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="isSchemeSecured">
                        {
                            translation.whistleblowerHowItWorksPage
                                .isSchemeSecured
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .independencyGuarantee
                        }
                    </ContentText>
                    <ContentDivider />
                    <ContentTitle id="whatIfImDissatisfied">
                        {
                            translation.whistleblowerHowItWorksPage
                                .whatIfImDissatisfied
                        }
                    </ContentTitle>
                    <ContentText>
                        {
                            translation.whistleblowerHowItWorksPage
                                .informCompetentAuth
                        }
                    </ContentText>
                </Content>
            </Container>
        </>
    )
}

export default HowItWorksPage

import styled from 'styled-components'
import { FiArrowLeft } from 'react-icons/fi'
import { FormInput50, PrimaryLabel } from '../../../../styled/formElements'
import { NextButton } from '../../../../reusable/buttons'
import { useEffect, useState } from 'react'
import { HeadingPrimary } from '../../../../styled/typography'
import { checkEmail, isValidEmail } from '../../../../../helpers/validation'
import {
    useActions,
    useTheme,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import ResetPasswordLinkSentModal from '../components/ResetPasswordLinkSentModal'

interface ForgotPasswordProps {
    setCurrentPageNumber: (pageNumber: number) => void
}

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 22rem;
`

const GoBackLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8.4rem;
`

const GoBackLinkText = styled.span`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    padding-top: 0.5rem;
    cursor: pointer;
    margin-left: 8px;
`

const Tagline = styled.h4`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.black};
    opacity: 0.8;
    margin-top: 2.8rem;
    margin-bottom: 5rem;
`

const Form = styled.form`
    width: 35rem;
    margin: 0 auto;
    text-align: left;
`

const NextButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 4rem;
`

const ErrorMessage = styled.p`
    color: red;
`

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
    setCurrentPageNumber,
}) => {
    const theme = useTheme()
    const translation = useTranslation()

    const {
        sendPasswordResetLink,
        clearResetPasswordSuccessMessage,
        clearResetPasswordError,
    } = useActions()

    const {
        resetPasswordLoading,
        resetPasswordError,
        resetPasswordSuccessMessage,
    } = useTypedSelector((state) => state.resetPassword)

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [emailSuccess, setEmailSuccess] = useState(false)

    const handleEmailValidation = () => {
        const validationError = checkEmail(email, translation)

        !validationError && setEmailSuccess(true)
        setEmailError(validationError)
    }

    const handleEmailOnFocus = () => {
        setEmailError('')
        setEmailSuccess(false)

        if (resetPasswordError) {
            clearResetPasswordError()
        }
    }

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault()
        await sendPasswordResetLink(email)
    }

    const [isModalVisible, setIsModalVisible] = useState(false)

    useEffect(() => {
        if (
            resetPasswordSuccessMessage &&
            resetPasswordSuccessMessage?.length > 0
        ) {
            setIsModalVisible(true)

            setTimeout(redirectAfterSendingLink, 5000)
        }
        // eslint-disable-next-line
    }, [resetPasswordSuccessMessage])

    const redirectAfterSendingLink = () => {
        setIsModalVisible(false)
        setCurrentPageNumber(1)
        clearResetPasswordSuccessMessage()
    }

    useEffect(() => {
        if (resetPasswordError) {
            setEmailError(resetPasswordError)
        }
    }, [resetPasswordError])

    return (
        <>
            {isModalVisible && <ResetPasswordLinkSentModal />}
            <Container>
                <GoBackLink onClick={() => setCurrentPageNumber(1)}>
                    <FiArrowLeft
                        size={14}
                        color={theme.primary}
                        style={{ opacity: 0.5 }}
                    />
                    <GoBackLinkText>
                        {translation.loginFlow.backToLogin}
                    </GoBackLinkText>
                </GoBackLink>
                <HeadingPrimary>
                    {translation.loginFlow.underPageForgotPassword}
                </HeadingPrimary>
                <Tagline>
                    {translation.loginFlow.underPageWriteYourEmail}
                </Tagline>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <PrimaryLabel
                        isError={emailError ? true : false}
                        isSuccess={emailSuccess}
                        htmlFor="email"
                    >
                        {translation.reusable.email}
                    </PrimaryLabel>
                    <FormInput50
                        onFocus={handleEmailOnFocus}
                        onBlur={handleEmailValidation}
                        className={
                            emailError
                                ? 'border-error'
                                : emailSuccess
                                ? 'border-success'
                                : ''
                        }
                        id={translation.reusable.email}
                        placeholder="Your email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
                    <NextButtonContainer>
                        <NextButton
                            text={translation.loginFlow.login}
                            isLoading={resetPasswordLoading}
                            isActive={isValidEmail(email)}
                            handleOnClick={() => {}}
                            width="133px"
                            icon={false}
                        />
                    </NextButtonContainer>
                </Form>
            </Container>
        </>
    )
}

export default ForgotPassword

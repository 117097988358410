const dk = {
    registerPage: {
        accountType: 'Abonnementer',
        noCreditCardRequired: 'Ingen kreditkort er nødvendigt',
        freeTrial: 'gratis prøveperiode',
        today: 'i dag',
        phoneNumber: 'Telefon nr.',
        software: 'Software',
        softwarePlusScreening: 'Software + Screening',
        easyAndSecure: 'Nem og sikker',
        chargedYearly: 'Opkræves årligt',
        secureWhistleblowerPlatform: 'Sikker whistleblower platform',
        tailoredWhistleblowerPolicy: 'Tilpasset whistleblowerpolitik',
        reportManagement: 'Overblik over sager',
        followUpCommunication: 'Follow-up kommunikation',
        emailNotifications: 'E-mail notifikationer',
        employeeGuides: 'Medarbejdervejledninger',
        areYouLookingFo: 'Leder du efter vores partner program?',
        partnerprogrammodal: 'Partner Program',
        iHaveAgreedToWalor: 'Jeg har læst og accepterer Walors',
        termsAndConditions: 'Vilkår og betingelser',
        and: 'og',
        privacyAndCookiePolicy: 'Privatlivspolitik og cookiepolitik',
        dataProcessingAgreement: 'Databehandleraftale',
        ifYouAreLookingFor:
            'Hvis du er på udkig efter et partner program, kan du kontakte os på',
        thanksCheckYour: 'Færdig! Tjek din e-mail',
        rememberToCheckYourSpam:
            'Har du ikke modtaget e-mailen? Tjek venligst din spam-mappe eller kontakt os på',
        areYouAMemberOf: 'Er du medlem af',
    },

    // Onboarding
    onboardingPage: {
        hi: 'Hej',
        welcomeToWalor: ', velkommen til Walor!',
        youAreAlmostDone:
            'Du er næsten færdig med at opsætte din nye whistleblowerløsning',
        subcreateYourCompanyInformation:
            'Tilføj firmaadressen og antallet af ansatte.',
        generateWhistleblowerPolicy: 'Whistleblowerpolitik',
        readyToGo: 'Kom igang',
        pleaseFillinThisInformation: 'Indtast dine virksomhedsoplysninger',
        nextStep: 'Næste',
        name: 'Virksomhedens navn',
        createYourLineOfContact: 'Opret din kontaktkæde',
        skipThisStep: 'Spring over',
        subcreateYourLineOfContact:
            'Disse brugere vil være ansvarlige for at håndtere whistleblowing-indberetningerne for din organisation',
        subgenerateyourwbpolicy:
            'Denne beskriver de juridiske rammer for din whistleblowerordning',
        clickHere: 'Klik her',
        complete: 'Færdig',
        yourWhistleblowerpolicyIsBeingGenerated:
            'Din whistleblowerpolitik er ved at blive udarbejdet',
        goodWork: 'Tillykke',
        yourPolicyHasBeen: 'Din whistleblowerpolitik er blevet oprettet!',
        allSetEasy: 'Alt er klar! Nemt, ikke?',
        letsGetYouStarted: 'Lad os få dig i gang med det samme',
        takeMeToTheDashboard: 'Gå til dashboardet',
    },

    onboardingFlow: {
        // Onboarding (1)
        enterCompanyName: 'Virksomhedens navn',
        enterPhone: '12345678',
        enterZipCode: '1234',

        // Onboarding (2)
        enterLastName: 'Efternavn',
        // Onboarding (3)
        // Onboarding (4)
        // MISSING!!!!!!

        // Onboarding (5)
    },

    loginFlow: {
        // Login (1)
        login: 'Log ind',
        forgotPassword: 'Glemt adgangskode?',

        // Login - Forgot password?
        underPageForgotPassword: 'Glemt adgangskode?',
        underPageWriteYourEmail:
            'Skriv din email herunder for at oprette en ny adgangskode',

        // Login (2)
        howDoYouWant: 'Hvordan vil du modtage din sikkerhedskode?',
        buttonEmail: 'Email',
        buttonSMS: 'SMS',
        or: ' eller',

        // Login (3)
        backToLogin: 'Tilbage til log ind',
        enterThe6digit: 'Indtast den 6-cifrede sikkerhedskode',
        yourCodeWillArrive: 'Din sikkerhedskode kommer om få sekunder til',
        didYouNotReceive:
            'Har du ikke modtaget koden? klik her for at sende den igen',
    },

    whistleblowerCreateCase: {
        // Create case (1)
        whistleblowerScheme: 'Whistleblowerordning',
        createReport: 'Opret indberetning',
        startReport: 'Start en anonym indberetning',
        startReportConfidential: 'Start en fortrolig indberetning',
        accessExistingReport: 'Se eksisterende indberetning',
        understandWhistleblowing: 'Hvad er whistleblowing?',
        readMore: 'Medarbejdervejledninger',
        playvideo: 'Afspil',
        howThisWorks: 'Sådan fungerer det',
        createAReport: 'Opret en indberetning',
        createAReportSimply:
            'Opret en anonym eller fortrolig indberetning. Du kan give alle de oplysninger og filer, som du har indsamlet. Når du har sendt indberetningen, får du en unik whistleblowerkode, som gør det muligt for dig at få adgang til indberetningen igen.',
        investigatingthereport: 'Intern undersøgelse',
        caseworkerinthecompany:
            'De sagsbehandlere, som du vælger til at behandle din indberetning, vil modtage en meddelelse på sin e-mail om, at vedkommende kan begynde at foretage den interne undersøgelse.',
        answeradditionalquestions: 'Besvar yderligere spørgsmål',
        additionalQuestions:
            'De sagsbehandlere, som du vælger, kan have yderligere spørgsmål til din indberetning. Sørg for at genbesøge din indberetning en gang imellem for at hjælpe den interne undersøgelse.',
        companiesWhistleblowerPolicy: 'Whistleblowerpolitik',
    },

    whistleblowerHowItWorksPage: {
        // whistleblowing in general
        whistleblowing: 'Whistleblowing',
        whatIsWhistleblowing: 'Hvad er whistleblowing?',
        whistleblowingDefinition:
            'Whistleblowing er en indberetning af ulovlige eller umoralske handlinger i en organisation. Den person, der indberetter dette, kaldet en whistleblower, kan enten være en del af organisationen eller en tredjepart med tilknytning til organisationen.',
        whereItsUseful:
            'I langt de fleste tilfælde vil whistleblowing finde sted i en arbejdsrelateret sammenhæng. En medarbejder kan f.eks. få oplysninger om ulovlige aktiviteter hos sin arbejdsgiver under udførelsen af sit arbejde, som medarbejderen beslutter sig for at indberette.',
        whatAreTheTypes: 'Hvilke typer af whistleblowing findes der?',
        typesOfWhistleblowing:
            'Der kan skelnes mellem intern og ekstern whistleblowing. Intern whistleblowing indebærer, at en whistleblower foretager en indberetning inden for den organisation, som han eller hun er en del af eller har tilknytning til. Det betyder, at whistlebloweren kun informerer om forseelsen inden for denne organisation. På den anden side betyder ekstern whistleblowing, at whistlebloweren advarer andre end organisationen selv om aktiviteter i organisationen. I så fald videregives oplysningerne til f.eks. offentlige myndigheder eller medierne.',
        whatIsTheImportance: 'Hvorfor er whistleblowing vigtigt?',
        preventionOrReparation:
            'For det første spiller whistleblowing en væsentlig rolle i forebyggelsen eller reduceringen af skader på samfundet, da personer, der er en del af eller har tilknytning til en organisation, ofte er de første eller eneste, der har viden om eller adgang til oplysninger om sådanne skader.',
        ethicCulture:
            'Ud fra organisationens perspektiv bidrager whistleblowing desuden til en kultur af etik, lovlighed, gennemsigtighed og deltagelse.',
        whistleblowerSafety:
            'Whistlebloweren har mulighed for at overvinde en følelse af skyld eller ansvarsfølelse ved at gøre brug af whistleblowing, og dermed dele oplysninger om ulovlige eller umoralske handlinger i den organisation, som whistlebloweren er en del af eller har tilknytning til.',
        whistleblowerImportance:
            'Whistleblowing er vigtigt for samfundet, og er derfor beskyttet ved lov.',
        // whistleblower scheme
        whistleblowerScheme: 'Whistleblowerordning',
        whatIsWhistleblowerScheme: 'Hvad er en whistleblowerordning?',
        whistleblowerIsATool:
            'En whistleblowerordning er et værktøj, der giver folk mulighed for at indberette oplysninger om en bestemt organisation. Denne ordning guider således whistleblowere gennem processen med at indberette en ulovlig eller uetisk aktivitet. Dette gør det muligt for organisationer at få kendskab til, undersøge og afhjælpe ulovlig eller uetisk adfærd.',
        whyHaveAWhistleBlowerScheme: 'Hvorfor har man en whistleblowerordning?',
        whistleblowerLaw:
            "Som følge af EU's øgede fokus på værdien af whistleblowing vedtog EU den 23. oktober 2019 direktiv 2019/1937 om beskyttelse af personer, der indberetter overtrædelser af EU-retten. Dette whistleblowerdirektiv, som er blevet implementeret i dansk lovgivning, skaber en juridisk ramme for whistleblowerordninger og beskytter whistleblowere, der indberetter oplysninger gennem en whistleblowerordning.",
        whistleblowingSchemeTransparency:
            'En whistleblowerordning fungerer som et initiativ, som giver alle medarbejdere mulighed for at ytre deres mulige bekymringer nemt og sikkert. Det er en af flere årsager til, at man skal betragte whistleblowing, som særligt værdiskabende, da det skaber en reel mulighed for at gøre virksomheden bedre, indefra. Det har nemlig en afgørende rolle for at skabe en kultur af gennemsigtighed og en følelse af tilhørsforhold for medarbejderne. Derudover støtter og fremmer ordningen at man rejser bekymringer internt, tæt på problemets kilde. Dette gør det muligt at afhjælpe disse problemer tidligt og effektivt.',
        whoCanUseThisWhistleblowerScheme:
            'Hvem kan bruge denne whistleblowerordning?',
        anyoneCanUse:
            'Whistleblowerordningen kan anvendes af alle, der har oplysninger om en offentlig eller privat organisation, som de har fået kendskab til i forbindelse med deres arbejdsrelaterede aktiviteter.',
        categoriesOfPeople: 'Indberetninger kan indgives af:',
        people1:
            'Medarbejdere. Dette omfatter både fuldtidsansatte og deltidsansatte samt ansatte, der arbejder under en ubegrænset eller tidsbegrænset kontrakt.',
        people2: 'Jobansøgere',
        people3: 'Lønnede eller ulønnede praktikanter',
        people4: 'Frivillige',
        people5: 'Selvstændigt erhvervsdrivende',
        people6: 'Aktionærer',
        people7:
            'Medlemmer af direktionen, bestyrelsen, tilsynsrådet eller andre ledende organer',
        people8:
            'Personer, som arbejder under tilsyn og ledelse af underleverandører og leverandører',
        noMatterTheRelationship:
            'Personer, der falder ind under disse kategorier, kan gøre brug af whistleblowerordningen, uanset om deres ansættelsesforhold til organisationen er ophørt, stadig er i gang eller endnu ikke er begyndt.',
        whatCanBeReported:
            'Hvilke forhold kan indberettes gennem denne whistleblowerordning?',
        seriousViolations:
            'Whistleblowerordningen kan bruges til at behandle oplysninger om alvorlige overtrædelser af loven, forsøg på at skjule sådanne overtrædelser eller øvrige alvorlige forhold, som har fundet sted eller med stor sandsynlighed vil finde sted i organisationen. Også i tilfælde af rimelig mistanke om sådanne overtrædelser eller forhold kan disse oplysninger indberettes.',
        violationsOfLaw:
            'De overtrædelser, der kan indberettes via whistleblowerordningen, vedrører specifikke overtrædelser af EU-lovgivningen eller andre alvorlige lovovertrædelser og forhold i henhold til dansk lovgivning. Aktiviteter, der kan indberettes gennem denne ordning, vedrører f.eks. korruption, miljøbeskyttelse, folkesundhed, bestikkelse, personoplysninger eller sexchikane. Dette er kun eksempler. Der kan også indberettes omkring andre sager eller overtrædelser af loven af alvorlig karakter.',
        whatCantBeReported:
            'Hvilke forhold kan ikke indberettes gennem denne whistleblowerordning?',
        lessSeriousMatters:
            'Denne whistleblowerordning er ikke beregnet til at indberette mindre alvorlige forhold eller almindelige ansættelsesmæssige spørgsmål, såsom utilfredshed med lønnen eller mindre kollegiale problemer. Det er også et krav, at indberetningerne indsendes i god tro, og at denne ordning ikke anvendes til ubegrundede beskyldninger mod uskyldige personer.',
        inCaseOfDoubt:
            'Hvis du er i tvivl om, hvorvidt din observation eller mistanke er af alvorlig karakter og bør indberettes eller ej, opfordres du til at gøre det. Alle henvendelser vil blive besvaret og behandlet.',
        howAreYouProtected:
            'Hvordan er du beskyttet, når du bruger denne ordning? ',
        noLiability:
            'Whistleblowere, der i god tro indberetter alvorlige forhold gennem denne ordning, nyder bred beskyttelse uden at blive gjort ansvarlig for at bryde fortrolighedspligten med hensyn til de indberettede oplysninger. Også personer, der bistår whistlebloweren, eller personer eller virksomheder, der har tilknytning til whistlebloweren, er omfattet af denne beskyttelse.',
        noRetaliation:
            'Denne beskyttelse indebærer en beskyttelse mod enhver form for repressalier. Repressalier indebærer enhver handling eller undladelse som følge af indberetningen, som forårsager eller kan forårsage uberettiget skade for whistlebloweren. Sådanne handlinger kan antage mange forskellige former, f.eks. afskedigelse, ændring af arbejdssted, lønnedgang eller skade det personlige eller faglige omdømme. Hvis en whistleblower eller en beskyttet person udsættes for repressalier, har han eller hun krav på godtgørelse. En organisation, der udøver gengældelsesforanstaltninger, kan også blive pålagt andre sanktioner.',
        // how does the process of reporting work?
        howDoesTheProcessWork: 'Indberetningsproces',
        howDoIReport: 'Hvordan indberetter man?',
        reportingChannel:
            'Du kan oprette en indberetning via den digitale indberetningskanal for den organisation, som du vil rapportere om.',
        whatIfIncomplete:
            'Hvad sker der, hvis min indberetning er ufuldstændig?',
        accessAndExpand:
            'Det er altid muligt at få adgang til din indberetning og derfra give yderligere oplysninger eller beviser til sagen. De personer, der behandler sagen, kan også anmode om yderligere oplysninger eller dokumentation, som kan være relevant for behandlingen og undersøgelsen af sagen. En efterfølgende indsendelse af svar eller dokumentation afhænger dog udelukkende af din vilje til at gøre det. Der er ingen forpligtelse til at gøre dette.',
        whoIsProccessing: 'Hvem behandler min indberetning?',
        thirdParty:
            'Din indberetning vil blive af den eller de valgte person(er) i virksomhedens whistleblowerenhed. Kun disse personer er autoriseret til at foretage en undersøgelse. Din sag vil under ingen omstændigheder blive overdraget til en person, der er involveret i de indberettede aktiviteter, eller som der kan være en interessekonflikt med.',
        howIsProccessed: 'Hvordan behandles min indberetning?',
        investigations:
            'Du vil modtage en kvittering for modtagelse af indberetning inden for syv dage. Herefter vil der blive foretaget en indledende undersøgelse af indberetningen. Hvis det viser sig, at indberetningen er åbenbart ubegrundet, vil den blive afvist. Den person, der har anmeldt sagen, vil blive underrettet herom hurtigst muligt. Hvis den indledende undersøgelse imidlertid viser, at indberetningen ikke kan karakteriseres som åbenlyst ubegrundet, vil den blive undersøgt yderligere. Sagen vil derefter blive behandlet internt. Whistlebloweren vil hurtigst muligt modtage feedback om eventuelle opdateringer eller resultater. Denne interne undersøgelse kan have konsekvenser for den eller de involverede personer. Hvis sagen er af særlig alvorlig karakter, kan den også blive videregivet til politiet med henblik på yderligere efterforskning. Når alle undersøgelser er gennemført og eventuelle sanktioner er pålagt, vil sagen blive afsluttet. Den slettes derefter fra systemet.',
        whatIfLooseCode:
            'Hvad sker der, hvis jeg mister min whistleblower-kode?',
        looseAccess:
            'Hvis du mister din whistleblowerkode, har du desværre ikke længere adgang til din indberetning. Dette er den eneste mulighed for at garantere en sikker og anonym indberetning process. Din indberetning vil dog stadig blive gennemgået og undersøgt.',
        unableToUptade:
            'Du vil ikke kunne opdatere din indberetning eller besvare spørgsmål om sagen. For at kunne gøre dette skal du lave en ny indberetning og indsende den via denne whistleblowerordning.',
        howLongDoesItTake:
            'Hvor lang tid vil det tage at behandle min indberetning?',
        whithin3Months:
            'Din indberetning vil blive behandlet og undersøgt hurtigst muligt. I løbet af denne proces vil du modtage feedback om eventuelle opdateringer eller resultater. Den samlede varighed afhænger af din sag, men du vil senest tre måneder efter indberetningen modtage oplysninger om de planlagte eller allerede udførte handlinger.',
        willIRemainSecret: 'Vil min identitet forblive hemmelig?',
        anounOrConfidential:
            'Du har mulighed for at indberette enten anonymt eller fortroligt via whistleblowerordningen. Mens førstnævnte indebærer, at der ikke deles nogen personlige oplysninger, omfatter sidstnævnte, at whistlebloweren deler personlige oplysninger. Hvis der deles personlige oplysninger i din indberetning, vil kun de personer, der er autoriseret til at undersøge sagen, have adgang til dem. Fortroligheden af den indsendte indberetning vil derfor under alle omstændigheder blive respekteret.',
        willPersonsBeNotified:
            'Vil nogle af de personer, der er berørt af en indberetning, blive underrettet?',
        concernedsNotified:
            'Den eller de berørte personer vil blive informeret om, at de er involveret i en sag. I hvert enkelt tilfælde vil der blive foretaget en vurdering af, hvornår denne meddelelse kan videregives, således at meddelelsen ikke får konsekvenser for indsamlingen af bevismateriale.',
        noPersonalInfo:
            'Der vil ikke blive givet nogen oplysninger, hverken direkte eller indirekte, om hvem der har foretaget indberetningen over for den pågældende person, selv om du har valgt at indberette fortroligt og derfor har delt din identitet.',
        isSchemeSecured: 'Er denne whistleblowerordning sikker?',
        independencyGuarantee:
            "Whistleblowerindberetningskanalen drives af Walor ApS, som er en uafhængig tredjepart, der garanterer systemets sikkerhed. Systemet logger ikke IP-adresser og maskin-ID'er og krypterer alle behandlede data. Alle involverede parters persondata rettigheder vil blive respekteret. Kun den ansvarlige sagsbehandler har adgang til indberetningen.",
        whatIfImDissatisfied:
            'Hvad sker der, hvis jeg er utilfreds med vurderingen eller resultatet af min indberetning?',
        informCompetentAuth:
            "Det er altid muligt at gøre brug af yderligere handlinger, hvis du mener, at der er grund til at gøre det. Et negativt resultat af en indberetning forhindrer dig under ingen omstændigheder i at gøre dette. Efter at have indberettet via whistleblowerordningen er det muligt for dig at indberette oplysningerne til de rette kompetente myndigheder. Herudover kan indberetninger, hvor det er relevant, også deles med EU's institutioner, organer, kontorer eller agenturer.",
    },

    whistleblowerPolicyPage: {
        // Whistleblower Policy (1)
        whistleblowerPolicyHeading: 'Whistleblowerpolitik',
        backToHomescreen: 'Tilbage til startskærmen',
        whistleblowerPolicy: "'s whistleblower policy",
        thePurposeOfThisWhistleblower:
            'Formålet med denne whistleblowerpolitik er at forklare, hvordan',
        whistleblowerSchemeWorks:
            's whistleblowerordning fungerer. Denne ordning giver dig mulighed for at indberette oplysninger om',
        aGoodUnderstanding:
            'En god forståelse af denne ordning kan dermed forhindre at potentielt vigtige forhold ikke bliver indberettet.',
        introductionTo: 'Introduktion til',
        whistleblowerScheme: 's whistleblowerordning',
        isAResponsibleCompanyWith:
            'er en ansvarlig virksomhed med en åben kultur, hvor alle kan føle sig sikre og ytre sig, hvis man oplever forseelser eller potentielle overtrædelser af loven. Som udgangspunkt opfordres medarbejdere til at tale med deres nærmeste leder eller et medlem af ledergruppen (istf. bestyrelsen) i disse situationer, men det er også muligt at benytte whistleblowerindberetningskanalen.',
        whistleblowerSchemeEnsuresProtection:
            's whistleblowerordning sikrer beskyttelse af personer, der indberetter om alvorlige lovovertrædelser og forhold. Denne ordning kan anvendes, hvis du oplever, er bekendt med eller har grund til at formode, at sådanne overtrædelser har fundet sted i',
        bothInThePast:
            'både tidligere, nu eller i den nærmeste fremtid. Indberetninger kan indgives enten anonymt eller fortroligt igennem whistleblowerindberetningskanalen.',
        whoCanUseThisWhistleblowingScheme:
            'Hvem kan bruge denne whistleblowerordning?',
        whistleblowerSchemeCanBeUsedByAnyone:
            's whistleblowerordning kan anvendes af alle, der har oplysninger om',
        whichTheyHaveAcquired:
            ', som de har fået kendskab til i forbindelse med deres arbejdsrelaterede aktiviteter hos',
        theCategoriesOfPeopleWho: 'Indberetninger kan indgives af:',
        employees:
            'Medarbejdere. Dette omfatter både fuldtidsansatte og deltidsansatte samt ansatte, der arbejder under en ubegrænset eller tidsbegrænset kontrakt.',
        jobApplicants: 'Jobansøgere',
        paidOrUnpaidTrainees: 'Lønnede eller ulønnede praktikanter',
        volunteers: 'Frivillige',
        selfEmployedPersons: 'Selvstændigt erhvervsdrivende',
        shareholders: 'Aktionærer',
        membersOfTheManagement:
            'Medlemmer af direktionen, bestyrelsen eller andre ledende organer i',
        personsWorkingUnder:
            'Personer, som arbejder under tilsyn og ledelse af kontrahenter, underleverandører og leverandører til',
        personsFallingUnderTheseCategories:
            'Personer, der falder ind under disse kategorier, kan gøre brug af whistleblowerordningen, uanset om deres ansættelsesforhold til',
        hasCeased: 'er ophørt, stadig er i gang eller endnu ikke er begyndt.',
        whatCanYouReport: 'Hvilke forhold kan indberettes?',
        theWhistleblowerSchemeCanBeUsed:
            'Whistleblowerordningen kan bruges til at behandle oplysninger om alvorlige overtrædelser af loven, forsøg på at skjule sådanne overtrædelser eller øvrige alvorlige forhold, som har fundet sted eller med stor sandsynlighed vil finde sted i',
        alsoInTheCaseOfRasonable:
            'Også i tilfælde af rimelig mistanke om sådanne overtrædelser eller forhold kan disse oplysninger indberettes. Det er dog et krav, at indberetningerne indsendes i god tro, og at denne whistleblowerordning ikke anvendes til ubegrundede beskyldninger mod uskyldige personer.',
        theViolationsWhich:
            'De overtrædelser, der kan indberettes via whistleblowerordningen, vedrører specifikke overtrædelser af EU-lovgivningen eller andre alvorlige lovovertrædelser og forhold i henhold til dansk lovgivning. Mindre alvorlige forhold eller almindelige ansættelsesmæssige spørgsmål, såsom utilfredshed med lønnen eller mindre kollegiale problemer, skal ikke indberettes gennem denne whistleblowerordning.',
        activitesThatCanBeReported:
            'Aktiviteter, der kan indberettes gennem denne ordning, vedrører f.eks. korruption, miljøbeskyttelse, folkesundhed, bestikkelse, personoplysninger eller sexchikane. Dette er kun eksempler. Der kan også indberettes omkring andre sager eller overtrædelser af loven af alvorlig karakter.',
        ifYouAreInDoubtAbout:
            'Hvis du er i tvivl om, hvorvidt din observation eller mistanke er af alvorlig karakter og bør indberettes eller ej, opfordres du til at gøre det. Alle henvendelser vil blive besvaret og behandlet.',
        protectionOfWhistleblowers: 'Beskyttelse af whistlebloweren',
        anyoneWhoReportsMatters:
            'Enhver, der i god tro indberetter forhold som beskrevet ovenfor, er beskyttet mod enhver form for repressalier. Repressalier indebærer direkte eller indirekte handlinger eller undladelser, som finder sted i en arbejdsrelateret sammenhæng som følge af indberetningen, og som forårsager eller kan forårsage uberettiget skade for whistlebloweren. Sådanne handlinger kan antage flere former, f.eks. afskedigelse, ændring af arbejdssted, lønnedgang eller skade af whistleblowerens omdømme.',
        ifAWhistleblowerSuffers:
            'Hvis en whistleblower udsættes for nogen form for repressalier fra',
        heOrSheWillBeCompensated:
            'har han eller hun krav på godtgørelse. I tilfælde af afskedigelse annulleres afskedigelsen, og ansættelsesforholdet opretholdes eller genetableres, hvis whistlebloweren ønsker det.',
        mayAlsoBeSubjectToOtherSanctions:
            'kan også blive pålagt andre sanktioner.',
        howToReport: 'Hvordan indberetter man?',
        reportsThatLieWithin:
            'Indberetninger, der er omfattet af denne politik, behandles af den eller de valgte person(er) i virksomhedens kontaktkæde, som er:',
        onlyThesePersonsAreAuthorised:
            'Kun disse personer er autoriseret til at foretage en undersøgelse. I nogle tilfælde kan behandlingen dog også foregå med hjælp fra eksterne parter, for eksempel advokater eller revisorer',
        howAreTheReportsProcessed: 'Hvordan behandles indberetningerne?',
        youWilRecieveAnAcknowledgement:
            'Du vil modtage en kvittering for modtagelse af indberetningen inden for syv dage. Herefter vil der blive foretaget en indledende undersøgelse af indberetningen. Hvis det viser sig, at indberetningen er åbenbart ubegrundet, vil den blive afvist. Den person, der har anmeldt sagen, vil blive underrettet herom hurtigst muligt. Hvis den indledende undersøgelse imidlertid viser, at indberetningen ikke kan karakteriseres som åbenlyst ubegrundet, vil den blive undersøgt yderligere. Sagen vil derefter blive behandlet internt. Whistlebloweren vil hurtigst muligt modtage feedback om eventuelle opdateringer eller resultater. Denne interne undersøgelse kan have konsekvenser for den eller de involverede personer. Hvis sagen er af særlig alvorlig karakter, kan den også blive videregivet til politiet med henblik på yderligere efterforskning. Når alle undersøgelser er gennemført og eventuelle sanktioner er pålagt, vil sagen blive afsluttet. Den slettes derefter fra systemet.',
        subsequentClarificationAndCollection:
            'Efterfølgende afklaring og indsamling af yderligere dokumentation',
        whenYouCreateAReport:
            'Når man laver en indberetning, får du mulighed for at logge ind i systemet og se, om sagsbehandleren har stillet yderligere spørgsmål om sagen eller har bedt om yderligere dokumentation. En eventuel efterfølgende dialog afhænger udelukkende af whistleblowerens villighed til at logge ind i systemet og besvare sagsbehandlerens spørgsmål.',
        confidentialityAndITSecurity: 'Fortrolighed og IT-sikkerhed',
        aReportCanBeMadeEither:
            'En indberetning kan ske enten anonymt eller fortroligt. Mens førstnævnte indebærer, at der ikke videregives nogen personlige oplysninger, omfatter sidstnævnte, at whistlebloweren videregiver personlige oplysninger. Hvis der deles personlige oplysninger i din indberetning, vil kun de personer, der er autoriseret til at undersøge sagen, have adgang til dem. Fortroligheden af den indsendte indberetning vil derfor under alle omstændigheder blive respekteret.',
        theWhistleblowerReportingChannel:
            "Whistleblowerindberetningskanalen drives af Walor ApS, som er en uafhængig tredjepart, der garanterer systemets sikkerhed. Systemet logger ikke IP-adresser og maskin-ID'er og krypterer alle behandlede data. Kun den ansvarlige sagsbehandler har adgang til indberetningerne.",
        noticeOfPersonConcerned: 'Meddelelse til den berørte person',
        theRecipientOfTheReport:
            'Modtageren af indberetningen er forpligtet til at give den person, der anmeldes, oplysninger om den anmeldte hændelse. I hvert enkelt tilfælde vil der blive foretaget en vurdering af, hvornår denne meddelelse kan videregives, således at meddelelsen ikke får konsekvenser for indsamlingen af bevismateriale.',
        noInformationEitherDirect:
            'Der vil ikke blive angivet nogen oplysninger, hverken direkte eller indirekte, om hvem der har foretaget indberetningen over for den pågældende person, selv om indberetteren har valgt at oplyse sin identitet.',
        questions: 'Spørgsmål',
        allQuestions: 'Alle spørgsmål om whistleblowerordningen kan rettes til',
    },

    newCaseFlow: {
        // New Case (1)
        headerInitiateReport: 'Start indberetning',
        headerDescription: 'Beskrivelse',
        headerChooseCaseworker: 'Vælg sagsbehandler',
        headerSummary: 'Opsummering',
        initiateReport: 'Start indberetning',
        howWouldYouLikeToAppear: 'Hvordan vil du fremstå i din indberetning?',
        or: 'eller',
        confidential: 'Fortrolig',
        whatIstheDifferenceBetweenAnAnonymous:
            'Hvad er forskellen på en anonym og en fortrolig indberetning?',

        // Pop-up Anonymous/Confidential
        popUpWhatIstheDifferenceBetweenAnAnonymous:
            'Hvad er forskellen på en anonym og en fortrolig indberetning?',
        popUpAnonymous: 'Anonym',
        popUpAnonymousYouProvideYourEmployer:
            'Du giver oplysninger om både den potentielle forseelse, den observerede hændelse eller ulykke, men du skal ikke at opgive nogen af dine personlige oplysninger.',
        popUpConfidential: 'Fortrolig',
        popUpConfidentialYouProvideYourEmployer:
            'Du giver oplysninger om den potentielle forseelse, observerede hændelse eller ulykke samt nogle oplysninger omkring dig selv. Disse oplysninger videregives ikke, men kan hjælpe troværdigheden i din indberetning.',

        // New case (1) - Confidential
        confidentialInformation: 'Fortrolig information',
        provideYourPersonalInformation:
            'Tilføj dine personlige oplysninger til indberetningen',
        secretInformationNote:
            'Dine oplysninger vil blive holdt hemmelige og kan kun ses af whistleblower-enheden.',
        enterLastName: 'Efternavn',
        enterPhone: '12345678',

        // New case (2)

        // New case (3)
        describeTheEvents: 'Beskrivelse af indberetning',
        theMoreDetailedTheDescription:
            'Jo flere detaljer man kan tilføje til beskrivelsen, jo nemmere kan man undersøge indberetningen',
        allFieldsWith: 'Alle felter markeret med * skal udfyldes',
        headline: 'Overskrift *',
        enterHeadline: 'Overskrift',
        description: 'Beskrivelse *',
        recordAVoice: 'Optag en talebesked',
        recordYourVoice: 'Optag din talebesked',
        yourVoiceIsAutomatically:
            'Din stemme bliver automatisk forvrænget for at sikre anonymitet.',
        listenToYourRecording: 'Lyt til din optagelse, før du vedhæfter den.',
        clickToStartRecording: 'Klik for at starte optagelsen',
        voiceMessage: 'Talebesked',
        stopRecording: 'Stoppe optagelsen',
        addRecording: 'Vedhæft talebesked',
        recordedVoiceMessage: 'Optaget talebesked',
        voiceRecording: 'Talebesked',
        enterDescription: 'Beskrivelse',
        uploadFiles: 'Upload filer',
        maximumFileSize: 'Maksimal uploadstørrelse: 100 MB',
        addFiles: 'Tilføj filer',
        youShouldBeAwareThatFilesCanContainMetadata:
            'Vær opmærksom på at filer kan indholde metadata, som potentielt kan afsløre din anonymitet.',
        informationYouCould: 'Oplysninger, du kan tilføje',
        whereAndWhen: 'Hvor og hvornår fandt hendelsen sted?',
        whatIsYourKnowledge: 'Hvilket kendskab har du til sagen?',
        whatDepartmentIsInvolved: 'Hvilken afdeling drejer det sig om?',
        whatIsYourRelationship: 'Hvilken relation har du til organisation?',
        // New Case (4)
        reportSummary: 'Opsummering af indberetningen',
        summaryCaseWorker: 'Hvem vil modtage indberetningen?',
        summaryHowWouldYouLikeToAppear:
            'Hvordan vil du fremstå i din indberetning?',
        summaryHeadline: 'Overskrift',
        summaryDescription: 'Beskrivelse',
        summaryFiles: 'Filer',
        expectToRecieveAnAnswer: 'Forvent at modtage et svar inden for 7 dage',
        submitReport: 'Indsend indberetning',

        // New Case (5)
        theReportHasBeenReceived: 'Indberetningen er modtaget',
        inOrderToAccessYourCaseAgain:
            'Du kan nu få adgang til din indberetning ved hjælp af følgende kode.',
        keepItSafe: 'Gem den sikkert og del den ikke med andre.',
        whistleblowerCode: 'Whistleblowerkode',
        copyToClipboard: 'Kopier kode',
        stayUpdated: 'Hold dig opdateret',
        youHaveTheOpportunityToReceiveAMail:
            'Modtag opdateringer ved at indtaste din e-mail.',
        itIsImportantToMention: 'Din e-mail deles ikke.',
        theWhistleblowerCodeWillNot:
            'Whistleblowerkoden vil IKKE blive sendt på mail.',
        email: 'Din e-mail adresse',

        chooseContactFrame: {
            pickYourCaseworker: 'Vælg sagsbehandler',
            pickExcludedCaseworker: 'Sagsbehandlere, der vil modtage rapporten',
            theContactWill:
                'Den valgte kontaktperson vil modtage indberetningen',
            theContactWillNot: 'Sagsbehandlere, der vil modtage rapporten',
            languages: 'Sprog',
            languagesNotGiven: 'Sprog ikke angivet',
            pickCaseworker: 'Vælg sagsbehandler',
        },

        // Pop-Up Whistleblower Code
        popUpRememberYourWhistleblowerCode: 'Husk din Whistleblowerkode',
        popUpItIsImportantThatYouSaveAndStoreTheWhistleblowerCode:
            'Det er vigtigt, at du husker, at gemme din Whistleblowerkode, så du kan tilgå din indberetning i fremtiden.',
    },

    accessCaseFlow: {
        // Access Case (1)
        accessExistingReport: 'Tilgå eksisterende indberetning',
        inOrderToAccessYourCase:
            'Brug den modtagne whistleblowerkode for at få adgang til din eksisterende indberetning.',
        missingCode: 'Mistet koden?',
        createNewRaport: 'Opret venligst en ny indberetning.',
        enterYourWhistleblowerCode: 'Indtast din whistleblowerkode',
        enterCode: 'Whistleblowerkode',

        // Access Case (2)
        logout: 'Log ud',
        info: 'Info',
        id: 'ID',
        date: 'Dato',
        appearance: 'Indberetter',
        caseworkers: 'Sagsbehandlere',
        files: 'Filer',
        fromWhistleblower: 'Fra whistlebloweren',
        fromCaseworker: 'Fra sagsbehandler',
        timeline: 'Tidslinje',
        description: 'Beskrivelse',
        messages: 'Beskeder',
        translate: 'Oversæt',
        translated: 'Oversat',
        anonymous: 'Anonym',
        enterMessage: 'Skriv besked',
        send: 'Send',
        logoutOfReport: 'Log ud af rapport',
        hiddenMsgOne: 'Dette er den dato, hvor indberetningen blev oprettet',
        hiddenMsgTwo:
            'Dette er deadlinen for sagsbehandlerens første svar til dig',
        hiddenMsgThree:
            'Dette er deadlinen for sagsbehandlerens feedback til dig',

        months: {
            january: 'Januar',
            february: 'Februar',
            march: 'Marts',
            april: 'April',
            may: 'Maj',
            june: 'Juni',
            july: 'Juli',
            august: 'August',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
    },

    header: {
        dashboard: 'Dashboard',
        cases: 'Indberetninger',
        clients: 'Klienter',
        notifications: 'Meddelelser',
        readAll: 'Læs alle',
    },

    dashboardPartner: {
        // Dashboard - Partner
        headlineDashboard: 'Dashboard',
        yourcases: 'Dine indberetninger',
        headline: 'Overskrift',
        notifications: 'Notifikationer',
        all: 'Alle',
        unread: 'Ulæst',
        noCases: 'Du har ingen indberetninger',
        noNotifications: 'Du har ingen notifikationer',

        yourStatistics: {
            yourStatistics: 'Din statistik',
            open: 'Åben',
            closed: 'Lukket',
            forwarded: 'Videresendt',
            totalCases: 'Alle indberetninger',
            activeCases: 'Aktive indberetninger',
            closedCases: 'Lukkede indberetninger',
            forwardedCases: 'Videresendte indberetninger',
        },
    },

    casesPartner: {
        // Cases - Partner
        headlineCases: 'Indberetninger',
        allCases: 'Alle indberetninger',
        headline: 'Overskrift',
        status: 'Status',
        filter: 'Filtre',
        removeFilter: 'Fjern filtre',
        myCases: 'Mine indberetninger',
        actionRequired: 'Handling påkrævet',
        awaitingResponse: 'Afventer svar',
        seeClosedCases: 'Se lukkede indberetninger',
        viewForwardedCases: 'Se videresendte sager',
        forwardedCases: 'Videresendte sager',
        searchCases: 'Søg efter sager',

        // Cases (closed) - Partner
        headlineClosedCases: 'Lukkede indberetninger',
        closedCases: 'Lukkede indberetninger',
    },

    caseworkerOpenReport: {
        transcription: 'Transskription',
        goBack: 'Tilbage',
        reportBy: 'Indberettet af',
        days: 'dage',
        hours: 'timer',
        thisReportHasBeen: 'Denne sag er blevet indsendt af',
        sevenDaysMessageOne:
            'Du skal give en kvittering for modtagelsen af en indberetning senest 7 dage efter modtagelsen af denne.',
        sevenDaysMessageTwo:
            'Send en besked til whistlebloweren herunder, og overhold lovgivningen.',
        ninetyDaysMessageOne:
            'Whistlebloweren skal modtage feedback hurtigst muligt og ikke senere end 3 måneder fra bekræftelsen af modtagelsen.',
        ninetyDaysMessageTwo:
            'Feedback kan fx være resultatet af en intern undersøgelse eller plan om samme.',
        name: 'Navn',
        exportCase: 'Eksporter',
        editInformation: 'Rediger oplysninger',
        changeResponsibility: 'Skift ansvarlig',
        thereAreNoOther: 'Der er ingen andre sagsbehandlere til rådighed',
        theNewResponsible:
            'Den nye sagsbehandler vil blive underrettet pr. e-mail',
        forward: 'Videresend',
        title: 'Titel',
        uncategorized: 'Ikke kategoriseret',
        categorizeCase: 'Kategoriser indberetning',
        selectCategory: 'Vælg en kategori for denne sag',
        selectedCategory: 'Den valgte kategori',
        close: 'Luk',
        saveThis: 'Gem',
        frequentCategories: 'Hyppige kategorier',
        allCategories: 'Alle kategorier',
        ViewMoreCategories: 'Se flere kategorier',
        ViewLess: 'Se mindre',
        status: 'Status',
        changeStatus: 'Skift status',
        youAreInTheProcess: 'Du er ved at skifte status til',
        awaitingResponse: 'Afventer svar',
        actionRequired: 'Handling påkrævet',
        confirm: 'Bekræft',
        caseDescription: 'Beskrivelse',
        noFilesUploaded: 'Der er ikke uploadet filer endnu',
        historyLog: 'Historik',
        preferredContact: 'Foretrukket kontakt',
        noMessages: 'Ingen beskeder',
        internalNotes: 'Interne noter',
        takeNotesHere: 'Tilføj dine noter her, så du ikke glemmer dem.',
        onlyTheCaseworkers: 'Dette er kun synligt for sagsbehandlerne',
        enterNote: 'Skriv note',
        saveNote: 'Gem',
        closeCase: 'Luk indberetning',
        solved: 'Løst',
        denied: 'Afvist',

        addComment: 'Tilføj kommentar',
        forwardedAt: 'Videresendt den',
        severityScore: 'Alvorlighedsgrad',
        comment: 'Kommentar',
        theCaseHasBeenForwardedTo: 'Sagen er blevet videresendt til',
        theCaseHasBeenForwardedBy: 'Sagen er blevet videresendt af',
        caseClosed: 'Sagen er lukket',
        toEnsureGDPR:
            'For at sikre overholdelse af GDPR vil denne sag blive slettet permanent om',
        theOptionToExportTheCase:
            'Muligheden for at eksportere sagen til en PDF er tilgængelig inden for denne tidsramme.',
        theDeletionOfTheCaseWillNot:
            'Sletningen af sagen vil ikke påvirke din statistikoversigt.',

        caseworkerPopup: {
            editCaseworker: 'Rediger sagsbehandler',
            close: 'Luk',
            caseworkerInCharge: 'Ansvarlig for sagen',
            newFlag: 'Ny',
            addCaseworkers: 'Tilføj sagsbehandler',
            noCaseworkerAvailable:
                'Der er ikke andre ledige sagsbehandlere, der kan tildeles denne sag',
            discardChanges: 'Kassér ændringer',
        },
    },

    caseClosedViewPartner: {
        // Case View (closed) - Partner
        closeDate: 'Lukkedato',
        comment: 'Kommentar',
        readMore: 'Læs mere',
        noComment: 'Ingen kommentar',
        automaticDelation: 'Automatisk sletning',
        exportCase: 'Eksporter sag',
        caseWillBePermanentlyDeleted:
            'For at sikre overholdelse af GDFR, vil denne sag blive slettet permanent',
    },

    clientsPartner: {
        // Clients - Partner
        headlineClients: 'Klienter',
        buttonAddNewClient: 'Tilføj ny klient',
        allClients: 'Alle klienter',
        name: 'Navn',
        openCases: 'Åbne indberetninger',
        closeCases: 'Lukkede indberetninger',
        newCases: 'Nye indberetninger',
    },

    singleClientViewPartner: {
        // Single Client View - Partner
        information: 'Informationer',
        lineOfContact: 'Kontaktkæde',
        cases: 'Indberetninger',
        buttonSave: 'Gem',

        // Single Client View - Partner (1)

        // Single Client View - Partner (2)
        saveOrderOfContacts: 'Gem rækkefølge af kontakter',
        lineOfContactsHaveNot: 'Kontaktkæden er endnu ikke etableret',
        deleteCaseworker: 'Slet sagsbehandler',
        youAreAboutToDeleteTheCaseworker: 'Du er ved at slette sagsbehandleren',
        theCaseworkerWillNotAbleToReceive:
            'Sagsbehandleren vil ikke længere være i stand til at modtage fremtidige indberetninger',
        theCaseworkerCannotBeDeletedIf:
            'Sagsbehandleren kan ikke slettes, hvis vedkommende er tilknyttet en åben sag',
        thisActionCannotBeUndone: 'Denne handling kan ikke fortrydes',
        caseworkerHasCasesAndCannotBeDeleted:
            'Sagsbehandleren har en eller flere aktive sager og kan derfor ikke slettes',
        contactAdded: 'En ny sagsbehandler er blevet tilføjet',
        contactUpdated: 'Sagsbehandler opdateret',
        inviteCaseworker: 'Inviter sagsbehandler',
        addLanguage: 'Tilføj sprog',
        searchLanguage: 'Søg på sprog',
        languageNotFound: 'Sprog ikke fundet',

        // Single Client View - Partner (3)
        allCases: 'Alle indberetninger',
        company: 'Virksomhed',
        headline: 'Overskrift',
        status: 'Status',
        actionRequired: 'Handling påkrævet',
        awaitingResponse: 'Afventer svar',

        // Single Client View - Partner (4)

        // Pop-up Change billing contact
        // Export and reopen case Modal
        reOpenCase: 'Genoptag sag',
        youAreInTheProcessOfExporting: 'Du er i gang med at eksportere sag',
        youAreInTheProcessOfReopening: 'Du er i gang med at genåbne sag',
        caseInfo: 'Sagsinformation',
        arrivalDate: 'Modtaget den',
        closeDate: 'Lukket den',
        caseID: 'Sags-ID',
    },

    addNewClientPartner: {
        // Add new client - Partner (1)
        headlineAddNewClient: 'Tilføj ny klient',
        searchForCompanyNameOrCompanyNumber:
            'Søg efter virksomhedens navn eller CVR-nummer',
        findCompany: 'Find selskab',
        enterInformationManually: 'Indtast oplysningerne manuelt',

        // Add new client - Partner (2)
        // Add new client - Partner (3)
        thanksForYourSubscription: 'Du har tilføjet en ny klient',
        informationOnHowToGetStarted: 'Sådan kommer din klient i gang',
        linkText:
            'Linket giver dig adgang til den digitale whistleblower indberetningskanal, hvor indberetningerne kan indsendes. Del dette med din klients organisation.',
        employeeLink: 'Medarbejder link',
        whistleblowerPolicy: 'Whistleblowerpolitik',
        whistleblowerPolicyText:
            'Din klients whistleblowerpolitik findes herunder: Henvis herefter din klient til at logge ind på platformen for at tilgå yderligere materiale.',
        download: 'Download',
        sendLinkText: 'Send oplysningerne til din klient',
        buttonSendEmail: 'Send email',

        // Pop-up Send Email
        popUpSendEmail: 'Send email',
        popUpText:
            'Du er ved at sende linket til indberetningskanalen og whistleblowerpolitikken til',
        popUpButtonSendInformation: 'Send oplysninger',

        // Pop-up Add new client (Cancel)
        popUpAreYouSureYouWantToCancelThisSession:
            'Er du sikker på, at du vil afbryde denne session?',
        popUpYouWillLoseAllYourProgress: 'Du vil miste alle dine fremskridt',
        popUpButtonNo: 'Nej',
        popUpButtonYes: 'Ja',
    },

    settingsPartner: {
        headlineSettings: 'Indstillinger',
        myAccount: 'Min konto',
        automaticReply: 'Automatisk svar',
        billing: 'Fakturering',
        users: 'Brugere',
        logOut: 'Log ud',

        // Settings - Partner - My account
        edit: 'Rediger',
        oldPassword: 'Gammel adgangskode',
        changePassword: 'Skift adgangskode',
        newPassword: 'Ny adgangskode',
        passwordUpdate: 'Færdig!',
        passwordUpdadetSuccessfully: 'Adgangskoden er blevet opdateret.',
        defaultLanguage: 'Standardsprog',
        emailUpdated: 'e-mailen er opdateret!',
        verificationLinkSent:
            'Et verifikationslink er blevet sendt til den nye e-mail. Du vil blive logget ud om',
        seconds: 'sekunder.',

        // Settings - Partner - Automatic reply
        reactivateAutoReply: 'Genaktiver automatisk svar',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        autoReplyAlertMessage:
            'Det automatiske svar vil blive sendt 15 minutter efter modtagelsen af indberetningen',

        // Settings - Partner - Organization

        // Settings - Partner - Users
        name: 'Navn',
        added: 'Tilføjet',
        openCases: 'Åbne sager',
        closedCases: 'Lukkede sager',
        buttonAddNewUser: 'Tilføj ny bruger',

        // Pop-up Add new user
        popUpAddingANewUser: 'Tilføj bruger',
        popUpAddPhoto: 'Tilføj billede',
        popUpChangePhoto: 'Skift billede',
        popUpLastName: 'Efternavn',
        popUpText:
            'Brugeren skal bekræfte sin konto igennem en aktiveringsmail',
        popUpButtonAddUser: 'Tilføj bruger',

        oldAddPaymentMethodFrame: {
            paymentMethods: 'Fakturering',
            addPaymentMethod: 'Tilføj betalingsmetode',
            defaultPaymentMethod: 'Standardbetalingsmetode',
            yourDefaultPaymentMethod:
                'Standardbetalingsmetoden vil blive anvendt til alle fremtidige betalinger. Hvis du har et skyldigt abonnementsgebyr, forsøger vi at opkræve det automatisk.',
            defaultPaymentMethodMissing:
                'Der mangler en standardbetalingsmetode',
            otherPaymentMethods: 'Andre betalingsmetoder',
            otherPaymentMethodsYouHave:
                'Andre betalingsmetoder, du har tilføjet. Markér den, du ønsker at bruge som standard.',
            currentPlan: 'Nuværende abonnement',
            belowYouCanSee: 'Her kan du se det valgte abonnement.',
            cardNumber: 'Kortnummer',
            expireDate: 'Udløbsdato',

            addPaymentMethodModal: {
                addPaymentMethod: 'Tilføj betalingsmetode',
                card: 'Kort',
                invoice: 'Faktura',
                errorLoadingStripe: 'Error, loading Stripe',
                fullName: 'Navn',
                bankInformation: 'Bankoplysninger',
                cardInformation: 'Kortoplys',
                loginMessage:
                    'Det kan tage et par minutter. Vær venligst tålmodig.',
            },
        },

        // Settings - Partner - User (Single view) AND Settings - Partner - User (Single)
        userInformation: 'Brugerinformation',

        // Pop-up Remove user

        // Settings - Partner - Invoices
    },

    clientView: {
        information: 'Information',
        lineOfContact: 'Sagsbehandlere',
        customization: 'Tilpasning',

        // Client_admin View - Information
        whistleblowerPolicy: 'Whistleblowerpolitik',
        reportingChannel: 'Rapporteringskanal',
        copyLinkReportingChannel: 'Kopier linket til din rapporteringskanal',
        downloadQRCodeImage: 'Download billede her',
        qrCode: 'QR-Kode',
        downloadQRCode: 'Download rapporteringskanal QR-kode',
        copyLinkWhistleblowerPolicy:
            'Kopier link til din whistleblower-politik',
        trainingMaterial: 'Træningsmateriale',
        copyLinkToTrainingMaterial: 'Kopier link til dit træningsmateriale',
        reportingChannelLanguage: 'Rapporteringskanalens sprog:',
        reportingChannelLanguageError:
            'Fejl ved valg af rapporteringskanalens sprog:',

        contactPerson: 'Kontaktperson',

        // Client_admin View - Line of Contact
        willBeDisplayedReportLink:
            'Nedenstående oplysninger vil blive vist til whistlebloweren i rapporteringslinket',
        languages: 'Sprog',
        notes: 'Noter',
        preview: 'Forhåndsvisning',
        pickCaseworker: 'Vælg din sagsbehandler',
        chosenContactWillReceive:
            'Den valgte kontaktperson vil modtage rapporten',
        editProfilePicture: 'Rediger profilbillede',
        addPhoto: 'Tilføj billede',
        changePhoto: 'Skift billede',

        //Client_admin View - Customization
        addQuestion: 'Tilføj spørgsmål',
        reportQuestions: 'Indberette spørgsmål',

        // Client_admin View - Invoices

        // Pop-up Change billing contact
        // Use already existing pop-up material!!!
    },
    verifyEmail: {
        verifyEmail: 'Bekræft e-mail',
        emailVerified: 'E-mailen er blevet verificeret!',
        yourEmailHasBeen:
            'Du kan nu besøge login-siden og logge ind med din e-mail samt den adgangskode, du lige har oprettet.',
        goToLogin: 'Gå til login',
        verifyNewEmail: 'Bekræftet!',
        loginWithNewEmail: 'Du kan nu logge ind med den nye e-mail.',
    },
    // reusable components
    nextButton: {
        nextStep: 'Næste',
    },
    finishButton: {
        finish: 'Afslut',
    },
    closeButton: {
        close: 'Luk',
    },
    dropdown: {
        countryNotFound: 'Land ikke fundet',
    },
    copiedText: {
        copied: 'Kopieret',
    },
    you: {
        you: 'Du',
    },
    warningText: {
        warning: 'Advarsel',
    },
    deleteText: {
        delete: 'Slet',
    },
    inactiveModal: {
        inactivityWarning: 'Advarsel om inaktivitet',
        youWillBeLoggedOut: 'Du bliver snart logget ud',
        youHaveBeenInactive:
            'Du har været inaktiv i 14 minutter. Din nuværende session udløber om lidt',
        stayLoggedIn: 'Fortsæt session',
    },
    notifications: {
        newMessage: 'Ny besked',
        deadlineAlert: 'Kommende deadline',
        newReport: 'Ny rapport',
        reportAssigned: 'En eksisterende rapport er blevet tildelt dig',
        reportUnassigned: 'Rapporten er blevet tildelt en anden',
    },
    historyLog: {
        // These were missing until 2024-05-15 and then added by DeepL
        caseClosed: 'Sagen blev lukket',
        caseClosedBy: 'Denne sag blev lukket af',
        caseReopened: 'Sagen blev genåbnet',
        caseReopenedBy: 'Denne sag blev genåbnet af',
        automaticReplySent: 'Automatisk svar sendt',
        automaticReplyWasSent: 'Automatisk svar blev sendt af',
        internalNote: 'Intern note',
        internalNoteHasBeen: 'Internt notat er skrevet af',
        caseworkerAdded: 'Sagsbehandler tilføjet',
        caseworker: 'Sagsbehandler',
        wasAddedBy: 'blev tilføjet af',
        newFile: 'Ny fil',
        newFileWasAdded: 'En ny fil blev tilføjet af',
        whistleblower: 'whistleblower',

        forwarded: 'Videresendt',
        exported: 'Eksporteret',
        changeResponsible: 'Ændring af ansvarlig',
        caseViewed: 'Sagen er set',
        fileUploaded: 'Fil uploadet',
        newMessage: 'Ny meddelelse',
        statusUpdated: 'Status opdateret',
        caseDelegation: 'Tildeling af sagen',
        reportCreated: 'Rapport oprettet',
        to: 'til',
        by: 'af',
        from: 'fra',
        caseHasBeenForwarded: 'Sagen er blevet videresendt',
        caseHasBeenExported: 'Sagen er blevet eksporteret',
        caseHasBeenReassigned: 'Sagen er blevet omfordelt',
        caseHasBeenViewed: 'Sagen er blevet set',
        categoryIsChanged: 'Kategorien er ændret',
        messageFrom: 'Meddelelse fra',
        caseAssigned: 'Tildelt sag',
        newCase: 'Ny sag',
    },

    // Validation
    validation: {
        nameOfTheCityMustBeAtLeast:
            'Navnet på byen skal være længere end ét bogstav',
        nameOfTheCityCannot:
            'Navnet på byen kan ikke være længere end 85 bogstaver',
        companyIdentificationNumberMust: 'CVR-nummeret er præcis 8 tal',
        addressIsToShort: 'Adressen er for kort',
        addressIsToLong: 'Adressen er for lang',
        organizationIsToShort: 'Virksomhedsnavnet er for kort',
        organizationIsToLong: 'Virksomhedsnavnet er for langt',
        mustBeAValidEmail: 'Emailen er ikke gyldig',
        firstNameCannotBeShorter:
            'Fornavnet må ikke være kortere end to bogstaver',
        firstNameCannotBeLonger:
            'Fornavnet må ikke være længere end 26 bogstaver',
        lastNameCannotBeShorter:
            'Efternavnet må ikke være kortere end to bogstaver',
        lastNameCannotBeLonger:
            'Efternavnet må ikke være længere end 26 bogstaver',
        passwordMustBeAtLeast: 'Adgangskoden skal mindst være 8 tegn',
        yourPasswordMustContainerAtLeastOneUppercase:
            'Din adgangskode skal indeholde mindst et stort bogstav',
        yourPasswordMustContainerAtLeastOneLowercase:
            'Din adgangskode skal indeholde mindst et lille bogstav',
        yourPasswordMustContainerAtLeastOneSymbol:
            'Din adgangskode skal indeholde mindst et symbol',
        yourPasswordMustContainerAtLeastOneNumber:
            'Din adgangskode skal indeholde mindst et tal',
        passwordsMustMatch: 'Din adgangskoder er ikke ens',
        notAValidPhoneNumber: 'Telefon nummeret er ikke gyldigt',
        titleOfPositionMustBe: 'Din titel skal være mindst to bogstaver',
        titleOfPositionCannotBe: 'Din titel må ikke være mere end 26 bogstaver',
        mustSetAPriority: 'Du skal angive en prioritet',
        notAValidZipCode: 'Postnummeret er ikke gyldigt',
        isLongEnough: 'Mindst 8 tegn',
        hasUpperCaseLetter: 'Mindst 1 stort bogstav [A-Z]',
        hasLowerCaseLetter: 'Mindst 1 lille bogstav [a-z]',
        hasNumber: 'Mindst 1 tal [0-9]',
        hasSpecialCharacter: 'Mindst 1 specialtegn [!@#$%^&&*]',
        passwordsMatch: 'Adgangskoder matcher',
    },

    // Forward Report
    forwardReportPage: {
        selectCatagory: 'Vælg kategori',
        uncategorized: 'Ingen kategori',
        accusationAndEvidenceScore: 'Beskyldning og bevis niveau',
        accusationScore: 'Beskyldnings score',
        evidenceScore: 'Bevis score',
        severityScore: 'Samlet alvorlighedsgrad',
        lineOfContact: 'Kontaktkæde',
        lineOfContactReminder:
            'Sørg for, at personen på ingen måde er indblandet i denne sag',
        comment: 'Kommentar',
        pleaseLeaveComment: 'Tilføj venligst en kommentar her',
        forwardCase: 'Videresend sagen',
        popUpConfirmForwardPartOne:
            'Er du sikker på, at du ønsker at videresende',
        popUpConfirmForwardPartTwo: 'til',
        popUpNo: 'Nej',
        popUpYes: 'Ja, jeg er helt sikker',
        minimumAbbreviated: 'Min',
        maximumAbbreviated: 'Max',
        saveThis: 'Gem',
    },

    // global
    trialWarning: {
        goToSettings: 'Gå til indstillinger',
        yourTrialHasEnded: 'Din prøveperiode er afsluttet.',
        yourFreeTrialEndedOn: 'Din gratis prøveperiode sluttede den:',
        yourFreeTrialEndsOn: 'Prøveperioden udløber om',
        day: 'dag',
        days: 'dage',
    },
    categories: {
        accounting_irregularities: 'Regnskabsmæssige uregelmæssigheder',
        bribery: 'Bestikkelse',
        consumer_protection: 'Forbrugerbeskyttelse',
        duty_of_confidentiality: 'Tavshedspligt',
        embezzlement: 'Underslæb',
        extortion: 'Afpresning',
        financial_services_products_and_markets:
            'Finansielle tjenesteydelser, produkter og markeder',
        food_safety_animal_health_and_welfare:
            'Fødevaresikkerhed, dyresundhed og dyrevelfærd',
        forgery: 'Dokumentforfalskning',
        fraudulence: 'Bedrageri',
        money_laundering_and_terrorist_financing:
            'Hvidvaskning og finansiering af terrorisme',
        occupational_health_and_safety: 'Arbejdsmiljø og sikkerhed',
        products_safety_and_compliance: 'Produkters sikkerhed og overholdelse',
        protection_of_environment: 'Beskyttelse af miljøet',
        protection_of_privacy_and_personal_data_and_security_of_network_and_information_systems:
            'Beskyttelse af privatlivets fred, personoplysninger og sikkerhed i net- og informationssystemer',
        public_health: 'Folkesundhed',
        public_procurement: 'Offentlige indkøb',
        radiation_protection_and_nuclear_safety:
            'Strålebeskyttelse og nuklear sikkerhed',
        serious_or_recurring_conflicts_at_the_workplace:
            'Alvorlige eller tilbagevendende konflikter på arbejdspladsen',
        sexual_harassment_and_sexual_assault:
            'Seksuel chikane og seksuelle overgreb',
        the_financial_interests_of_the_european_union:
            'Den Europæiske Unions finansielle interesser',
        the_internal_market_of_the_european_union_including_state_aid_and_corporate_taxation:
            'Den Europæiske Unions indre marked, herunder statsstøtte ',
        theft: 'Tyveri',
        conflict_of_interest: 'Interessekonflikter',
        non_whistleblower_case: 'Ikke whistleblower-sag',
        transport_safety: 'Transportsikkerhed',
    },
    integrationModal: {
        thisAppWouldLikeToAccess:
            'Denne app vil gerne have adgang til din Walor-konto. Du skal give tilladelse til dette',
        thisAppRequires: 'Denne app kræver tilladelse til ',
        whistleblowingChannel: 'indberetningskanalen”',
        whistleblowingPolicy: 'whistleblowerpolitikken',
        employeeGuides: 'medarbejdervejledingerne',
        companyName: 'virksomhedens navn',
        approve: 'Godkend',
        non_whistleblower_case: 'Ikke whistleblower-sag',
    },

    dacarpoPolicy: {
        itIsImportant:
            'Det er vigtigt at du gemmer whistleblowerkoden fra indberetningen, så du kan tilgå sagen efterfølgende.',
        allQuestions:
            'Alle spørgsmål om whistleblowerordningen kan rettes till HR afdelingen.',

        // Info list
        youWillReceive:
            'Du vil modtage en kvittering for modtagelse af indberetningen inden for syv dage.',
        afterThis:
            'Herefter vil der blive foretaget en indledende undersøgelse af indberetningen.',
        ifTheReport:
            'Hvis det viser sig, at indberetningen er åbenbart ubegrundet, vil den blive afvist. Den person, der har anmeldt sagen, vil blive underrettet herom hurtigst muligt.',
        howeverIfTheInitial:
            'Hvis den indledende undersøgelse viser, at indberetningen ikke kan karakteriseres som åbenlyst ubegrundet, vil den blive undersøgt yderligere. ',
        theCaseWillThen:
            'Sagen vil derefter blive behandlet internt. Whistlebloweren vil hurtigst muligt modtage feedback om eventuelle opdateringer eller resultater.',
        thisInternalInvestigation:
            'Denne interne undersøgelse kan have konsekvenser for den eller de involverede personer.',
        ifTheCaseIs:
            'Hvis sagen er af særlig alvorlig karakter, kan den også blive videregivet til politiet med henblik på yderligere efterforskning.',
        afterConducting:
            'Når alle undersøgelser er gennemført og eventuelle sanktioner er pålagt, vil sagen blive afsluttet. Den slettes derefter fra systemet.',
    },

    inviteCaseworkerModal: {
        // MODAL
        inviteNewCaseworker: 'Tilføj en ny sagsbehandler',
        sendInvitation: 'Send invitationen',
    },

    caseworkerSetupAccountPage: {
        // Caseworker onboarding page
        setupYourWalor: 'Opsæt din Walor konto',
        youHaveBeenInvited: 'Du er blevet inviteret til',
        onWalorAs: 'på Walor som en',
        caseworker: 'sagsbehandler',
        additionalInformation: 'Supplerende oplysninger',
        preview: 'Forhåndsvisning',

        // Personal Information Form
        // Additional information form
        profilePicture: 'Profilbillede',
        thisPictureWillAppear:
            'Dette billede vises, når whistlebloweren vælger sagsbehandlere.',
        addPicture: 'Tilføj billede',
        notes: 'Noter',
        typeNoteHere: 'Skriv en note her..',
        addLanguages: 'Tilføj sprog',
        whichDepartmentAreYou: 'Hvilken afdeling er du en del af?',
        chooseOneOption: 'Vælg én af mulighederne',
        humanResources: 'Human Resources',
        compliance: 'Compliance',
        finance: 'Økonomi',
        operations: 'Drift',
        marketing: 'Marketing',
        other: 'Andre',

        //Preview
        languages: 'Sprog',
        pickCaseworker: 'Vælg sagsbehandler',

        // Notes hover-effect
        notesHover: 'Noterne vil blive vist til whistlebloweren',

        // Languages hover-effect
        languagesHover:
            'Angiv de sprog, du taler, så whistlebloweren kan vælge den bedst egnede sagsbehandler',
    },
    aalborgTeaterPolicy: {
        whistleblowerSchemeEnsuresProtection:
            's whistleblowerordning sikrer beskyttelse af personer, der indberetter om alvorlige lovovertrædelser og forhold. Denne ordning kan anvendes, hvis du oplever, er bekendt med eller har grund til at formode, at sådanne overtrædelser der har fundet sted hos',
        theWhistleblowerSchemeCanBeUsed:
            'Whistleblowerordningen kan bruges til at behandle oplysninger om alvorlige overtrædelser af loven, forsøg på at skjule sådanne overtrædelser eller øvrige alvorlige forhold, som har fundet sted eller med stor sandsynlighed vil finde sted hos',
        aReportCanBeMadeEither:
            'En indberetning kan kun ske fortrolligt, hvilket indebærer at whistlebloweren videregiver personlige oplysninger. Hvis der deles personlige oplysninger i din indberetning, vil kun de personer, der er autoriseret til at undersøge sagen, have adgang til dem. Fortroligheden af den indsendte indberetning vil derfor under alle omstændigheder blive respekteret.',
    },
    reusable: {
        voiceRecording: 'Talebesked',
        email: 'Email',
        personalInformation: 'Personlige oplysninger',
        firstName: 'Fornavn',
        lastName: 'Efternavn',
        cancel: 'Fortryd',
        address: 'Adresse',
        city: 'By',
        zipCode: 'Postnummer',
        country: 'Land',
        numberOfEmployees: 'Antal medarbejdere',
        phone: 'Telefon nr',
        position: 'Stilling',
        companyInformation: 'Oplysninger om virksomheden',
        companyNumber: 'CVR-nummer',
        companyName: 'Virksomhedens navn',
        workEmail: 'Arbejdsmail',
        enterWorkEmail: 'Arbejdsmail',
        password: 'Adgangskode',
        anonymous: 'Anonym',
        files: 'Filer',
        date: 'Dato',
        messages: 'Beskeder',
        organization: 'Organisation',
        new: 'Ny',
        search: 'Søg',
        category: 'Kategori',
        save: 'Gem',
        confirmPassword: 'Bekræft adgangskode',
        name: 'Navn',

        closed: 'Lukket',
        archived: 'Arkiveret',
    },

    statisticsPage: {
        statistics: 'Statistik',
        overview: 'Oversigt',
        compareToLastPeriod: 'Sammenlign med sidste periode',
        noData: 'Ingen data',
        lastYear: 'sidste år',
        anonymousVsConfidential: 'Anonym vs. fortrolig',
        actionStats: 'Handlingsstatistik',
        avgDaysToFirstResponse: 'Gennemsnitlige dage til første svar',
        avgDaysToClosedCase: 'Gennemsnitlige dage til lukket sag',
        distributionOfCategories: 'Fordeling af kategorier',
        casesByMonth: 'Sager fordelt på måneder',
        months: {
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'Maj',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Okt',
            nov: 'Nov',
            dec: 'Dec',
        },
    },
}

export default dk

import produce from 'immer'
import { ReportInternalNoteInterface } from '../../ts/interfaces/ReportInternalNoteInterface'
import { ReportInternalNoteAction } from '../actions'
import { ReportInternalNoteActionType } from '../action-types'

interface ReportInternalNoteState {
    notesLoading: boolean
    notesError: null | string
    newNoteLoading: boolean
    newNoteError: null | string
    notes: ReportInternalNoteInterface[]
}

const initialState: ReportInternalNoteState = {
    notesLoading: false,
    notesError: null,
    newNoteLoading: false,
    newNoteError: null,
    notes: [],
}

const reducer = produce(
    (
        state: ReportInternalNoteState = initialState,
        action: ReportInternalNoteAction
    ) => {
        switch (action.type) {
            case ReportInternalNoteActionType.GET_INTERNAL_NOTES:
                state.notesLoading = false
                state.notesError = null
                state.notes = action.payload

                return state

            case ReportInternalNoteActionType.CREATE_INTERNAL_NOTE:
                state.newNoteLoading = false
                state.newNoteError = null
                state.notes.unshift(action.payload)

                return state

            case ReportInternalNoteActionType.SET_NOTES_LOADING:
                state.notesLoading = true

                return state

            case ReportInternalNoteActionType.SET_CREATE_NOTE_LOADING:
                state.newNoteLoading = true

                return state

            case ReportInternalNoteActionType.SET_CREATE_NOTE_ERROR:
                state.newNoteLoading = false
                state.newNoteError = action.payload

                return state

            case ReportInternalNoteActionType.CLEAR_INTERNAL_NOTES:
                state.notes = []

                return state

            default:
                return state
        }
    }
)

export default reducer

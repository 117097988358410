import { useTranslation, useTypedSelector } from '../../../../../hooks'
import styled from 'styled-components'
import {
    NewCasePageFrame,
    NewCasePageFrameHeading,
    NewCasePageFrameTagline,
} from '../styled'
import { NextButton } from '../../../../reusable/buttons'
import noProfileImage from '../../../../../assets/images/no-profile-image.svg'
import { useState } from 'react'
import {
    AvatarImage,
    ChooseContactWrapper,
    ContactCard,
    ContactContainer,
    ContactHeading,
    ContactHeadingContainer,
    ContactPosition,
    Flag,
    FlagContainer,
    Flex,
    GreyFont,
    LanguagesContainer,
} from '../styled/ChooseContactCard'
import NewCaseContactTooltip from '../components/NewCaseContactTooltip'
import { CountryInterface } from '../../../../../state/reducers/countriesReducer'
import { ReactComponent as ArrowLeft } from '../../../../../assets/images/chevron-left.svg'
import { ReactComponent as ArrowRight } from '../../../../../assets/images/chevron-right.svg'

interface IsActiveProp {
    isActive: boolean
}

const StandardContactCard = styled(ContactCard)<IsActiveProp>`
    cursor: pointer;
    background: ${({ theme, isActive }) =>
        isActive ? theme.white : `${theme.white}0`};
`

const PickCaseWorkerContainer = styled.div<IsActiveProp>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 2rem;
    background-color: ${({ theme, isActive }) => isActive && theme.black};
    margin: 0 auto;
    margin-top: 2.7rem;

    ${StandardContactCard}:hover & {
        background-color: ${({ theme, isActive }) => !isActive && theme.black};
    }
`

const RadioButtonCircle = styled.div<IsActiveProp>`
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : theme.white};
    border: 1px solid
        ${({ theme, isActive }) => (isActive ? theme.white : theme.black)};

    ${StandardContactCard}:hover & {
        border: 1px solid ${({ theme }) => theme.white};
    }
`

const RadioButtonText = styled.p<IsActiveProp>`
    font-size: 1.2rem;
    color: ${({ theme, isActive }) => (isActive ? theme.white : theme.black)};

    ${StandardContactCard}:hover & {
        color: ${({ theme }) => theme.white};
    }
`
const ChevronBase = styled.div`
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.grey};
    cursor: pointer;

    display: flex;
    align-items: center;

    position: absolute;
    top: 60%;

    &:hover {
        opacity: 0.7;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
`

const ChevronLeftBg = styled(ChevronBase)`
    left: 23%;

    @media screen and (max-width: 1000px) {
        left: 3.5%;
    }
`

const ChevronRightBg = styled(ChevronBase)`
    left: 75%;

    @media screen and (max-width: 1000px) {
        left: 93%;
    }
`

const CevronLeft = styled(ArrowLeft)`
    width: 3rem;
    padding-inline-end: 0.2rem;
`

const ChevronRight = styled(ArrowRight)`
    width: 3rem;
    padding-inline-start: 0.2rem;
`

interface ChooseContactFrameProps {
    updateReport: (key: string, value: Array<any>) => void
    updatePage: () => void
    selectedContact: any
}

const CARD_THRESHOLD = 3

const ChooseContactFrame: React.FC<ChooseContactFrameProps> = ({
    updateReport,
    updatePage,
    selectedContact,
}) => {
    const { lineOfContacts } = useTypedSelector((state) => state.lineOfContact)
    const [selectedContacts, setSelectedContacts] =
        useState<Array<any>>(selectedContact)
    const [scrollPosition, setScrollPosition] = useState<number>(0)

    const translation = useTranslation()

    const toggleContact = (contact: any) => {
        const exist = !!selectedContacts.find(
            (selectedContact) => contact.id === selectedContact.id
        )
        if (exist) {
            setSelectedContacts([
                ...selectedContacts.filter(
                    (selectedContact) => selectedContact.id !== contact.id
                ),
            ])
        } else {
            setSelectedContacts([...selectedContacts, contact])
        }
    }

    const caseworkersCards = lineOfContacts.length
    const shouldShowActiveSlider = caseworkersCards > CARD_THRESHOLD

    const scrollLeft = () => {
        setScrollPosition(
            scrollPosition === 0 ? caseworkersCards : scrollPosition - 1
        )
    }

    const scrollRight = () => {
        setScrollPosition(
            scrollPosition === caseworkersCards - 3
                ? caseworkersCards - 3
                : scrollPosition + 1
        )
    }

    return (
        <NewCasePageFrame>
            <NewCasePageFrameHeading>
                {translation.newCaseFlow.chooseContactFrame.pickYourCaseworker}
            </NewCasePageFrameHeading>
            <NewCasePageFrameTagline>
                {translation.newCaseFlow.chooseContactFrame.theContactWill}
            </NewCasePageFrameTagline>
            {shouldShowActiveSlider && (
                <>
                    {scrollPosition > 0 && (
                        <ChevronLeftBg>
                            <CevronLeft onClick={scrollLeft} />
                        </ChevronLeftBg>
                    )}

                    {scrollPosition < caseworkersCards - 3 && (
                        <ChevronRightBg>
                            <ChevronRight onClick={scrollRight} />
                        </ChevronRightBg>
                    )}
                </>
            )}
            <ContactContainer>
                <ChooseContactWrapper
                    style={{
                        transform: `translateX(-${scrollPosition * 24}rem)`,
                    }}
                >
                    {lineOfContacts.map((contact) => (
                        <StandardContactCard
                            isActive={
                                !!selectedContacts.find(
                                    (c) => c.id === contact.id
                                )
                            }
                            onClick={() => toggleContact(contact)}
                            key={contact.id}
                        >
                            <NewCaseContactTooltip
                                note={contact.contact_information.note}
                            />
                            <Flex>
                                <AvatarImage
                                    src={
                                        contact.contact_information
                                            .profile_img === null
                                            ? noProfileImage
                                            : contact.contact_information
                                                  .profile_img
                                    }
                                    alt="Profile Image"
                                />
                                <ContactHeadingContainer>
                                    <ContactHeading>
                                        {contact.first_name +
                                            ' ' +
                                            contact.last_name}
                                    </ContactHeading>
                                    <ContactPosition>
                                        {contact.contact_information
                                            .position === null
                                            ? 'Position not given'
                                            : contact.contact_information
                                                  .position}
                                    </ContactPosition>
                                </ContactHeadingContainer>
                                <LanguagesContainer>
                                    <GreyFont>
                                        {
                                            translation.newCaseFlow
                                                .chooseContactFrame.languages
                                        }
                                    </GreyFont>
                                    <FlagContainer>
                                        {contact.languages.data.length === 0 ? (
                                            <ContactPosition>
                                                {
                                                    translation.newCaseFlow
                                                        .chooseContactFrame
                                                        .languagesNotGiven
                                                }
                                            </ContactPosition>
                                        ) : (
                                            contact.languages.data.map(
                                                (
                                                    language: CountryInterface
                                                ) => (
                                                    <Flag
                                                        src={language?.flag}
                                                    />
                                                )
                                            )
                                        )}
                                    </FlagContainer>
                                </LanguagesContainer>
                            </Flex>
                            <PickCaseWorkerContainer
                                isActive={
                                    !!selectedContacts.find(
                                        (c) => c.id === contact.id
                                    )
                                }
                            >
                                <RadioButtonCircle
                                    isActive={
                                        !!selectedContacts.find(
                                            (c) => c.id === contact.id
                                        )
                                    }
                                ></RadioButtonCircle>
                                <RadioButtonText
                                    isActive={
                                        !!selectedContacts.find(
                                            (c) => c.id === contact.id
                                        )
                                    }
                                >
                                    {
                                        translation.newCaseFlow
                                            .chooseContactFrame.pickCaseworker
                                    }
                                </RadioButtonText>
                            </PickCaseWorkerContainer>
                        </StandardContactCard>
                    ))}
                </ChooseContactWrapper>
            </ContactContainer>
            <NextButton
                handleOnClick={() => {
                    updateReport('contacts', selectedContacts)
                    updatePage()
                }}
                isActive={selectedContacts.length > 0}
            />
        </NewCasePageFrame>
    )
}

export default ChooseContactFrame

import { useState } from 'react'
import styled from 'styled-components'
import noProfileImage from '../../../../assets/images/no-profile-image.svg'
import {
    AvatarImage,
    ChooseContactWrapper,
    ContactCard,
    ContactHeading,
    ContactHeadingContainer,
    ContactPosition,
    Flag,
    FlagContainer,
    Flex,
    GreyFont,
    LanguagesContainer,
} from '../../whistleblower/new-case/styled/ChooseContactCard'
import { AdditionalInfo, PersonalInfo } from '../SetupAccountPage'
import { FiInfo } from 'react-icons/fi'
import { useTranslation } from '../../../../hooks'

interface IsActiveProp {
    isActive: boolean
}

interface HiddenNoteProps {
    isVisible: boolean
}

const PreviewGroup = styled.div`
    background: white;
    width: 38.7rem;
    height: 42.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1024px) {
        width: auto;
        height: auto;
        max-width: 38.7rem;
        max-height: 42.9rem;
    }
`

const IconWrapper = styled.div`
    display: flex;
    display: inline-block;
    vertical-align: middle;
    margin-left: auto;
`

const Info = styled(FiInfo)`
    color: ${({ theme }) => theme.lineGrey};
    font-size: 2rem;
`

const HiddenNote = styled.div<HiddenNoteProps>`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    position: absolute;
    left: 0;
    top: 1.5rem;
    width: 20.5rem;
    height: 10.7rem;
    border-radius: 1.6rem;
    padding: 1.6rem;
    transition: 0.8s ease;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.grey};
`

const NoteText = styled.div`
    margin-top: 0.6rem;
    height: 5.5rem;
    overflow-wrap: break-word;
    overflow-y: auto;
`

const GreyLine = styled.hr`
    border: none;
    height: 1px;
    background: ${({ theme }) => theme.lineGrey};
    width: 100%;
    margin: 1rem 0 0;
`

const CustomText = styled.p`
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
`

const StandardContactCard = styled(ContactCard)<IsActiveProp>`
    cursor: pointer;
    background: ${({ theme, isActive }) =>
        isActive ? theme.platformGrey : theme.white};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media (max-width: 1024px) {
        margin: 2rem;
    }
`

const PickCaseWorkerContainer = styled.div<IsActiveProp>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 2rem;
    background-color: ${({ theme, isActive }) => isActive && theme.black};
    margin: 0 auto;
    margin-top: 2.7rem;

    ${StandardContactCard}:hover & {
        background-color: ${({ theme, isActive }) => !isActive && theme.black};
    }
`

const RadioButtonCircle = styled.div<IsActiveProp>`
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : theme.white};
    border: 1px solid
        ${({ theme, isActive }) => (isActive ? theme.white : theme.black)};

    ${StandardContactCard}:hover & {
        border: 1px solid ${({ theme }) => theme.white};
    }
`

const RadioButtonText = styled.p<IsActiveProp>`
    font-size: 1.2rem;
    color: ${({ theme, isActive }) => (isActive ? theme.white : theme.black)};

    ${StandardContactCard}:hover & {
        color: ${({ theme }) => theme.white};
    }
`

interface PrevewProps {
    personalInfo: PersonalInfo
    additionalInfo: AdditionalInfo
}

const Preview: React.FC<PrevewProps> = ({ personalInfo, additionalInfo }) => {
    const translation = useTranslation()
    const [isActive, setIsActive] = useState(false)
    const [isNoteVisible, setIsNoteVisible] = useState(false)
    return (
        <PreviewGroup>
            <CustomText style={{ paddingTop: '1rem' }}>
                {translation.caseworkerSetupAccountPage.preview}
            </CustomText>
            <GreyLine />
            <ChooseContactWrapper style={{ margin: 'auto auto' }}>
                <StandardContactCard
                    isActive={isActive}
                    onClick={() => setIsActive(!isActive)}
                >
                    {additionalInfo.note && (
                        <>
                            <IconWrapper>
                                <Info
                                    onMouseEnter={() => setIsNoteVisible(true)}
                                />
                            </IconWrapper>
                            <HiddenNote
                                isVisible={isNoteVisible}
                                onMouseEnter={() => setIsNoteVisible(true)}
                                onMouseLeave={() => setIsNoteVisible(false)}
                            >
                                <GreyFont>
                                    {
                                        translation.caseworkerSetupAccountPage
                                            .notes
                                    }
                                </GreyFont>
                                <NoteText>{additionalInfo.note}</NoteText>
                            </HiddenNote>
                        </>
                    )}
                    <Flex>
                        <AvatarImage
                            src={
                                additionalInfo.profileImg
                                    ? URL.createObjectURL(
                                          additionalInfo.profileImg
                                      )
                                    : noProfileImage
                            }
                            alt="Profile Image"
                        />
                        <ContactHeadingContainer>
                            <ContactHeading style={{ textAlign: 'center' }}>
                                {personalInfo.firstName +
                                    ' ' +
                                    personalInfo.lastName}
                            </ContactHeading>
                            <ContactPosition style={{ textAlign: 'center' }}>
                                {personalInfo.position}
                            </ContactPosition>
                        </ContactHeadingContainer>
                        {additionalInfo.languages.length >= 1 && (
                            <LanguagesContainer>
                                <GreyFont style={{ textAlign: 'center' }}>
                                    {
                                        translation.caseworkerSetupAccountPage
                                            .languages
                                    }
                                </GreyFont>
                                <FlagContainer>
                                    {additionalInfo.languages.map(
                                        (language) => (
                                            <Flag src={language?.flag} />
                                        )
                                    )}
                                </FlagContainer>
                            </LanguagesContainer>
                        )}
                    </Flex>
                    <PickCaseWorkerContainer
                        isActive={isActive}
                        onClick={() => setIsActive(!isActive)}
                    >
                        <RadioButtonCircle
                            isActive={isActive}
                            onClick={() => setIsActive(!isActive)}
                        ></RadioButtonCircle>
                        <RadioButtonText
                            isActive={isActive}
                            onClick={() => setIsActive(!isActive)}
                        >
                            {
                                translation.caseworkerSetupAccountPage
                                    .pickCaseworker
                            }
                        </RadioButtonText>
                    </PickCaseWorkerContainer>
                </StandardContactCard>
            </ChooseContactWrapper>
        </PreviewGroup>
    )
}

export default Preview

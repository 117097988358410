import { Card, CardHeader, CardBody } from '../../../../styled/cards'
import { ModalContainer, Modal } from '../../../../styled/modals'
import { DynamicHeightBlueButton } from '../../../../styled/buttons'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import styled from 'styled-components'
import { formatDateWithTime } from '../../../../../helpers/date'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'
import { useEffect } from 'react'

interface ReopenCaseModalProps {
    closeModal: () => void
}

const CancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    padding: 1rem;
    cursor: pointer;
`

const Title = styled.h1`
    font-size: 1.4rem;
    margin: 3.2rem 0;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.primary};
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`

const CaseInfo = styled.div`
    width: 30rem;
    height: 20.2rem;
    background: ${({ theme }) => `${theme.primary}10`};
    margin: auto;
    border-radius: 1rem;
`

const CaseInfoTitle = styled.div`
    font-weight: 900;
    height: 3.4rem;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => `${theme.primary}10`};
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: ${({ theme }) => theme.primary};
`

const CaseInfoContent = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => `${theme.primary}10`};
    height: 2.8rem;
    font-size: 1.2rem;
    padding: 0 1.5rem;
    overflow: hidden;
    color: ${({ theme }) => `${theme.primary}80`};
    backgrond: ${({ theme }) => theme.lightGrey};

    &:last-child {
        border-bottom: none;
    }
`

const RightCaseInfoSubContent = styled.div`
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
`
const LeftCaseInfoSubContent = styled.div`
    width: 100%;
`

const ReopenCaseModal: React.FC<ReopenCaseModalProps> = ({ closeModal }) => {
    const translation = useTranslation()
    const { openReport, reopenReportLoading, reopenReportError } =
        useTypedSelector((state) => state.caseworkerOpenReport)

    const { reopenReport } = useActions()

    const handleOpenReport = async () => {
        if (!openReport) {
            return
        }

        reopenReport(openReport.id)

        closeModal()
    }

    useEffect(() => {
        if (reopenReportError) {
            alert(reopenReportError)
        }
    }, [reopenReportError])

    return (
        <ModalContainer>
            <Modal height="42.7rem" width="55.6rem">
                <Card>
                    <CardHeader>
                        {translation.singleClientViewPartner.reOpenCase}
                        <CancelButton onClick={closeModal}>
                            {translation.reusable.cancel}
                        </CancelButton>
                    </CardHeader>
                    <CardBody>
                        <Title>
                            {
                                translation.singleClientViewPartner
                                    .youAreInTheProcessOfReopening
                            }{' '}
                            {openReport?.readable_id}.
                        </Title>
                        <CaseInfo>
                            <CaseInfoTitle>
                                {translation.singleClientViewPartner.caseInfo}
                            </CaseInfoTitle>
                            <CaseInfoContent>
                                <LeftCaseInfoSubContent>
                                    {translation.caseworkerOpenReport.title}
                                </LeftCaseInfoSubContent>
                                <RightCaseInfoSubContent>
                                    {openReport?.title}
                                </RightCaseInfoSubContent>
                            </CaseInfoContent>
                            <CaseInfoContent>
                                <LeftCaseInfoSubContent>
                                    {translation.reusable.category}
                                </LeftCaseInfoSubContent>
                                <RightCaseInfoSubContent>
                                    {openReport?.category
                                        ? openReport.category
                                        : translation.caseworkerOpenReport
                                              .uncategorized}
                                </RightCaseInfoSubContent>
                            </CaseInfoContent>
                            <CaseInfoContent>
                                <LeftCaseInfoSubContent>
                                    ID
                                </LeftCaseInfoSubContent>
                                <RightCaseInfoSubContent>
                                    {openReport?.readable_id}
                                </RightCaseInfoSubContent>
                            </CaseInfoContent>
                            <CaseInfoContent>
                                <LeftCaseInfoSubContent>
                                    {
                                        translation.singleClientViewPartner
                                            .arrivalDate
                                    }
                                </LeftCaseInfoSubContent>
                                <RightCaseInfoSubContent>
                                    {openReport?.created_at &&
                                        formatDateWithTime(
                                            openReport.created_at
                                        )}
                                </RightCaseInfoSubContent>
                            </CaseInfoContent>
                            <CaseInfoContent>
                                <LeftCaseInfoSubContent>
                                    {
                                        translation.singleClientViewPartner
                                            .closeDate
                                    }
                                </LeftCaseInfoSubContent>
                                <RightCaseInfoSubContent>
                                    {openReport?.closed_at &&
                                        formatDateWithTime(
                                            openReport.closed_at
                                        )}
                                </RightCaseInfoSubContent>
                            </CaseInfoContent>
                            {/*<CaseInfoContent>*/}
                            {/*    <LeftCaseInfoSubContent>*/}
                            {/*        {translation.singleClientViewPartner.closer}*/}
                            {/*    </LeftCaseInfoSubContent>*/}
                            {/*    <RightCaseInfoSubContent>*/}
                            {/*        Frederik Bow*/}
                            {/*    </RightCaseInfoSubContent>*/}
                            {/*</CaseInfoContent>*/}
                        </CaseInfo>
                        <ButtonContainer>
                            <DynamicHeightBlueButton
                                width="24.3rem"
                                height="4.5rem"
                                fontSize="1.6rem"
                                style={{ marginTop: '2.6rem' }}
                                onClick={handleOpenReport}
                            >
                                {!reopenReportLoading ? (
                                    translation.singleClientViewPartner
                                        .reOpenCase
                                ) : (
                                    <ButtonSpinnerWhite />
                                )}
                            </DynamicHeightBlueButton>
                        </ButtonContainer>
                    </CardBody>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default ReopenCaseModal

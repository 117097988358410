import { FiPlusCircle } from 'react-icons/fi'
import styled from 'styled-components'
import {
    Card,
    CardCaseHeadingRow,
    CardCaseHeadingText,
    CardCaseRow,
    CardHeader,
    CaseCardText,
    CasePill,
    CasePillText,
} from '../../../../styled/cards'
import { FlexAlignCenter, FlexJustSpaceBetween } from '../../../../styled/flex'
import { ClientGrid5Items } from '../../../../styled/grids'
import { HeadingSecondary } from '../../../../styled/typography'
import { useHistory } from 'react-router-dom'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import { useEffect } from 'react'
import Spinner from '../../../../layout/spinner/Spinner'

const Container = styled.div`
    width: 100%;
    padding: 4rem;
`

const AddNewClientBtn = styled.div`
    height: 3.5rem;
    padding: 0 1rem;
    background: ${({ theme }) => `${theme.blue}10`};
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    :hover {
        background: ${({ theme }) => `${theme.blue}20`};
    }
`

const PlusIcon = styled(FiPlusCircle)`
    opacity: '1 !imporant';
    color: ${({ theme }) => theme.blue};
`

const AddNewClientBtnText = styled.span`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.blue};
    margin-left: 0.5rem;
    opacity: 1 !important;
`

const CLientCard = styled(Card)`
    margin-top: 2rem;
`

const NewPill = styled(CasePill)`
    margin-left: 1rem;
`

const NewPillText = styled(CasePillText)`
    margin-top: 0.2rem;
`

const SpinnerContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 34vh;
`

const ClientError = styled.p`
    font-size: 1.6rem;
    text-align: center;
    padding-top: 2rem;
`

interface AllClientsProps {
    setCurrentFrameNumber: (frameNumber: number) => void
}

const AllClients: React.FC<AllClientsProps> = ({ setCurrentFrameNumber }) => {
    const translation = useTranslation()

    const history = useHistory()

    const { organization, clients, caseworkerLoading, caseworkerError } =
        useTypedSelector((state) => state.caseworker)

    const { getOrganizations, getClientInformation } = useActions()

    useEffect(() => {
        organization && getOrganizations(organization.id)

        // eslint-disable-next-line
    }, [organization])

    const handleSelectOrganization = async (organizationId: number) => {
        await Promise.all([
            organization &&
                getClientInformation(organization.id, organizationId),
        ])
        setCurrentFrameNumber(2)
    }

    return (
        <Container>
            <FlexJustSpaceBetween>
                <HeadingSecondary>
                    {translation.clientsPartner.headlineClients}
                </HeadingSecondary>
                <AddNewClientBtn
                    onClick={() => history.replace('/add-new-client')}
                >
                    <PlusIcon size={15} />
                    <AddNewClientBtnText>
                        {translation.clientsPartner.buttonAddNewClient}
                    </AddNewClientBtnText>
                </AddNewClientBtn>
            </FlexJustSpaceBetween>
            {!caseworkerLoading && clients ? (
                <CLientCard>
                    <CardHeader>
                        {translation.clientsPartner.allClients}
                    </CardHeader>
                    <CardCaseHeadingRow>
                        <ClientGrid5Items>
                            {/*<CardCaseHeadingText>*/}
                            {/*    {translation.clientsPartner.name}*/}
                            {/*</CardCaseHeadingText>*/}
                            <CardCaseHeadingText>
                                {translation.reusable.country}
                            </CardCaseHeadingText>
                            <CardCaseHeadingText>
                                {translation.clientsPartner.openCases}
                            </CardCaseHeadingText>
                            <CardCaseHeadingText>
                                {translation.clientsPartner.closeCases}
                            </CardCaseHeadingText>
                            <CardCaseHeadingText>
                                {translation.clientsPartner.newCases}
                            </CardCaseHeadingText>
                        </ClientGrid5Items>
                    </CardCaseHeadingRow>
                    <div
                        style={{
                            height: '65rem',
                        }}
                    >
                        {clients.map((client: any) => (
                            <CardCaseRow
                                key={client.id}
                                onClick={() =>
                                    handleSelectOrganization(client.id)
                                }
                            >
                                <ClientGrid5Items>
                                    <CaseCardText>{client.name}</CaseCardText>
                                    <CaseCardText>
                                        {client.country_name}
                                    </CaseCardText>
                                    <CaseCardText>
                                        {client.open_cases}
                                    </CaseCardText>
                                    <CaseCardText>
                                        {client.closed_cases}
                                    </CaseCardText>
                                    <CaseCardText>
                                        <FlexAlignCenter>
                                            {client.new_cases}
                                            {client.new_cases > 0 && (
                                                <NewPill isNew={true}>
                                                    <NewPillText isNew={true}>
                                                        New
                                                    </NewPillText>
                                                </NewPill>
                                            )}
                                        </FlexAlignCenter>
                                    </CaseCardText>
                                </ClientGrid5Items>
                            </CardCaseRow>
                        ))}
                    </div>
                </CLientCard>
            ) : caseworkerLoading && !caseworkerError ? (
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            ) : (
                <ClientError>{caseworkerError}</ClientError>
            )}
        </Container>
    )
}

export default AllClients

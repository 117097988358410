import styled from 'styled-components'

interface BlackButtonProps {
    isActive: boolean
}

export const BlackButton = styled.div<BlackButtonProps>`
    height: 2.7rem;
    padding: 0 1.6rem;

    display: flex;
    align-items: center;
    background: ${({ theme, isActive }) =>
        !isActive ? `${theme.black}50` : theme.black};
    color: ${({ theme }) => theme.white};
    font-size: 1.4rem;
    border-radius: 0.5rem;

    pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};
`

import { ChangeEvent, useState, useEffect } from 'react'
import styled from 'styled-components'
import {
    PrimaryLabel,
    InputFieldIcon,
    ErrorMessage,
} from '../../../../styled/formElements'
import CountryCodeDropdown from '../../../../reusable/form/CountryCodeDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import PasswordValidationChecklist from '../../../../PasswordValidationChecklist'
import {
    checkEmail,
    checkName,
    checkLastName,
    checkPhone,
    checkPosition,
    isValidEmail,
    isValidName,
    isValidPhone,
    isValidPosition,
} from '../../../../../helpers/validation'
import { useTranslation, useTypedSelector } from '../../../../../hooks'
import { PersonalInfo } from '../../SetupAccountPage'
const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

interface PrimaryLabelProps {
    isError?: boolean
    isSuccess?: boolean
}

const NewInput = styled.input<PrimaryLabelProps>`
    width: 100%;
    border: 1px solid
        ${({ theme, isError, isSuccess }) =>
            isError ? theme.red : isSuccess ? 'green' : theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 0.4rem;
    padding: 0 1rem;
    height: 4rem;
`

const Form = styled.form`
    padding: 10rem 0 2rem;
    width: 70%;

    @media (max-width: 768px) {
        width: 100%;
    }
`

const Field = styled.div`
    width: 100%;
    padding-bottom: 1.4rem;
    position: relative;
`

const FieldWrap = styled.div`
    display: flex;
    gap: 2rem;
`

const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        width: 95%;
    }
`

const PassFlex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const GreyLine = styled.hr`
    border: none;
    height: 1px;
    background: ${({ theme }) => theme.lineGrey};
    width: 100%;
    margin: 1rem 0 2rem 0;
`

const PasswordWrap = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;

    @media (max-width: 768px) {
        display: block;
    }
`

const PhoneRow = styled.div`
    display: flex;
    width: 100%;
    gap: 1.6rem;
`

const ValidationPassColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 78%;
    justify-content: center;
    width: 100%;
    align-items: end;

    @media (max-width: 768px) {
        align-items: center;
    }
`

interface PersonalInformationProps {
    personalInfo: PersonalInfo
    updatePersonalInfo: (key: string, value: string) => void
    selectedCountryCode: { calling_code: string }
    setSelectedCountryCode: (value: { calling_code: string }) => void
    setCountryId: (value: number) => void
    handleFormValidation: () => void
    handleSubmit: () => void
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
    personalInfo,
    updatePersonalInfo,
    selectedCountryCode,
    setSelectedCountryCode,
    setCountryId,
    handleFormValidation,
    handleSubmit,
}) => {
    const {
        firstName,
        lastName,
        position,
        email,
        phone,
        password,
        passwordConfirm,
    } = personalInfo

    const { onboardingSuccess } = useTypedSelector(
        (state) => state.caseworkerOnboarding
    )

    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [positionError, setPositionError] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')

    const [firstNameSuccess, setFirstNameSuccess] = useState(false)
    const [lastNameSuccess, setLastNameSuccess] = useState(false)
    const [emailSuccess, setEmailSuccess] = useState(false)
    const [positionSuccess, setPositionSuccess] = useState(false)
    const [phoneNumberSuccess, setPhoneNumberSuccess] = useState(false)

    const [showPassword, setShowPassword] = useState(false)

    const translation = useTranslation()
    const handleFocus = (event: React.FocusEvent<HTMLFormElement>) => {
        const name = event.target.name

        if (name === 'firstName') {
            setFirstNameError('')
        } else if (name === 'lastName') {
            setLastNameError('')
        } else if (name === 'email') {
            setEmailError('')
        } else if (name === 'position') {
            setPositionError('')
        } else if (name === 'phone') {
            setPhoneNumberError('')
        }
    }

    const handleBlur = (event: React.FocusEvent<HTMLFormElement>) => {
        const name = event.target.name

        if (name === 'firstName') {
            if (!isValidName(firstName)) {
                return setFirstNameError(checkName(firstName, translation))
            }

            return setFirstNameSuccess(true)
        }
        if (name === 'lastName') {
            if (!isValidName(lastName)) {
                return setLastNameError(checkLastName(lastName, translation))
            }

            return setLastNameSuccess(true)
        }
        if (name === 'email') {
            if (!isValidEmail(email)) {
                return setEmailError(checkEmail(email, translation))
            }

            return setEmailSuccess(true)
        }
        if (name === 'position') {
            if (!isValidPosition(position)) {
                return setPositionError(checkPosition(position, translation))
            }

            return setPositionSuccess(true)
        }
        if (name === 'phone') {
            if (!isValidPhone(`${selectedCountryCode.calling_code}${phone}`)) {
                return setPhoneNumberError(
                    checkPhone(
                        `${selectedCountryCode.calling_code}${phone}`,
                        translation
                    )
                )
            }

            return setPhoneNumberSuccess(true)
        }
    }

    useEffect(() => {
        if (onboardingSuccess) {
            const onboardingEmail = onboardingSuccess.data.email
            if (onboardingEmail) {
                updatePersonalInfo('email', onboardingEmail)
            }
        }
        // eslint-disable-next-line
    }, [onboardingSuccess])

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value
        updatePersonalInfo('email', newEmail)
    }
    return (
        <ColumnFlex>
            <Form
                onBlur={handleBlur}
                onFocus={handleFocus}
                onSubmit={handleSubmit}
            >
                <FieldWrap>
                    <Field>
                        <PrimaryLabel isSuccess={firstNameSuccess}>
                            {translation.reusable.firstName} *
                        </PrimaryLabel>
                        <NewInput
                            name="firstName"
                            value={firstName}
                            onChange={(e) =>
                                updatePersonalInfo('firstName', e.target.value)
                            }
                            isSuccess={firstNameSuccess}
                            isError={firstNameError.length > 0}
                        />
                        {firstNameError && (
                            <ErrorMessage>{firstNameError}</ErrorMessage>
                        )}
                    </Field>
                    <Field>
                        <PrimaryLabel isSuccess={lastNameSuccess}>
                            {translation.reusable.lastName} *
                        </PrimaryLabel>
                        <NewInput
                            name="lastName"
                            value={lastName}
                            onChange={(e) =>
                                updatePersonalInfo('lastName', e.target.value)
                            }
                            isSuccess={lastNameSuccess}
                            isError={lastNameError.length > 0}
                        />
                        {lastNameError && (
                            <ErrorMessage>{lastNameError}</ErrorMessage>
                        )}
                    </Field>
                </FieldWrap>
                <Field>
                    <PrimaryLabel isSuccess={emailSuccess}>
                        {translation.reusable.workEmail} *
                    </PrimaryLabel>
                    <NewInput
                        name="email"
                        value={email}
                        isSuccess={emailSuccess}
                        isError={emailError.length > 0}
                        onChange={handleEmailChange}
                    />
                    {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
                </Field>
                <FieldWrap>
                    <Field>
                        <PrimaryLabel isSuccess={positionSuccess}>
                            {translation.reusable.position} *
                        </PrimaryLabel>
                        <NewInput
                            name="position"
                            value={position}
                            onChange={(e) =>
                                updatePersonalInfo('position', e.target.value)
                            }
                            isSuccess={positionSuccess}
                            isError={positionError.length > 0}
                        />
                        {positionError && (
                            <ErrorMessage>{positionError}</ErrorMessage>
                        )}
                    </Field>
                    <Field>
                        <PrimaryLabel isSuccess={phoneNumberSuccess}>
                            {translation.reusable.phone} *
                        </PrimaryLabel>
                        <PhoneRow>
                            <CountryCodeDropdown
                                error={phoneNumberError.length > 0}
                                success={phoneNumberSuccess}
                                selectDropdownCB={(code) =>
                                    setSelectedCountryCode(code)
                                }
                                handleAddLanguage={(countryId) =>
                                    setCountryId(countryId)
                                }
                                hideCallingCode={true}
                                border="1px solid"
                                width="6.8rem"
                            />
                            <NewInput
                                name="phone"
                                value={
                                    selectedCountryCode.calling_code +
                                    ' ' +
                                    phone
                                }
                                onChange={(e) =>
                                    updatePersonalInfo('phone', e.target.value)
                                }
                                isSuccess={phoneNumberSuccess}
                                isError={phoneNumberError.length > 0}
                            />
                        </PhoneRow>
                        {phoneNumberError && (
                            <ErrorMessage>{phoneNumberError}</ErrorMessage>
                        )}
                    </Field>
                </FieldWrap>
                <GreyLine />
                <PasswordWrap>
                    <PassFlex>
                        <Field>
                            <PrimaryLabel>
                                {translation.reusable.password} *
                            </PrimaryLabel>
                            <NewInput
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) =>
                                    updatePersonalInfo(
                                        'password',
                                        e.target.value
                                    )
                                }
                            />
                            <InputFieldIcon
                                style={{ marginTop: '.2rem' }}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {!showPassword ? eye : eyeSlash}
                            </InputFieldIcon>
                        </Field>
                        <Field>
                            <PrimaryLabel>
                                {translation.reusable.confirmPassword} *
                            </PrimaryLabel>
                            <NewInput
                                name="passwordConfirm"
                                type={showPassword ? 'text' : 'password'}
                                value={passwordConfirm}
                                onChange={(e) =>
                                    updatePersonalInfo(
                                        'passwordConfirm',
                                        e.target.value
                                    )
                                }
                            />
                            <InputFieldIcon
                                style={{ marginTop: '.2rem' }}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {!showPassword ? eye : eyeSlash}
                            </InputFieldIcon>
                        </Field>
                    </PassFlex>
                    <ValidationPassColumn>
                        <PasswordValidationChecklist
                            password={password}
                            passwordConfirm={passwordConfirm}
                            isFormValidated={handleFormValidation}
                        />
                    </ValidationPassColumn>
                </PasswordWrap>
            </Form>
        </ColumnFlex>
    )
}

export default PersonalInformation

import axios from 'axios'
import config from '../../config'
import { Contact } from '../../ts/interfaces'
import { LineOfContactActionType } from '../action-types'
import { Dispatch } from 'redux'
import { PriorityInterface } from '../../components/reusable/frames/LineOfContactsFrame'
import { FlashMessageInterface } from '../reducers/lineOfContactReducer'
import Translation from '../state-types/TranslationStateType'

const { apiUrl } = config

export const addLineOfContact =
    (organizationId: number, clientId: number, contact: Contact) =>
    async (dispatch: Dispatch) => {
        dispatch(setLineOfContactLoading())

        const payload = new FormData()

        payload.append('contact[first_name]', contact.contact.first_name)
        payload.append('contact[last_name]', contact.contact.last_name)
        payload.append('contact[email]', contact.contact.email)
        payload.append(
            'contact[country_id]',
            contact.contact.country_id.toString()
        )
        payload.append('contact[phone]', contact.contact.phone.toString())
        if (contact.contact_information) {
            payload.append(
                'contact_information[position]',
                contact.contact_information.position || ''
            )
            payload.append(
                'contact_information[priority]',
                contact.contact_information.priority?.toString() || ''
            )
            payload.append(
                'contact_information[note]',
                contact.contact_information.note || ''
            )
            if (contact.contact_information.profile_img) {
                payload.append(
                    'contact_information[profile_img]',
                    contact.contact_information.profile_img
                )
            }
        }

        contact.languages.data.forEach((language, index) => {
            if (language.country_id) {
                payload.append(
                    `languages[${index}][country_id]`,
                    language.country_id.toString()
                )
            }
        })

        try {
            const res = await axios.post(
                `${apiUrl}/v1/partners/${organizationId}/clients/${clientId}/contact-lines`,
                payload
            )

            if (res.status === 422) {
                dispatch({
                    type: LineOfContactActionType.SHOW_ADD_LINE_OF_CONTACT_ERRORS,
                    payload: res.data.errors,
                })

                return false
            }

            dispatch({
                type: LineOfContactActionType.ADD_LINE_OF_CONTACT,
                payload: res.data.data,
            })

            return true
        } catch (err: any) {
            if (err.response.status === 422) {
                dispatch({
                    type: LineOfContactActionType.SHOW_ADD_LINE_OF_CONTACT_ERRORS,
                    payload: err.response.data.errors,
                })
            }

            return false
        }
    }

export const addPriorityOfContacts =
    (clientId: number, priorityList: PriorityInterface[]) =>
    async (dispatch: any) => {
        dispatch(setLineOfContactUpdatePriorityLoading())

        const payload = { contacts: priorityList }

        const res = await axios.post(
            `${apiUrl}/v1/clients/${clientId}/contacts/change-priority`,
            payload
        )

        if (res.status !== 204) {
            return
        }

        dispatch(
            showFlashMessage({ message: 'Priority updated', type: 'success' })
        )

        dispatch({
            type: LineOfContactActionType.ADD_PRIORITY_OF_CONTACTS,
            payload: priorityList,
        })

        return dispatch(locClearFlashMessage())
    }

export const addContact =
    (clientId: number, contact: Contact, translation: Translation) =>
    async (dispatch: Dispatch) => {
        dispatch(setLineOfContactLoading())

        const payload = new FormData()

        payload.append('contact[first_name]', contact.contact.first_name)
        payload.append('contact[last_name]', contact.contact.last_name)
        payload.append('contact[email]', contact.contact.email)
        payload.append(
            'contact[country_id]',
            contact.contact.country_id.toString()
        )
        payload.append('contact[phone]', contact.contact.phone.toString())
        if (contact.contact_information) {
            payload.append(
                'contact_information[position]',
                contact.contact_information.position || ''
            )
            payload.append(
                'contact_information[priority]',
                contact.contact_information.priority?.toString() || ''
            )
            if (contact.contact_information.note) {
                payload.append(
                    'contact_information[note]',
                    contact.contact_information.note
                )
            }

            if (contact.contact_information.profile_img) {
                payload.append(
                    'contact_information[profile_img]',
                    contact.contact_information.profile_img
                )
            }
        }

        contact.languages.data.forEach((language, index) => {
            if (language.country_id) {
                payload.append(
                    `languages[${index}][country_id]`,
                    language.country_id.toString()
                )
            }
        })

        try {
            const res = await axios.post(
                `${apiUrl}/v1/clients/${clientId}/contacts`,
                payload
            )

            if (res.status === 422) {
                dispatch({
                    type: LineOfContactActionType.SHOW_ADD_CONTACT_ERRORS,
                    payload: res.data.errors,
                })

                return false
            }

            dispatch(
                showFlashMessage({
                    message: `${translation.singleClientViewPartner.contactAdded}`,
                    type: 'success',
                })
            )

            dispatch(updateContactListInState(res.data.data))

            // @ts-ignore
            dispatch(locClearFlashMessage())

            return true
        } catch (err: any) {
            if (err.response.status === 422) {
                dispatch({
                    type: LineOfContactActionType.SHOW_ADD_CONTACT_ERRORS,
                    payload: err.response.data.errors,
                })
            }

            return false
        }
    }

export interface UpdateContactInterface {
    contact: {
        first_name?: string
        last_name?: string
        email?: string
        country_id?: number
        phone?: string
    }
    contact_information: {
        position?: string
        priority?: number
        note?: string
        profile_img?: File
    }
    languages: { data: { language_id: number }[] }
}

export const updateContact =
    (
        clientId: number,
        contactId: number,
        contact: UpdateContactInterface,
        translation: Translation
    ) =>
    async (dispatch: Dispatch) => {
        dispatch({
            type: LineOfContactActionType.SET_LINE_OF_CONTACT_UPDATE_LOADING,
        })

        const payload = new FormData()

        payload.append('_method', 'PUT')

        Object.entries(contact.contact).forEach(([key, value]) => {
            payload.append(`contact[${key}]`, value.toString())
        })

        Object.entries(contact.contact_information).forEach(([key, value]) => {
            if (key === 'profile_img') {
                payload.append(`contact_information[${key}]`, value as File)
            } else {
                payload.append(`contact_information[${key}]`, value.toString())
            }
        })

        contact.languages.data.forEach((language, index) => {
            payload.append(
                `languages[${index}][language_id]`,
                language.language_id.toString()
            )
        })

        try {
            const res = await axios.post(
                `${apiUrl}/v1/clients/${clientId}/contacts/${contactId}`,
                payload
            )

            if (res.status === 422) {
                dispatch({
                    type: LineOfContactActionType.SHOW_UPDATE_CONTACT_ERRORS,
                    payload: res.data,
                })

                return false
            }

            dispatch({
                type: LineOfContactActionType.UPDATE_CONTACT_LIST_IN_STATE,
                payload: res.data.data,
            })

            dispatch(
                showFlashMessage({
                    message: `${translation.singleClientViewPartner.contactUpdated}`,
                    type: 'success',
                })
            )

            // @ts-ignore
            dispatch(locClearFlashMessage())

            return true
        } catch (err: any) {
            if (err.response.status === 422) {
                dispatch({
                    type: LineOfContactActionType.SHOW_UPDATE_CONTACT_ERRORS,
                    payload: err.response.data,
                })
            }

            return false
        }
    }

export const deleteContact =
    (clientId: number, contactId: number, translation: Translation) =>
    async (dispatch: Dispatch) => {
        dispatch({
            type: LineOfContactActionType.SET_LINE_OF_CONTACT_DELETE_LOADING,
        })

        try {
            const res = await axios.delete(
                `${apiUrl}/v1/clients/${clientId}/contacts/${contactId}`
            )

            if (res.status === 422 || res.status === 405) {
                dispatch({
                    type: LineOfContactActionType.SHOW_DELETE_CONTACT_ERRORS,
                    payload: res.data.errors,
                })

                return false
            }

            dispatch(
                showFlashMessage({ message: 'Contact deleted', type: 'error' })
            )

            dispatch({
                type: LineOfContactActionType.UPDATE_CONTACT_LIST_IN_STATE,
                payload: res.data.data,
            })

            // @ts-ignore
            dispatch(locClearFlashMessage())

            return true
        } catch (err: any) {
            if (err.response.status === 422) {
                dispatch({
                    type: LineOfContactActionType.SHOW_UPDATE_CONTACT_ERRORS,
                    payload: err.response.data.errors,
                })
            } else if (err.response.status === 405) {
                dispatch(
                    showFlashMessage({
                        message: `${translation.singleClientViewPartner.caseworkerHasCasesAndCannotBeDeleted}`,
                        type: 'error',
                    })
                )

                // @ts-ignore
                dispatch(locClearFlashMessage())
                dispatch({
                    type: LineOfContactActionType.RESET_LINE_OF_CONTACT_LOADING,
                })
            }

            return false
        }
    }

export const addSelf =
    (clientId: any, payload: any) => async (dispatch: Dispatch) => {
        dispatch(setLineOfContactLoading())

        try {
            const res = await axios.post(
                `${apiUrl}/v1/clients/${clientId}/contacts/add-self`,
                payload
            )

            if (res.status === 422) {
                dispatch({
                    type: LineOfContactActionType.SHOW_ADD_SELF_ERRORS,
                    payload: res.data.errors,
                })

                return false
            }

            dispatch({
                type: LineOfContactActionType.ADD_SELF,
                payload: res.data.data,
            })

            dispatch(
                showFlashMessage({
                    message:
                        'Contact role added. You will need to log out and in again for the changes to take effect.',
                    type: 'success',
                })
            )

            // @ts-ignore
            dispatch(locClearFlashMessage())

            return true
        } catch (err: any) {
            if (err.response.status === 422) {
                dispatch({
                    type: LineOfContactActionType.SHOW_ADD_SELF_ERRORS,
                    payload: err.response.data.errors,
                })
            }

            return false
        }
    }

export const clearAddLineOfContactErrors = () => ({
    type: LineOfContactActionType.CLEAR_ADD_LINE_OF_CONTACT_ERRORS,
})

export const clearAddContactErrors = () => ({
    type: LineOfContactActionType.CLEAR_ADD_CONTACT_ERRORS,
})

export const clearUpdateContactErrors = () => ({
    type: LineOfContactActionType.CLEAR_UPDATE_CONTACT_ERRORS,
})

export const clearDeleteContactErrors = () => ({
    type: LineOfContactActionType.CLEAR_DELETE_CONTACT_ERRORS,
})

export const clearAddSelfContactErrors = () => ({
    type: LineOfContactActionType.CLEAR_ADD_SELF_ERRORS,
})

const setLineOfContactLoading = () => {
    return {
        type: LineOfContactActionType.SET_LINE_OF_CONTACTS_LOADING,
    }
}

const setLineOfContactUpdatePriorityLoading = () => ({
    type: LineOfContactActionType.SET_LINE_OF_CONTACT_UPDATE_PRIORITY_LOADING,
})

export const updateContactListInState = (contacts: unknown[]) => ({
    type: LineOfContactActionType.UPDATE_CONTACT_LIST_IN_STATE,
    payload: contacts,
})

const showFlashMessage = (message: FlashMessageInterface) => ({
    type: LineOfContactActionType.SHOW_FLASH_MESSAGE,
    payload: message,
})

const locClearFlashMessage = () => (dispatch: any) => {
    return setTimeout(() => {
        dispatch({
            type: LineOfContactActionType.LOC_CLEAR_FLASH_MESSAGE,
        })
    }, 5000)
}

import {
    Modal,
    ModalCancelButton,
    ModalContainer,
} from '../../../../styled/modals'
import { Card, CardBody, CardHeader } from '../../../../styled/cards'
import { useTranslation, useTypedSelector } from '../../../../../hooks'
import styled from 'styled-components'

const Text = styled.p`
    font-size: 1.2rem;
    padding: 2.4rem;
    overflow-y: auto;
    height: 40rem;
`

interface TranscriptionModalProps {
    close: () => void
}

const TranscriptionModal: React.FC<TranscriptionModalProps> = ({ close }) => {
    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const translation = useTranslation()

    return (
        <ModalContainer>
            <Modal height="50rem" width="50rem">
                <Card>
                    <CardHeader>
                        {translation.caseworkerOpenReport.transcription}
                        <ModalCancelButton onClick={close}>
                            {translation.reusable.cancel}
                        </ModalCancelButton>
                    </CardHeader>
                    <CardBody>
                        <Text>{openReport?.recording.transcription}</Text>
                    </CardBody>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default TranscriptionModal

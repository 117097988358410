import axios from 'axios'
import config from '../../config'
import { TranslateTextActionType } from '../action-types'
import { Dispatch } from 'redux'

const { apiUrl } = config

export const translateToDefaultLang =
    (
        text: string,
        selectedTranslation: 'en' | 'dk' | 'se' | 'no' | 'fi',
        user: 'caseworker' | 'whistleblower',
        organizationalCode: null | string = null
    ) =>
    async (dispatch: Dispatch) => {
        const targetLanguage =
            selectedTranslation === 'dk'
                ? 'da'
                : selectedTranslation === 'se'
                ? 'sv'
                : 'en'

        dispatch(translationLoading())
        try {
            const endpoint =
                user === 'caseworker'
                    ? `${apiUrl}/v1/translate`
                    : `${apiUrl}/v1/whistleblower/organizations/${organizationalCode}/translate-text`

            const res = await axios.post(endpoint, {
                text,
                target_lang: targetLanguage,
            })
            const translatedText = res.data.data.text

            if (res.status !== 200) {
                return dispatch(
                    translationError(
                        'Something went wrong. Please, try again later.'
                    )
                )
            } else if (res.status === 200) {
                dispatch(translationSuccess())
                return translatedText
            }
        } catch (error) {
            dispatch(
                translationError(
                    'Something went wrong. Please, try again later.'
                )
            )
        }
    }

export const translationLoading = () => ({
    type: TranslateTextActionType.TRANSLATION_LOADING,
})

export const translationError = (error: string) => ({
    type: TranslateTextActionType.TRANSLATION_ERROR,
    payload: error,
})

export const translationSuccess = () => ({
    type: TranslateTextActionType.TRANSLATION_SUCCESS,
})

import { FC, useState } from 'react'
import { Card } from '../../../../styled/cards'
import { AssignedTo } from './InfoCard'
import { AiFillInfoCircle } from 'react-icons/ai'
import noProfileImage from '../../../../../assets/images/no-profile-image.svg'
import { useTranslation } from '../../../../../hooks'
import {
    ContactHeading,
    ContactHeadingContainer,
    ContactPosition,
    Flag,
    FlagContainer,
    Flex,
    GreyFont,
    LanguagesContainer,
} from '../../new-case/styled/ChooseContactCard'
import styled from 'styled-components'

const TooltipCard = styled(Card)`
    position: absolute;
    max-height: 18.2rem;
    height: fit-content;
    width: 60%;
    z-index: 99;
    right: 1.5rem;
    bottom: 4.4rem;
    padding: 1.5rem 1rem;
`

const IconWrapper = styled.div`
    display: flex;
    justify-content: right;
`

const Info = styled(AiFillInfoCircle)`
    color: ${({ theme }) => theme.lineGrey};
    font-size: 1.5rem;
    cursor: pointer;
`

const HiddenNote = styled(Card)`
    position: absolute;
    max-height: 6rem;
    width: 90%;
    top: 1.5rem;
    right: 1rem;
    padding: 0.25rem
`

const NoteText = styled.div`
    margin-top: 0.6rem;
    overflow-wrap: break-word;
    overflow-y: auto;
`

const FlexCard = styled(Flex)`
    gap: 1.5rem;
    align-items: center;
`

const Image = styled.img`
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
`

interface CasewrokerTooltipProps {
    assignedTo: AssignedTo
    setShowTooltip: (value: boolean) => void
}

const CaseworkerTooltip: FC<CasewrokerTooltipProps> = ({
    assignedTo,
    setShowTooltip,
}) => {
    const translation = useTranslation()

    const [isNoteVisible, setIsNoteVisible] = useState(false)

    return (
        <TooltipCard
            key={assignedTo.id}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {assignedTo.note && (
                <IconWrapper>
                    <Info onMouseEnter={() => setIsNoteVisible(true)} />
                    {isNoteVisible && (
                        <HiddenNote
                            onMouseEnter={() => setIsNoteVisible(true)}
                            onMouseLeave={() => setIsNoteVisible(false)}
                        >
                            <GreyFont>
                                {translation.caseworkerSetupAccountPage.notes}
                            </GreyFont>
                            <NoteText>{assignedTo.note}</NoteText>
                        </HiddenNote>
                    )}
                </IconWrapper>
            )}
            <FlexCard>
                <Image src={assignedTo.profile_img || noProfileImage} />
                <ContactHeadingContainer>
                    <ContactHeading>{assignedTo.name}</ContactHeading>
                    {assignedTo.position !== 'Please add your position' && (
                        <ContactPosition>{assignedTo.position}</ContactPosition>
                    )}
                </ContactHeadingContainer>
                {assignedTo.languages.length >= 1 && (
                    <LanguagesContainer>
                        <GreyFont>{translation.clientView.languages}</GreyFont>
                        <FlagContainer>
                            {assignedTo.languages.map((language) => (
                                <Flag key={language.id} src={language.flag} />
                            ))}
                        </FlagContainer>
                    </LanguagesContainer>
                )}
            </FlexCard>
        </TooltipCard>
    )
}

export default CaseworkerTooltip

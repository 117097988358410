export const isValidWhistleblowerCode = (code: string) => {
    /*
    * Old codes length = 24
    * New codes length = 34
     */
    if (code.length !== 24 && code.length !== 34) {
        return false
    }

    return true
}

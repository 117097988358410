import styled from 'styled-components'
import OnboardingEn from '../../../assets/videos/onboarding-en.mp4'
import OnboardingDk from '../../../assets/videos/onboarding-dk.mp4'
import { FiPause, FiPlay } from 'react-icons/fi'
import { useState } from 'react'
import { useTranslation, useTypedSelector } from '../../../hooks'

const VideoBoxContainer = styled.div`
    height: 34rem;
    width: 100%;
    background: ${({ theme }) => theme.primary};
    border-radius: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
`

const LeftContainer = styled.div`
    width: 51.7rem;
    margin-left: 10rem;

    display: flex;
    flex-direction: column;
    gap: 2.6rem;
`

const WelcomeText = styled.h3`
    font-size: 2rem;
    color: ${({ theme }) => theme.blue};
    font-weight: 600;
`

const Heading = styled.h2`
    font-size: 3.4rem;
    color: ${({ theme }) => theme.white};
`

const ProgressBar = styled.div`
    position: relative;
    width: 40rem;
    padding: 1rem 0;
    border-radius: 1rem;
    background: ${({ theme }) => theme.white};
`

interface ActiveProgressBarProps {
    currentStep: number
}

const ActiveProgressBar = styled.div<ActiveProgressBarProps>`
    background: ${({ theme }) => theme.blue};
    height: 100%;
    position: absolute;
    top: 0;
    left: -0.15rem;
    border-radius: 1rem;
    ${({ currentStep }) =>
        currentStep === 1
            ? 'width: 0;'
            : currentStep === 2
            ? 'width: 33%;'
            : currentStep === 3
            ? 'width: 66%;'
            : 'width: 101%;'};
`

const ActiveProgress = styled.span`
    color: ${({ theme }) => theme.white};
    font-size: 1.2rem;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const PercentageContainer = styled.div`
    width: 40rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -1rem;
`

const Percentage = styled.p`
    font-size: 1rem;
    color: ${({ theme }) => theme.white};
`

const RightContainer = styled.div`
    height: 30rem;
    width: 50rem;
    margin-right: 2rem;
    position: relative;
    cursor: pointer;
`

const PlayButton = styled.div`
    height: 5.6rem;
    width: 5.6rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.blue};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;
`

const PlayIcon = styled(FiPlay)`
    color: ${({ theme }) => theme.white};
    font-size: 1.6rem;
    fill: ${({ theme }) => theme.white};
`

const PauseIcon = styled(FiPause)`
    color: ${({ theme }) => theme.white};
    font-size: 1.6rem;
    fill: ${({ theme }) => theme.white};
`

const Video = styled.video`
    border-radius: 0.5rem;
`

interface VideoBoxProps {
    currentStep: number
}

const VideoBox: React.FC<VideoBoxProps> = ({ currentStep }) => {
    const { selectedTranslation } = useTypedSelector(
        (state) => state.translation
    )

    const { user } = useTypedSelector((state) => state.auth)

    const translation = useTranslation()

    const getPercentage = () => {
        if (currentStep === 1) {
            return ''
        } else if (currentStep === 2) {
            return '33%'
        } else if (currentStep === 3) {
            return '66%'
        } else if (currentStep === 4) {
            return '100%'
        }
    }

    const [isHoveringVideo, setIsHoveringVideo] = useState(false)
    const [isVideoPlaying, setIsVideoPlaying] = useState(false)

    const handlePlayVideo = () => {
        const videoElement = document.getElementById('onboarding-video') as HTMLVideoElement

        if (isVideoPlaying) {
            videoElement?.play()
        } else {
            videoElement?.pause()
        }
    }

    return (
        <VideoBoxContainer>
            <LeftContainer>
                <WelcomeText>
                    {translation.onboardingPage.hi} {user?.firstName}
                    {translation.onboardingPage.welcomeToWalor}
                </WelcomeText>
                <Heading>{translation.onboardingPage.youAreAlmostDone}</Heading>
                <ProgressBar>
                    <ActiveProgressBar currentStep={currentStep}>
                        <ActiveProgress>{getPercentage()}</ActiveProgress>
                    </ActiveProgressBar>
                </ProgressBar>

                <PercentageContainer>
                    <Percentage>0%</Percentage>
                    <Percentage>100%</Percentage>
                </PercentageContainer>
            </LeftContainer>
            <RightContainer>
                {!isVideoPlaying && (
                    <PlayButton>
                        <PlayIcon />
                    </PlayButton>
                )}
                {isVideoPlaying && isHoveringVideo && (
                    <PlayButton>
                        <PauseIcon />
                    </PlayButton>
                )}
                <Video
                    id="onboarding-video"
                    src={
                        selectedTranslation === 'en' ||
                        selectedTranslation === 'se'
                            ? OnboardingEn
                            : OnboardingDk
                    }
                    width="100%"
                    height="100%"
                    controls={isHoveringVideo}
                    onPlay={() => setIsVideoPlaying(true)}
                    onPause={() => setIsVideoPlaying(false)}
                    onClick={() => handlePlayVideo()}
                    onMouseEnter={() => setIsHoveringVideo(true)}
                    onMouseLeave={() => setIsHoveringVideo(false)}
                >
                    <source
                        src={
                            selectedTranslation === 'en'
                                ? OnboardingEn
                                : selectedTranslation === 'se'
                                ? OnboardingEn
                                : OnboardingDk
                        }
                        type="video/mp4"
                    />
                </Video>
            </RightContainer>
        </VideoBoxContainer>
    )
}

export default VideoBox

import styled from 'styled-components'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { differenceInMinutes, format, isAfter, isToday } from 'date-fns'
import { Card, CardHeader } from '../../../../styled/cards'
import ProgressiveTimeline from '../../../../reusable/timeline/ProgressiveTimeline'
import { useTranslation } from '../../../../../hooks'

const TimelineCard = styled(Card)`
    margin-top: 1.2rem;
    height: 13.9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: visible;
    min-width: 25.5rem;
    max-width: 65.5rem;
`

const HiddenMsg = styled(Card)`
    width: 15rem;
    height: 5rem;
    border-radius: 1rem;
    padding: 0.5rem;
    position: absolute;
    color: ${({ theme }) => theme.greyText};
    font-size: 1.2rem;
    bottom: -4rem;
`

const HiddenMsgFirst = styled(HiddenMsg)`
    left: 0;
`

const HiddenMsgSecond = styled(HiddenMsg)`
    left: 25%;
`

const HiddenMsgThird = styled(HiddenMsg)`
    right: 0;
`

interface ReportTimelineProps {
    dateOne: Date
    dateTwo: Date
    dateThree: Date
    repliedWithinWeek: boolean | undefined
    closedAt: string | null
}

const ReportTimelineCard: FC<ReportTimelineProps> = ({
    dateOne,
    dateTwo,
    dateThree,
    repliedWithinWeek,
    closedAt,
}) => {
    const translation = useTranslation()

    const [today, setToday] = useState(new Date())

    const [breakpoints, setBreakpoints] = useState([
        { date: '', month: '' },
        { date: '', month: '' },
        { date: '', month: '' },
    ])
    const [isSecondBreakpoint, setIsSecondBreakpoint] = useState(false)
    const [isThirdBreakpoint, setIsThirdBreakpoint] = useState(false)

    const [hoveredIcon, setHoveredIcon] = useState<
        'first' | 'second' | 'third' | null
    >(null)

    const monthObj = useMemo(
        () => translation.accessCaseFlow.months,
        [translation.accessCaseFlow.months]
    )

    const getDateParts = useCallback(
        (dateString: Date) => {
            const months = Object.values(monthObj)
            const dateParts = format(dateString, 'dd.MM.yyyy')
                .toString()
                .split('.')
            const date = dateParts[0]
            const month = months[Number(dateParts[1]) - 1]
            return { date, month }
        },
        [monthObj]
    )

    useEffect(() => {
        setBreakpoints([
            getDateParts(dateOne),
            getDateParts(dateTwo),
            getDateParts(dateThree),
        ])
    }, [dateOne, dateTwo, dateThree, getDateParts])

    useEffect(() => {
        setToday(new Date())
    }, [])

    useEffect(() => {
        if (
            repliedWithinWeek ||
            isAfter(today, dateTwo) ||
            isToday(dateTwo) ||
            closedAt
        ) {
            setIsSecondBreakpoint(true)
        }
    }, [today, dateTwo, repliedWithinWeek, closedAt])

    useEffect(() => {
        if (isAfter(today, dateThree) || isToday(dateThree) || closedAt) {
            setIsThirdBreakpoint(true)
        }
    }, [today, dateThree, closedAt])

    const calcWidthSegmentOne = () => {
        if (isSecondBreakpoint) return '100%'

        const minutesInWeek = 7 * 24 * 60
        const differenceInMin = differenceInMinutes(today, dateOne)
        const percentage = (100 / minutesInWeek) * differenceInMin
        return `${percentage}%`
    }

    const calcWidthSegmentTwo = () => {
        if (isThirdBreakpoint) return '100%'

        if (isSecondBreakpoint) {
            const differenceInMin = differenceInMinutes(today, dateTwo)
            const minutesIn92Days = 92 * 24 * 60
            const percentage = (100 / minutesIn92Days) * differenceInMin
            return `${percentage}%`
        }
    }

    return (
        <TimelineCard>
            <CardHeader>{translation.accessCaseFlow.timeline}</CardHeader>
            <ProgressiveTimeline
                breakpoints={breakpoints}
                calcWidthSegmentOne={calcWidthSegmentOne}
                calcWidthSegmentTwo={calcWidthSegmentTwo}
                isSecondBreakpoint={isSecondBreakpoint}
                isThirdBreakpoint={isThirdBreakpoint}
                setHoveredIcon={setHoveredIcon}
            />
            {hoveredIcon === 'first' && (
                <HiddenMsgFirst>
                    {translation.accessCaseFlow.hiddenMsgOne}
                </HiddenMsgFirst>
            )}
            {hoveredIcon === 'second' && (
                <HiddenMsgSecond>
                    {translation.accessCaseFlow.hiddenMsgTwo}
                </HiddenMsgSecond>
            )}
            {hoveredIcon === 'third' && (
                <HiddenMsgThird>
                    {translation.accessCaseFlow.hiddenMsgThree}
                </HiddenMsgThird>
            )}
        </TimelineCard>
    )
}
export default ReportTimelineCard

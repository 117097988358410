import React, { ChangeEvent, useEffect, useState } from 'react'
import {
    StatusBar,
    StatusBarItem,
    StatusBarNumber,
    StatusBarStatus,
    StatusBarText,
} from '../../../styled/header-status-bar'
import PersonalInformationFrame from './components/frames/PersonalInformationFrame'
import AccountTypeFrame from './components/frames/AccountTypeFrame'
import { useDefaultCountryDenmark } from '../../../../hooks/useDefaultCountryDenmark'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import ThanksCheckYourEmail from './components/frames/ThanksCheckYourEmail'
import PartnerProgramInformationModal from './components/PartnerProgramInformationModal'
import { FiArrowLeft } from 'react-icons/fi'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import ResolveRegisterIntegrationData from '../register/resolvers/ResolveRegisterIntegrationData'

export type PreferredLanguage = 'en' | 'dk' | 'se'

export interface IntegrationPartnerInterface {
    id: string
    uuid: string
    name: string
    logo_en: null | string
    logo_dk: null | string
    logo_se: null | string
    preferred_language: PreferredLanguage | null
}

const GoBackButtonContainer = styled.div`
    position: absolute;
    top: 24rem;
    left: 12rem;
    opacity: 0.5;
    cursor: pointer;
    z-index: 500;

    @media (max-width: 1000px) {
        left: 5vw;
    }

    @media (max-width: 600px) {
        top: 20rem;
        left: 2vw;
    }
`

export type AccountTypeType = 'software' | 'software_plus_screening' | 'partner'

export interface PersonalInformation {
    firstName: string
    lastName: string
    country: any
    phoneNumber: string
    email: string
    partnerId: null | string
}

const IntegrationPartnerLogo = styled.img`
    width: 100%;
    margin-top: 11.5rem;
`
interface NewestRegisterPageProps {}

const NewestRegisterPage: React.FC<NewestRegisterPageProps> = () => {
    const { registerUser, getIntegrationPartner, changeLanguage } = useActions()

    const location = useLocation()

    const queryParams = new URLSearchParams(location.search)

    /*
     * Get integration id from query params
     * Used for integrations where we auto fill data
     * Like hr-on & humaans
     */
    const integrationId = queryParams.get('integrationId')

    const [
        shouldShowResolveIntegrationData,
        setShouldShowResolveIntegrationData,
    ] = useState(false)

    useEffect(() => {
        if (!integrationId) return

        if (location.pathname === '/register/integrations') {
            setShouldShowResolveIntegrationData(true)
        }
    }, [integrationId, location.pathname])

    const { selectedTranslation } = useTypedSelector(
        (state) => state.translation
    )

    const translation = useTranslation()

    const { partnerId } = useParams<{ partnerId: string }>()

    const [currentStep, setCurrentStep] = useState(1)

    const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false)

    const [integrationPartner, setIntegrationPartner] =
        useState<null | IntegrationPartnerInterface>(null)

    const [integrationPartnerLogo, setIntegrationPartnerLogo] = useState<
        null | string
    >(null)

    const denmark = useDefaultCountryDenmark()

    const handleGetIntegrationPartner = async () => {
        const res: any | IntegrationPartnerInterface =
            await getIntegrationPartner(partnerId)

        if (!res) {
            return
        }

        setIntegrationPartner(res)

        if (res.logo_en) {
            setIntegrationPartnerLogo(res.logo_en)
        }

        return setPersonalInformation({
            ...personalInformation,
            partnerId: res.uuid,
        })
    }

    useEffect(() => {
        if (typeof partnerId !== 'undefined') {
            handleGetIntegrationPartner()
        }

        // eslint-disable-next-line
    }, [partnerId])

    useEffect(() => {
        if (!integrationPartner) {
            return
        }

        const logoUrl =
            selectedTranslation === 'dk'
                ? integrationPartner.logo_dk
                : integrationPartner.logo_en

        setIntegrationPartnerLogo(logoUrl)

        // eslint-disable-next-line
    }, [selectedTranslation])

    const [selectedAccountType, setSelectedAccountType] =
        useState<null | AccountTypeType>('software')

    const [personalInformation, setPersonalInformation] =
        useState<PersonalInformation>({
            firstName: '',
            lastName: '',
            country: denmark,
            phoneNumber: '',
            email: '',
            partnerId: null,
        })

    const [termsAndConditionsChecked, setTermsAndConditionsChecked] =
        useState(false)
    const [dataProcessingChecked, setDataProcessingChecked] = useState(false)

    useEffect(() => {
        setPersonalInformation({ ...personalInformation, country: denmark })

        // eslint-disable-next-line
    }, [denmark])

    const handleSetPersonalInformation = (
        event: ChangeEvent<HTMLFormElement>
    ) => {
        const { name, value } = event.target

        if (name === 'phoneNumber') {
            return setPersonalInformation({
                ...personalInformation,
                phoneNumber: value.replace(/^\+\d+\s?/, ''),
            })
        }
        return setPersonalInformation({
            ...personalInformation,
            [name]: value,
        })
    }

    useEffect(() => {
        if (!integrationPartner?.preferred_language) return

        changeLanguage(integrationPartner.preferred_language)
    }, [integrationPartner, changeLanguage])

    const [currentFrame, setCurrentFrame] = useState(
        <>
            <PersonalInformationFrame
                setCurrentStep={setCurrentStep}
                personalInformation={personalInformation}
                handleSetPersonalInformation={handleSetPersonalInformation}
                setPersonalInformation={setPersonalInformation}
                integrationPartner={integrationPartner}
            />
            {shouldShowResolveIntegrationData && (
                <ResolveRegisterIntegrationData
                    integrationId={integrationId}
                    setPersonalInformation={setPersonalInformation}
                    personalInformation={personalInformation}
                />
            )}
        </>
    )

    useEffect(() => {
        if (currentStep === 1) {
            setCurrentFrame(
                <>
                    <PersonalInformationFrame
                        setCurrentStep={setCurrentStep}
                        personalInformation={personalInformation}
                        handleSetPersonalInformation={
                            handleSetPersonalInformation
                        }
                        setPersonalInformation={setPersonalInformation}
                        integrationPartner={integrationPartner}
                    />
                    {shouldShowResolveIntegrationData && (
                        <ResolveRegisterIntegrationData
                            integrationId={integrationId}
                            setPersonalInformation={setPersonalInformation}
                            personalInformation={personalInformation}
                        />
                    )}
                </>
            )
        } else if (currentStep === 2) {
            setCurrentFrame(
                <AccountTypeFrame
                    setIsPartnerModalOpen={setIsPartnerModalOpen}
                    selectedAccountType={selectedAccountType}
                    setSelectedAccountType={setSelectedAccountType}
                    handleRegisterUser={handleRegisterUser}
                    termsAndConditionsChecked={termsAndConditionsChecked}
                    setTermsAndConditionsChecked={() =>
                        setTermsAndConditionsChecked(!termsAndConditionsChecked)
                    }
                    dataProcessingChecked={dataProcessingChecked}
                    setDataProcessingChecked={() =>
                        setDataProcessingChecked(!dataProcessingChecked)
                    }
                    integrationPartner={integrationPartner}
                />
            )
        } else if (currentStep === 3) {
            setCurrentFrame(<ThanksCheckYourEmail />)
        }

        // eslint-disable-next-line
    }, [
        currentStep,
        selectedAccountType,
        personalInformation,
        termsAndConditionsChecked,
        dataProcessingChecked,
        integrationPartner,
        integrationId,
    ])

    const handleRegisterUser = async () => {
        if (!selectedAccountType) {
            return null
        }

        const payload = {
            ...personalInformation,
            accountType: selectedAccountType,
            integrationId,
        }

        const success: any = await registerUser(payload)

        success && setCurrentStep(3)
    }
    const handleBack = () => {
        if (currentStep === 2) {
            setCurrentStep((step) => step - 1)
        }
    }

    return (
        <div>
            {currentStep === 2 && (
                <GoBackButtonContainer onClick={handleBack}>
                    <FiArrowLeft size={36} />
                </GoBackButtonContainer>
            )}

            {isPartnerModalOpen && (
                <PartnerProgramInformationModal
                    closeModal={() => setIsPartnerModalOpen(false)}
                />
            )}
            <StatusBar>
                <StatusBarItem>
                    <StatusBarNumber shouldGlow={currentStep === 1}>
                        1
                    </StatusBarNumber>
                    <StatusBarText shouldGlow={currentStep === 1}>
                        {translation.reusable.personalInformation}
                    </StatusBarText>
                </StatusBarItem>
                <StatusBarItem>
                    <StatusBarNumber shouldGlow={currentStep === 2}>
                        2
                    </StatusBarNumber>
                    <StatusBarText shouldGlow={currentStep === 2}>
                        {translation.registerPage.accountType}
                    </StatusBarText>
                </StatusBarItem>
                <StatusBarStatus statusWidth={`${currentStep * 50}%`} />
            </StatusBar>
            <div
                style={{
                    display: 'flex',
                    gap: '2.4rem',
                }}
            >
                <div style={{ flex: 1 }}>
                    {integrationPartnerLogo && (
                        <IntegrationPartnerLogo src={integrationPartnerLogo} />
                    )}
                </div>
                <div style={{ flex: 1.6 }}>{currentFrame}</div>
                <div style={{ flex: 1 }}></div>
            </div>
        </div>
    )
}

export default NewestRegisterPage

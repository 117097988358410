import styled from 'styled-components'

interface SingleStackedBarProps {
    value1: number
    value2: number
}

interface SegmentProps {
    width: string
    value2?: number
}

const barWidth = 80
const StackedBar = styled.div`
    height: 4.4rem;
    width: ${barWidth}%;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.black};
    margin: 1rem auto;

    & > * {
        display: grid;
        place-items: center;
        font-size: 2rem;
    }
`

const AnonymousSegment = styled.div<SegmentProps>`
    background-color: #b0c1c7;
    width: ${({ width }) => width};
    border-right: ${({ value2 }) => (value2 ? '0.1rem solid #3d4b60' : '')};
`

const ConfidentialSegment = styled.div<SegmentProps>`
    background-color: #849bb5;
    width: ${({ width }) => width};
`

const SingleStackedBar: React.FC<SingleStackedBarProps> = ({
    value1,
    value2,
}) => {
    const width1 = `${barWidth * value1}%`
    const width2 = `${barWidth * value2}%`
    return (
        <StackedBar>
            <AnonymousSegment width={width1} value2={value2}>
                {Math.round(value1)}%
            </AnonymousSegment>
            <ConfidentialSegment width={width2}>
                {Math.round(value2)}%
            </ConfidentialSegment>
        </StackedBar>
    )
}
export default SingleStackedBar

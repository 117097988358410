import axios from 'axios'
import config from '../../config'
import { Dispatch } from 'redux'
import { SpokenLanguagesActionType } from '../action-types'

const { apiUrl } = config

export const getSpokenLanguages = () => async (dispatch: Dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/v1/languages`)

        if (res.status !== 200) {
            return false
        }
        dispatch({
            type: SpokenLanguagesActionType.GET_SPOKEN_LANGUAGES,
            payload: {
                data: res.data.data,
            },
        })
    } catch (error) {
        console.log('🚀 ~ getSpokenLanguages ~ error:', error)
    }
}

export const deleteSpokenLanguage =
    (languageId: number) => async (dispatch: Dispatch) => {
        dispatch({
            type: SpokenLanguagesActionType.SPOKEN_LANGUAGE_LOADING,
        })
        try {
            const res = await axios.delete(
                `${apiUrl}/v1/me/languages/${languageId}`
            )

            if (res.status !== 204) {
                return
            }

            dispatch({
                type: SpokenLanguagesActionType.DELETE_SPOKEN_LANGUAGE,
                payload: languageId,
            })
        } catch (error) {
            console.log('🚀 ~ error:', error)
        }
    }

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import {
    StatusBarItem,
    StatusBarNumber,
    StatusBarText,
} from '../../styled/header-status-bar'
import PersonalInformation from './components/frames/PersonalInformation'
import { NextButton } from '../../reusable/buttons'
import AdditionalInformation from './components/frames/AdditionalInformation'
import {
    isValidEmail,
    isValidName,
    isValidPassword,
    isValidPasswordConfirm,
    isValidPhone,
    isValidPosition,
} from '../../../helpers/validation'
import { isValidNotes } from '../../../helpers/validation/note'
import Preview from './components/Preview'
import { useParams } from 'react-router-dom'
import { useTypedSelector, useActions, useTranslation } from '../../../hooks'
import ButtonSpinnerWhite from '../../layout/spinner/ButtonSpinnerWhite'
import FlashMessage from '../../reusable/feedback/FlashMessage'
import { SpokenLanguagesInterface } from '../../../state/reducers/spokenLanguagesReducer'

export interface PersonalInfo {
    firstName: string
    lastName: string
    email: string
    position: string
    countryId: number
    phone: string
    password: string
    passwordConfirm: string
}

export interface AdditionalInfo {
    department: string
    profileImg: File | null
    languages: SpokenLanguagesInterface[]
    note: string
    termsAndConditionsChecked: boolean
}

interface StatusBarStatusProps {
    statusWidth: string
}

interface ContainerProps {
    height?: string
    padding?: string
}

const Container = styled.div<ContainerProps>`
    background: ${({ theme }) => theme.white};
    border: ${({ theme }) => `1px solid ${theme.grey}`};
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: ${({ height }) => height};
    padding: ${({ padding }) => padding};

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`

const LeftGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;

    @media (max-width: 1024px) {
        width: 100%;
    }
`

const RightGroup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(36, 125, 208, 0.1);
    padding: 1rem;
`

const Heading = styled.h3`
    padding: 2rem 0;
    font-size: 3.2rem;
    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.primary};
`

const TaglineSpan = styled.h5`
    color: ${({ theme }) => theme.blue};
    font-weight: 500;
    font-size: 2rem;
`

const StatusBar = styled.div`
    height: 7.5rem;
    width: 100%;
    background: ${({ theme }) => theme.white};
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;

    @media (max-width: 600px) {
        padding: 0 6vw;
    }
`
const StatusBarStatus = styled.div<StatusBarStatusProps>`
    height: 4px;
    background: ${({ theme, statusWidth }) =>
        statusWidth === '50%'
            ? `linear-gradient(90deg, ${theme.blue} 50%, ${theme.grey} 50%)`
            : theme.blue};
    width: 100%;
    transition: width 0.2s;
    position: absolute;
    bottom: 0;
    left: 0;
`

const HeadingWrap = styled.div`
    padding-top: 2rem;
    width: 70%;
    margin: auto auto;
    position: relative;

    @media (max-width: 768px) {
        width: 95%;
    }
`

const SetupAccountPage: React.FC = () => {
    const translation = useTranslation()

    const history = useHistory()

    const { token } = useParams<{ token: string }>()
    const { onboardingSuccess, onboardingError } = useTypedSelector(
        (state) => state.caseworkerOnboarding
    )

    const { personalInfoLoading, personalInfoError, personalInfoSuccess } =
        useTypedSelector((state) => state.caseworkerOnboarding)

    const {
        additionalInfoLoading,
        additionalInfoError,
        additionalInfoSuccess,
    } = useTypedSelector((state) => state.caseworkerOnboarding)

    const {
        startOnboarding,
        submitPersonalInformation,
        submitAdditionalInformation,
    } = useActions()

    const [termsAndConditionsChecked, setTermsAndConditionsChecked] =
        useState(false)

    const [selectedCountryCode, setSelectedCountryCode] = useState({
        calling_code: '+45',
    })
    const [countryId, setCountryId] = useState(208)

    const [personalInfo, setPersonalInfo] = useState<PersonalInfo>({
        firstName: '',
        lastName: '',
        email: '',
        position: '',
        countryId,
        phone: '',
        password: '',
        passwordConfirm: '',
    })

    const updatePersonalInfo = (key: string, value: string) => {
        if (key === 'phone') {
            setPersonalInfo({
                ...personalInfo,
                phone: value.replace(/^\+\d+\s?/, ''),
            })
            return
        }

        return setPersonalInfo({ ...personalInfo, [key]: value })
    }

    const {
        firstName,
        lastName,
        position,
        email,
        phone,
        password,
        passwordConfirm,
    } = personalInfo

    const onboarding_step =
        onboardingSuccess?.data.onboarding_step === 'additional_information'
            ? 2
            : 1

    const [isFirstFormValid, setIsFirstFormValid] = useState(false)
    const [currentStep, setCurrentStep] = useState(onboarding_step)
    const [isActive, setIsActive] = useState(false)

    const handleFormValidation = () => {
        const isValid =
            isValidName(firstName) &&
            isValidName(lastName) &&
            isValidEmail(email) &&
            isValidPosition(position) &&
            isValidPhone(`${selectedCountryCode.calling_code}${phone}`) &&
            isValidPassword(password) &&
            isValidPasswordConfirm(password, passwordConfirm)

        return setIsFirstFormValid(isValid)
    }

    //second step related
    const [note, setNote] = useState('')
    const [department, setDepartment] = useState('')
    const [selectedLanguages, setSelectedLanguages] = useState<
        SpokenLanguagesInterface[]
    >([])
    const [editedImageFile, setEditedImageFile] = useState<File | null>(null)
    const [isSecondFormValid, setIsSecondFormValid] = useState(false)
    const handleEditedImageFileChange = (imageFile: File | null) => {
        setEditedImageFile(imageFile)
    }

    const additionalInfo: AdditionalInfo = {
        department,
        profileImg: editedImageFile,
        languages: selectedLanguages,
        note,
        termsAndConditionsChecked,
    }

    /*
     * Technically does not handle submit, but rather checks if the form is valid
     * Should be renamed to something more appropriate in the future
     */
    const handleSubmit = () => {
        if (currentStep === 1) {
            isFirstFormValid ? setIsActive(true) : setIsActive(false)
        } else if (currentStep === 2) {
            const isValid =
                isValidNotes(note) &&
                department.length !== 0 &&
                termsAndConditionsChecked
            setIsSecondFormValid(isValid)
            isSecondFormValid ? setIsActive(true) : setIsActive(false)
        }
    }

    const handleNextClick = () => {
        if (currentStep === 1) {
            submitPersonalInformation(token, personalInfo)
        } else if (currentStep === 2) {
            submitAdditionalInformation(token, additionalInfo)
        }
    }

    useEffect(() => {
        handleSubmit()
        // eslint-disable-next-line
    }, [
        isFirstFormValid,
        isSecondFormValid,
        currentStep,
        note,
        department,
        termsAndConditionsChecked,
    ])

    useEffect(() => {
        startOnboarding(token)
        // eslint-disable-next-line
    }, [token])

    useEffect(() => {
        onboardingSuccess && setCurrentStep(onboarding_step)
        personalInfoSuccess && setCurrentStep(2)
        additionalInfoSuccess && history.push('/')
        // eslint-disable-next-line
    }, [
        onboardingSuccess,
        personalInfoSuccess,
        additionalInfoSuccess,
        currentStep,
    ])

    if (!onboardingSuccess) {
        if (onboardingError) {
            return (
                <Container height="100vh" padding="0 1.4rem">
                    <FlashMessage
                        message={{
                            type: 'error',
                            message: onboardingError,
                        }}
                    />
                </Container>
            )
        }
        return null
    }

    return (
        <Container>
            <LeftGroup>
                <HeadingWrap>
                    {personalInfoError && (
                        <FlashMessage
                            message={{
                                type: 'error',
                                message: personalInfoError,
                            }}
                        />
                    )}
                    {additionalInfoError && (
                        <FlashMessage
                            message={{
                                type: 'error',
                                message: additionalInfoError,
                            }}
                        />
                    )}
                    <Heading>
                        {translation.caseworkerSetupAccountPage.setupYourWalor}
                    </Heading>
                    <TaglineSpan>
                        {
                            translation.caseworkerSetupAccountPage
                                .youHaveBeenInvited
                        }{' '}
                        {onboardingSuccess.data.organization_name}{' '}
                        {translation.caseworkerSetupAccountPage.onWalorAs}{' '}
                        {translation.caseworkerSetupAccountPage.caseworker}.
                    </TaglineSpan>
                    <StatusBar>
                        <StatusBarItem>
                            <StatusBarNumber shouldGlow={currentStep === 1}>
                                1
                            </StatusBarNumber>
                            <StatusBarText shouldGlow={currentStep === 1}>
                                {
                                    translation.caseworkerSetupAccountPage
                                        .personalInformation
                                }
                            </StatusBarText>
                        </StatusBarItem>
                        <StatusBarItem>
                            <StatusBarNumber shouldGlow={currentStep === 2}>
                                2
                            </StatusBarNumber>
                            <StatusBarText shouldGlow={currentStep === 2}>
                                {
                                    translation.caseworkerSetupAccountPage
                                        .additionalInformation
                                }
                            </StatusBarText>
                        </StatusBarItem>
                        <StatusBarStatus statusWidth={`${currentStep * 50}%`} />
                    </StatusBar>
                </HeadingWrap>
                {currentStep === 1 ? (
                    <PersonalInformation
                        personalInfo={personalInfo}
                        updatePersonalInfo={updatePersonalInfo}
                        selectedCountryCode={selectedCountryCode}
                        setSelectedCountryCode={setSelectedCountryCode}
                        setCountryId={setCountryId}
                        handleFormValidation={handleFormValidation}
                        handleSubmit={handleSubmit}
                    />
                ) : (
                    <AdditionalInformation
                        note={note}
                        setNote={setNote}
                        department={department}
                        setDepartment={setDepartment}
                        selectedLanguages={selectedLanguages}
                        setSelectedLanguages={setSelectedLanguages}
                        onEditedImageFileChange={handleEditedImageFileChange}
                        termsAndConditionsChecked={termsAndConditionsChecked}
                        setTermsAndConditionsChecked={
                            setTermsAndConditionsChecked
                        }
                        handleSubmit={handleSubmit}
                    />
                )}
                <NextButton
                    height="49px"
                    width="219px"
                    margin-top="5rem"
                    isActive={isActive}
                    handleOnClick={handleNextClick}
                    text={currentStep === 1 ? 'Next' : 'Finish'}
                    isLoading={
                        currentStep === 1
                            ? personalInfoLoading
                            : additionalInfoLoading
                    }
                >
                    {(personalInfoLoading || additionalInfoLoading) && (
                        <ButtonSpinnerWhite />
                    )}
                </NextButton>
            </LeftGroup>
            <RightGroup>
                <Preview
                    personalInfo={personalInfo}
                    additionalInfo={additionalInfo}
                />
            </RightGroup>
        </Container>
    )
}

export default SetupAccountPage

import { useState, useCallback, FC } from 'react'
import {
    ResponsiveContainer,
    PieChart,
    Pie,
    Sector,
    Legend,
    Tooltip,
} from 'recharts'
import { PieSectorDataItem } from 'recharts/types/polar/Pie'
import styled from 'styled-components'

const RADIAN = Math.PI / 180

interface LegendItemProps {
    isActive: boolean
    isHovered: boolean
}

const LegendItem = styled.span<LegendItemProps>`
    color: ${({ theme }) => theme.black};
    border: ${({ isActive }) => isActive && '1px solid black'};
    filter: ${({ isActive, isHovered }) =>
        !isActive && isHovered ? 'blur(2px)' : 'none'};
    transition: filter 0.3s ease;
    cursor: pointer;
`

const StyledResponsiveContainer = styled(ResponsiveContainer)`
    .recharts-pie,
    .recharts-pie-sector {
        outline: none;
    }
`

interface RenderCustomizedLabelProps {
    cx: number
    cy: number
    midAngle: number
    innerRadius: number
    outerRadius: number
    percent: number
}

const renderCustomizedLabel: FC<RenderCustomizedLabelProps> = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
}) => {
    const coefficient = percent < 0.05 ? 1.25 : 0.55
    const radius = innerRadius + (outerRadius - innerRadius) * coefficient
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const percentToDisplay = `${Math.round(percent * 100)}%`

    if (percent < 0.02) {
        return null
    }

    return (
        <text
            x={x}
            y={y}
            fill={percent < 0.05 ? '#666' : 'white'}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={14}
            style={{ fontWeight: 500 }}
        >
            {percentToDisplay}
        </text>
    )
}

interface RenderActiveShapeProps {
    cx: number
    cy: number
    innerRadius: number
    outerRadius: number
    startAngle: number
    endAngle: number
    midAngle: number
    fill: string
}

const RenderActiveShape: FC<RenderActiveShapeProps> = ({
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    midAngle,
    fill,
}) => {
    const [, setActiveIndex] = useState<number | undefined | null>(null)
    const onMouseLeave = useCallback((_, index) => {
        setActiveIndex(null)
    }, [])

    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius - 85) * cos
    const sy = cy + (outerRadius - 85) * sin
    return (
        <Sector
            cx={sx}
            cy={sy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
            onMouseLeave={() => onMouseLeave}
            cursor="pointer"
        />
    )
}

interface PieChartCategoriesProps {
    data: {
        slag: string
        value: number
        fill: string
        name: string
    }[]
}

const PieChartCategories: FC<PieChartCategoriesProps> = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState<number | undefined | null>(
        null
    )
    const [isHovered, setIsHovered] = useState(false)

    const onMouseOver = useCallback((_, index) => {
        setActiveIndex(index)
        setIsHovered(true)
    }, [])
    const onMouseLeave = useCallback((_, index) => {
        setActiveIndex(null)
        setIsHovered(false)
    }, [])

    return (
        <StyledResponsiveContainer width="100%" height="100%">
            <PieChart onMouseLeave={onMouseLeave}>
                <Pie
                    activeIndex={activeIndex as number | undefined}
                    data={data}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    minAngle={1}
                    outerRadius={95}
                    onMouseOver={onMouseOver}
                    onMouseLeave={onMouseLeave}
                    activeShape={RenderActiveShape as PieSectorDataItem}
                    labelLine={false}
                    label={renderCustomizedLabel}
                />
                <Tooltip
                    formatter={(value) => (
                        <span>{Math.round(Number(value))}%</span>
                    )}
                />
                <Legend
                    verticalAlign="bottom"
                    align="center"
                    wrapperStyle={{ fontSize: '1.4rem' }}
                    iconType="circle"
                    formatter={(value, _, index) => (
                        <LegendItem
                            isActive={index === activeIndex}
                            isHovered={isHovered}
                            onMouseOver={() => onMouseOver(_, index)}
                            onMouseOut={() => onMouseLeave(_, index)}
                        >
                            {value}
                        </LegendItem>
                    )}
                    onMouseLeave={onMouseLeave}
                />
            </PieChart>
        </StyledResponsiveContainer>
    )
}

export default PieChartCategories

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import styled from 'styled-components'
import { ModalContainer, Modal } from '../../../../styled/modals'
import { Card, CardHeader } from '../../../../styled/cards'
import React from 'react'
import AddPaymentMethodForm from './frames/AddPaymentMethodForm'
import config from '../../../../../config'
import { useActions, useTranslation } from '../../../../../hooks'

const stripeKey = config.stripeKey

const CancelButton = styled.div`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
    padding: 1rem;
`

interface AddPaymentMethodModalProps {
    closeModal: () => void
}

const StripeProvider: React.FC<AddPaymentMethodModalProps> = ({
    closeModal,
}) => {
    const stripePromise =
        typeof stripeKey !== 'undefined' ? loadStripe(stripeKey) : null

    const translation = useTranslation()

    return (
        <>
            {stripePromise ? (
                <Elements stripe={stripePromise}>
                    <AddPaymentMethodModal closeModal={closeModal} />
                </Elements>
            ) : (
                <>
                    {
                        translation.settingsPartner.oldAddPaymentMethodFrame
                            .addPaymentMethodModal.errorLoadingStripe
                    }
                </>
            )}
        </>
    )
}

const AddPaymentMethodModal: React.FC<AddPaymentMethodModalProps> = ({
    closeModal,
}) => {
    const translation = useTranslation()

    const { clearAddPaymentMethodError } = useActions()

    const handleCloseModal = () => {
        closeModal()
        clearAddPaymentMethodError()
    }

    return (
        <ModalContainer>
            <Modal height="65rem" width="50rem">
                <Card>
                    <CardHeader>
                        {
                            translation.settingsPartner.oldAddPaymentMethodFrame
                                .addPaymentMethodModal.addPaymentMethod
                        }
                        <CancelButton onClick={handleCloseModal}>
                            {translation.reusable.cancel}
                        </CancelButton>
                    </CardHeader>
                    <AddPaymentMethodForm
                        paymentType="card"
                        closeModal={handleCloseModal}
                    />
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default StripeProvider

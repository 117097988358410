import styled from 'styled-components'
import {
    ErrorMessage,
    FormField,
    FormInput40,
    PrimaryLabel,
} from '../../../styled/formElements'
import { NextButton } from '../../buttons'
import {
    Heading,
    HeadingContainer,
    HeadingText,
    // HelpIcon
} from '../styled'
import { useEffect, useState } from 'react'
import { useTranslation, useTypedSelector } from '../../../../hooks'
import {
    checkAddress,
    checkCity,
    checkCompanyName,
    checkZipCode,
    isValidAddress,
    isValidCity,
    isValidCompanyName,
    isValidZipCode,
} from '../../../../helpers/validation'
import { NewOrganizationInterface } from '../../../../ts/interfaces'
import CountryDropdown from '../../CountryDropdown'

const Form = styled.div`
    height: 34.1rem;
    width: 71.1rem;
    margin-top: 2rem;
    border: ${({ theme }) => `1px solid ${theme.grey}`};
    border-radius: 0.5rem;
`

const FormRow = styled.div`
    display: flex;
    align-items: center;
    margin: 0 2.4rem;
    column-gap: 2.4rem;
    padding-bottom: 1rem;
    position: relative;

    :first-child {
        margin-top: 2.6rem;
    }
`

const FormFieldNoPadding = styled(FormField)`
    padding: 0;
`

interface EmployeeNumberButtonProps {
    isActive: boolean
}

const EmployeeNumberButton = styled.div<EmployeeNumberButtonProps>`
    height: 7.4rem;
    width: 7.4rem;
    border-radius: 50%;
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : theme.lightBlue};
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};
    margin-right: 1rem;
    font-size: 1.4rem;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
`

const Error = styled(ErrorMessage)`
    bottom: auto;
`
const SubTitleText = styled.p`
    font-size: 1.4rem;
    margin-block-start: 0.6rem;
    color: #a0a0a6;
`

interface ConfirmCompanyInformationFrameProps {
    clickNextCB: () => void
    organizationInformation: NewOrganizationInterface
    updateOrganizationInformation: (
        key: string,
        value: null | string | any
    ) => void
}

const OnboardingCompanyInformationFrame: React.FC<
    ConfirmCompanyInformationFrameProps
> = ({
    clickNextCB,
    updateOrganizationInformation,
    organizationInformation,
}) => {
    const translation = useTranslation()

    const {
        authLoading,
        // validationErrors
    } = useTypedSelector((state) => state.auth)

    const [companyNameError, setCompanyNameError] = useState('')
    const [addressError, setAddressError] = useState('')
    const [cityError, setCityError] = useState('')
    const [zipCodeError, setZipCodeError] = useState('')

    useEffect(() => {}, [translation])

    const checkValidation = (field: string) => {
        if (field === 'name') {
            setCompanyNameError(
                checkCompanyName(
                    organizationInformation.companyName,
                    translation
                )
            )
        } else if (field === 'address') {
            setAddressError(
                checkAddress(organizationInformation.address, translation)
            )
        } else if (field === 'city') {
            setCityError(checkCity(organizationInformation.city, translation))
        } else if (field === 'zip') {
            setZipCodeError(
                checkZipCode(organizationInformation.zipCode, translation)
            )
        }
    }

    const handleClickNext = async () => {
        if (isFormValid()) {
            clickNextCB()
        }
    }
    const isFormValid = () => {
        if (
            !isValidCompanyName(organizationInformation.companyName) ||
            !isValidAddress(organizationInformation.address) ||
            !isValidCity(organizationInformation.city) ||
            !isValidZipCode(organizationInformation.zipCode) ||
            organizationInformation.employees.length === 0
        ) {
            return false
        }
        return true
    }
    return (
        <>
            <HeadingContainer>
                <Heading>
                    <HeadingText>
                        {translation.onboardingPage.pleaseFillinThisInformation}
                    </HeadingText>
                    {/*<HelpIcon />*/}
                </Heading>
                <NextButton
                    isLoading={authLoading}
                    text={translation.onboardingPage.nextStep}
                    handleOnClick={handleClickNext}
                    isActive={isFormValid()}
                    height="3.5rem"
                    width="12.1rem"
                    fontSize="1.4rem"
                />
            </HeadingContainer>
            <SubTitleText>
                {translation.onboardingPage.subcreateYourCompanyInformation}
            </SubTitleText>
            <Form>
                <FormRow>
                    <FormFieldNoPadding>
                        <PrimaryLabel isError={companyNameError.length > 0}>
                            {translation.onboardingPage.name}
                        </PrimaryLabel>
                        <FormInput40
                            value={organizationInformation.companyName}
                            onBlur={() => checkValidation('name')}
                            onFocus={() => setCompanyNameError('')}
                            isError={companyNameError.length > 0}
                            onChange={(e) =>
                                updateOrganizationInformation(
                                    'companyName',
                                    e.target.value
                                )
                            }
                        />
                        {companyNameError.length > 0 && (
                            <Error>{companyNameError}</Error>
                        )}
                    </FormFieldNoPadding>
                </FormRow>

                <FormRow>
                    <FormFieldNoPadding>
                        <PrimaryLabel isError={addressError.length > 0}>
                            {translation.reusable.address}
                        </PrimaryLabel>
                        <FormInput40
                            value={organizationInformation.address}
                            onBlur={() => checkValidation('address')}
                            onFocus={() => setAddressError('')}
                            isError={addressError.length > 0}
                            onChange={(e) =>
                                updateOrganizationInformation(
                                    'address',
                                    e.target.value
                                )
                            }
                        />
                        {addressError.length > 0 && (
                            <Error>{addressError}</Error>
                        )}
                    </FormFieldNoPadding>
                    <FormFieldNoPadding>
                        <div
                            style={{
                                display: 'flex',
                                gap: '16px',
                            }}
                        >
                            <div
                                style={{
                                    width: '60%',
                                }}
                            >
                                <PrimaryLabel isError={cityError.length > 0}>
                                    {translation.reusable.city}
                                </PrimaryLabel>
                                <FormInput40
                                    value={organizationInformation.city}
                                    onBlur={() => checkValidation('city')}
                                    onFocus={() => setCityError('')}
                                    isError={cityError.length > 0}
                                    onChange={(e) =>
                                        updateOrganizationInformation(
                                            'city',
                                            e.target.value
                                        )
                                    }
                                />
                                {cityError.length > 0 && (
                                    <Error>{cityError}</Error>
                                )}
                            </div>
                            <div
                                style={{
                                    width: '40%',
                                }}
                            >
                                <PrimaryLabel isError={zipCodeError.length > 0}>
                                    {translation.reusable.zipCode}
                                </PrimaryLabel>
                                <FormInput40
                                    value={organizationInformation.zipCode}
                                    onBlur={() => checkValidation('zip')}
                                    onFocus={() => setZipCodeError('')}
                                    isError={zipCodeError.length > 0}
                                    onChange={(e) =>
                                        updateOrganizationInformation(
                                            'zipCode',
                                            e.target.value
                                        )
                                    }
                                />
                                {zipCodeError.length > 0 && (
                                    <Error>{zipCodeError}</Error>
                                )}
                            </div>
                        </div>
                    </FormFieldNoPadding>
                </FormRow>
                <FormRow>
                    <FormFieldNoPadding>
                        <PrimaryLabel>
                            {translation.reusable.country}
                        </PrimaryLabel>
                        <CountryDropdown
                            onCountrySelect={(country) =>
                                updateOrganizationInformation(
                                    'country',
                                    country
                                )
                            }
                        />
                    </FormFieldNoPadding>
                </FormRow>
                {/* <FormRow>
                    <FormFieldNoPadding>
                        <PrimaryLabel>Email</PrimaryLabel>
                        <FormInput40 />
                    </FormFieldNoPadding>
                    <FormFieldNoPadding
                        style={{
                            display: 'flex',
                            gap: '16px',
                        }}
                    >
                        <div>
                            <PrimaryLabel>Phone</PrimaryLabel>
                            <CountryCodeDropdown
                                selectDropdownCB={() => {}}
                                error={false}
                                success={false}
                            />
                        </div>
                        <FormInput40
                            style={{
                                marginTop: '1.8rem',
                            }}
                        />
                    </FormFieldNoPadding>
                </FormRow> */}
                <div
                    style={{
                        margin: '0 2.4rem',
                    }}
                >
                    <PrimaryLabel style={{ lineHeight: '2rem' }}>
                        {translation.reusable.numberOfEmployees}
                    </PrimaryLabel>
                    <div
                        style={{
                            display: 'flex',
                            gap: '1rem',
                            marginTop: '0.5rem',
                        }}
                    >
                        <EmployeeNumberButton
                            isActive={
                                organizationInformation.employees === '1-49'
                            }
                            onClick={() =>
                                updateOrganizationInformation(
                                    'employees',
                                    '1-49'
                                )
                            }
                        >
                            1-49
                        </EmployeeNumberButton>
                        <EmployeeNumberButton
                            isActive={
                                organizationInformation.employees === '50-249'
                            }
                            onClick={() =>
                                updateOrganizationInformation(
                                    'employees',
                                    '50-249'
                                )
                            }
                        >
                            50-249
                        </EmployeeNumberButton>
                        <EmployeeNumberButton
                            isActive={
                                organizationInformation.employees === '250-499'
                            }
                            onClick={() =>
                                updateOrganizationInformation(
                                    'employees',
                                    '250-499'
                                )
                            }
                        >
                            250-499
                        </EmployeeNumberButton>
                        <EmployeeNumberButton
                            isActive={
                                organizationInformation.employees === '500-999'
                            }
                            onClick={() =>
                                updateOrganizationInformation(
                                    'employees',
                                    '500-999'
                                )
                            }
                        >
                            500-999
                        </EmployeeNumberButton>
                        {/* <EmployeeNumberButton
                            isActive={
                                organizationInformation.employees === '+500'
                            }
                            onClick={() =>
                                updateOrganizationInformation(
                                    'employees',
                                    '+500'
                                )
                            }
                        >
                            500+
                        </EmployeeNumberButton>*/}
                    </div>
                </div>
            </Form>
        </>
    )
}

export default OnboardingCompanyInformationFrame

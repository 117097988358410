import styled from 'styled-components'
import { CardCaseRow, CaseCardText } from '../../styled/cards'
import { CaseGrid3Items } from '../../styled/grids'
// import { CaseInterface } from './Cases'
import { useHistory } from 'react-router'
import { useTranslation, useTypedSelector } from '../../../hooks'

interface StatusProp {
    status: string
}

const StatusFlex = styled.div`
    display: flex;
    align-items: center;
`

const StatusCircle = styled.div<StatusProp>`
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    margin-right: 1rem;

    background: ${({ status, theme }) => {
        if (status === 'new') {
            return theme.red
        } else if (status === 'awaiting response') {
            return theme.blue
        } else if (status === 'action required') {
            return theme.yellow
        }
    }};
`

const StatusText = styled.div<StatusProp>`
    font-size: 1.4rem;
    font-weight: 600;

    color: ${({ status, theme }) => {
        if (status === 'new') {
            return theme.red
        } else if (status === 'awaiting response') {
            return theme.blue
        } else if (status === 'action required') {
            return theme.yellow
        }
    }};
`

interface CaseProps {
    currentCase: any
    isAllClosed: boolean
}

const Case: React.FC<CaseProps> = ({ currentCase, isAllClosed }) => {
    const translation = useTranslation()
    const history = useHistory()
    const { isTrialExpired } = useTypedSelector((state) => state.payment)

    const statusMapping: { [key: string]: string } = {
        new: translation.reusable.new,
        'action required': translation.singleClientViewPartner.actionRequired,
        solved: translation.reusable.closed,
        rejected: translation.reusable.closed,
        deleted: translation.reusable.archived,
    }

    const handleRouteToCase = async () => {
        if (isTrialExpired) {
            return
        }
        const url =
            currentCase.status === 'deleted'
                ? `deleted-report/${currentCase.id}`
                : `report/${currentCase.id}`

        history.push(url)
    }

    return (
        <CardCaseRow onClick={handleRouteToCase}>
            <CaseGrid3Items>
                <CaseCardText>{currentCase.title}</CaseCardText>
                <CaseCardText>{currentCase.organizationName}</CaseCardText>
                <CaseCardText>
                    <StatusFlex>
                        <StatusCircle status={currentCase.status} />
                        <StatusText status={currentCase.status}>
                            {statusMapping[currentCase.status] ||
                                translation.singleClientViewPartner
                                    .awaitingResponse}
                        </StatusText>
                    </StatusFlex>
                </CaseCardText>
            </CaseGrid3Items>
        </CardCaseRow>
    )
}

export default Case

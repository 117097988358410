import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    FiAlertCircle,
    FiInfo,
    FiSend,
    FiUpload,
    FiArchive,
} from 'react-icons/fi'
import { IoIosHourglass } from 'react-icons/io'
import { formatDateWithTime } from '../../../../../helpers/date'
import initials from '../../../../../helpers/string/initials'
import { Link } from 'react-router-dom'
import { getResponseDeadline } from '../../../../../helpers/data/getResponseDeadline'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import { FiUser } from 'react-icons/fi'
import { AvatarWrapper } from '../../../../layout/header/Header'
import { AvatarInitials } from '../../../../layout/header/Header'
import CaseworkersPopup from './CaseworkersPopup'

const InformationBarContainer = styled.div`
    height: 5.7rem;
    width: 100%;
    border: ${({ theme }) => `${theme.greyText}50 1px solid`};
    border-radius: 1rem;
    background: ${({ theme }) => theme.white};
    padding: 0 1.6rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    @media screen and (max-width: 600px) {
        height: 9rem;
        align-items: flex-start;
        padding-block-start: 2rem;
    }
`

const Flex = styled.div`
    display: flex;
    align-items: center;
`

const MobileBar = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }
`

const GreyText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.greyText};
    display: flex;
    gap: 0.3rem;
    white-space: nowrap;
`

const Dot = styled.div`
    height: 0.3rem;
    width: 0.3rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.greyText};
    margin: 0 1.2rem;
`

const InfoIcon = styled(FiInfo)`
    font-size: 1.8rem;
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.white};
    fill: ${({ theme }) => theme.greyText};
`

const IconContainer = styled.div`
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    @media screen and (max-width: 480px) {
        margin: 0;
    }
`

const AlertIconContainer = styled(IconContainer)`
    background: ${({ theme }) => `${theme.red}50`};
`

const WarningIconContainer = styled(IconContainer)`
    background: ${({ theme }) => theme.yellow};
`

const HourglassIconContainer = styled(IconContainer)`
    background: ${({ theme }) => theme.greyText};
`

const AlertIcon = styled(FiAlertCircle)`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.red};
`

const WarningIcon = styled(FiAlertCircle)`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.yellow};
`

const HourglassIcon = styled(IoIosHourglass)`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.white};
`

const AlertText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.red};
    margin-left: 0.5rem;
    white-space: nowrap;
`

const WarningText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.yellow};
    margin-left: 0.5rem;
`

const HourglassText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.greyText};
    margin-left: 0.5rem;
`
const HoverContainer = styled.div`
    position: relative;
`
const HiddenDeadlineMsg = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 5rem;
    left: 4rem;
    color: ${({ theme }) => theme.greyText};
    background: ${({ theme }) => theme.white};
    border: ${({ theme }) => `${theme.greyText}50 1px solid`};
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    width: 50rem;
    padding: 1rem;
    font-size: 1.2rem;
    line-height: 1.6rem;

    @media screen and (max-width: 1500px) {
        left: 3rem;
    }

    @media screen and (max-width: 1000px) {
        left: 1rem;
    }
`

const InitialsCircle = styled.div`
    height: 2.6rem;
    width: 2.6rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.memberColor};
    color: #aeb9c3;
    font-size: 1.2rem;
    margin-right: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
`

const InformationBarRightContainer = styled.div`
    display: flex;
    gap: 0;
    align-items: center;
`

const UserClick = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    :hover {
        opacity: 0.6;
    }
`

const UserIcon = styled(FiUser)`
    color: ${({ theme }) => theme.greyText};
    font-size: 2rem;
`

const UserCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    color: ${({ theme }) => theme.white};
    border-radius: 50%;
    margin-right: 1rem;
    background: ${({ theme }) => theme.greyText};
`

export const AvatarWrapperSmaller = styled(AvatarWrapper)`
    height: 3rem !important;
    width: 3rem !important;
    font-size: 1rem !important;
    margin-right: 0rem !important;
`

const PointerFlex = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 0.3s;

    :hover {
        opacity: 0.6;
    }
`

const PointerFlexLink = styled(Link)`
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 0.3s;

    :hover {
        opacity: 0.6;
    }
`
const ExportIcon = styled(FiUpload)`
    color: ${({ theme }) => theme.greyText};
    margin-left: 2rem;
    margin-right: 0.5rem;
    font-size: 1.4rem;
`

const ForwardIcon = styled(FiSend)`
    color: ${({ theme }) => theme.greyText};
    margin-left: 2rem;
    margin-right: 0.5rem;
    font-size: 1.4rem;
`

const CloseIcon = styled(FiArchive)`
    color: ${({ theme }) => theme.greyText};
    margin-left: 2rem;
    margin-right: 0.5rem;
    font-size: 1.4rem;
`

const MobileDisplayNone = styled.div`
    display: inherit;
    @media screen and (max-width: 480px) {
        display: none;
    }
`

const ReporterNameDesktopView = styled.div`
    @media screen and (max-width: 600px) {
        display: none;
    }
`

const ReporterNameMobileView = styled.div`
    display: none;

    @media screen and (max-width: 600px) {
        display: block;
    }
`

const TimeAndCloseCaseWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 87rem;

    @media screen and (max-width: 600px) {
        gap: 5rem;
    }
`

interface InformationBarProps {
    report: any
    messages: any
    openReporterModal: () => void
    openChangeResponsibleModal: () => void
    openCloseCaseModal: () => void
}

const InformationBar: React.FC<InformationBarProps> = ({
    report,
    messages,
    openReporterModal,
    openChangeResponsibleModal,
    openCloseCaseModal,
}) => {
    const { user } = useTypedSelector((state) => state.auth)
    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )
    const contactLines = openReport?.caseworkers?.data
    const translation = useTranslation()

    const [deadline, setDeadline] = useState<null | string>('')
    const [daysLeft, setDaysLeft] = useState<any>(92)

    const [showMessage, setShowMessage] = useState<boolean>(false)

    const [isCaseworkersPopupOpen, setIsCaseworkersPopupOpen] = useState(false)

    const { exportReport } = useActions()

    useEffect(() => {
        if (report) {
            const res: any = getResponseDeadline(report, messages, translation)

            setDeadline(res.dateString)
            setDaysLeft(res.daysLeft)
        }
    }, [report, messages, translation])

    const handleOpenCaseworkersPopup = () => {
        if (openReport?.closed_at) {
            return
        }

        setIsCaseworkersPopupOpen(true)
    }

    const handleExport = async () => {
        exportReport(report.id)
    }

    return (
        <InformationBarContainer>
            <MobileBar>
                <Flex>
                    <>
                        <GreyText>{report?.readable_id}</GreyText>
                        <Dot />
                        <GreyText>
                            {report && formatDateWithTime(report?.created_at)}
                        </GreyText>
                    </>

                    <>
                        <Dot />
                        <PointerFlex onClick={openReporterModal}>
                            <GreyText>
                                <MobileDisplayNone>
                                    {translation.caseworkerOpenReport.reportBy}
                                </MobileDisplayNone>
                                {report &&
                                !Array.isArray(report.reporter.data) ? (
                                    <>
                                        <ReporterNameDesktopView>
                                            {`${report?.reporter.data.first_name} ${report?.reporter.data.last_name}`}
                                        </ReporterNameDesktopView>
                                        <ReporterNameMobileView>
                                            {initials(
                                                `${report?.reporter.data.first_name} ${report?.reporter.data.last_name}`
                                            )}
                                        </ReporterNameMobileView>
                                    </>
                                ) : (
                                    translation.reusable.anonymous
                                )}
                            </GreyText>
                            <InfoIcon />
                        </PointerFlex>
                    </>
                </Flex>
                <TimeAndCloseCaseWrapper>
                    <Flex>
                        {!report?.closed_at && deadline && (
                            <>
                                {daysLeft < 8 ? (
                                    <HoverContainer>
                                        {showMessage && (
                                            <HiddenDeadlineMsg>
                                                {`${translation.caseworkerOpenReport.sevenDaysMessageOne} ${translation.caseworkerOpenReport.sevenDaysMessageTwo}`}
                                            </HiddenDeadlineMsg>
                                        )}
                                        <Flex
                                            onMouseOver={() =>
                                                setShowMessage(true)
                                            }
                                            onMouseOut={() =>
                                                setShowMessage(false)
                                            }
                                        >
                                            <AlertIconContainer>
                                                <AlertIcon />
                                            </AlertIconContainer>
                                            <AlertText>
                                                {deadline.at(0) === ','
                                                    ? deadline.slice(1, 99)
                                                    : deadline}
                                            </AlertText>
                                        </Flex>
                                    </HoverContainer>
                                ) : daysLeft < 15 ? (
                                    <Flex>
                                        <WarningIconContainer>
                                            <WarningIcon />
                                        </WarningIconContainer>
                                        <WarningText>{deadline}</WarningText>
                                    </Flex>
                                ) : (
                                    <HoverContainer>
                                        {showMessage && (
                                            <HiddenDeadlineMsg>
                                                {`${translation.caseworkerOpenReport.ninetyDaysMessageOne} ${translation.caseworkerOpenReport.ninetyDaysMessageTwo}`}
                                            </HiddenDeadlineMsg>
                                        )}
                                        <Flex
                                            onMouseOver={() =>
                                                setShowMessage(true)
                                            }
                                            onMouseOut={() =>
                                                setShowMessage(false)
                                            }
                                        >
                                            <HourglassIconContainer>
                                                <HourglassIcon />
                                            </HourglassIconContainer>
                                            <HourglassText>
                                                {deadline}
                                            </HourglassText>
                                        </Flex>
                                    </HoverContainer>
                                )}
                            </>
                        )}

                        <Flex>
                            {user?.roles.includes('partner') && (
                                <PointerFlex
                                    onClick={openChangeResponsibleModal}
                                >
                                    <InitialsCircle>
                                        {initials(
                                            `${report?.assigned.data.first_name} ${report?.assigned.data.last_name}`
                                        )}
                                    </InitialsCircle>
                                    <GreyText>{`${report?.assigned.data.first_name} ${report?.assigned.data.last_name}`}</GreyText>
                                </PointerFlex>
                            )}

                            {!user?.roles.includes('contact') &&
                                report?.status !== 'Forwarded' && (
                                    <PointerFlexLink
                                        to={`/forward-report/${report?.id}`}
                                    >
                                        <ForwardIcon />
                                        <GreyText>
                                            {
                                                translation.caseworkerOpenReport
                                                    .forward
                                            }
                                        </GreyText>
                                    </PointerFlexLink>
                                )}
                        </Flex>
                    </Flex>
                </TimeAndCloseCaseWrapper>
            </MobileBar>

            <InformationBarRightContainer>
                <UserClick onClick={handleOpenCaseworkersPopup}>
                    <UserIcon />
                    <UserCircle>{contactLines?.length}</UserCircle>
                    <AvatarWrapperSmaller>
                        <AvatarInitials>
                            {' '}
                            {initials(user?.firstName + ' ' + user?.lastName)}
                        </AvatarInitials>
                    </AvatarWrapperSmaller>
                    <GreyText>
                        {user?.firstName + ' ' + user?.lastName}
                    </GreyText>
                </UserClick>
                {isCaseworkersPopupOpen && (
                    <CaseworkersPopup
                        closeModal={() => setIsCaseworkersPopupOpen(false)}
                    />
                )}

                {!openReport?.closed_at && (
                    <PointerFlex onClick={handleExport}>
                        <ExportIcon />
                        <GreyText>
                            {translation.caseworkerOpenReport.exportCase}
                        </GreyText>
                    </PointerFlex>
                )}

                {user?.roles.includes('contact') && !openReport?.closed_at && (
                    <PointerFlex onClick={openCloseCaseModal}>
                        <CloseIcon />
                        <GreyText>
                            {translation.caseworkerOpenReport.closeCase}
                        </GreyText>
                    </PointerFlex>
                )}
            </InformationBarRightContainer>
        </InformationBarContainer>
    )
}

export default InformationBar

import {
    CardBody,
    CasesNumber,
    CasesOverview,
    CasesStatus,
    Flex,
    NoData,
    SmallCard,
    Title,
    TotalNumber,
} from '../StatisticsPage'
import { useTranslation, useTypedSelector } from '../../../../../hooks'

const TotalCases = () => {
    const translation = useTranslation()
    const { statisticsOverview } = useTypedSelector(
        (state) => state.statisticsOverview
    )
    return (
        <SmallCard>
            <Title>{translation.header.cases}</Title>
            {statisticsOverview ? (
                <>
                    <CardBody>
                        <TotalNumber>
                            {statisticsOverview?.totalCases.all}
                        </TotalNumber>
                        <CasesOverview>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <CasesStatus>
                                    {
                                        translation.dashboardPartner
                                            .yourStatistics.activeCases
                                    }
                                </CasesStatus>
                                <CasesNumber>
                                    {statisticsOverview?.totalCases.active}
                                </CasesNumber>
                            </Flex>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <CasesStatus>
                                    {
                                        translation.dashboardPartner
                                            .yourStatistics.closedCases
                                    }
                                </CasesStatus>
                                <CasesNumber>
                                    {statisticsOverview?.totalCases.closed}
                                </CasesNumber>
                            </Flex>
                        </CasesOverview>
                    </CardBody>
                    {/* <Block>
                        <UpOrDown color="green">
                            <ArrowUp color="green" />
                            21% {translation.statisticsPage.lastYear}
                        </UpOrDown>
                    </Block> */}
                </>
            ) : (
                <NoData>{translation.statisticsPage.noData}</NoData>
            )}
        </SmallCard>
    )
}

export default TotalCases

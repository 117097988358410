import { Card, CardHeader, CardBody } from '../../../../styled/cards'
import { ModalContainer, Modal } from '../../../../styled/modals'
import { DynamicHeightBlueButton } from '../../../../styled/buttons'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import FileImage from '../../../../../assets/images/file-05.svg'
import { formatDateWithTime } from '../../../../../helpers/date'
import styled from 'styled-components'
import { useState } from 'react'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'

interface ExportCaseModalProps {
    closeModal: () => void
}

const CancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    padding: 1rem;
    cursor: pointer;
`

const Title = styled.h1`
    font-size: 1.4rem;
    margin: 3.2rem 0;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.primary};
`

const ExportFile = styled.div`
    width: 23.4rem;
    height: 5.7rem;
    margin: auto;
    background: ${({ theme }) => `${theme.primary}10`};
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    grid-template-rows: auto auto;
    border-radius: 1rem;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`

const DocumentIconGrid = styled.div`
    display: grid;
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
    margin: auto;
`

const CaseNumberGrid = styled.div`
    grid-column: 2 / span 2;
    grid-row: 1 / span 1;
    font-size: 1.4rem;
    margin-top: 1rem;
    color: ${({ theme }) => theme.primary};
`
const DateGrid = styled.div`
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    margin-top: 0.8rem;
    color: ${({ theme }) => `${theme.primary}70`};
`

const FileSizeGrid = styled.div`
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    margin-top: 0.8rem;
    color: ${({ theme }) => `${theme.primary}70`};
`

const ExportCaseModal: React.FC<ExportCaseModalProps> = ({ closeModal }) => {
    const translation = useTranslation()
    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const [isLoading, setIsLoading] = useState(false)

    const { exportReport } = useActions()

    const handleExport = async () => {
        setIsLoading(true)

        if (!openReport?.id) return

        await exportReport(openReport.id)

        setIsLoading(false)
        closeModal()
    }

    return (
        <ModalContainer>
            <Modal height="32.3rem" width="55.6rem">
                <Card>
                    <CardHeader>
                        {translation.caseworkerOpenReport.exportCase}
                        <CancelButton onClick={closeModal}>
                            {translation.reusable.cancel}
                        </CancelButton>
                    </CardHeader>
                    <CardBody>
                        <Title>
                            {
                                translation.singleClientViewPartner
                                    .youAreInTheProcessOfExporting
                            }{' '}
                            {openReport?.readable_id}.
                        </Title>
                        <ExportFile>
                            <DocumentIconGrid>
                                <img src={FileImage} alt="file" />
                            </DocumentIconGrid>
                            <CaseNumberGrid>
                                {translation.singleClientViewPartner.caseID}{' '}
                                {openReport?.readable_id}.pdf
                            </CaseNumberGrid>
                            <DateGrid>
                                {!openReport?.created_at
                                    ? 'loading'
                                    : formatDateWithTime(openReport.created_at)}
                            </DateGrid>
                            <FileSizeGrid>21 MB</FileSizeGrid>
                        </ExportFile>
                        <ButtonContainer>
                            <DynamicHeightBlueButton
                                width="24.3rem"
                                height="4.5rem"
                                fontSize="1.6rem"
                                style={{ marginTop: '3.6rem' }}
                                onClick={handleExport}
                            >
                                {isLoading ? (
                                    <ButtonSpinnerWhite />
                                ) : (
                                    translation.caseworkerOpenReport.exportCase
                                )}
                            </DynamicHeightBlueButton>
                        </ButtonContainer>
                    </CardBody>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default ExportCaseModal

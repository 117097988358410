import styled from 'styled-components'
import { Card, CardHeader } from '../../../../../styled/cards'
import {
    FormField,
    PrimaryLabel,
    FormInput40,
} from '../../../../../styled/formElements'
import CountryCodeDropdown from '../../../../../reusable/form/CountryCodeDropdown'
import { useState, useEffect, useRef } from 'react'
import { useTypedSelector, useActions } from '../../../../../../hooks'
import {
    Identity,
    PersonalInfoTypes,
    UpdateIdentityStatus,
} from '../../../../../../state/state-types/IdentityTypes'
import { useSaveIdentity } from '../../../../../reusable/functions/useSaveIdentity'
import useCheckValidation from '../../../../../reusable/functions/useCheckValidation'
import profilePlaceholder from '../../../../../../assets/images/profile.svg'
import ChangeImage from '../../../../../reusable/changeProfileImage/ChangeImage'
import ProfileImageEditorModal from '../../modals/ProfileImageEditorModal'
import AvatarEditor from 'react-avatar-editor'
import DefaultLanguageV2 from './DefaultLanguage.V2'
import ButtonSpinnerWhite from '../../../../../layout/spinner/ButtonSpinnerWhite'
import {
    FeedbackMessage,
    StyledCheckIcon,
    StyledCancelIcon,
} from '../../../../../styled/formElements'
import User from '../../../../../../state/state-types/UserStateType'
import { EditSection } from '../MyAccount'

const StyledCard = styled(Card)`
    overflow: visible;
    height: fit-content;
`

const Container = styled.div`
    width: 55%;
    margin-top: 2.8rem;
`

const StyledFormField = styled(FormField)`
    margin-right: 2rem;
`

const BlackButton = styled.div<BlackButtonProps>`
    height: 2.7rem;
    padding: 0 1.6rem;
    display: flex;
    align-items: center;
    background: ${({ theme, isActive }) =>
        !isActive ? `${theme.black}50` : theme.black};
    color: ${({ theme }) => theme.white};
    font-size: 1.4rem;
    border-radius: 0.5rem;
    pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};
`

const CancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}60`};
    cursor: pointer;
    padding: 1.4rem;
`

const HeaderButtonFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const FormContainer = styled.form`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const FormBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 100%;
`

const ProfileImg = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    gap: 1rem;
`

const Image = styled.img`
    width: 12rem;
    height: 12rem;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 50%;
`

const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

const PhoneInputContainer = styled.div`
    width: 85%;
`

const ErrorMessage = styled.p`
    color: ${({ theme }) => theme.red};
`

const StyledFormBody = styled(FormBody)`
    margin-bottom: 3rem;
`

const RemoveCaseworkerImage = styled.button`
    border: none;
    background-color: transparent;
    margin: 1rem 2rem;

    :hover {
        cursor: pointer;
        color: ${({ theme }) => theme.blue};
    }
`

interface ProfilePictureType {
    image: string
    zoom: number
    id: number | null
}

interface BlackButtonProps {
    isActive: boolean | string
}

interface PersonalInformationFormProps {
    saveIdentity: (
        user: User,
        personalInfo: PersonalInfoTypes,
        getIdentity: PersonalInfoTypes
    ) => Promise<void>
    updateIdentityStatus: UpdateIdentityStatus
    setEditSection: React.Dispatch<React.SetStateAction<EditSection>>
    editSection: EditSection
}

const PersonalInformationForm: React.FC<PersonalInformationFormProps> = ({
    saveIdentity,
    updateIdentityStatus,
    setEditSection,
    editSection,
}) => {
    const { getIdentity, identityLoading, identityErrors } = useTypedSelector(
        (state) => state.identity
    )
    const { user } = useTypedSelector((state) => state.auth)

    const { changeLanguage, deleteProfileImage } = useActions()
    const editor = useRef<AvatarEditor>(null)

    const [personalInfo, setPersonalInfo] = useState(getIdentity)
    const [focused, setFocused] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [isImageEditorOpen, setIsImageEditorOpen] = useState<boolean>(false)
    const [isDropdownActive, setIsDropdownActive] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const { comparePersonalInfo } = useSaveIdentity()
    const [canBeSave, setCanBeSave] = useState(false)

    const {
        isFormValidated,
        isPersonalInfomationFormValidated,
        firstNameError,
        lastNameError,
        emailError,
        phoneError,
        setFirstNameError,
        setLastNameError,
        setEmailError,
        setPhoneError,
    } = useCheckValidation()

    const [profilePicture, setProfilePicture] = useState<ProfilePictureType>({
        image: '',
        zoom: 1,
        id: null,
    })

    useEffect(() => {
        if (identityErrors) {
            setShowMessage(true)
            setIsDeleting(false)
        }
    }, [identityErrors])

    useEffect(() => {
        if (updateIdentityStatus.success === true) {
            setShowMessage(true)
            setCanBeSave(false)
        } else if (updateIdentityStatus.success === false) {
            setShowMessage(true)
            setCanBeSave(true)
        }
    }, [updateIdentityStatus])

    useEffect(() => {
        setPersonalInfo(getIdentity)
    }, [getIdentity])

    useEffect(() => {
        if (personalInfo) {
            isFormValidated(
                'phone',
                personalInfo?.phone?.country_code + personalInfo.phone.phone
            )
        }
        // eslint-disable-next-line
    }, [personalInfo?.phone?.country_code])

    useEffect(() => {
        const { hasChanges } = comparePersonalInfo(personalInfo, getIdentity)
        setCanBeSave(hasChanges)
        // eslint-disable-next-line
    }, [personalInfo])

    useEffect(() => {
        let timeoutId: NodeJS.Timeout

        if (showMessage) {
            timeoutId = setTimeout(() => {
                setShowMessage(false)
            }, 5000)
        }
        return () => clearTimeout(timeoutId)
    }, [showMessage])

    const handlerUpdatePersonalInfo = <Key extends keyof Identity>(
        field: Key,
        value: Identity[Key]
    ) => {
        setPersonalInfo({ ...personalInfo, [field]: value })
    }

    const profileImageSrc = personalInfo?.contact_information?.profile_img
    const isInstanceOfFile = profileImageSrc instanceof File
    const imgAlt = isInstanceOfFile ? 'img selected' : 'profile img'

    const handleCancelEditProfilePicture = () => {
        setIsImageEditorOpen(false)
        setProfilePicture({
            ...profilePicture,
        })
    }

    const handleProfilePictureZoom = (value: number) => {
        setProfilePicture({
            ...profilePicture,
            zoom: value,
        })
    }

    const handleSaveEditedProfilePicture = async () => {
        if (editor.current) {
            const canvasScaled = editor.current.getImageScaledToCanvas()
            const dataUrl = canvasScaled.toDataURL()
            const result = await fetch(dataUrl)
            const blob = await result.blob()
            const imageFile = new File([blob], 'imageFile')

            if (profilePicture) {
                handlerUpdatePersonalInfo('contact_information', {
                    ...personalInfo.contact_information,
                    profile_img: imageFile,
                })
            }
        }

        setIsImageEditorOpen(false)
    }

    const handlerSaveIdentity = () => {
        user && saveIdentity(user, personalInfo, getIdentity)
        setEditSection('personal')
    }

    const handlerCancelUpdateIdentity = () => {
        setPersonalInfo(getIdentity)
        changeLanguage(getIdentity.selected_language.data.code || 'en') // set changeLanguage back to the one in system
        setFirstNameError('')
        setLastNameError('')
        setEmailError('')
        setPhoneError('')
        setIsDropdownActive(false)
    }

    const removeImage = () => {
        setIsDeleting(true)

        deleteProfileImage(user?.userId)
        // After calling deleteCaseworkerImage, the profile_image state will be set to null, but the component won't re-render automatically.
        // This causes the previous image to remain displayed. To ensure that the component reflects the latest state of profile_image,
        // we call handlerUpdateInfo, which triggers a re-render with the updated state
        handlerUpdatePersonalInfo('contact_information', {
            ...personalInfo.contact_information,
            profile_img: null,
        })
        setShowMessage(true)
        setIsDeleting(false)
    }

    return (
        personalInfo && (
            <>
                {isImageEditorOpen && (
                    <ProfileImageEditorModal
                        onClose={handleCancelEditProfilePicture}
                        onSave={handleSaveEditedProfilePicture}
                        editorRef={editor}
                        image={profilePicture.image}
                        onChange={(e) =>
                            handleProfilePictureZoom(e.target.value)
                        }
                        value={profilePicture.zoom}
                        scale={profilePicture.zoom}
                    />
                )}
                <>
                    <StyledCard>
                        <CardHeader>
                            Personal information
                            {user && (
                                <HeaderButtonFlex>
                                    <CancelButton
                                        onClick={handlerCancelUpdateIdentity}
                                    >
                                        Cancel
                                    </CancelButton>
                                    <BlackButton
                                        onClick={handlerSaveIdentity}
                                        isActive={
                                            canBeSave &&
                                            isPersonalInfomationFormValidated
                                        }
                                    >
                                        {identityLoading &&
                                        editSection === 'personal' ? (
                                            <ButtonSpinnerWhite />
                                        ) : (
                                            'Save'
                                        )}
                                    </BlackButton>
                                </HeaderButtonFlex>
                            )}
                        </CardHeader>

                        <StyledFormBody>
                            <div>
                                <ProfileImg>
                                    {profileImageSrc ? (
                                        <Image
                                            src={
                                                isInstanceOfFile
                                                    ? URL.createObjectURL(
                                                          profileImageSrc
                                                      )
                                                    : profileImageSrc
                                            }
                                            alt={imgAlt}
                                        />
                                    ) : (
                                        <Image
                                            src={profilePlaceholder}
                                            alt="img placeholder"
                                        />
                                    )}
                                    <p>Max. 200 MB</p>

                                    <ChangeImage
                                        setProfilePicture={setProfilePicture}
                                        setIsImageEditorOpen={
                                            setIsImageEditorOpen
                                        }
                                    />
                                </ProfileImg>
                                {profileImageSrc && (
                                    <RemoveCaseworkerImage
                                        onClick={removeImage}
                                    >
                                        {isDeleting
                                            ? 'Deleting'
                                            : 'Remove image'}
                                    </RemoveCaseworkerImage>
                                )}
                            </div>

                            <Container>
                                <FormContainer>
                                    <StyledFormField>
                                        <PrimaryLabel>First name</PrimaryLabel>
                                        <FormInput40
                                            value={personalInfo?.first_name}
                                            onChange={(event) => {
                                                handlerUpdatePersonalInfo(
                                                    'first_name',
                                                    event.target.value
                                                )
                                                isFormValidated(
                                                    'first_name',
                                                    event.target.value
                                                )
                                            }}
                                            onBlur={() => setFocused(false)}
                                            onFocus={() => setFocused(true)}
                                        />
                                        <ErrorMessage>
                                            {!focused && firstNameError}
                                        </ErrorMessage>
                                    </StyledFormField>
                                    <FormField>
                                        <PrimaryLabel>Last name</PrimaryLabel>
                                        <FormInput40
                                            value={personalInfo.last_name}
                                            onChange={(event) => {
                                                handlerUpdatePersonalInfo(
                                                    'last_name',
                                                    event.target.value
                                                )
                                                isFormValidated(
                                                    'last_name',
                                                    event.target.value
                                                )
                                            }}
                                            onBlur={() => {
                                                setFocused(false)
                                            }}
                                            onFocus={() => setFocused(true)}
                                        />
                                        <ErrorMessage>
                                            {!focused && lastNameError}
                                        </ErrorMessage>
                                    </FormField>
                                </FormContainer>
                                <FormField>
                                    <PrimaryLabel>Work email</PrimaryLabel>
                                    <FormInput40
                                        value={personalInfo.email}
                                        onChange={(event) => {
                                            handlerUpdatePersonalInfo(
                                                'email',
                                                event.target.value
                                            )
                                            isFormValidated(
                                                'email',
                                                event.target.value
                                            )
                                        }}
                                        onBlur={() => setFocused(false)}
                                        onFocus={() => setFocused(true)}
                                    />
                                    <ErrorMessage>
                                        {!focused && emailError}
                                    </ErrorMessage>
                                </FormField>
                                <FormContainer>
                                    <StyledFormField>
                                        <PrimaryLabel>
                                            Default language
                                        </PrimaryLabel>

                                        <DefaultLanguageV2
                                            personalInfo={personalInfo}
                                            handlerUpdatePersonalInfo={
                                                handlerUpdatePersonalInfo
                                            }
                                            setIsDropdownActive={
                                                setIsDropdownActive
                                            }
                                            isDropdownActive={isDropdownActive}
                                        />
                                    </StyledFormField>
                                    <FormField>
                                        <PrimaryLabel>Phone</PrimaryLabel>
                                        <FlexInputContainer>
                                            <CountryCodeDropdown
                                                selectDropdownCB={(country) => {
                                                    handlerUpdatePersonalInfo(
                                                        'phone',
                                                        {
                                                            ...personalInfo.phone,
                                                            country_code:
                                                                country.calling_code,
                                                        }
                                                    )
                                                    handlerUpdatePersonalInfo(
                                                        'country_id',
                                                        country.id
                                                    )
                                                }}
                                                error={false}
                                                success={false}
                                                previouslySelectedCountryCode={
                                                    personalInfo?.phone
                                                        ?.country_code
                                                }
                                                width="9.8rem"
                                            />
                                            <PhoneInputContainer>
                                                <FormInput40
                                                    value={
                                                        personalInfo.phone.phone
                                                    }
                                                    onChange={(event) => {
                                                        handlerUpdatePersonalInfo(
                                                            'phone',
                                                            {
                                                                ...personalInfo.phone,
                                                                phone: event
                                                                    .target
                                                                    .value,
                                                            }
                                                        )
                                                        isFormValidated(
                                                            'phone',
                                                            personalInfo.phone
                                                                .country_code +
                                                                event.target
                                                                    .value
                                                        )
                                                    }}
                                                    onBlur={() =>
                                                        setFocused(false)
                                                    }
                                                    onFocus={() =>
                                                        setFocused(true)
                                                    }
                                                />
                                                <ErrorMessage>
                                                    {!focused && phoneError}
                                                </ErrorMessage>
                                            </PhoneInputContainer>
                                        </FlexInputContainer>
                                    </FormField>
                                </FormContainer>
                            </Container>
                        </StyledFormBody>
                        {showMessage && (
                            <FeedbackMessage
                                isSuccess={identityErrors}
                                showMessage={showMessage}
                            >
                                {identityErrors ? (
                                    <StyledCancelIcon />
                                ) : (
                                    <StyledCheckIcon />
                                )}
                                <p>
                                    {identityErrors
                                        ? 'Something went wrong. Please try again.'
                                        : 'Your changes have been saved successfully'}
                                </p>
                            </FeedbackMessage>
                        )}
                    </StyledCard>
                </>
            </>
        )
    )
}

export default PersonalInformationForm

import React, { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import logo from './logo-white.svg'
import { Link } from 'react-router-dom'
import { useActions, useTypedSelector } from '../../../hooks'
import { ModalContainer, Modal } from '../../styled/modals'
import { VscChromeClose } from 'react-icons/vsc'
import { FiFolder, FiUser } from 'react-icons/fi'
import { BiStoreAlt } from 'react-icons/bi'
import { TbGridDots } from 'react-icons/tb'
import { BsBarChart } from 'react-icons/bs'

interface MobileHeaderInterface {
    isOpen: boolean
    close: () => void
    isPartnerOrCaseworker: boolean
}

interface IsOpenProps {
    isOpen: boolean
    isClosing: boolean
}

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const Drawer = styled(Modal)<IsOpenProps>`
    background: white;
    opacity: 0;
    ${({ isOpen, isClosing }) =>
        isClosing
            ? css`
                  animation: ${fadeOutAnimation} 1s ease-in-out forwards;
              `
            : isOpen
            ? css`
                  animation: ${fadeInAnimation} 1s ease-in-out forwards;
              `
            : null};
`
const DrawerContainer = styled.div`
    @media screen and (max-width: 1000px) {
        display: block;
        width: 100vw;
        height: 100vh;
        margin: 0 auto;
        background: ${({ theme }) => theme.primary};
    }
    display: none;
`
const DrawerHeader = styled.div`
    height: 85px;
    padding: 0 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Logo = styled.img`
    height: 2.6rem;
`
const CloseDrawer = styled.div``
const CloseIcon = styled(VscChromeClose)`
    color: white;
    font-size: 3rem;
`

const DrawerNavigation = styled.div`
    height: 85%;
    display: flex;
    flex-direction: column;
    margin: 0 3rem;
    justify-content: space-between;

    @media screen and (max-width: 480px) {
        height: 80%;
    }
`

const DrawerNavigationLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0;
`

const DrawerNavigationOption = styled.div`
    padding-left: 1rem;
    font-size: 1.6rem;
    color: white;
`
const DrawerNavigationIcon = styled.div``

const DashboardIcon = styled(TbGridDots)`
    color: white;
    font-size: 2rem;
`

const CasesIcon = styled(FiFolder)`
    color: white;
    font-size: 2rem;
`
const OrganizationIcon = styled(BiStoreAlt)`
    color: white;
    font-size: 2rem;
`
const UserIcon = styled(FiUser)`
    color: white;
    font-size: 2rem;
`
const StatisticsIcon = styled(BsBarChart)`
    color: white;
    font-size: 2rem;
`

const NavigationOptions = styled.div`
    display: flex;
    flex-direction: column;
`

const LogoutPlatform = styled.div`
    font-size: 1.8rem;
    color: white;
`

const MobileHeader: React.FC<MobileHeaderInterface> = ({
    isOpen,
    close,
    isPartnerOrCaseworker,
}) => {
    const { logout } = useActions()
    const {
        translation: { translation },
        auth: { isLoggedIn, user },
    } = useTypedSelector((state) => state)

    const [isClosing, setIsClosing] = useState(false)
    useEffect(() => {
        if (!isOpen && isClosing) {
            const timeoutId = setTimeout(() => {
                setIsClosing(false)
            }, 500)

            return () => clearTimeout(timeoutId)
        }
    }, [isOpen, isClosing])

    const handleClose = () => {
        setIsClosing(true)
        setTimeout(() => {
            close()
        }, 500)
    }
    return (
        <ModalContainer>
            <Drawer
                isOpen={isOpen}
                isClosing={isClosing}
                width="100vw"
                height="100vh"
            >
                <DrawerContainer>
                    <DrawerHeader>
                        <Logo src={logo} />
                        <CloseDrawer onClick={handleClose}>
                            <CloseIcon />
                        </CloseDrawer>
                    </DrawerHeader>
                    <DrawerNavigation>
                        <NavigationOptions>
                            {isLoggedIn &&
                                user?.roles.includes('contact') &&
                                user &&
                                !user.temp && (
                                    <>
                                        <DrawerNavigationLink
                                            onClick={handleClose}
                                            to="/"
                                        >
                                            <DrawerNavigationIcon>
                                                <DashboardIcon />
                                            </DrawerNavigationIcon>
                                            <DrawerNavigationOption>
                                                {translation.header.dashboard}
                                            </DrawerNavigationOption>
                                        </DrawerNavigationLink>
                                        <DrawerNavigationLink
                                            onClick={handleClose}
                                            to="/cases"
                                        >
                                            <DrawerNavigationIcon>
                                                <CasesIcon />
                                            </DrawerNavigationIcon>
                                            <DrawerNavigationOption>
                                                {translation.header.cases}
                                            </DrawerNavigationOption>
                                        </DrawerNavigationLink>
                                        {user?.roles.includes('client') && (
                                            <DrawerNavigationLink
                                                onClick={handleClose}
                                                to="/organization"
                                            >
                                                <DrawerNavigationIcon>
                                                    <OrganizationIcon />
                                                </DrawerNavigationIcon>
                                                <DrawerNavigationOption>
                                                    {
                                                        translation.reusable
                                                            .organization
                                                    }
                                                </DrawerNavigationOption>
                                            </DrawerNavigationLink>
                                        )}
                                        <DrawerNavigationLink
                                            onClick={handleClose}
                                            to="/statistics"
                                        >
                                            <DrawerNavigationIcon>
                                                <StatisticsIcon />
                                            </DrawerNavigationIcon>
                                            <DrawerNavigationOption>
                                                {
                                                    translation.statisticsPage
                                                        .statistics
                                                }
                                            </DrawerNavigationOption>
                                        </DrawerNavigationLink>
                                    </>
                                )}
                            {isLoggedIn &&
                                isPartnerOrCaseworker &&
                                user &&
                                !user.temp && (
                                    <>
                                        <DrawerNavigationLink
                                            onClick={handleClose}
                                            to="/"
                                        >
                                            <DrawerNavigationIcon>
                                                <DashboardIcon />
                                            </DrawerNavigationIcon>
                                            <DrawerNavigationOption>
                                                {translation.header.dashboard}
                                            </DrawerNavigationOption>
                                        </DrawerNavigationLink>
                                        <DrawerNavigationLink
                                            onClick={handleClose}
                                            to="/cases"
                                        >
                                            <DrawerNavigationIcon>
                                                <CasesIcon />
                                            </DrawerNavigationIcon>
                                            <DrawerNavigationOption>
                                                {translation.header.cases}
                                            </DrawerNavigationOption>
                                        </DrawerNavigationLink>
                                        {user?.roles.includes('partner') && (
                                            <DrawerNavigationLink
                                                onClick={handleClose}
                                                to="/clients"
                                            >
                                                <DrawerNavigationIcon>
                                                    <UserIcon />
                                                </DrawerNavigationIcon>
                                                <DrawerNavigationOption>
                                                    {translation.header.clients}
                                                </DrawerNavigationOption>
                                            </DrawerNavigationLink>
                                        )}
                                    </>
                                )}
                        </NavigationOptions>
                        <LogoutPlatform onClick={logout}>
                            {translation.settingsPartner.logOut}
                        </LogoutPlatform>
                    </DrawerNavigation>
                </DrawerContainer>
            </Drawer>
        </ModalContainer>
    )
}

export default MobileHeader

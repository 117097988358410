import { useTypedSelector } from '../../../../hooks'
import WhistleblowerMagicLogin from '../../redirects/WhistleblowerMagicLogin'
import HowItWorksPage from './HowItWorksPage'

interface ReportingChannelProps {}

const HowItWorks: React.FC<ReportingChannelProps> = () => {
    const { whistleblowerLoading } = useTypedSelector(
        (state) => state.whistleblower
    )

    return (
        <>
            {whistleblowerLoading ? (
                <WhistleblowerMagicLogin />
            ) : (
                <HowItWorksPage />
            )}
        </>
    )
}

export default HowItWorks

import axios from 'axios'
import config from '../../config'
import { getUrlAfterAppSubdomain } from '../../helpers/routing'
import {
    OrganizationAndPersonalInformationInterface,
    OrganizationInterface,
} from '../../ts/interfaces'
import { AuthActionType } from '../action-types'
import User from '../state-types/UserStateType'
import { Dispatch } from 'redux'
import {
    AccountTypeType,
    PersonalInformation,
} from '../../components/pages/auth/newest-register/NewestRegisterPage'
import { ContactInterface } from '../../components/reusable/onboarding/frames/OnboardingCreateYourLineOfContactsFrame'

const { apiUrl, appUrlExtension } = config

interface RegisterUserInterface extends PersonalInformation {
    accountType: AccountTypeType
    integrationId: null | string
}

export const getIntegrationPartner =
    (partnerIdentiferName: string) => async (dispatch: Dispatch) => {
        // TODO set loading

        try {
            const res = await axios.get(
                `${apiUrl}/v1/integration-partners/${partnerIdentiferName}`
            )

            if (res.status === 404) {
                alert(
                    'Integration partner not found. You will be redirected to regular register page.'
                )

                window.location.href = `${appUrlExtension}/register`

                return false
            }

            return res.data.data
        } catch (error: any) {
            if (error.response.status === 404) {
                alert(
                    'Integration partner not found. You will be redirected to regular register page.'
                )

                window.location.replace(
                    `http://app.${appUrlExtension}/register`
                )

                return false
            }
        }
    }

export const registerUser =
    (data: RegisterUserInterface) => async (dispatch: Dispatch) => {
        dispatch(setAuthLoading())

        const payload = {
            integration_partner_id: data.partnerId,
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            country_id: data.country.id,
            phone: data.phoneNumber,
            account_type: data.accountType,
            integration_id: data.integrationId,
        }

        const res = await axios.post(`${apiUrl}/v1/auth/register-user`, payload)

        if (res.status !== 202) {
            return false
        }

        dispatch({
            type: AuthActionType.REGISTER_USER,
        })

        return true
    }

export const selfRegisterOrganization =
    (organization: any, clientId: number, accountType: string | null) =>
    async (dispatch: Dispatch, getState: any) => {
        dispatch(setAuthLoading())

        const state = getState()

        const { activeIntegration } = state.hrOnIntegration

        interface PayloadInterface {
            vat: string
            name: string
            employee_count: string
            postal_code: string
            country_id: number
            address: string
            city: string
            integration_id?: number
        }

        const payload: PayloadInterface = {
            vat: organization.companyNumber,
            name: organization.companyName,
            employee_count: organization.employees,
            postal_code: organization.zipCode,
            country_id: organization.country.id,
            address: organization.address,
            city: organization.city,
        }

        if (activeIntegration) {
            payload['integration_id'] = activeIntegration.id
        }

        try {
            const res = await axios.post(
                `${apiUrl}/v1/clients/${clientId}/register-organization`,
                payload
            )

            if (res.status !== 200) {
                if (res.status === 422) {
                    dispatch({
                        type: AuthActionType.SET_VALIDATION_ERRORS,
                        payload: res.data.errors,
                    })
                }

                return false
            }

            dispatch({
                type: AuthActionType.SELF_REGISTER_ORGANIZATION,
            })

            return true
        } catch (e: any) {
            if (e.response.status === 422) {
                dispatch({
                    type: AuthActionType.SET_VALIDATION_ERRORS,
                    payload: e.response.data.errors,
                })
            }

            return false
        }
    }

export const onboardingCreateLineOfContact =
    (clientId: number, organizationId: number, payload: ContactInterface[]) =>
    async (dispatch: Dispatch) => {
        dispatch(setAuthLoading())

        // Filter out contacts that have not been filled
        payload = payload.filter((contact) => {
            return (
                contact.first_name &&
                contact.last_name &&
                contact.position &&
                contact.email &&
                contact.country &&
                contact.phone
            )
        })

        const payloadWithCountryId = payload.map((contact) => ({
            first_name: contact.first_name,
            last_name: contact.last_name,
            position: contact.position,
            email: contact.email,
            phone: contact.phone,
            country_id: contact.country.id,
        }))

        try {
            const res = await axios.post(
                `${apiUrl}/v1/clients/${clientId}/organizations/${organizationId}/register-contact-lines`,
                { contacts: payloadWithCountryId }
            )
            if (res.status !== 200) {
                if (res.status === 422) {
                    dispatch({
                        type: AuthActionType.SET_VALIDATION_ERRORS,
                        payload: res.data.errors,
                    })
                }

                return false
            }

            const roles = res.data.user.data.groups.map((role: any) => {
                if (role.slug === 'contact-line') {
                    role.slug = 'contact'
                }

                return role.slug
            })

            // Dispatch with user roles/groups
            dispatch({
                type: AuthActionType.ONBOARDING_CREATE_LINE_OF_CONTACT,
                payload: roles,
            })

            // Update roles/groups in localStorage
            const userFromLocal = localStorage.getItem('user')
            if (userFromLocal) {
                const userJson = JSON.parse(userFromLocal)
                userJson.roles = roles

                localStorage.setItem('user', JSON.stringify(userJson))
            }

            dispatch({
                type: AuthActionType.CLEAR_VALIDATION_ERRORS,
            })

            return true
        } catch (e: any) {
            if (e.response.status === 422) {
                dispatch({
                    type: AuthActionType.SET_VALIDATION_ERRORS,
                    payload: e.response.data.errors,
                })
            }
        }
    }

export const onboardingGenerateWhistleblowerPolicy =
    (clientId: number, organizationId: number) =>
    async (dispatch: Dispatch) => {
        dispatch(setAuthLoading())

        const res = await axios.post(
            `${apiUrl}/v1/clients/${clientId}/organizations/${organizationId}/generate-wb-policy`
        )

        if (res.status !== 200) {
            dispatch({
                type: AuthActionType.SET_ERROR,
                payload: {
                    message: 'Something went wrong, please try again later.',
                },
            })

            return false
        }

        dispatch({
            type: AuthActionType.ONBOARDING_GENERATE_WHISTLEBLOWER_POLICY,
        })
        return true
    }

export const organizationFinishOnboarding =
    (clientId: number, organizationId: number) =>
    async (dispatch: Dispatch) => {
        dispatch(setAuthLoading())

        const res = await axios.post(
            `${apiUrl}/v1/clients/${clientId}/organizations/${organizationId}/finish-onboarding`
        )

        if (res.status !== 200) {
            dispatch({
                type: AuthActionType.SET_ERROR,
                payload: {
                    message: 'Something went wrong, please try again later.',
                },
            })

            return false
        }

        const userResponse = res.data.data.user

        const roles = userResponse.groups.map((currentGroup: any) => {
            let group = currentGroup.name
            if (currentGroup.name === 'Contact line') {
                group = 'contact'
            }

            return group.toLowerCase()
        })

        const user = {
            magicLoginCode: userResponse.magic_login_code,
            roles,
        }

        return user
    }

export const registerOrganization =
    (
        userId: number,
        organizationAndPersonalInfo: OrganizationAndPersonalInformationInterface
    ) =>
    async (dispatch: Dispatch) => {
        dispatch(setAuthLoading())

        const organization = {
            country_id: organizationAndPersonalInfo.countryId,
            has_external_caseworker: 0,
            vat: organizationAndPersonalInfo.cprNumber,
            name: organizationAndPersonalInfo.organizationName,
            email: organizationAndPersonalInfo.email,
            address: organizationAndPersonalInfo.address,
            postal_code: organizationAndPersonalInfo.zipCode,
            phone_number: organizationAndPersonalInfo.phone,
            employee_count: organizationAndPersonalInfo.employeeCount,
            city: organizationAndPersonalInfo.city,
        }

        const registerer = {
            first_name: organizationAndPersonalInfo.registererFirstName,
            last_name: organizationAndPersonalInfo.registererLastName,
            email: organizationAndPersonalInfo.registererEmail,
            phone: organizationAndPersonalInfo.registererPhone,
            country_id: organizationAndPersonalInfo.registererCountryId,
        }

        const payload = {
            organization,
            registerer,
        }

        try {
            const res = await axios.post(
                `${apiUrl}/v1/partners/${userId}/clients`,
                payload
            )

            if (res.status !== 201) {
                if (typeof res.data.errors !== 'undefined') {
                    dispatch({
                        type: AuthActionType.SET_VALIDATION_ERRORS,
                        payload: res.data.errors,
                    })

                    return false
                } else {
                    dispatch(
                        setAuthError(
                            'Something went wrong please try again later'
                        )
                    )

                    return false
                }
            }
            dispatch({
                type: AuthActionType.REGISTER_ORGANIZATION,
                payload: {
                    code: res.data.data.organizational_code,
                },
            })

            return res.data.data
        } catch (error: any) {
            if (error?.response?.status === 422) {
                dispatch({
                    type: AuthActionType.SET_VALIDATION_ERRORS,
                    payload: error.response.data.errors,
                })
            } else {
                dispatch(
                    setAuthError('Something went wrong please try again later')
                )
            }

            return false
        }
    }

export const verifyLoginCredentials =
    (email: string, password: string, integrationId: null | string = null) =>
    async (dispatch: Dispatch) => {
        dispatch(setAuthLoading())

        interface Payload {
            email: string
            password: string
            integration_id?: number
        }

        const payload: Payload = { email, password }

        if (integrationId) {
            payload['integration_id'] = parseInt(integrationId)
        }

        try {
            const res = await axios.post(
                `${apiUrl}/v1/auth/verify-login`,
                payload
            )

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                dispatch(setAuthError('1. Login failed please try again'))

                return false
            }

            const user = res.data.payload

            const roles = user.groups.map((currentGroup: any) => {
                let group = currentGroup.name

                if (currentGroup.name === 'Super Admin') {
                    group = 'admin'
                } else if (currentGroup.name === 'Contact line') {
                    group = 'contact'
                }

                return group.toLowerCase()
            })

            res.data.payload = {
                email: res.data.payload.email,
                phoneNumber: res.data.payload.phone.phone,
                countryCode: res.data.payload.phone.country_code,
                magicLoginCode: res.data.payload.magic_login_code,
                temp: res.data.payload.temp,
                roles,
            }

            dispatch({
                type: AuthActionType.VERIFY_LOGIN_CREDENTIALS,
            })

            return res
        } catch (error: any) {
            //return dispatch(setAuthError('2. Login failed please try again'))

            dispatch({
                type: AuthActionType.SET_DYNAMIC_LOGIN_ERROR,
                payload: error.response.data,
            })

            return false
        }
    }

export const send2FA =
    (method: string, value: string) => async (dispatch: Dispatch) => {
        dispatch({ type: AuthActionType.SET_LOADING })

        const payload = {
            verification_method: method,
            [method === 'sms' ? 'phone_number' : 'email']: value,
        }

        try {
            const res = await axios.post(`${apiUrl}/v1/auth/send-otp`, payload)

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                return dispatch(
                    setAuthError('Sending the code failed please try again')
                )
            }

            dispatch({
                type: AuthActionType.SEND_2FA_TOKEN,
            })

            return res
        } catch (error) {
            return dispatch(
                setAuthError('Sending the code failed please try again')
            )
        }
    }

export const verify2FA =
    (method: string, email: string, password: string, otp: string) =>
    async (dispatch: Dispatch) => {
        dispatch(setAuthLoading())

        const payload = {
            email,
            verified_by: method,
            password,
            otp,
        }

        try {
            const res = await axios.post(`${apiUrl}/v1/auth/login`, payload)

            if (res.status !== 200) {
                dispatch(setAuthError('Something went wrong please try again'))

                return null
            }

            const userResponse = res.data.payload.user

            const roles = userResponse.groups.map((currentGroup: any) => {
                let group = currentGroup.name

                if (currentGroup.name === 'Super Admin') {
                    group = 'admin'
                } else if (currentGroup.name === 'Contact line') {
                    group = 'contact'
                }

                return group.toLowerCase()
            })

            const user = {
                email: userResponse.email,
                phoneNumber: userResponse.phone,
                countryCode: userResponse.country_code,
                magicLoginCode: userResponse.magic_login_code,
                temp: userResponse.temp,
                roles,
            }

            return user
        } catch (error) {
            dispatch(setAuthError('Something went wrong please try again'))

            return null
        }
    }

export const login = (user: User) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.apiToken}`
    return {
        type: AuthActionType.LOGIN,
        payload: {
            user,
        },
    }
}

export const logout = () => async () => {
    localStorage.clear()
    axios.post(`${apiUrl}/v1/logout`)
    window.location.replace(`http://app.${getUrlAfterAppSubdomain()}/`)
}

export const setAuthLoading = () => ({ type: AuthActionType.SET_LOADING })

export const setAuthError = (errorMessage: string) => ({
    type: AuthActionType.SET_ERROR,
    payload: {
        message: errorMessage,
    },
})

export const verifyCompanyInformation =
    (organization: OrganizationInterface) => async (dispatch: Dispatch) => {
        dispatch(setAuthLoading())

        const payload = {
            email: organization.email,
            name: organization.organizationName,
            vat: organization.cprNumber,
            phone_number: organization.phone,
        }

        try {
            const res = await axios.post(
                `${apiUrl}/v1/auth/register-step-1`,
                payload
            )

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                dispatch({
                    type: AuthActionType.SET_VALIDATION_ERRORS,
                    payload: res.data.errors,
                })

                return false
            }

            dispatch({
                type: AuthActionType.VERIFY_COMPANY_INFORMATION,
            })

            return true
        } catch (error: any) {
            dispatch({
                type: AuthActionType.SET_VALIDATION_ERRORS,
                payload: error.response.data.errors,
            })
            return false
        }
    }

export const newVerifyOrganizationInformation =
    (organization: any) => async (dispatch: Dispatch) => {
        const payload = {
            name: organization.companyName,
            vat: organization.companyNumber,
            phone_number: 99999999, // todo remove
            email: 'nikulasoskarsson@gmail.com',
        }
        try {
            const res = await axios.post(
                `${apiUrl}/v1/auth/register-step-1`,
                payload
            )

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                dispatch({
                    type: AuthActionType.SET_VALIDATION_ERRORS,
                    payload: res.data.errors,
                })

                return false
            }

            dispatch({
                type: AuthActionType.VERIFY_COMPANY_INFORMATION,
            })

            return true
        } catch (error: any) {
            dispatch({
                type: AuthActionType.SET_VALIDATION_ERRORS,
                payload: error.response.data.errors,
            })
            return false
        }
    }

export const clearValidationErrors = () => ({
    type: AuthActionType.CLEAR_VALIDATION_ERRORS,
})

export const verifyCompanyUserInformation =
    (user: any) => async (dispatch: Dispatch) => {
        dispatch({ type: AuthActionType.SET_LOADING })

        const payload = {
            first_name: user.firstName,
            last_name: user.lastName,
            phone: user.phoneNumber,
            email: user.email,
            country_id: user.country.id,
        }

        try {
            const res = await axios.post(
                `${apiUrl}/v1/auth/validate-personal-information`,
                payload
            )
            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                dispatch({
                    type: AuthActionType.SET_VALIDATION_ERRORS,
                    payload: res.data.errors,
                })

                return false
            }

            dispatch({
                type: AuthActionType.VERIFY_COMPANY_INFORMATION,
            })

            return true
        } catch (error: any) {
            dispatch({
                type: AuthActionType.SET_VALIDATION_ERRORS,
                payload: error.response.data.errors,
            })
            return false
        }
    }

export const verifyEmail =
    (
        requestNumber: string,
        expires: string,
        hash: string,
        signature: string,
        password: string,
        passwordConfirm: string
    ) =>
    async (dispatch: Dispatch) => {
        dispatch(setAuthLoading())

        const payload = {
            password,
            password_confirmation: passwordConfirm,
        }

        try {
            await axios.post(
                `${apiUrl}/v1/email/verify/${requestNumber}?expires=${expires}&hash=${hash}&signature=${signature}`,
                payload
            )

            return dispatch({
                type: AuthActionType.VERIFY_EMAIL,
                payload: {
                    message: 'Email has been verified, click here to login',
                },
            })
        } catch (error) {
            return dispatch(
                setAuthError('Something went wrong, please try again latery')
            )
        }
    }

export const sendClientInformationEmail =
    (partnerId: number, organizationId: number) =>
    async (dispatch: Dispatch) => {
        dispatch(setAuthLoading())

        try {
            const res: any = await axios.post(
                `${apiUrl}/v1/partners/${partnerId}/organizations/${organizationId}/send-information-email`
            )

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                dispatch(setAuthError('Something went wrong please try again'))

                return false
            }
            dispatch({
                type: AuthActionType.SEND_CLIENT_INFORMATION_EMAIL,
                payload: {
                    message: res.message,
                },
            })

            return true
        } catch (error) {
            dispatch(setAuthError('Something went wrong please try again'))

            return false
        }
    }

export const loginWithMagicLoginToken =
    (token: string) => async (dispatch: Dispatch) => {
        dispatch(setAuthLoading())

        try {
            const res: any = await axios.post(
                `${apiUrl}/v1/magic-login/${token}`
            )

            if (res.status !== 200) {
                dispatch(setAuthError('Something went wrong please try again'))

                alert(
                    'Code is invalid or has expired. You will be redirected to login page'
                )

                window.location.replace(`http://app.${appUrlExtension}`)

                return false
            }

            return res.data.payload
        } catch (error) {
            dispatch(setAuthError('Something went wrong please try again'))

            alert(
                'Code is invalid or has expired. You will be redirected to login page'
            )

            window.location.replace(`http://app.${appUrlExtension}`)

            return false
        }
    }

export const pingAPI = () => async () => {
    await axios.post(`${apiUrl}/v1/ping`)
}

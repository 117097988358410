import React from 'react'
import styled from 'styled-components'
import { Modal, ModalContainer } from '../../styled/modals'
import { Card, CardBody } from '../../styled/cards'
import { useActions, useTypedSelector, useTranslation } from '../../../hooks'
import ButtonSpinnerWhite from '../../layout/spinner/ButtonSpinnerWhite'
import { AiFillWarning } from 'react-icons/ai'

export const CardHeader = styled.div`
    height: 5rem;
    padding: 0 1.4rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.grey};
`

const CloseButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}90`};
    padding: 1rem;
    cursor: pointer;
`

const ModalHeading = styled.h2`
    font-size: 1.8rem;
    text-align: center;
    margin-top: 2.3rem;
`

const CaseworkerName = styled.h2`
    font-weight: 700;
    text-align: center;
    font-size: 2rem;
    margin: 2.3rem 0;
`

const WarningText = styled.h2`
    color: ${({ theme }) => theme.red};
    text-align: center;
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 1.6rem;
    margin-bottom: 0.6rem;
`

const ModalListContainer = styled.div`
    line-height: 1.8rem;
`

const ModalListContent = styled.li`
    color: ${({ theme }) => `${theme.primary}50`};
    font-size: 1.4rem;
    font-weight: 400;
`

const ButtonContainer = styled.div`
    display: flex;
    gap: 2.6rem;
    justify-content: center;
    margin-top: 2rem;
`

const DeleteButton = styled.div`
    height: 4.5rem;
    width: 24.3rem;
    background: ${({ theme }) => theme.red};
    color: ${({ theme }) => theme.white};
    font-size: 1.6rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 10rem;

    :hover {
        opacity: 0.8;
    }
`

const CancelButton = styled.div`
    height: 4.5rem;
    width: 24.3rem;
    background: transparent;
    color: ${({ theme }) => theme.primary}60;
    font-size: 1.6rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 5rem;
    border: solid 0.1rem ${({ theme }) => theme.primary}60;

    :hover {
        opacity: 0.8;
    }
`

const WarningIcon = styled(AiFillWarning)`
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.5rem;
`

const OrderListModal = styled.ul`
    text-align: center;
    list-style-position: inside;
`

interface DeleteContactModalProps {
    contact: any

    closeModal: () => void
    handleDelete: () => void
}

const DeleteContactModal: React.FC<DeleteContactModalProps> = ({
    contact,
    closeModal,
}) => {
    const translation = useTranslation()

    const { first_name, last_name } = contact

    const { deleteContact } = useActions()

    const { client } = useTypedSelector((state) => state.client)

    const { lineOfContactDeleteLoading } = useTypedSelector(
        (state) => state.lineOfContact
    )

    const handleDeleteContact = async () => {
        await deleteContact(client?.id, contact?.id, translation)

        closeModal()
    }

    return (
        <ModalContainer>
            <Modal height="35rem" width="64.3rem">
                <Card>
                    <CardHeader>
                        {translation.singleClientViewPartner.deleteCaseworker}
                        <CloseButton onClick={closeModal}>
                            {translation.closeButton.close}
                        </CloseButton>
                    </CardHeader>
                    <CardBody
                        style={{
                            padding: '1.4rem',
                        }}
                    >
                        <ModalHeading>
                            {
                                translation.singleClientViewPartner
                                    .youAreAboutToDeleteTheCaseworker
                            }
                            <CaseworkerName>{`${first_name} ${last_name}`}</CaseworkerName>
                        </ModalHeading>

                        <WarningText>
                            <WarningIcon />
                            {translation.warningText.warning}
                        </WarningText>
                        <ModalListContainer>
                            <OrderListModal>
                                <ModalListContent>
                                    {
                                        translation.singleClientViewPartner
                                            .theCaseworkerWillNotAbleToReceive
                                    }
                                </ModalListContent>
                                <ModalListContent>
                                    {
                                        translation.singleClientViewPartner
                                            .theCaseworkerCannotBeDeletedIf
                                    }
                                </ModalListContent>
                                <ModalListContent>
                                    {
                                        translation.singleClientViewPartner
                                            .thisActionCannotBeUndone
                                    }
                                </ModalListContent>
                            </OrderListModal>
                        </ModalListContainer>
                        <ButtonContainer>
                            <CancelButton onClick={closeModal}>
                                {translation.reusable.cancel}
                            </CancelButton>
                            <DeleteButton onClick={handleDeleteContact}>
                                {lineOfContactDeleteLoading ? (
                                    <ButtonSpinnerWhite />
                                ) : (
                                    `${translation.deleteText.delete}`
                                )}
                            </DeleteButton>
                        </ButtonContainer>
                    </CardBody>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default DeleteContactModal

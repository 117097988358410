const no = {
    registerPage: {
        accountType: 'Kontotype',
        noCreditCardRequired: 'Ingen kredittkort kreves, start din',
        freeTrial: 'gratis prøveperiode',
        today: 'i dag',
        firstname: 'Fornavn',
        phoneNumber: 'Telefonnummer',
        software: 'Programvare',
        softwarePlusScreening: 'Programvare + screening',
        easyAndSecure: 'Enkelt og sikkert',
        chargedYearly: 'Belastes årlig',
        secureWhistleblowerPlatform: 'Sikker plattform for varslere',
        tailoredWhistleblowerPolicy:
            'Skreddersydde retningslinjer for varsling',
        reportManagement: 'Håndtering av rapporter',
        followUpCommunication: 'Oppfølging av kommunikasjon',
        emailNotifications: 'E-postvarsler',
        employeeGuides: 'Veiledninger for ansatte',
        areYouLookingFo: 'Er du på utkikk etter partnerprogrammet vårt?',
        partnerprogrammodal: 'Partnerprogram',
        iHaveAgreedToWalor: 'Jeg har lest og samtykker til Walors',
        termsAndConditions: 'vilkår og betingelser',
        and: 'og',
        privacyAndCookiePolicy:
            'retningslinjer for personvern og informasjonskapsler',
        dataProcessingAgreement: 'Avtale om databehandling',
        ifYouAreLookingFor:
            'Hvis du er på utkikk etter et partnerprogram, kontakt oss på',
        thanksCheckYour: 'Fullført! Sjekk e-posten din',
        rememberToCheckYourSpam:
            'Har du ikke mottatt e-posten? Sjekk søppelpostmappen din eller kontakt oss på',
        areYouAMemberOf: 'Er du medlem av',
    },
    onboardingPage: {
        hi: 'Hei',
        welcomeToWalor: 'Velkommen til Walor!',
        youAreAlmostDone:
            'Du er nesten ferdig med å konfigurere den nye løsningen',
        insertCompanyInformation: 'Informasjon om selskapet',
        subcreateYourCompanyInformation:
            'Legg til selskapets adresse og antall ansatte.',
        generateWhistleblowerPolicy: 'Retningslinjer for varsling',
        readyToGo: 'La oss begynne',
        pleaseFillinThisInformation: 'Skriv inn selskapsinformasjonen din',
        nextStep: 'Neste trinn',
        name: 'Selskapets navn',
        createYourLineOfContact: 'Opprett en kontaktlinje',
        skipThisStep: 'Hopp over dette trinnet',
        subcreateYourLineOfContact:
            'Disse brukerne vil være ansvarlige for å håndtere varslingsrapporter for organisasjonen din.',
        subgenerateyourwbpolicy:
            'Her beskrives det juridiske rammeverket for varslingsløsningen.',
        clickHere: 'Klikk her',
        complete: 'Fullstendig',
        yourWhistleblowerpolicyIsBeingGenerated:
            'Retningslinjene dine for varsling er under utarbeidelse i dette øyeblikk.',
        goodWork: 'Gratulerer',
        yourPolicyHasBeen: 'Retningslinjene dine for varsling er opprettet!',
        allSetEasy: 'Alt er klart! Enkelt, ikke sant?',
        letsGetYouStarted: 'La oss sette i gang umiddelbart',
        takeMeToTheDashboard: 'Ta meg til dashbordet',
    },
    onboardingFlow: {
        enterCompanyNumber: 'Organisasjonsnummer',
        enterCompanyName: 'Selskapets navn',
        enterPhone: '12345678',
        enterAddress: 'Adresse',
        enterCity: 'Sted',
        enterZipCode: '1234',
    },
    loginFlow: {
        login: 'Logg inn',
        forgotPassword: 'Har du glemt passordet?',
        underPageForgotPassword: 'Har du glemt passordet?',
        underPageWriteYourEmail:
            'Skriv inn e-postadressen din nedenfor for å opprette et nytt passord',
        howDoYouWant: 'Hvordan vil du motta sikkerhetskoden din?',
        buttonEmail: 'E-post',
        buttonSMS: 'SMS',
        or: 'eller',
        backToLogin: 'Tilbake til innlogging',
        enterThe6digit: 'Skriv inn den sekssifrede koden',
        yourCodeWillArrive: 'Koden din kommer om noen få sekunder til',
        didYouNotReceive:
            'Har du ikke mottatt koden? Klikk her for å sende den på nytt',
    },
    whistleblowerCreateCase: {
        whistleblowerScheme: 'Kanal for varsling av kritikkverdige forhold',
        createReport: 'Opprett rapport',
        startReport: 'Starte en anonym og sikker rapportering',
        accessExistingReport: 'Få tilgang til eksisterende rapport',
        understandWhistleblowing: 'Forstå varsling',
        readMore: 'Veiledninger for ansatte',
        playvideo: 'Spill',
        howThisWorks: 'Slik fungerer dette',
        createAReport: 'Opprette en rapport',
        createAReportSimply:
            'Opprett en rapport anonymt eller konfidensielt. Du kan oppgi all informasjon og legge ved alle filer du har samlet inn. Når du har sendt rapporten, får du en unik varslingskode som gir deg tilgang til rapporten igjen.',
        investigatingthereport: 'Intern gransking',
        caseworkerinthecompany:
            'Saksbehandlerne du velger for å håndtere rapporten, vil motta et varsel på e-post om at de skal starte den interne gransking.',
        answeradditionalquestions: 'Svar på ytterligere spørsmål',
        additionalQuestions:
            'Saksbehandlerne du velger, kan ha tilleggsspørsmål til rapporten din. Sørg for å legge inn rapporten på nytt med jevne mellomrom for å lette den interne granskningen.',
        companiesWhistleblowerPolicy: 'Retningslinjer for varsling',
    },
    whistleblowerHowItWorksPage: {
        whistleblowing: 'Varsling generelt',
        whatIsWhistleblowing: 'Hva er varsling?',
        whistleblowingDefinition:
            'Varsling er en persons rapportering av ulovlige eller umoralske handlinger i en organisasjon. Personen som varsler, en såkalt varsler, kan enten være en del av organisasjonen eller en tredjepart med tilknytning til organisasjonen.',
        whereItsUseful:
            'I de aller fleste tilfeller skjer varsling i jobbsammenheng. En arbeidstaker kan for eksempel få informasjon om ulovlige aktiviteter hos arbeidsgiveren i forbindelse med utførelsen av arbeidet, og bestemmer seg for å varsle om dette.',
        whatAreTheTypes: 'Hvilke typer varsling finnes?',
        typesOfWhistleblowing:
            'Det kan skilles mellom intern og ekstern varsling. Intern varsling innebærer at varsleren varsler innenfor den organisasjonen man er en del av eller tilknyttet. Det betyr at varsleren kun kommuniserer om kritikkverdige forhold innenfor denne organisasjonen. Ekstern varsling innebærer derimot at varsleren varsler andre enn organisasjonen selv om aktiviteter i organisasjonen. I så fall formidles informasjonen til for eksempel offentlige myndigheter eller media.',
        whatIsTheImportance: 'Hvilken betydning har varsling?',
        preventionOrReparation:
            'For det første spiller varsling en viktig rolle når det gjelder å forebygge eller avhjelpe skade på allmennhetens interesser, ettersom det ofte er personer som er en del av eller har tilknytning til en organisasjon som er de første eller eneste som har kunnskap om eller tilgang til informasjon om dette.',
        ethicCulture:
            'Fra organisasjonens perspektiv bidrar varsling dessuten til en kultur preget av etikk, lovlighet, åpenhet og deltakelse.',
        whistleblowerSafety:
            'Til slutt gir varsling varsleren mulighet til å overvinne skyldfølelse eller ansvarsfølelse etter å ha fått informasjon om ulovlige eller umoralske handlinger i selskapet varsleren er en del av eller tilknyttet.',
        whistleblowerImportance:
            'På grunn av iktigheten av dette finnes det lovfestet beskyttelse for varslere.',
        whistleblowerScheme: 'Varslerordning',
        whatIsWhistleblowerScheme: 'Hva er en varslingsordning?',
        whistleblowerIsATool:
            'En varslingsordning er et verktøy som gjør det mulig for folk å rapportere om en bestemt organisasjon. Systemet veileder dermed varslere gjennom prosessen med å rapportere om ulovlig eller uetisk aktivitet. Dette gjør det mulig for organisasjoner å få kunnskap om, undersøke og rette opp ulovlig eller uetisk atferd.',
        whyHaveAWhistleBlowerScheme: 'Hvorfor ha en varslingsordning?',
        whistleblowerLaw:
            'Som følge av EUs økte fokus på verdien av varsling, vedtok EU 23. oktober 2019 direktiv 2019/1937 om beskyttelse av personer som varsler om brudd på EU-retten. Dette varslingsdirektivet er implementert i flere EU-land, og vurderes implementert i norsk lov. Direktivet gir et juridisk rammeverk for varslingsordninger og beskytter varslere som rapporterer informasjon gjennom en varslingsordning.',
        whistleblowingSchemeTransparency:
            'Denne varslingsordningen gjør det mulig for alle å komme til orde og utøve sin rett til å bli hørt, noe som er avgjørende for å skape en åpenhetskultur og en følelse av tilhørighet. I tillegg støtter og oppmuntrer ordningen til å ta opp bekymringer internt, nær kilden til problemet. Dette gjør det mulig å løse problemene på et tidlig tidspunkt og på en effektiv måte.',
        whoCanUseThisWhistleblowerScheme:
            'Hvem kan bruke denne varslingsordningen?',
        anyoneCanUse:
            'Varslerordningen kan brukes av alle som sitter på informasjon om en offentlig eller privat virksomhet som de har fått kjennskap til i forbindelse med sitt arbeid.',
        categoriesOfPeople:
            'Kategorien av personer som kan benytte seg av denne varslingsordningen er bred. Varsler kan således sendes inn av:',
        people1:
            'Ansatte. Dette omfatter både heltids- og deltidsansatte samt ansatte som arbeider på en tidsubegrenset eller tidsbegrenset kontrakt.',
        people2: 'Jobbsøkere',
        people3: 'Betalte eller ulønnede praktikanter',
        people4: 'Frivillige',
        people5: 'Selvstendig næringsdrivende',
        people6: 'Aksjonærer',
        people7:
            'Medlemmer av ledelsen, representantskapet eller andre styrende organer',
        people8:
            'Personer som arbeider under tilsyn og ledelse av entreprenører, underentreprenører og leverandører.',
        noMatterTheRelationship:
            'Personer som faller inn under disse kategoriene, kan benytte seg av varslingsordningen uavhengig av om deres ansettelsesforhold til organisasjonen har opphørt, fortsatt pågår eller ennå ikke har begynt.',
        whatCanBeReported:
            'Hva kan rapporteres gjennom denne varslingsordningen?',
        seriousViolations:
            'Varslingsordningen kan brukes til å håndtere informasjon om alvorlige lovbrudd, forsøk på å skjule slike brudd eller andre alvorlige forhold som har skjedd eller sannsynligvis vil skje i en organisasjon. Også ved begrunnet mistanke om at slike brudd eller forhold har funnet sted, kan denne informasjonen rapporteres.',
        violationsOfLaw:
            'Overtredelsene som kan rapporteres gjennom varslingsordningen, gjelder konkrete brudd på EU-retten eller andre alvorlige lovbrudd og forhold i henhold til dansk lov. Aktiviteter som kan rapporteres gjennom denne ordningen, gjelder for eksempel korrupsjon, miljøvern, folkehelse, personvern og personopplysninger eller seksuell trakassering og seksuelle overgrep. Dette er bare eksempler. Også andre forhold eller lovbrudd av alvorlig karakter kan rapporteres.',
        whatCantBeReported:
            'Hva kan ikke rapporteres gjennom denne varslingsordningen?',
        lessSeriousMatters:
            'Denne varslingsordningen er ikke ment for varsling av mindre alvorlige forhold eller vanlige ansettelsessaker, for eksempel misnøye med lønn eller mindre kollegiale problemer. I tillegg skal det ikke varsles om forhold som ikke er fremsatt i god tro og som er åpenbart grunnløse.',
        inCaseOfDoubt:
            'Hvis du er i tvil om en observasjon eller mistanke er av alvorlig karakter og bør rapporteres eller ikke, oppfordres du likevel til å gjøre det. Alle henvendelser vil bli besvart og behandlet.',
        howAreYouProtected:
            'Hvordan er du beskyttet når du bruker denne ordningen?',
        noLiability:
            'Varslere som varsler om forhold av alvorlig karakter i god tro gjennom denne ordningen, nyter et bredt vern, uten å pådra seg noe ansvar for brudd på taushetsplikten med hensyn til de innrapporterte opplysningene. Også personer som bistår varsleren, eller personer eller selskaper som er knyttet til varsleren, omfattes av denne beskyttelsen.',
        noRetaliation:
            'Dette vernet innebærer et vern mot enhver form for gjengjeldelse. Gjengjeldelse innebærer enhver handling eller unnlatelse som følge av varslingen, og som påfører eller kan påføre varsleren uberettiget skade. Slike handlinger kan ta mange former, som for eksempel oppsigelse, endring av arbeidssted, lønnsreduksjon eller skade på det personlige eller profesjonelle omdømmet. Hvis en varsler eller en beskyttet person utsettes for gjengjeldelse, vil han eller hun bli kompensert for dette. En organisasjon som utøver gjengjeldelse, kan også bli utsatt for andre sanksjoner.',
        howDoesTheProcessWork: 'Hvordan fungerer rapporteringsprosessen?',
        howDoIReport: 'Hvordan rapporterer jeg?',
        reportingChannel:
            'Du kan opprette en rapport via den digitale rapporteringskanalen til organisasjonen du ønsker å rapportere om.',
        whatIfIncomplete: 'Hva om rapporten min er ufullstendig?',
        accessAndExpand:
            'Det er alltid mulig å få tilgang til rapporten og utvide den med ytterligere opplysninger eller bevis. Saksbehandlerne kan også be om ytterligere spørsmål eller dokumentasjon som kan være relevant for å håndtere og undersøke rapporten. En etterfølgende innsending av svar eller dokumentasjon avhenger imidlertid utelukkende av din vilje til å gjøre det. Du er ikke forpliktet til å gjøre dette.',
        whoIsProccessing: 'Hvem behandler rapporten min?',
        thirdParty:
            'Varselet ditt vil bli behandlet av den eller de utvalgte personene i selskapets varslingsenhet. Det er kun disse personene som er autorisert til å gjennomføre en undersøkelse. Saken din vil ikke under noen omstendigheter bli overlatt til en person som er involvert i de rapporterte aktivitetene, eller som det kan være en interessekonflikt med.',
        howIsProccessed: 'Hvordan behandles rapporten min?',
        investigations:
            'Du vil motta en bekreftelse på at du har mottatt rapporten innen sju dager. Etter dette vil det bli foretatt en første gjennomgang av anmeldelsen. Hvis anmeldelsen viser seg å være åpenbart grunnløs, vil den bli avvist. Du vil få beskjed om dette så snart som mulig. Hvis den innledende undersøkelsen viser at anmeldelsen ikke kan karakteriseres som åpenbart grunnløs, vil den bli undersøkt nærmere. Saken din vil da bli behandlet internt. Denne interne undersøkelsen kan få konsekvenser for den eller de involverte. Hvis saken er av særlig alvorlig karakter, kan den også bli sendt videre til politiet for videre etterforskning. Etter at alle undersøkelser og eventuelle sanksjoner er gjennomført, avsluttes saken. Den vil da bli slettet fra systemet.',
        whatIfLooseCode: 'Hva om jeg mister varslerkoden min?',
        looseAccess:
            'Hvis du mister varslerkoden, vil du dessverre ikke lenger ha tilgang til rapporten din. Dette er den eneste muligheten til å garantere en sikker og anonym varsling. Rapporten din vil likevel bli gjennomgått og undersøkt.',
        unableToUptade:
            'Du vil ikke kunne oppdatere varselet ditt eller svare på eventuelle spørsmål om varselet. For å gjøre dette må du opprette et nytt varsel og sende det inn gjennom denne varslingsordningen.',
        howLongDoesItTake: 'Hvor lang tid tar det å behandle rapporten min?',
        whithin3Months:
            'Rapporten din vil bli behandlet og undersøkt så snart som mulig. I løpet av denne prosessen vil du få tilbakemelding om eventuelle oppdateringer eller resultater. Den totale saksbehandlingstiden vil avhenge av saken din, men du vil senest tre måneder etter at du har rapportert, få informasjon om hvilke tiltak som er planlagt eller iverksatt.',
        willIRemainSecret: 'Vil identiteten min forbli hemmelig?',
        anounOrConfidential:
            'Du har mulighet til å varsle enten anonymt eller konfidensielt gjennom varslingsordningen. Førstnevnte innebærer at ingen personopplysninger deles, mens sistnevnte innebærer at varsleren deler personopplysninger. Hvis personopplysninger deles i rapporten, er det bare de som er autorisert til å undersøke saken, som får tilgang til dem. Konfidensialiteten til den innsendte rapporten vil derfor uansett bli respektert.',
        willPersonsBeNotified:
            'Vil noen av de berørte personene i en rapport bli varslet?',
        concernedsNotified:
            'Vedkommende vil bli informert om at han eller hun er involvert i en anmeldelse. Det vil i hvert enkelt tilfelle bli gjort en vurdering av når dette varselet kan videreformidles, slik at varselet ikke får konsekvenser for bevisinnhentingen.',
        noPersonalInfo:
            'Ingen informasjon, verken direkte eller indirekte, vil bli spesifisert om hvem som har rapportert til den berørte personen, selv om du har valgt å rapportere konfidensielt og derfor deler din identitet.',
        isSchemeSecured: 'Er denne varslingsordningen sikret?',
        independencyGuarantee:
            'Varslingskanalen drives av Walor ApS, som er en uavhengig tredjepart som garanterer for systemets sikkerhet. Systemet logger ikke IP-adresser og maskin-ID-er og krypterer alle data som behandles. Personopplysningsrettighetene til alle involverte parter vil bli respektert. Det er kun den ansvarlige saksbehandleren som har tilgang til rapporten.',
        whatIfImDissatisfied:
            'Hva gjør jeg hvis jeg er misfornøyd med vurderingen eller resultatet av rapporten?',
        informCompetentAuth:
            'Det er alltid mulig å iverksette ytterligere tiltak hvis du mener det er grunnlag for det. Et negativt utfall av en varsling skal ikke i noe tilfelle hindre deg i å gjøre det. Etter at du har rapportert gjennom varslingsordningen, har du mulighet til å rapportere informasjonen til kompetente myndigheter. I tillegg kan du, der det er relevant, også rapportere til institusjoner, organer, kontorer eller byråer i EU.',
    },
    whistleblowerPolicyPage: {
        whistleblowerPolicyHeading: 'Retningslinjer for varsling',
        backToHomescreen: 'Tilbake til startskjermen',
        whistleblowerPolicy:
            's retningslinjer for varsling av kritikkverdige forhold',
        thePurposeOfThisWhistleblower:
            'Formålet med disse retningslinjene for varslere er å forklare hvordan',
        whistleblowerSchemeWorks:
            'varslingsordning fungerer. Denne ordningen gir deg mulighet til å rapportere om',
        aGoodUnderstanding:
            'En god forståelse av denne ordningen kan derfor forhindre at potensielt viktige saker ikke blir rapportert.',
        introductionTo: 'Introduksjon til',
        whistleblowerScheme: 's varslingsordning',
        isAResponsibleCompanyWith:
            'er et ansvarlig selskap med en åpen kultur, der alle kan føle seg trygge og ytre seg hvis de opplever kritikkverdige forhold eller potensielle lovbrudd. Som utgangspunkt er det alltid mulig og sterkt anbefalt å snakke med nærmeste leder eller et medlem av ledelsen i slike situasjoner, men det er også mulig å bruke varslingskanalen.',
        whistleblowerSchemeEnsuresProtection:
            'Varslingsordningen sikrer beskyttelse for personer som varsler om alvorlige lovbrudd og forhold. Ordningen kan brukes hvis du opplever, er kjent med eller har grunn til å mistenke at det har forekommet slike brudd i',
        bothInThePast:
            'både i fortid, nåtid og nær fremtid. Rapporter kan sendes inn anonymt eller konfidensielt.',
        whoCanUseThisWhistleblowingScheme:
            'Hvem kan bruke denne varslingsordningen?',
        whistleblowerSchemeCanBeUsedByAnyone:
            's varslingsordning kan brukes av alle som har informasjon om',
        whichTheyHaveAcquired:
            'som man har tilegnet seg i løpet av sin arbeidsrelaterte virksomhet med',
        theCategoriesOfPeopleWho:
            'Kategorien av personer som kan benytte seg av denne varslingsordningen er bred. Varsler kan således sendes inn av:',
        employees:
            'Ansatte. Dette omfatter både heltids- og deltidsansatte samt ansatte som arbeider på en tidsubegrenset eller tidsbegrenset kontrakt.',
        jobApplicants: 'Jobbsøkere',
        paidOrUnpaidTrainees: 'Betalte eller ulønnede praktikanter',
        volunteers: 'Frivillige',
        selfEmployedPersons: 'Selvstendig næringsdrivende',
        shareholders: 'Aksjonærer',
        membersOfTheManagement:
            'Medlemmer av ledelsen, representantskapet eller andre styrende organer i',
        personsWorkingUnder:
            'Personer som arbeider under tilsyn og ledelse av entreprenører, underentreprenører og leverandører til',
        personsFallingUnderTheseCategories:
            'Personer som faller inn under disse kategoriene, kan benytte seg av varslingsordningen uavhengig av om de har et ansettelsesforhold med',
        hasCeased: 'har opphørt, fortsatt pågår eller ennå ikke er påbegynt.',
        whatCanYouReport: 'Hva kan du rapportere?',
        theWhistleblowerSchemeCanBeUsed:
            'Varslingsordningen kan brukes til å håndtere informasjon om alvorlige lovbrudd, forsøk på å skjule slike lovbrudd eller andre alvorlige forhold som har skjedd eller sannsynligvis vil skje i',
        alsoInTheCaseOfRasonable:
            'Også ved begrunnet mistanke om at slike brudd eller forhold har funnet sted, kan denne informasjonen rapporteres. Det kreves imidlertid at rapporteringen skjer i god tro, og at rapporteringsordningen ikke brukes til ubegrunnede beskyldninger mot uskyldige personer.',
        theViolationsWhich:
            'Overtredelsene som kan rapporteres gjennom varslingsordningen, gjelder konkrete brudd på EU-retten eller andre alvorlige lovbrudd og forhold i henhold til gjeldende lover. Mindre alvorlige forhold eller vanlige ansettelsessaker, som misnøye med lønn eller mindre  problemer med kolleger, skal ikke rapporteres gjennom denne varslingsordningen.',
        activitesThatCanBeReported:
            'Aktiviteter som kan rapporteres gjennom denne ordningen, gjelder for eksempel korrupsjon, miljøvern, folkehelse, personvern og personopplysninger eller seksuell trakassering og seksuelle overgrep. Dette er bare eksempler. Også andre forhold eller lovbrudd av alvorlig grad kan rapporteres.',
        ifYouAreInDoubtAbout:
            'Hvis du er i tvil om observasjonen eller mistanken er av alvorlig grad og bør rapporteres eller ikke, så oppfordres du til å gjøre det. Alle henvendelser vil bli besvart og behandlet.',
        protectionOfWhistleblowers: 'Beskyttelse av varslere',
        anyoneWhoReportsMatters:
            'Alle som i god tro varsler om forhold som beskrevet ovenfor, er beskyttet mot enhver form for gjengjeldelse. Gjengjeldelse innebærer direkte eller indirekte handlinger eller unnlatelser som skjer i jobbsammenheng som følge av varslingen, og som påfører eller kan påføre varsleren uberettiget skade. Slike handlinger kan være for eksempel oppsigelse, endring av arbeidssted, lønnsreduksjon eller skade på varslerens omdømme.',
        ifAWhistleblowerSuffers:
            'Hvis en varsler utsettes for noen form for gjengjeldelse fra',
        heOrSheWillBeCompensated:
            'vil man bli kompensert for dette. Ved oppsigelse skal oppsigelsen annulleres, og arbeidsforholdet skal opprettholdes eller gjenopprettes hvis varsleren ønsker det.',
        mayAlsoBeSubjectToOtherSanctions:
            'kan også bli gjenstand for andre sanksjoner.',
        howToReport: 'Hvordan rapportere?',
        reportsThatLieWithin:
            'Rapporter som omfattes av disse retningslinjene behandles av den eller de utvalgte personene i selskapets avtalte kontaktlinje, som er:',
        onlyThesePersonsAreAuthorised:
            'Det er kun disse personene som er autorisert til å gjennomføre en granskning. I noen tilfeller kan imidlertid granskningen gjennomføres ved hjelp av eksterne parter, for eksempel advokater eller revisorer.',
        howAreTheReportsProcessed: 'Hvordan behandles rapportene?',
        youWilRecieveAnAcknowledgement:
            'Du vil motta en bekreftelse på at du har mottatt rapporten innen sju dager. Etter dette vil det bli foretatt en første gjennomgang av rapporten. Hvis rapporten viser seg å være åpenbart grunnløs, vil den bli avvist. Personen som rapporterte saken, vil bli underrettet om dette så snart som mulig. Hvis den innledende undersøkelsen viser at rapporten ikke kan karakteriseres som åpenbart grunnløs, vil den bli undersøkt nærmere. Saken vil deretter bli behandlet internt. Varsleren vil få tilbakemelding om eventuelle oppdateringer eller resultater så snart som mulig. Denne interne granskningen kan få konsekvenser for den eller de involverte. Hvis saken er av særlig alvorlig karakter, kan den også bli sendt videre til politiet for videre etterforskning. Etter at alle undersøkelser og eventuelle sanksjoner er gjennomført, avsluttes saken. Den vil da bli slettet fra systemet.',
        subsequentClarificationAndCollection:
            'Etterfølgende avklaring og innhenting av ytterligere dokumentasjon',
        whenYouCreateAReport:
            'Når du oppretter en rapport, får du mulighet til å logge deg inn i systemet og se om saksbehandleren har stilt ytterligere spørsmål om saken eller bedt om ytterligere dokumentasjon. Eventuell etterfølgende dialog avhenger utelukkende av varslerens vilje til å logge inn i systemet og svare på saksbehandlerens spørsmål.',
        confidentialityAndITSecurity: 'Konfidensialitet og IT-sikkerhet',
        aReportCanBeMadeEither:
            'En varsling kan gjøres enten anonymt eller konfidensielt. Førstnevnte innebærer at ingen personopplysninger deles, mens sistnevnte innebærer at varsleren deler personopplysninger. Hvis personopplysninger deles i varselet, er det bare de som er autorisert til å undersøke saken, som får tilgang til dem. Konfidensialiteten til den innsendte rapporten vil derfor uansett bli respektert.',
        theWhistleblowerReportingChannel:
            'Varslingskanalen drives av Walor ApS, som er en uavhengig tredjepart som garanterer for systemets sikkerhet. Systemet logger ikke IP-adresser og maskin-ID-er og krypterer alle data som behandles. Det er kun den ansvarlige saksbehandleren som har tilgang til rapporten.',
        noticeOfPersonConcerned: 'Varsel om berørt person',
        theRecipientOfTheReport:
            'Mottakeren av varselet er forpliktet til å gi den som blir varslet informasjon om den innrapporterte hendelsen. Det vil i hvert enkelt tilfelle bli gjort en vurdering av når dette varselet kan gis videre, slik at varselet ikke får konsekvenser for bevisinnhentingen.',
        noInformationEitherDirect:
            'Ingen informasjon, verken direkte eller indirekte, vil bli spesifisert om hvem som har rapportert til den berørte personen, selv om rapportøren har valgt å rapportere konfidensielt.',
        questions: 'Spørsmål',
        allQuestions: 'Alle spørsmål om varslingsordningen kan rettes til',
    },
    newCaseFlow: {
        headerInitiateReport: 'Start rapport',
        headerDescription: 'Beskrivelse',
        headerChooseCaseworker: 'Velg saksbehandler',
        headerSummary: 'Sammendrag',
        initiateReport: 'Start rapport',
        howWouldYouLikeToAppear: 'Hvordan ønsker du å vises i rapporten?',
        or: 'eller',
        confidential: 'Konfidensielt',
        whatIstheDifferenceBetweenAnAnonymous:
            'Hva er forskjellen på en anonym og en konfidensiell rapport?',
        popUpWhatIstheDifferenceBetweenAnAnonymous:
            'Hva er forskjellen på en anonym og en konfidensiell rapport?',
        popUpAnonymous: 'Anonym',
        popUpAnonymousYouProvideYourEmployer:
            'Du gir informasjon om både den potensielle forseelsen, den observerte hendelsen eller ulykken, men du trenger IKKE å oppgi personopplysninger.',
        popUpConfidential: 'Konfidensielt',
        popUpConfidentialYouProvideYourEmployer:
            'Du gir informasjon om både den potensielle forseelsen, den observerte hendelsen eller ulykken og noen opplysninger om deg selv.',
        confidentialInformation: 'Konfidensiell informasjon',
        provideYourPersonalInformation:
            'Gi din personlige informasjon til rapporten',
        secretInformationNote:
            'Informasjonen din vil bli holdt hemmelig og kan bare ses av varslingsenheten.',
        enterPosition: 'Stilling',
        enterPhone: '12345678',
        describeTheEvents: 'Beskriv hendelsene',
        theMoreDetailedTheDescription:
            'Jo mer detaljert beskrivelsen er, desto lettere er det å granske saken.',
        allFieldsWith: 'Alle felt med * er obligatoriske',
        headline: 'Overskrift *',
        enterHeadline: 'Overskrift',
        description: 'Beskrivelse',
        enterDescription: 'Beskrivelse',
        recordAVoice: 'Spille inn en talemelding',
        recordYourVoice: 'Registrer Din Stemme Melding',
        yourVoiceIsAutomatically:
            'Stemmen din blir automatisk forvrengt for anonymitetens skyld',
        listenToYourRecording: 'Lytt til opptaket før du sender det',
        clickToStartRecording: 'Klikk for å starte opptaket',
        voiceMessage: 'Talemelding',
        addRecording: 'Legg til opptak',
        recordedVoiceMessage: 'Innspilt talemelding',
        uploadFiles: 'Last opp filer',
        maximumFileSize: 'Maksimal filstørrelse: 100 MB',
        addFiles: 'Legg til filer',
        youShouldBeAwareThatFilesCanContainMetadata:
            'Du bør være oppmerksom på at filer kan inneholde metadata som kan kompromittere anonymiteten din.',
        informationYouCould: 'Informasjon du kan inkludere',
        whereAndWhen: 'Hvor og når fant hendelsen sted?',
        whatIsYourKnowledge: 'Hva vet du om saken?',
        whatDepartmentIsInvolved: 'Hvilken avdeling er involvert?',
        whatIsYourRelationship: 'Hvilket forhold har du til organisasjonen?',
        reportSummary: 'Sammendrag av rapporten',
        summaryCaseWorker: 'Hvem skal motta rapporten',
        summaryHowWouldYouLikeToAppear:
            'Hvordan ønsker du å vises i rapporten?',
        summaryHeadline: 'Overskrift',
        summaryDescription: 'Beskrivelse',
        summaryFiles: 'Filer',
        expectToRecieveAnAnswer: 'Forvent å motta svar innen syv dager',
        submitReport: 'Send inn rapport',
        theReportHasBeenReceived: 'Rapporten er mottatt',
        inOrderToAccessYourCaseAgain:
            'For å få tilgang til saken din igjen må du lagre varslingskoden.',
        keepItSafe:
            'På en sikker måte, da dette er den eneste måten å få tilgang til saken din på.',
        whistleblowerCode: 'Kode for varslere',
        copyToClipboard: 'Kopier kode',
        stayUpdated: 'Hold deg oppdatert',
        youHaveTheOpportunityToReceiveAMail:
            'Motta oppdateringer ved å skrive inn e-postadressen din.',
        itIsImportantToMention: 'E-postadressen din vil ikke bli delt.',
        theWhistleblowerCodeWillNot:
            'Varslerkoden vil IKKE bli sendt i posten.',
        yourEmailAddress: 'E-postadressen din',
        chooseContactFrame: {
            pickYourCaseworker: 'Velg saksbehandler',
            pickExcludedCaseworker: 'Saksbehandlere som skal motta rapporten',
            theContactWill: 'Den valgte kontakten vil motta rapporten',
            theContactWillNot: 'Saksbehandlere som skal motta rapporten',
            languages: 'Språk',
            languagesNotGiven: 'Språk ikke oppgitt',
            pickCaseworker: 'Velg saksbehandler',
        },
        popUpRememberYourWhistleblowerCode: 'Husk retningslinjene for varslere',
        popUpItIsImportantThatYouSaveAndStoreTheWhistleblowerCode:
            'Det er viktig at du lagrer og tar vare på varslingskoden, slik at du kan få tilgang til saken i fremtiden',
    },
    accessCaseFlow: {
        date: 'Dato',
        files: 'Filer',
        accessExistingReport: 'Få tilgang til eksisterende rapport',
        inOrderToAccessYourCase:
            'Bruk den mottatte varslingskoden for å få tilgang til den eksisterende rapporten.',
        enterYourWhistleblowerCode: 'Skriv inn varslingskoden',
        missingCode: 'Har du mistet koden?',
        createNewRaport: 'Vennligst opprett en ny rapport.',
        enterCode: 'Angi kode',
        logout: 'Logg ut',
        info: 'Informasjon',
        id: 'ID',
        appearance: 'Utseende',
        fromWhistleblower: 'Fra varsleren',
        fromCaseworker: 'Fra saksbehandler',
        timeline: 'Tidslinje',
        caseworkers: 'Saksbehandlere',
        description: 'Beskrivelse',
        enterMessage: 'Skriv inn melding',
        send: 'Send',
        logoutOfReport: 'Logg ut av rapporten',
        translate: 'Oversett',
        translated: 'Oversatt',
        hiddenMsgOne: 'Dette er datoen da rapporten ble opprettet.',
        hiddenMsgTwo:
            'Dette er fristen for saksbehandlerens første svar til deg.',
        hiddenMsgThree:
            'Dette er fristen for saksbehandlerens tilbakemelding til deg.',
        months: {
            january: 'Januar',
            february: 'Februar',
            march: 'Mars',
            april: 'April',
            may: 'Mai',
            june: 'Juni',
            july: 'Juli',
            august: 'August',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'Desember',
        },
    },
    header: {
        dashboard: 'Dashbord',
        cases: 'Tilfeller',
        clients: 'Kunder',
        notifications: 'Varsler',
        readAll: 'Les alt',
    },
    dashboardPartner: {
        headlineDashboard: 'Dashbord',
        yourcases: 'Dine saker',
        headline: 'Overskrift',
        notifications: 'Varsler',
        all: 'Alle',
        unread: 'Ulest',
        noCases: 'Du har ingen saker',
        noNotifications: 'Du har ingen varsler',
        yourStatistics: {
            yourStatistics: 'Statistikken din',
            open: 'Åpen',
            closed: 'Stengt',
            forwarded: 'Videresendt',
            totalCases: 'Totalt antall saker',
            activeCases: 'Aktive saker',
            closedCases: 'Avsluttede saker',
            forwardedCases: 'Videresendte saker',
        },
    },
    casesPartner: {
        headlineCases: 'Tilfeller',
        allCases: 'Alle tilfeller',
        headline: 'Overskrift',
        status: 'Status',
        filter: 'Filtre',
        removeFilter: 'Fjern filtre',
        myCases: 'Mine saker',
        actionRequired: 'Nødvendige tiltak',
        awaitingResponse: 'Venter på svar',
        seeClosedCases: 'Se avsluttede saker',
        viewForwardedCases: 'Se videresendte saker',
        forwardedCases: 'Videresendte saker',
        searchCases: 'Søk etter saker',
        headlineClosedCases: 'Avsluttede saker',
        closedCases: 'Avsluttede saker',
    },
    caseworkerOpenReport: {
        transcription: 'Transkripsjon',
        goBack: 'Gå tilbake',
        reportBy: 'Rapport fra',
        days: 'dager',
        deadlineExceededTagOne: 'Frist overskredet med',
        deadlineExceededTagTwo: 'Juridisk frist overholdt',
        hours: 'timer',
        thisReportHasBeen: 'Denne rapporten er sendt av',
        sevenDaysMessageOne:
            'Du må gi en bekreftelse på at du har mottatt en rapport innen syv dager etter at du har mottatt den.',
        sevenDaysMessageTwo:
            'Send en melding til varsleren nedenfor og følg loven.',
        ninetyDaysMessageOne:
            'Varsleren skal få tilbakemelding så snart som mulig og senest tre måneder etter at det er bekreftet at varselet er mottatt.',
        ninetyDaysMessageTwo:
            'Tilbakemeldinger kan for eksempel være planen for eller resultatet av en intern undersøkelse.',
        exportCase: 'Eksport',
        editInformation: 'Redigere informasjon',
        changeResponsibility: 'Endringsansvarlig',
        theNewResponsible: 'Den nye ansvarlige vil bli varslet via e-post',
        thereAreNoOther: 'Det er ingen andre tilgjengelige saksbehandlere',
        forward: 'Videresende',
        title: 'Title',
        uncategorized: 'Ikke kategorisert',
        categorizeCase: 'Kategorisere saken',
        selectCategory: 'Velg en kategori for denne saken',
        selectedCategory: 'Valgt kategori',
        close: 'Lukk',
        saveThis: 'Lagre',
        frequentCategories: 'Hyppige kategorier',
        allCategories: 'Alle kategorier',
        ViewMoreCategories: 'Se flere kategorier',
        ViewLess: 'Se mindre',
        status: 'Status',
        changeStatus: 'Endre status',
        youAreInTheProcess: 'Du er i ferd med å endre status til',
        awaitingResponse: 'Venter på svar',
        actionRequired: 'Nødvendige tiltak',
        confirm: 'Bekreft',
        caseDescription: 'Beskrivelse',
        noFilesUploaded: 'Ingen filer lastet opp',
        historyLog: 'Historielogg',
        preferredContact: 'Foretrukket kontakt',
        noMessages: 'Ingen meldinger',
        internalNotes: 'Interne notater',
        takeNotesHere:
            'Legg til notatene dine her, slik at du ikke glemmer dem.',
        onlyTheCaseworkers: 'Dette er bare synlig for saksbehandlerne',
        enterNote: 'Skriv notat',
        saveNote: 'Lagre',
        closeCase: 'Avslutt saken',
        solved: 'Løst',
        denied: 'Avvist',
        forwarded: 'Videresendt',
        addComment: 'Legg til kommentar',
        forwardedAt: 'Videresendt på',
        severityScore: 'Alvorlighetsgrad',
        comment: 'Hvordan',
        theCaseHasBeenForwardedTo: 'Saken er sendt videre til',
        theCaseHasBeenForwardedBy: 'Saken er oversendt av',
        caseClosed: 'Saken er avsluttet',
        toEnsureGDPR:
            'For å sikre at GDPR overholdes, vil denne saken bli slettet permanent i',
        theOptionToExportTheCase:
            'Muligheten til å eksportere saken til en PDF-fil er tilgjengelig innenfor denne tidsrammen.',
        theDeletionOfTheCaseWillNot:
            'Sletting av saken vil ikke påvirke statistikkoversikten din.',
    },
    caseworkerPopup: {
        editCaseworker: 'Rediger saksbehandler',
        close: 'Lukk',
        caseworkerInCharge: 'Ansvarlig for saken',
        newFlag: 'Ny',
        addCaseworkers: 'Legg til saksbehandlere',
        noCaseworkerAvailable:
            'Det er ingen andre tilgjengelige saksbehandlere som kan tildeles denne saken.',
        discardChanges: 'Forkast endringer',
    },
    caseClosedViewPartner: {
        closeDate: 'Sluttdato',
        comment: 'Hvordan',
        readMore: 'Les mer',
        noComment: 'Ingen kommentarer',
    },
    clientsPartner: {
        headlineClients: 'Kunder',
        buttonAddNewClient: 'Legg til ny kunde',
        allClients: 'Alle kunder',
        openCases: 'Åpne saker',
        closeCases: 'Avsluttede saker',
        newCases: 'Nye saker',
    },
    singleClientViewPartner: {
        information: 'Informasjon',
        lineOfContact: 'Kontaktlinje',
        cases: 'Tilfeller',
        buttonSave: 'Lagre',
        saveOrderOfContacts: 'Lagre rekkefølgen på kontaktene',
        lineOfContactsHaveNot: 'Det er ikke opprettet noen kontaktlinje',
        deleteCaseworker: 'Slett saksbehandler',
        youAreAboutToDeleteTheCaseworker:
            'Du er i ferd med å slette saksbehandleren',
        theCaseworkerWillNotAbleToReceive:
            'Saksbehandleren vil ikke lenger kunne motta fremtidige rapporter.',
        theCaseworkerCannotBeDeletedIf:
            'Saksbehandleren kan ikke slettes hvis vedkommende er tilknyttet en åpen sak.',
        thisActionCannotBeUndone: 'Denne handlingen kan ikke angres',
        caseworkerHasCasesAndCannotBeDeleted:
            'Saksbehandleren har en eller flere aktive saker og kan ikke slettes.',
        contactAdded: 'En ny saksbehandler er lagt til',
        contactUpdated: 'Saksbehandler oppdatert',
        inviteCaseworker: 'Invitere saksbehandler',
        allCases: 'Alle saker',
        company: 'Selskap',
        headline: 'Overskrift',
        status: 'Status',
        actionRequired: 'Nødvendige tiltak',
        awaitingResponse: 'Venter på svar',
        reOpenCase: 'Gjenåpne saken',
        youAreInTheProcessOfExporting: 'Du er i ferd med å eksportere en sak',
        youAreInTheProcessOfReopening: 'Du er i ferd med å gjenåpne saken.',
        caseInfo: 'Informasjon om saken',
        arrivalDate: 'Ankomstdato',
        closeDate: 'Sluttdato',
        caseID: 'Sak-ID',
        theInternalInvestigationHasConcluded:
            'Den interne etterforskningen er avsluttet, og saken vil bli slettet i',
        addLanguage: 'Legg til språk',
        searchLanguage: 'Søk i språk',
        languageNotFound: 'Språk ikke funnet',
    },
    addNewClientPartner: {
        headlineAddNewClient: 'Legg til ny kunde',
        buttonCancel: 'Avbryt',
        searchForCompanyNameOrCompanyNumber:
            'Søk etter firmanavn eller firmanummer',
        findCompany: 'Finn selskap',
        enterInformationManually: 'Angi informasjon manuelt',
        thanksForYourSubscription: 'Du har lagt til en ny kunde',
        informationOnHowToGetStarted:
            'Informasjon om hvordan kunden kan komme i gang',
        linkText:
            'Lenken vil gi deg tilgang til den digitale varslingskanalen der rapportene kan opprettes og sendes inn. Del denne med klienten.',
        employeeLink: 'Lenke til ansatte',
        whistleblowerPolicyText:
            'Finn kundens retningslinjer for varsling nedenfor, og henvis kunden til å logge inn på plattformen for ytterligere materiale.',
        whistleblowerPolicy: 'Retningslinjer for varsling',
        download: 'Last ned',
        sendLinkText: 'Send informasjonen til kunden din',
        buttonSendEmail: 'Send e-post',
        popUpSendEmail: 'Send e-post',
        popUpCancel: 'Avbryt',
        popUpText:
            'Du er i ferd med å sende lenken til varslingskanalen og retningslinjene for varslere til',
        popUpButtonSendInformation: 'Send informasjon',
        popUpAreYouSureYouWantToCancelThisSession:
            'Er du sikker på at du vil avbryte denne økten?',
        popUpYouWillLoseAllYourProgress: 'Du vil miste alt arbeid',
        popUpButtonNo: 'Nei',
        popUpButtonYes: 'Ja',
    },
    settingsPartner: {
        headlineSettings: 'Innstillinger',
        myAccount: 'Min konto',
        automaticReply: 'Automatisk svar',
        billing: 'Fakturering',
        users: 'Brukere',
        logOut: 'Logg ut',
        edit: 'Redigere',
        changePassword: 'Endre passord',
        oldPassword: 'Gammelt passord',
        newPassword: 'Nytt passord',
        passwordUpdate: 'Fullført!',
        passwordUpdadetSuccessfully: 'Passordet er oppdatert.',
        defaultLanguage: 'Standardspråk',
        emailUpdated: 'E-post oppdatert!',
        invoices: 'Fakturaer',
        verificationLinkSent:
            'Vi har sendt en bekreftelseslenke til den nye e-posten. Du vil bli logget ut om',
        seconds: 'sekunder.',
        reactivateAutoReply: 'Aktivere automatisk svar på nytt',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        autoReplyAlertMessage:
            'Det automatiske svaret sendes 15 minutter etter at rapporten er mottatt.',
        added: 'Lagt til',
        openCases: 'Åpne saker',
        closedCases: 'Avsluttede saker',
        buttonAddNewUser: 'Legg til ny bruker',
        popUpAddingANewUser: 'Legge til en ny bruker',
        popUpCancel: 'Avbryt',
        popUpAddPhoto: 'Legg til bilde',
        popUpChangePhoto: 'Endre bilde',
        popUpFirstName: 'Fornavn',
        popUpLastName: 'Etternavn',
        popUpWorkEmail: 'E-post på jobb',
        popUpPhone: 'Telefon',
        popUpPosition: 'Stilling',
        popUpText:
            'Brukeren må bekrefte kontoen sin ved hjelp av en aktiverings-e-post.',
        popUpButtonAddUser: 'Legg til bruker',
        myAccountFrame: {
            somethingWentWrong: 'Noe gikk galt. Prøv igjen.',
            yourChangesHaveBeen: 'Endringene dine har blitt lagret',
        },
        oldAddPaymentMethodFrame: {
            paymentMethods: 'Fakturering',
            addPaymentMethod: 'Legg til betalingsmåte',
            defaultPaymentMethod: 'Standard betalingsmåte',
            yourDefaultPaymentMethod:
                'Standard betalingsmåte vil bli brukt for alle fremtidige betalinger. Hvis du har en abonnementsavgift til gode, vil vi forsøke å trekke den automatisk.',
            defaultPaymentMethodMissing: 'Standardbetaling mangler',
            otherPaymentMethods: 'Andre betalingsmåter',
            otherPaymentMethodsYouHave:
                'Andre betalingsmåter du har lagt til. Merk av den du ønsker å bruke som standard.',
            currentPlan: 'Gjeldende plan',
            belowYouCanSee: 'Her kan du se den valgte abonnementsplanen.',
            cardNumber: 'Kortnummer',
            expireDate: 'Utløpsdato',
            addPaymentMethodModal: {
                addPaymentMethod: 'Legg til betalingsmåte',
                cancel: 'Avbryt',
                card: 'Kort',
                invoice: 'Faktura',
                errorLoadingStripe: 'Feil, lasting av Stripe',
                fullName: 'Fullstendig navn',
                email: 'E-post',
                address: 'Adresse',
                city: 'By',
                zipCode: 'Postnummer',
                bankInformation: 'Bankinformasjon',
                cardInformation: 'Kortinformasjon',
                loginMessage: 'Dette kan ta noen minutter. Vær tålmodig.',
            },
        },
        addPaymentMethodFrame: {
            creditOrDebit: 'Kreditt- eller debetkort',
            invoice: 'Faktura',
            addACard: 'Legg til et kort som betalingsmåte',
            useAnInvoice: 'Bruk faktura som betalingsmåte',
            active: 'Aktiv',
            freeTrial: 'Gratis prøveperiode',
            canceled: 'Avlyst',
            manageSubscription: 'Abonnement på Mange',
            addPaymentMethod: 'Legg til betalingsmåte',
            reactiveSubscription: 'Aktivere abonnementet på nytt',
            cancelSubscription: 'Avbryt abonnementet',
            youCanCancel:
                'Du kan når som helst si opp abonnementet ditt. Du vil fortsatt ha tilgang til kontoen din frem til den nåværende faktureringsperioden utløper.',
            needHelp: 'Trenger hjelp',
            areYouSure: 'Er du sikker på at du vil si opp abonnementet ditt?',
            pleaseLetUsKnow:
                'Gi oss beskjed hvis det er noe vi kan gjøre noe med før du forlater oss.',
            ifYourCertain:
                'Hvis du er sikker på at du vil avbestille, er vi her for deg når du er klar til å komme tilbake.',
            noGoBack: 'Nei, gå tilbake',
            itIsToo: 'Det er for dyrt',
            weDidNotUse: 'Vi brukte ikke plattformen',
            other: 'Annet',
            whyAreYouCanceling: 'Hvorfor avbestiller du',
            yourSubscriptionHasBeen: 'Abonnementet ditt har blitt kansellert',
            yourPlanIsActiveUntil: 'Planen din er aktiv frem til',
            youWillContinue:
                'Du vil fortsatt ha tilgang til kontoen din frem til da.',
            editPaymentMethod: 'Rediger betalingsmåte',
            currentPaymentMethods: 'Gjeldende betalingsmåter',
            chooseAnotherMethod: 'Velg en annen metode',
            paymentFailed: 'Betaling mislyktes',
            yourPaymentHasFailed: 'Betalingen din har mislyktes',
            theInvoiceHasNotBeenPayed: 'Fakturaen er ikke betalt',
            doYouNeedHelp: 'Trenger du hjelp?',
            weWillGetInContact: 'Vi tar kontakt med deg innen to timer.',
            talkToYouSoon: 'Vi snakkes snart',
            getBack: 'Gå tilbake',
            submit: 'Send inn',
            day: 'Dag',
            days: 'Dager',
            yourCardHasExpired: 'Kortet ditt er utløpt',
            pdf: 'PDF',
            whistleblowingSoftware: 'Programvare for varsling',
            amount: 'Beløp',
            back: 'Tilbake',
            subscription: 'Abonnement',
            chooseThePlanThat:
                'Velg det abonnementet som passer til organisasjonens totale antall ansatte.',
            employees: 'Ansatte',
            year: 'år',
            currentPlan: 'Gjeldende abonnement',
            newPlan: 'Nytt abonnement',
            choosePlan: 'Velg abonnement',
        },
        invoicesFrame: {
            billingHistory: 'Faktureringshistorikk',
            invoice: 'faktura',
            amount: 'Beløp',
            plan: 'Abonnement',
            status: 'Status',
            paid: 'Betalt',
            waitingToPay: 'Venter på å betale',
            download: 'Last ned',
        },
        userInformation: 'Brukerinformasjon',
    },
    clientView: {
        information: 'Informasjon',
        lineOfContact: 'Saksbehandlere',
        customization: 'Tilpasning',
        whistleblowerPolicy: 'Retningslinjer for varsling',
        reportingChannel: 'Rapporteringskanal',
        copyLinkReportingChannel: 'Kopier lenken til rapporteringskanalen din',
        downloadQRCodeImage: 'Last ned bildet her',
        visitReportLink: 'Klikk her for å gå til selskapets rapporteringslenke',
        downloadQRCode: 'Last ned QR-kode for rapporteringskanalen',
        copyLinkWhistleblowerPolicy:
            'Kopier lenken til retningslinjene dine for varsling.',
        reportingChannelLanguage: 'Rapporteringskanalens språk:',
        reportingChannelLanguageError: 'Rapportering av kanalspråkfeil:',
        qrcode: 'QR-kode',
        trainingMaterial: 'Opplæringsmateriell',
        copyLinkToTrainingMaterial:
            'Kopier lenken til opplæringsmateriellet ditt',
        contactPerson: 'Kontaktperson',
        willBeDisplayedReportLink:
            'Informasjonen nedenfor vises for varsleren i rapporteringslenken.',
        languages: 'Språk',
        notes: 'Merknader',
        preview: 'Forhåndsvisning',
        pickYourCaseworker: 'Velg dine saksbehandlere',
        theContactWill: 'De utvalgte saksbehandlerne vil motta rapporten',
        note: 'Merknad',
        editProfilePicture: 'Rediger profilbilde',
        addPhoto: 'Legg til bilde',
        changePhoto: 'Endre bilde',
        addQuestion: 'Legg til spørsmål',
        reportQuestions: 'Spørsmål til rapporten',
        addAQuestion: 'Legg til dine egne spørsmål',
        questionOnReportingChannel: 'Spørsmål om rapporteringskanalen din',
        dragToChangeOrderOfQuestions:
            'Dra og endre rekkefølgen på spørsmålene her.',
        saveOderOfQuestions: 'Lagre rekkefølgen på spørsmålene',
        industrySuggestedQuestionTitle:
            'Her er noen anbefalte spørsmål som er spesifikke for din bransje.',
        suggestedQuestionText:
            'Disse sitatene er basert på din bransje. Bruk dem, la deg inspirere eller bare la dem ligge.',
    },
    verifyEmail: {
        verifyEmail: 'Bekreft e-post',
        emailVerified: 'E-post bekreftet',
        yourEmailHasBeen:
            'E-postadressen din er bekreftet. Du kan nå gå til påloggingssiden og logge inn med e-postadressen din og det nyopprettede passordet.',
        goToLogin: 'Gå til innlogging',
        verifyNewEmail: 'Verifisert!',
        loginWithNewEmail: 'Du kan nå logge inn med den nye e-posten.',
    },
    nextButton: { nextStep: 'Neste' },
    finishButton: { finish: 'Avslutt' },
    closeButton: { close: 'Lukk' },
    dropdown: { countryNotFound: 'Land ikke funnet' },
    copiedText: { copied: 'Kopiert' },
    you: { you: 'Du' },
    warningText: { warning: 'Advarsel' },
    deleteText: { delete: 'Slett' },
    inactiveModal: {
        inactivityWarning: 'Advarsel om inaktivitet',
        youWillBeLoggedOut: 'Du vil bli logget ut',
        youHaveBeenInactive:
            'Du har vært inaktiv i 14 minutter. Når timeren er utløpt, blir du logget ut.',
        stayLoggedIn: 'Forbli innlogget',
    },
    notifications: {
        newMessage: 'Ny melding',
        deadlineAlert: 'Varsel om tidsfrist',
        newReport: 'Ny rapport',
        reportAssigned: 'Rapport tildelt',
        reportUnassigned: 'Rapportere ikke tildelt',
    },
    historyLog: {
        forwarded: 'Videresendt',
        exported: 'Eksportert',
        changeResponsible: 'Ansvarlig for endring',
        caseViewed: 'Sak sett',
        fileUploaded: 'Filen er lastet opp',
        newMessage: 'Ny melding',
        statusUpdated: 'Status oppdatert',
        caseDelegation: 'Delegering av saker',
        reportCreated: 'Rapport opprettet',
        to: 'til',
        by: 'av',
        from: 'fra',
        caseHasBeenForwarded: 'Saken er videresendt',
        caseHasBeenExported: 'Saken er eksportert',
        caseHasBeenReassigned: 'Saken er omfordelt',
        caseHasBeenViewed: 'Saken har blitt sett',
        categoryIsChanged: 'Kategorien er endret',
        messageFrom: 'Melding fra',
        caseAssigned: 'Tildelt sak',
        newCase: 'Ny sak',
        caseClosed: 'Saken ble avsluttet',
        caseClosedBy: 'Denne saken ble avsluttet av',
        caseReopened: 'Saken ble gjenåpnet',
        caseReopenedBy: 'Denne saken ble gjenåpnet av',

        internalNote: 'Internt notat',
        internalNoteHasBeen: 'Internt notat er skrevet av',
        caseworkerAdded: 'Saksbehandler lagt til',
        caseworker: 'Saksbehandler',
        wasAddedBy: 'ble lagt til av',
        newFile: 'Ny fil',
        newFileWasAdded: 'En ny fil ble lagt til av',
        whistleblower: 'varsler',
        automaticReplySent: 'Automatisk svar sendt',
        automaticReplyWasSent: 'Automatisk svar ble sendt av',
    },
    validation: {
        nameOfTheCityMustBeAtLeast: 'Stedets navn må bestå av minst ett tegn.',
        nameOfTheCityCannot: 'Stedets navn kan ikke være lengre enn 85 tegn.',
        companyIdentificationNumberMust:
            'Nummeret må være nøyaktig åtte tegn langt',
        addressIsToShort: 'Adressen er for kort',
        addressIsToLong: 'Adressen er for lang',
        organizationIsToShort: 'Organisasjonen er for kort',
        organizationIsToLong: 'Organisasjonen er for lang',
        mustBeAValidEmail: 'Må være en gyldig e-postadresse',
        firstNameCannotBeShorter: 'Fornavnet kan ikke være kortere enn to tegn',
        firstNameCannotBeLonger: 'Fornavnet kan ikke være lengre enn 26 tegn',
        lastNameCannotBeShorter:
            'Etternavnet kan ikke være kortere enn to tegn',
        lastNameCannotBeLonger: 'Etternavnet kan ikke være lengre enn 26 tegn',
        passwordMustBeAtLeast: 'Passordet må bestå av minst åtte tegn',
        yourPasswordMustContainerAtLeastOneUppercase:
            'Passordet må inneholde minst en stor bokstav.',
        yourPasswordMustContainerAtLeastOneLowercase:
            'Passordet må inneholde minst en liten bokstav.',
        yourPasswordMustContainerAtLeastOneSymbol:
            'Passordet må inneholde minst en symbolbokstav',
        yourPasswordMustContainerAtLeastOneNumber:
            'Passordet må inneholde minst ett tall og en bokstav',
        passwordsMustMatch: 'Passordene må stemme overens',
        notAValidPhoneNumber: 'Ikke et gyldig telefonnummer',
        titleOfPositionMustBe:
            'Tittelen på stillingen må være lengre enn to tegn',
        titleOfPositionCannotBe:
            'Tittelen på stillingen kan ikke være lengre enn 26 tegn',
        mustSetAPriority: 'Du må angi en prioritet',
        notAValidZipCode: 'Ikke et gyldig postnummer',
        isLongEnough: 'Minst åtte tegn lang',
        hasUpperCaseLetter: 'Minst en stor bokstav [A–Å]',
        hasLowerCaseLetter: 'Minst en liten bokstav [a–å].',
        hasNumber: 'Minst ett tall [0–9]',
        hasSpecialCharacter: 'Minst ett spesialtegn[!@#$%^&*()\\-__+.].',
        passwordsMatch: 'Begge passordene samsvarer',
    },
    forwardReportPage: {
        selectCatagory: 'Velg kategori',
        uncategorized: 'Ikke kategorisert',
        accusationAndEvidenceScore: 'Poengsum for anklager og bevis',
        accusationScore: 'Poengsum for anklager',
        evidenceScore: 'Poengsum for bevis',
        severityScore: 'Total alvorlighetsgrad',
        lineOfContact: 'Kontaktlinje',
        lineOfContactReminder:
            'Forsikre deg om at personen på ingen måte er innblandet i denne saken.',
        comment: 'Hvordan',
        pleaseLeaveComment: 'Legg igjen en kommentar her',
        forwardCase: 'Videresende sak',
        popUpConfirmForwardPartOne: 'Er du sikker på at du vil videresende',
        popUpConfirmForwardPartTwo: 'til',
        popUpNo: 'Nei',
        popUpYes: 'Ja, det er jeg sikker på',
        minimumAbbreviated: 'Min',
        maximumAbbreviated: 'Maks.',
        saveThis: 'Lagre dette',
    },
    trialWarning: {
        goToSettings: 'Gå til innstillinger',
        yourTrialHasEnded: 'Prøveperioden din er avsluttet.',
        yourFreeTrialEndedOn: 'Gratis prøveperiode ble avsluttet den:',
        yourFreeTrialEndsOn: 'Prøveperioden utløper om',
        day: 'dag',
        days: 'dager',
    },
    categories: {
        accounting_irregularities: 'Regnskapsmessige uregelmessigheter',
        bribery: 'Bestikkelser',
        consumer_protection: 'Forbrukerbeskyttelse',
        duty_of_confidentiality: 'Taushetsplikt',
        embezzlement: 'Underslag',
        extortion: 'Utpressing',
        financial_services_products_and_markets:
            'Finansielle tjenester, produkter og markeder',
        food_safety_animal_health_and_welfare:
            'Mattrygghet, dyrehelse og dyrevelferd',
        forgery: 'Falskneri',
        fraudulence: 'Bedrageri',
        money_laundering_and_terrorist_financing:
            'Hvitvasking av penger og finansiering av terrorisme',
        occupational_health_and_safety: 'Helse og sikkerhet på arbeidsplassen',
        products_safety_and_compliance: 'Produktsikkerhet og samsvar',
        protection_of_environment: 'Beskyttelse av miljøet',
        protection_of_privacy_and_personal_data_and_security_of_network_and_information_systems:
            'Beskyttelse av personvern og personopplysninger og sikkerhet i nettverks- og informasjonssystemer.',
        public_health: 'Folkehelse',
        public_procurement: 'Offentlige anskaffelser',
        radiation_protection_and_nuclear_safety:
            'Strålevern og kjernefysisk sikkerhet',
        serious_or_recurring_conflicts_at_the_workplace:
            'Alvorlige eller gjentatte konflikter på arbeidsplassen',
        sexual_harassment_and_sexual_assault:
            'Seksuell trakassering og seksuelle overgrep',
        the_financial_interests_of_the_european_union:
            'Den europeiske unions finansielle interesser',
        the_internal_market_of_the_european_union_including_state_aid_and_corporate_taxation:
            'EUs indre marked, inkludert statsstøtte og virksomhetsbeskatning.',
        theft: 'Tyveri',
        conflict_of_interest: 'Interessekonflikt',
        transport_safety: 'Transportsikkerhet',
        non_whistleblower_case: 'Ikke-varslingssak',
    },
    integrationModal: {
        thisAppWouldLikeToAccess:
            'Denne appen vil gjerne ha tilgang til Walor-kontoen din. Du må gi tillatelse til dette',
        thisAppRequires: 'Denne appen krever tillatelse til å',
        whistleblowingChannel: 'varslingskanal',
        whistleblowingPolicy: 'retningslinjer for varsling',
        employeeGuides: 'veiledninger for ansatte',
        companyName: 'Selskapsnavn',
        approve: 'Godkjenne',
    },
    dacarpoPolicy: {
        itIsImportant:
            'Det er viktig at du lagrer varslingskoden fra rapporten for å få tilgang til saken i etterkant.',
        allQuestions:
            'Alle spørsmål om varslingsordningen kan rettes til vår HR-avdeling.',
        youWillReceive:
            'Du vil motta en bekreftelse på at du har mottatt rapporten innen sju dager.',
        afterThis:
            'Etter dette vil det bli foretatt en første gjennomgang av rapporten.',
        ifTheReport:
            'Hvis anmeldelsen viser seg å være åpenbart grunnløs, vil den bli avvist. Personen som har rapportert saken, vil få beskjed om dette så snart som mulig.',
        howeverIfTheInitial:
            'Men hvis den innledende undersøkelsen viser at rapporten ikke kan karakteriseres som særlig grunnløs, vil den bli undersøkt nærmere.',
        theCaseWillThen:
            'Saken vil deretter bli behandlet internt. Varsleren vil få tilbakemelding om eventuelle oppdateringer eller resultater så snart som mulig.',
        thisInternalInvestigation:
            'Denne interne granskningen kan få konsekvenser for den eller de som er involvert.',
        ifTheCaseIs:
            'Hvis saken er av særlig alvorlig karakter, kan den også sendes videre til politiet for videre etterforskning.',
        afterConducting:
            'Etter at alle undersøkelser er gjennomført og eventuelle sanksjoner er iverksatt, avsluttes saken. Den vil deretter bli slettet fra systemet.',
    },
    inviteCaseworkerModal: {
        inviteNewCaseworker: 'Invitere en ny saksbehandler',
        sendInvitation: 'Send invitasjon',
    },
    caseworkerSetupAccountPage: {
        setupYourWalor: 'Konfigurer Walor-kontoen din',
        youHaveBeenInvited: 'Du har blitt invitert til å bli med',
        onWalorAs: 'på Walor som',
        caseworker: 'saksbehandler',
        personalInformation: 'Personlige opplysninger',
        additionalInformation: 'Ytterligere opplysninger',
        preview: 'Forhåndsvisning',
        profilePicture: 'Profilbilde',
        thisPictureWillAppear:
            'Dette bildet vises når varsleren velger saksbehandlere.',
        addPicture: 'Legg til bilde',
        notes: 'Merknader',
        typeNoteHere: 'Skriv inn en merknad her.',
        languages: 'Språk',
        whichDepartmentAreYou: 'Hvilken avdeling er du en del av?',
        chooseOneOption: 'Velg ett alternativ',
        humanResources: 'Menneskelige ressurser',
        compliance: 'Overholdelse',
        finance: 'Finans',
        operations: 'Operasjoner',
        marketing: 'Markedsføring',
        other: 'Annet',
        notesHover: 'Notatene vises til varsleren.',
        languagesHover:
            'Oppgi hvilke språk du snakker, slik at varsleren kan velge den saksbehandleren som er best egnet.',
    },
    reusable: {
        voiceRecording: 'Taleopptak',
        email: 'E-post',
        personalInformation: 'Personlige opplysninger',
        firstName: 'Fornavn',
        lastName: 'Etternavn',
        cancel: 'Avbryt',
        address: 'Adresse',
        city: 'By',
        zipCode: 'Postnummer',
        country: 'Land',
        numberOfEmployees: 'Antall ansatte',
        phone: 'Telefonnummer',
        position: 'Stilling',
        companyInformation: 'Informasjon om selskapet',
        companyNumber: 'Firmanummer',
        companyName: 'Selskapets navn',
        workEmail: 'E-post på jobb',
        enterWorkEmail: 'E-post på jobb',
        password: 'Passord',
        anonymous: 'Anonym',
        files: 'Filer',
        date: 'Dato',
        messages: 'Meldinger',
        organization: 'Organisasjon',
        new: 'Ny',
        search: 'Søk',
        category: 'Kategori',
        save: 'Lagre',
        confirmPassword: 'Bekreft passord',

        closed: 'Stengt',
        archived: 'Arkivert',
    },
    statisticsPage: {
        statistics: 'Statistikk',
        overview: 'Oversikt',
        compareToLastPeriod: 'Sammenlignet med forrige periode',
        download: 'Last ned',
        cases: 'Lagt fram',
        active: 'Aktiv',
        closed: 'Stengt',
        lastYear: 'I fjor',
        anonymousVs: 'Anonym vs. konfidensiell',
        anonymous: 'Anonym',
        confidential: 'Konfidensielt',
        actionStats: 'Handlingsstatistikk',
        avgDaysToFirst: 'Gjennomsnittlig antall dager til første respons',
        acvDaysToCLosed: 'Gjennomsnittlig antall dager til avsluttet sak',
        distributionOfCategories: 'Fordeling av kategorier',
        casesByMonth: 'Tilfeller per måned',
        months: {
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'May',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Oct',
            nov: 'Nov',
            dec: 'Dec',
        },
    },
    customizeWhistleblowerPolicy: {
        whistleblowerPolicy: 'Retningslinjer for varsling',
        whistleBlowerPolicyLanguages: 'Språk i retningslinjene for varsling',
        reviseTheDefault:
            'Endre standardretningslinjene før du legger til oversettelser',
        addLanguage: 'Legg til språk',
        selectALanguage: 'Velg et språk',
        translations: 'Oversettelser',
        default: 'Standardinnstilling',
        deleteLanguage: 'Slett språk',
        youAreAboutToDelete:
            'Du er i ferd med å slette ordlyden i retningslinjene for varsling.',
        noGoBack: 'Nei, gå tilbake',
        restoreDefaultPolicy: 'Gjenopprett standardretningslinjer',
        youAreAbout:
            'Du er i ferd med å gjenopprette standardreglene for varsling!',
        anyModifications:
            'Eventuelle endringer i den engelske oversettelsen vil bli overstyrt.',
        restoreToDefault: 'Gjenopprett til standard',
        youHaveTheOption:
            'Du har mulighet til å gjøre rettelser i retningslinjene for varsling her. Når du gjør endringer i forhold til standardretningslinjene, må du oversette retningslinjene til de språkene du ønsker å støtte. Du kan bruke det automatiske oversettelsesverktøyet nedenfor og redigere dette.',
        autoTranslate: 'Automatisk oversettelse',
        addMoreLanguages: 'Legg til flere språk',
    },
    clientsPage: {
        clients: 'Kunde',
        allClients: 'Alle kunder',
        addClient: 'Legg til kunde',
    },
}

export default no

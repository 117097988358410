import produce from 'immer'
import { TranslateDescriptionAction } from '../actions'
import { TranslateDescriptionActionType } from '../action-types'

interface TranslateDescriptionState {
    translateDescriptionLoading: boolean
    translateDescriptionError: null | string
    translateDescriptionSuccess: boolean
}

const InitialState: TranslateDescriptionState = {
    translateDescriptionLoading: false,
    translateDescriptionError: null,
    translateDescriptionSuccess: false,
}

const reducer = produce(
    (
        state: TranslateDescriptionState = InitialState,
        action: TranslateDescriptionAction
    ) => {
        switch (action.type) {
            case TranslateDescriptionActionType.TRANSLATION_LOADING:
                state.translateDescriptionLoading = true
                state.translateDescriptionError = null
                state.translateDescriptionSuccess = false

                return state

            case TranslateDescriptionActionType.TRANSLATION_ERROR:
                state.translateDescriptionLoading = false
                state.translateDescriptionError = action.payload
                state.translateDescriptionSuccess = false

                return state

            case TranslateDescriptionActionType.TRANSLATION_SUCCESS:
                state.translateDescriptionLoading = false
                state.translateDescriptionError = null
                state.translateDescriptionSuccess = true
                return state

            default:
                return state
        }
    }
)

export default reducer

import styled from 'styled-components'
import { PrimaryLabel } from '../../styled/formElements'
import { useTranslation, useTypedSelector } from '../../../hooks'
import { OptionScroll, Dropdown, Select } from '../form/CountryCodeDropdown'
import {
    Identity,
    Language,
    PersonalInfoTypes,
} from '../../../state/state-types/IdentityTypes'
import LanguageDropdown from '../LanguageDropdown'
import {
    CountryFlag,
    DeleteIconAndFlagContainerWrapper,
    DeleteLanguagesIcon,
} from '../form/MiniPersonForm'

const LanguagesWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    z-index: 20;
`

const LanguagesSpoken = styled.div`
    border: 2px solid ${({ theme }) => theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 0.4rem;
    padding: 0 1rem;
    height: 4rem;
    width: 10rem;
    display: flex;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
`

const StyledDropDown = styled.div`
    width: 100%;
    position: relative;

    & ${Dropdown} {
        width: 100%;
    }

    & ${Select} {
        top: 4.5rem;
        width: 27rem;
        height: 16.6rem;
        left: 0;
    }

    & ${OptionScroll} {
        background: white;
        height: 8.9rem;
    }
`

interface SpokenLangugeOptionProps {
    personalInfo: PersonalInfoTypes
    handlerUpdatePersonalInfo: <Key extends keyof Identity>(
        field: Key,
        value: Identity[Key]
    ) => void
    onDeleteLanguage: (id: number) => void
    languagesToBeDeleted: any
}

const SpokenLanguageOption: React.FC<SpokenLangugeOptionProps> = ({
    personalInfo,
    handlerUpdatePersonalInfo,
    onDeleteLanguage,
    languagesToBeDeleted,
}) => {
    const translation = useTranslation()
    const { spokenLanguages } = useTypedSelector(
        (state) => state.spokenLanguages
    )

    return (
        <>
            <PrimaryLabel>{translation.clientView.languages}</PrimaryLabel>
            <LanguagesWrapper>
                <LanguagesSpoken>
                    {spokenLanguages &&
                        personalInfo.languages?.data.map(
                            (language: Language) => {
                                if (
                                    languagesToBeDeleted.includes(language.id)
                                ) {
                                    return null
                                }
                                return (
                                    <DeleteIconAndFlagContainerWrapper
                                        isEditing={true}
                                        key={language.id}
                                    >
                                        <DeleteLanguagesIcon
                                            onClick={() =>
                                                onDeleteLanguage(language.id)
                                            }
                                        />
                                        <CountryFlag
                                            src={language.flag}
                                            alt="country flag"
                                            isEditing={true}
                                        />
                                    </DeleteIconAndFlagContainerWrapper>
                                )
                            }
                        )}
                </LanguagesSpoken>

                {personalInfo.languages.data.length < 4 && (
                    <StyledDropDown>
                        <LanguageDropdown
                            selectDropdownLanguage={(language) => {
                                handlerUpdatePersonalInfo('languages', {
                                    data: [
                                        ...personalInfo.languages.data,
                                        language,
                                    ],
                                })
                            }}
                        />
                    </StyledDropDown>
                )}
            </LanguagesWrapper>
        </>
    )
}

export default SpokenLanguageOption

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    useActions,
    useIsPartner,
    useTranslation,
    useTypedSelector,
} from '../../../../hooks'
import { Card } from '../../../styled/cards'
import { HeadingSecondary } from '../../../styled/typography'
import MyAccount from './frames/MyAccount'
import PaymentDeitails from './frames/PaymentDeitails'
import Payment from './frames/Payment'
import Organization from './frames/Organization'
import AutoReply from './frames/AutoReply'

type CurrentFrameNameType =
    | 'my-account'
    | 'organization'
    | 'users'
    | 'payment-details'
    | 'payment-methods'
    | 'auto-reply'

const Container = styled.div`
    width: 100%;
    padding: 4rem;
    position: relative;
`

const FlexContainer = styled.div`
    display: flex;
    gap: 2.2rem;
    padding-top: 2rem;
`

const LeftContainer = styled.div`
    width: 25%;
`

const RightContainer = styled.div`
    width: 75%;
`

const NavigationContainer = styled.div`
    height: 46rem;
    position: relative;
`

interface IsActive {
    isActive: boolean
}

const NavigationLink = styled.div<IsActive>`
    height: 6.2rem;
    width: 100%;
    border-bottom: ${({ theme, isActive }) =>
        isActive ? `2px solid ${theme.blue}` : `1px solid ${theme.primary}10`};

    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;

    :hover {
        background: ${({ theme }) => `${theme.primary}05`};
    }
`

const NavigationLinkText = styled.span<IsActive>`
    font-size: 1.8rem;
    color: ${({ theme }) => theme.primary};
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    margin-left: 1.4rem;
`

const LogOutLink = styled.span`
    cursor: pointer;

    font-size: 1.8rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    position: absolute;
    bottom: 2rem;
    left: 1.4rem;
    transition: transform 0.2s;

    :hover {
        transform: scale(1.02);
    }
`

const SettingsPage: React.FC = () => {
    const { getSelf, getAllCaseworkers, logout } = useActions()
    const { user, organization, caseworkers } = useTypedSelector(
        (state) => state.caseworker
    )

    const authUser = useTypedSelector((state) => state.auth.user)
    const { isTrialExpired } = useTypedSelector((state) => state.payment)

    const isPartner = useIsPartner()

    const translation = useTranslation()

    const [currentFrame, setCurrentFrame] = useState(
        isTrialExpired ? <Payment /> : <MyAccount />
    )
    const [currentFrameName, setCurrentFrameName] =
        useState<CurrentFrameNameType>(
            isTrialExpired ? 'payment-methods' : 'my-account'
        )

    useEffect(() => {
        !user && getSelf()
        if (isPartner && organization) {
            caseworkers.length === 0 && getAllCaseworkers(organization.id)
        }
        // eslint-disable-next-line
    }, [organization])

    useEffect(() => {
        if (currentFrameName === 'my-account') {
            setCurrentFrame(<MyAccount />)
        } else if (currentFrameName === 'organization') {
            setCurrentFrame(<Organization />)
        } else if (currentFrameName === 'payment-methods') {
            setCurrentFrame(<Payment />)
        } else if (currentFrameName === 'payment-details') {
            setCurrentFrame(<PaymentDeitails />)
        } else if (currentFrameName === 'auto-reply') {
            setCurrentFrame(<AutoReply />)
        }

        // eslint-disable-next-line
    }, [currentFrameName])

    return (
        <Container>
            <HeadingSecondary>
                {translation.settingsPartner.headlineSettings}
            </HeadingSecondary>
            <FlexContainer>
                <LeftContainer>
                    <NavigationContainer>
                        <Card>
                            <NavigationLink
                                isActive={currentFrameName === 'my-account'}
                                onClick={() =>
                                    setCurrentFrameName('my-account')
                                }
                            >
                                <NavigationLinkText
                                    isActive={currentFrameName === 'my-account'}
                                >
                                    {translation.settingsPartner.myAccount}
                                </NavigationLinkText>
                            </NavigationLink>
                            <NavigationLink
                                isActive={currentFrameName === 'auto-reply'}
                                onClick={() =>
                                    setCurrentFrameName('auto-reply')
                                }
                            >
                                <NavigationLinkText
                                    isActive={currentFrameName === 'auto-reply'}
                                >
                                    {translation.settingsPartner.automaticReply}
                                </NavigationLinkText>
                            </NavigationLink>
                            {isPartner && (
                                <NavigationLink
                                    isActive={
                                        currentFrameName === 'organization'
                                    }
                                    onClick={() =>
                                        setCurrentFrameName('organization')
                                    }
                                >
                                    <NavigationLinkText
                                        isActive={
                                            currentFrameName === 'organization'
                                        }
                                    >
                                        {translation.reusable.organization}
                                    </NavigationLinkText>
                                </NavigationLink>
                            )}
                            {isPartner && (
                                <NavigationLink
                                    isActive={currentFrameName === 'users'}
                                    onClick={() => setCurrentFrameName('users')}
                                >
                                    <NavigationLinkText
                                        isActive={currentFrameName === 'users'}
                                    >
                                        {translation.settingsPartner.users}
                                    </NavigationLinkText>
                                </NavigationLink>
                            )}
                            {authUser?.roles.includes('client') && (
                                <NavigationLink
                                    isActive={
                                        currentFrameName === 'payment-methods'
                                    }
                                    onClick={() =>
                                        setCurrentFrameName('payment-methods')
                                    }
                                >
                                    <NavigationLinkText
                                        isActive={
                                            currentFrameName ===
                                            'payment-methods'
                                        }
                                    >
                                        {translation.settingsPartner.billing}
                                    </NavigationLinkText>
                                </NavigationLink>
                            )}
                            {/* <NavigationLink
                                isActive={currentFrameNumber === 4}
                                onClick={() => setCurrentFrameNumber(4)}
                            >
                                <NavigationLinkText
                                    isActive={currentFrameNumber === 4}
                                >
                                    {translation.settingsPartner.invoices}
                                </NavigationLinkText>
                            </NavigationLink> */}
                            <LogOutLink onClick={logout}>
                                {translation.settingsPartner.logOut}
                            </LogOutLink>
                        </Card>
                    </NavigationContainer>
                </LeftContainer>
                <RightContainer>{currentFrame}</RightContainer>
            </FlexContainer>
        </Container>
    )
}

export default SettingsPage

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../../../hooks'
import MessageForm from '../../../../../../reusable/message-form/MessageForm'
import Messages, {
    MessageInterface,
} from '../../../../../../reusable/messages/Messages'
import { FiMail } from 'react-icons/fi'
import Spinner from '../../../../../../layout/spinner/Spinner'
import TranslateButton from '../../../../../../reusable/buttons/translate-button/TranslateButton'
import { StreamedFile } from '../../../../../whistleblower/new-case/NewCasePage'

const MessagesLoading = styled.div`
    height: 32.4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const NoMessagesContainer = styled.div`
    height: 31.4rem;
    padding-top: 13.4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    text-align: center;
    flex-direction: column;
`

const NoMessagesText = styled.p`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.4rem;
`

const MailIcon = styled(FiMail)`
    color: ${({ theme }) => theme.greyText};
    font-size: 3rem;
`

interface MessagesFrameProps {
    isLoading?: boolean
}

const MessagesFrame: React.FC<MessagesFrameProps> = ({ isLoading = false }) => {
    const translation = useTranslation()
    const componentMounted = useRef(true)

    const { caseworkerAddMessage } = useActions()

    const { openReport, openReportMessages, addMessageLoading } =
        useTypedSelector((state) => state.caseworkerOpenReport)

    const { translateTextLoading, translateTextError } = useTypedSelector(
        (state) => state.translateText
    )

    const { selectedTranslation } = useTypedSelector(
        (state) => state.translation
    )

    const { translateToDefaultLang } = useActions()

    const [message, setMessage] = useState('')
    const [files, setFiles] = useState<StreamedFile[]>([])
    const [isTranslateBtnActive, setIsTranslateBtnActive] = useState(false)
    const [translatedMessages, setTranslatedMessages] = useState<
        MessageInterface[]
    >([])

    const handleAddMessage = async () => {
        await caseworkerAddMessage(openReport.id, message, files)
    }

    const isMessageFromReporter = openReportMessages.some(
        (message) => message.source === 'reporter'
    )

    const translateMessagesFromReporter = async () => {
        try {
            const translatedContent = await Promise.all(
                openReportMessages.map(async (message) => {
                    if (message.source === 'reporter') {
                        const translatedContent = await translateToDefaultLang(
                            message.content,
                            selectedTranslation,
                            'caseworker'
                        )
                        return {
                            ...message,
                            content: translatedContent.toString(),
                        }
                    }

                    return message
                })
            )
            setTranslatedMessages(translatedContent)
        } catch (error) {
            translateTextError && setTranslatedMessages(openReportMessages)
        }
    }

    //do not make API request if number of messages is not changed
    useEffect(() => {
        const isNewMessageReceived =
            translatedMessages.length !== openReportMessages.length

        if (isTranslateBtnActive && isNewMessageReceived) {
            translateMessagesFromReporter()
        }
        return () => {
            componentMounted.current = false
        }
        // eslint-disable-next-line
    }, [isTranslateBtnActive])

    //display original messages instead of translated when new message has been sent
    useEffect(() => {
        const isNewMessageSent =
            openReportMessages.length > translatedMessages?.length

        if (isTranslateBtnActive && isNewMessageSent) {
            setIsTranslateBtnActive(false)
        }
        // eslint-disable-next-line
    }, [translatedMessages, openReportMessages])

    return (
        <>
            <>
                {isLoading || translateTextLoading ? (
                    <MessagesLoading>
                        <Spinner />
                    </MessagesLoading>
                ) : (
                    <>
                        {openReportMessages.length > 0 ||
                        openReport?.forwarded_at ? (
                            <>
                                <Messages
                                    messages={openReportMessages}
                                    userType="caseworker"
                                    height={
                                        isMessageFromReporter ? '25.2rem' : ''
                                    }
                                    isTranslateBtnActive={isTranslateBtnActive}
                                    translatedMessages={translatedMessages}
                                />
                                {isMessageFromReporter && (
                                    <TranslateButton
                                        isActive={isTranslateBtnActive}
                                        handleClick={() =>
                                            setIsTranslateBtnActive(
                                                !isTranslateBtnActive
                                            )
                                        }
                                        isLoading={translateTextLoading}
                                    />
                                )}
                            </>
                        ) : (
                            <NoMessagesContainer>
                                {/*<NoMessagesImage src={NoMessages} />*/}
                                <MailIcon />
                                <NoMessagesText>
                                    {
                                        translation.caseworkerOpenReport
                                            .noMessages
                                    }
                                </NoMessagesText>
                            </NoMessagesContainer>
                        )}
                    </>
                )}
            </>
            <MessageForm
                message={message}
                setMessage={setMessage}
                files={files}
                setFiles={setFiles}
                handleAddMessage={handleAddMessage}
                isLoading={addMessageLoading}
                isReportClosed={openReport?.closed_at}
            />
        </>
    )
}

export default MessagesFrame

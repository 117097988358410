import axios from 'axios'
import { CaseworkerActionType, LineOfContactActionType } from '../action-types'
import config from '../../config'
import { Dispatch } from 'redux'

const { apiUrl } = config

export const setCaseworkerLoading = () => {
    return {
        type: CaseworkerActionType.SET_CASEWORKER_LOADING,
    }
}

export const setCaseworkerError = (message: string) => {
    return {
        type: CaseworkerActionType.SET_CASEWORKER_ERROR,
        payload: {
            message,
        },
    }
}

export const getOrganizations = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setCaseworkerLoading())

    try {
        const res = await axios.get(`${apiUrl}/v1/partners/${id}/clients`)

        if (res.status !== 201 && res.status !== 200 && res.status !== 204) {
            return dispatch(
                setCaseworkerError(
                    'Something went wrong fetching the clients please try again later'
                )
            )
        }

        return dispatch({
            type: CaseworkerActionType.GET_ORGANIZATIONS,
            payload: res.data.data,
        })
    } catch (error) {
        return dispatch(
            setCaseworkerError(
                'Something went wrong fetching the clients please try agin later'
            )
        )
    }
}

export const getOwnReports = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setCaseworkerLoading())
    try {
        const res = await axios.get(
            `${apiUrl}/v1/me/incidents?status=new,action+required,awaiting+response`
        )

        if (res.status !== 201 && res.status !== 200 && res.status !== 204) {
            return dispatch(
                setCaseworkerError('Something went wrong fetching the reports')
            )
        }

        return dispatch({
            type: CaseworkerActionType.GET_OWN_REPORTS,
            payload: res.data.data,
        })
    } catch (error) {
        return dispatch(
            setCaseworkerError('Something went wrong fetching the reports')
        )
    }
}

export const getAllReports = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setCaseworkerLoading())

    try {
        const res = await axios.get(
            `${apiUrl}/v1/partners/${id}/incidents?with=organization,assigned&status=new,action+required,awaiting+response`
        )

        if (res.status !== 201 && res.status !== 200 && res.status !== 204) {
            return dispatch(
                setCaseworkerError('Something went wrong fetching the reports')
            )
        }

        return dispatch({
            type: CaseworkerActionType.GET_ALL_REPORTS,
            payload: res.data.data,
        })
    } catch (error) {
        return dispatch(
            setCaseworkerError('Something went wrong fetching the reports')
        )
    }
}

export const getClosedReports =
    (partnerId: number | null = null) =>
    async (dispatch: Dispatch) => {
        dispatch(setCaseworkerLoading())

        let url

        if (!partnerId) {
            url = `${apiUrl}/v1/me/incidents?status=solved,rejected&with=trashed`
        } else {
            url = `${apiUrl}/v1/partners/${partnerId}/incidents?with=organization,assigned&status=forwarded`
        }

        try {
            const res = await axios.get(url)

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                return dispatch(
                    setCaseworkerError(
                        'Something went wrong fetching the reports'
                    )
                )
            }

            return dispatch({
                type: CaseworkerActionType.GET_CLOSED_REPORTS,
                payload: res.data.data,
            })
        } catch (error) {
            return dispatch(
                setCaseworkerError('Something went wrong fetching the reports')
            )
        }
    }

export const getClientInformation =
    (partnerId: number, id: number) => async (dispatch: Dispatch) => {
        dispatch(setCaseworkerLoading())

        try {
            const res = await axios.get(
                `${apiUrl}/v1/partners/${partnerId}/clients/${id}?with=contact_person,incidents,contact_lines`
            )

            if (res.status !== 200) {
                return dispatch(
                    setCaseworkerError('Something went wrong please reload')
                )
            }

            dispatch({
                type: CaseworkerActionType.GET_CLIENT_INFORMATION,
                payload: res.data.data,
            })

            dispatch({
                type: LineOfContactActionType.GET_LINE_OF_CONTACTS,
                payload: res.data.data.contact_lines.data,
            })
        } catch (error) {
            return dispatch(
                setCaseworkerError('Something went wrong please reload')
            )
        }
    }

export const getSelf =
    () => async (dispatch: Dispatch, getState: () => any) => {
        dispatch(setCaseworkerLoading())

        const state: any = getState()

        const user = state.auth.user

        let includes

        // At the moment the plan information is only relevant for the client
        if (user.roles.includes('client')) {
            includes = 'organization,plan'
        } else {
            includes = 'organization'
        }

        try {
            const res = await axios.get(
                `${apiUrl}/v1/auth/identity?with=${includes}`
            )

            if (res.status !== 200) {
                return dispatch(
                    setCaseworkerError('Something went wrong please reload')
                )
            }

            return dispatch({
                type: CaseworkerActionType.GET_SELF,
                payload: res.data.data,
            })
        } catch (error) {
            return dispatch(
                setCaseworkerError('Something went wrong please reload')
            )
        }
    }

export const getSelfOrganization = () => async (dispatch: Dispatch) => {
    dispatch(setCaseworkerLoading())

    try {
        const res = await axios.get(
            `${apiUrl}/v1/partner/partners/organization`
        )

        if (res.status !== 200) {
            return dispatch(
                setCaseworkerError('Something went wrong please reload')
            )
        }

        return dispatch({
            type: CaseworkerActionType.GET_SELF_ORGANIZATION,
            payload: res.data.payload,
        })
    } catch (error) {
        return dispatch(
            setCaseworkerError('Something went wrong please reload')
        )
    }
}

export const addCaseworker =
    (partnerId: number, payload: any) => async (dispatch: Dispatch) => {
        dispatch(setCaseworkerLoading())

        try {
            const res = await axios.post(
                `${apiUrl}/v1/partners/${partnerId}/caseworkers`,
                payload
            )

            if (res.status !== 201) {
                if (typeof res.data.data.errors !== 'undefined') {
                    dispatch({
                        type: CaseworkerActionType.SHOW_CREATE_CASEWORKER_VALIDATION_ERRORS,
                        payload: res.data.data.errors,
                    })
                } else {
                    dispatch(
                        setCaseworkerError('Something went wrong please reload')
                    )
                }

                return false
            }

            const user = res.data.data

            dispatch({
                type: CaseworkerActionType.ADD_CASEWORKER,
                payload: user,
            })

            return true
        } catch (error: any) {
            if (typeof error.response.data.errors !== 'undefined') {
                dispatch({
                    type: CaseworkerActionType.SHOW_CREATE_CASEWORKER_VALIDATION_ERRORS,
                    payload: error.response.data.errors,
                })
            } else {
                dispatch(
                    setCaseworkerError('Something went wrong please reload')
                )
            }

            return false
        }
    }

export const getAllCaseworkers =
    (partnerId: number) => async (dispatch: Dispatch) => {
        dispatch({
            type: CaseworkerActionType.SET_FETCH_CASEWORKERS_LOADING,
        })

        try {
            const res = await axios.get(
                `${apiUrl}/v1/partners/${partnerId}/caseworkers/`
            )

            if (res.status !== 200) {
                return dispatch(
                    setCaseworkerError('Something went wrong please reload')
                )
            }

            return dispatch({
                type: CaseworkerActionType.GET_ALL_CASEWORKERS,
                payload: res.data.data,
            })
        } catch (error) {
            return dispatch(
                setCaseworkerError('Something went wrong please reload')
            )
        }
    }

export const getSingleCaseworker =
    (id: number) => async (dispatch: Dispatch) => {
        dispatch(setCaseworkerLoading())

        try {
            const res = await axios.get(`${apiUrl}/v1/users/${id}`)

            if (res.status !== 200) {
                return dispatch(
                    setCaseworkerError('Something went wrong please reload')
                )
            }

            return dispatch({
                type: CaseworkerActionType.GET_SINGLE_CASEWORKER,
                payload: res.data.data,
            })
        } catch (error) {
            return dispatch(
                setCaseworkerError('Something went wrong please reload')
            )
        }
    }

export const searchAllReports =
    (searchValue: string) => (dispatch: Dispatch) => {
        dispatch({
            type: CaseworkerActionType.SEARCH_ALL_CASES,
            payload: {
                searchValue,
            },
        })

        return dispatch({
            type: CaseworkerActionType.FILTER_ALL_CASES,
        })
    }

export const addStatusFilter = (status: string) => (dispatch: Dispatch) => {
    dispatch({
        type: CaseworkerActionType.ADD_STATUS_FILTER,
        payload: {
            status,
        },
    })

    return dispatch({
        type: CaseworkerActionType.FILTER_ALL_CASES,
    })
}

export const addCaseTypeFilter = (type: string) => (dispatch: Dispatch) => {
    dispatch({
        type: CaseworkerActionType.ADD_CASE_TYPE_FILTER,
        payload: {
            type,
        },
    })

    return dispatch({
        type: CaseworkerActionType.FILTER_ALL_CASES,
    })
}

export const removeAllFilters = () => (dispatch: Dispatch) => {
    dispatch({
        type: CaseworkerActionType.REMOVE_ALL_FILTERS,
    })
}

export const searchClosedReports = (searchValue: string) => {
    return {
        type: CaseworkerActionType.SEARCH_CLOSED_REPORTS,
        payload: {
            searchValue,
        },
    }
}

export const addCaseworkerValidation =
    (user: any) => async (dispatch: Dispatch) => {
        dispatch(setCaseworkerLoading())

        const payload = {
            name: user.name,
            first_name: 'X',
            last_name: 'X',
            phone: user.phone,
            email: user.email,
        }

        try {
            const res = await axios.post(
                `${apiUrl}/v1/auth/validate-personal-information`,
                payload
            )
            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                dispatch({
                    type: CaseworkerActionType.ADD_CASEWORKER_VALIDATION,
                    payload: res.data.errors,
                })

                return false
            }

            return true
        } catch (error: any) {
            dispatch({
                type: CaseworkerActionType.ADD_CASEWORKER_VALIDATION,
                payload: error.response.data.errors,
            })
            return false
        }
    }

export const clearCaseworkerValidationErrors = () => ({
    type: CaseworkerActionType.CLEAR_CASEWORKER_VALIDATION_ERRORS,
})

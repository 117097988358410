import produce from 'immer'
import { HrOnIntegrationActions } from '../actions/hrOnIntegrationAction'
import { HrOnIntegrationActionType } from '../action-types'

export type HrOnIntegrationType = 'login' | 'register'

export interface FlashMessage {
    type: 'success' | 'error'
    message: string
}

export interface IntegrationInterface {
    id: number
    name: null | string
    client_id: null | string
    integration_type: null | HrOnIntegrationType
    employee_id: null | string
    is_integration_confirmed: boolean
    uuid: null | string
    redirect_url: null | string
}

interface HrOnIntegrationState {
    activeIntegration: null | IntegrationInterface
    flashMessage: null | FlashMessage
}

const initialState: HrOnIntegrationState = {
    activeIntegration: null,
    flashMessage: null,
}

const reducer = produce(
    (
        state: HrOnIntegrationState = initialState,
        action: HrOnIntegrationActions
    ) => {
        switch (action.type) {
            case HrOnIntegrationActionType.GET_INTEGRATION_DATA:
                state.activeIntegration = action.payload

                return state

            case HrOnIntegrationActionType.APPROVE_INTEGRATION:
                if (!state.activeIntegration) {
                    return state
                }

                state.activeIntegration.is_integration_confirmed = true

                return state

            case HrOnIntegrationActionType.SET_FLASH_MESSAGE:
                state.flashMessage = action.payload

                return state

            case HrOnIntegrationActionType.CLEAR_FLASH_MESSAGE:
                state.flashMessage = null

                return state

            default:
                return state
        }
    }
)

export default reducer
